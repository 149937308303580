import {Component, OnInit, ViewChild} from '@angular/core';
import {UserInformationModel} from '../models/UserInformation.model';
import {StateModel} from '../models/State.model';
import {FcicadminService} from '../services/fcicadmin.service';
import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import {Message} from 'primeng/api';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {NgForm} from '@angular/forms';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {ApiError} from '../models/ApiError';
import {environment} from '../../environments/environment';

@Component({
	selector: 'update-profile',
	templateUrl: './update-profile.component.html',
	styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit {
	showSpinner: boolean = false;
	reportMsgs: Message[] = [];
	salutationDropdown = [];
	countryDropdown = [];
	stateDropdown = [];
	public stateresultset: StateModel[];
	UserInformation: UserInformationModel;
	wslID: string;
	FirstcustomerName: string;
	MiddlecustomerName: string;
	LastcustomerName: string;
	customerEmailAddressText: string;
	customerAliseEmailAddressText: string;
	customerPhoneNumber: string;
	customerFaxNumber: string;
	customerTitle: string;
	Mail: string;
	Continued: string;
	City: string;
	State: string;
	Postal: string;
	salutationDropdownValue: string;
	countryDropdownValue: string;
	fincodeDropdownValue: string;
	updateID: string;
	organizationalUnit: string;
	finCodes;
	countryId: string;
	userID: string;
	isEnglish: string;
	lblFins: string;
	lblSalutation: string;
	lblFName: string;
	lblMiddleI: string;
	lblLastName: string;
	lblPhone: string;
	lblFax: string;
	lblEMail: string;
	lblAliasEMail: string;
	lblTitle: string;
	lblAddress1: string;
	lblAddress2: string;
	lblCity: string;
	lblStProv: string;
	lblPostalCode: string;
	lblCountry: string;
	lblSubmit: string;
	lblReset: string;
	lblHeader: string;
	lblLink: string;
	lblUpdate: string;
	lblUpdateFail: string;
	lblSuccess: string;
	lblFailed: string;
	lblMail: string;
	lang: string;
	site: string;
	countryCan: string;
	langCan: string;
	siteCan: string;
	errorTest: ApiError;
	formSubmitted = false;
	aliasEmail: boolean = true;
	spsEndpoint: string;

	errorMessage: string;
	public displayMessage: boolean = false;

	public Noauthorized: boolean = false;
	public showWslDetails: boolean = false;
	public USFin = false;
	public CANFin = false;
	isFirstNameInValid: boolean = false;
	isLastNameInValid: boolean = false;
	isMiddleNameInValid: boolean = false;
	isPhoneNoInValid: boolean = false;
	isFaxInValid: boolean = false;
	isUSZipcodeInValid: boolean = false;
	isCanZipcodeInValid: boolean = false;
	@ViewChild('profileUpdate',{static:false}) profileUpdate: NgForm;

	constructor(private fcicadminService: FcicadminService, private messageService: MessageService, private confirmationService: ConfirmationService, private route: ActivatedRoute) {
	}

	labelMessage() {
		if (this.isEnglish === '1') {
			this.aliasEmail = false;
			this.lblFins = 'N.I.P.(s)';
			this.lblSalutation = 'Salutation';
			this.lblFName = 'Prénom';
			this.lblMiddleI = 'Initiale';
			this.lblLastName = 'Nom de famille';
			this.lblPhone = 'Téléphone';
			this.lblFax = 'Télécopieur';
			this.lblEMail = 'Courrier électronique';
			this.lblAliasEMail = 'Veuillez taper à nouveau votre email';
			this.lblTitle = 'Titre';
			this.lblAddress1 = 'Adresse postale';
			this.lblAddress2 = '(suite)';
			this.lblCity = 'Ville';
			this.lblStProv = 'Province/État';
			this.lblPostalCode = 'Code postal';
			this.lblCountry = 'Pays';
			this.lblSubmit = 'Soumettre';
			this.lblReset = 'Remettre';
			this.lblHeader = 'Réviser mon profil';
			this.lblLink = 'Modifier Mes préférences pour communication';
			this.lang = 'fr';
			this.lblUpdate = 'Votre profil a été révisé.';
			this.lblUpdateFail = 'Les informations de votre profil mises à jour échouent.';
			this.lblSuccess = 'Succès';
			this.lblFailed = 'Échoué';
			this.lblMail = 'Informations postales';
		} else if (this.isEnglish === '2') {
			this.aliasEmail = true;
			this.lblFins = 'FIN(S)';
			this.lblSalutation = 'Salutation';
			this.lblFName = 'First Name';
			this.lblMiddleI = 'Middle Initial';
			this.lblLastName = 'Last Name';
			this.lblPhone = 'Phone';
			this.lblFax = 'Fax';
			this.lblEMail = 'Email';
			this.lblAliasEMail = 'Please type in your email again';
			this.lblTitle = 'Professional Title';
			this.lblAddress1 = 'Address 1';
			this.lblAddress2 = 'Address 2';
			this.lblCity = 'City';
			this.lblStProv = 'State/Prov.';
			this.lblPostalCode = 'Zip/Postal Code';
			this.lblCountry = 'Country';
			this.lblSubmit = 'Submit';
			this.lblReset = 'Reset';
			this.lblHeader = 'Update My Profile';
			this.lblLink = 'Change my Communication Preferences';
			this.lang = '';
			this.lblUpdate = 'Your personal profile has been updated.';
			this.lblUpdateFail = 'Your Profile information updated fails.';
			this.lblSuccess = 'Success';
			this.lblFailed = 'Failed';
			this.lblMail = 'Mailing Information';
		} else {
			this.aliasEmail = true;
			this.lblFins = 'FIN(S)';
			this.lblSalutation = 'Salutation';
			this.lblFName = 'First Name';
			this.lblMiddleI = 'Middle Initial';
			this.lblLastName = 'Last Name';
			this.lblPhone = 'Phone';
			this.lblFax = 'Fax';
			this.lblEMail = 'Email';
			this.lblAliasEMail = 'Please type in your email again';
			this.lblTitle = 'Professional Title';
			this.lblAddress1 = 'Address 1';
			this.lblAddress2 = 'Address 2';
			this.lblCity = 'City';
			this.lblStProv = 'State/Prov.';
			this.lblPostalCode = 'Zip/Postal Code';
			this.lblCountry = 'Country';
			this.lblSubmit = 'Submit';
			this.lblReset = 'Reset';
			this.lblHeader = 'Update My Profile';
			this.lblLink = 'Change my Communication Preferences';
			this.lang = '';
			this.lblUpdate = 'Your personal profile has been updated.';
			this.lblUpdateFail = 'Your Profile information updated fails.';
			this.lblSuccess = 'Success';
			this.lblFailed = 'Failed';
			this.lblMail = 'Mailing Information';
		}
	}


	ngOnInit() {
		this.spsEndpoint = environment.spsEndpoint;
		window.location.href = this.spsEndpoint;

		this.userID = sessionStorage.getItem('userId');

		this.isEnglish = '0';
		this.site = 'USA';

		this.countryCan = this.route.snapshot.paramMap.get('site');
		this.langCan = this.route.snapshot.paramMap.get('language');

		if ((this.countryCan !== null) && (this.langCan !== null)) {
			if ((this.countryCan.toUpperCase() === 'CAN') && (this.langCan.toUpperCase() === 'FR')) {
				this.site = 'CAN';
				this.isEnglish = '1';
			} else if ((this.countryCan.toUpperCase() === 'CAN') && (this.langCan.toUpperCase() === 'ENG')) {
				this.site = 'CAN';
				this.isEnglish = '2';
			}
		}

		this.labelMessage();
		this.loadSalutation();
		this.loadCountry();
		this.loadUserInformation(this.userID);
		this.loadFinCode(this.userID);
		this.formSubmitted = false;
	}


	numberkeyPress(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;

	}

	showErrorMsg(errorMsg) {
		this.displayMessage = true;
		this.errorMessage = errorMsg;
	}

	loadSalutation() {
		this.showSpinner = true;
		let optionModelSalutation = [];
		optionModelSalutation.push({'label': 'Mr.', 'value': '1'});
		optionModelSalutation.push({'label': 'Mrs.', 'value': '2'});
		optionModelSalutation.push({'label': 'Ms.', 'value': '3'});
		this.salutationDropdown = optionModelSalutation;
	}


	loadCountry() {
		let optionModelCountry = [];
		optionModelCountry.push({'label': '-- Countries --', 'value': ''});
		optionModelCountry.push({'label': 'USA', 'value': 'USA'});
		optionModelCountry.push({'label': 'CAN', 'value': 'CAN'});
		this.countryDropdown = optionModelCountry;
	}


	loadFinCode(value) {
		if (value) {
			this.fcicadminService.getFincode(value.toUpperCase()).subscribe(data => {
				this.showSpinner = false;
				let fincode = [];
				for (const val of data['result']['finCode']) {
					fincode.push(val.finCode);
				}
				if (fincode && fincode.length > 0) {
					this.finCodes = fincode.join(', ');
				}
			});
		}
	}

	loadState(country) {
		let optionModelState = [];
		if (country === 'CAN' && this.isEnglish === '1') {
			this.CANFin = true;
			this.USFin  = false;
			optionModelState.push({'label': '-- Provinces --', 'value': ''});
			optionModelState.push({'label': 'AB - Alberta', 'value': 'AB'});
			optionModelState.push({'label': 'BC - Colombie-Britannique', 'value': 'BC'});
			optionModelState.push({'label': 'MB - Manitoba', 'value': 'MB'});
			optionModelState.push({'label': 'NB - Nouveau-Brunswick', 'value': 'NB'});
			optionModelState.push({'label': 'NF - Terre-Neuve-et-Labrador', 'value': 'NF'});
			optionModelState.push({'label': 'NS - Nouvelle-Écosse', 'value': 'NS'});
			optionModelState.push({'label': 'NV - Nunavut', 'value': 'NV'});
			optionModelState.push({'label': 'ON - Ontario', 'value': 'ON'});
			optionModelState.push({'label': 'PE - Île-du-Prince-Édouard', 'value': 'PE'});
			optionModelState.push({'label': 'PQ - Québec', 'value': 'PQ'});
			optionModelState.push({'label': 'SK - Saskatchewan', 'value': 'SK'});
			optionModelState.push({'label': 'NT - Territoires du Nord-Ouest', 'value': 'NT'});
			optionModelState.push({'label': 'YT - Yukon', 'value': 'YT'});
		} else if ((country === 'CAN' && this.isEnglish === '2') || (country === 'CAN' && this.isEnglish === '0')) {
			this.CANFin = true;
			this.USFin  = false;
			optionModelState.push({'label': '-- Provinces --', 'value': ''});
			optionModelState.push({'label': 'AB - Alberta', 'value': 'AB'});
			optionModelState.push({'label': 'BC - British Columbia', 'value': 'BC'});
			optionModelState.push({'label': 'MB - Manitoba', 'value': 'MB'});
			optionModelState.push({'label': 'NB - New Brunswick', 'value': 'NB'});
			optionModelState.push({'label': 'NF - Newfoundland and Labrador', 'value': 'NF'});
			optionModelState.push({'label': 'NS - Nova Scotia', 'value': 'NS'});
			optionModelState.push({'label': 'NV - Nunavut', 'value': 'NV'});
			optionModelState.push({'label': 'ON - Ontario', 'value': 'ON'});
			optionModelState.push({'label': 'PE - Prince Edward Island', 'value': 'PE'});
			optionModelState.push({'label': 'PQ - Quebec', 'value': 'PQ'});
			optionModelState.push({'label': 'SK - Saskatchewan', 'value': 'SK'});
			optionModelState.push({'label': 'NT - Northwest Territories', 'value': 'NT'});
			optionModelState.push({'label': 'YT - Yukon', 'value': 'YT'});
		} else if (country === 'USA') {
			this.CANFin = false;
			this.USFin  = true;
			optionModelState.push({'label': '-- States --', 'value': ''});
			optionModelState.push({'label': 'AL-Alabama', 'value': 'AL'});
			optionModelState.push({'label': 'AK-Alaska', 'value': 'AK'});
			optionModelState.push({'label': 'AZ-Arizona', 'value': 'AZ'});
			optionModelState.push({'label': 'AR-Arkansas', 'value': 'AR'});
			optionModelState.push({'label': 'CA-California', 'value': 'CA'});
			optionModelState.push({'label': 'CO-Colorado', 'value': 'CO'});
			optionModelState.push({'label': 'CT-Connecticut', 'value': 'CT'});
			optionModelState.push({'label': 'DC-Washington D.C.', 'value': 'DC'});
			optionModelState.push({'label': 'DE-Delaware', 'value': 'DE'});
			optionModelState.push({'label': 'FL-Florida', 'value': 'FL'});
			optionModelState.push({'label': 'GA-Georgia', 'value': 'GA'});
			optionModelState.push({'label': 'HI-Hawaii', 'value': 'HI'});
			optionModelState.push({'label': 'ID-Idaho', 'value': 'ID'});
			optionModelState.push({'label': 'IL-Illinois', 'value': 'IL'});
			optionModelState.push({'label': 'IN-Indiana', 'value': 'IN'});
			optionModelState.push({'label': 'IA-Iowa', 'value': 'IA'});
			optionModelState.push({'label': 'KS-Kansas', 'value': 'KS'});
			optionModelState.push({'label': 'KY-Kentucky', 'value': 'KY'});
			optionModelState.push({'label': 'LA-Louisiana', 'value': 'LA'});
			optionModelState.push({'label': 'ME-Maine', 'value': 'ME'});
			optionModelState.push({'label': 'MD-Maryland', 'value': 'MD'});
			optionModelState.push({'label': 'MA-Massachusetts', 'value': 'MA'});
			optionModelState.push({'label': 'MI-Michigan', 'value': 'MI'});
			optionModelState.push({'label': 'MN-Minnesota', 'value': 'MN'});
			optionModelState.push({'label': 'MS-Mississippi', 'value': 'MS'});
			optionModelState.push({'label': 'MO-Missouri', 'value': 'MO'});
			optionModelState.push({'label': 'MT-Montana', 'value': 'MT'});
			optionModelState.push({'label': 'NE-Nebraska', 'value': 'NE'});
			optionModelState.push({'label': 'NV-Nevada', 'value': 'NV'});
			optionModelState.push({'label': 'NH-New Hampshire', 'value': 'NH'});
			optionModelState.push({'label': 'NJ-New Jersey', 'value': 'NJ'});
			optionModelState.push({'label': 'NM-New Mexico', 'value': 'NM'});
			optionModelState.push({'label': 'NY-New York', 'value': 'NY'});
			optionModelState.push({'label': 'NC-North Carolina', 'value': 'NC'});
			optionModelState.push({'label': 'ND-North Dakota', 'value': 'ND'});
			optionModelState.push({'label': 'OH-Ohio', 'value': 'OH'});
			optionModelState.push({'label': 'OK-Oklahoma', 'value': 'OK'});
			optionModelState.push({'label': 'OR-Oregon', 'value': 'OR'});
			optionModelState.push({'label': 'PA-Pennsylvania', 'value': 'PA'});
			optionModelState.push({'label': 'PR-Puerto Rico', 'value': 'PR'});
			optionModelState.push({'label': 'RI-Rhode Island', 'value': 'RI'});
			optionModelState.push({'label': 'SC-South Carolina', 'value': 'SC'});
			optionModelState.push({'label': 'SD-South Dakota', 'value': 'SD'});
			optionModelState.push({'label': 'TN-Tennessee', 'value': 'TN'});
			optionModelState.push({'label': 'TX-Texas', 'value': 'TX'});
			optionModelState.push({'label': 'UT-Utah', 'value': 'UT'});
			optionModelState.push({'label': 'VT-Vermont', 'value': 'VT'});
			optionModelState.push({'label': 'VA-Virginia', 'value': 'VA'});
			optionModelState.push({'label': 'WA-Washington', 'value': 'WA'});
			optionModelState.push({'label': 'WV-West Virginia', 'value': 'WV'});
			optionModelState.push({'label': 'WI-Wisconsin', 'value': 'WI'});
			optionModelState.push({'label': 'WY-Wyoming', 'value': 'WY'});
		}

		this.stateDropdown = optionModelState;
	}

	loadUserInformation(value) {
		if (value) {
			this.fcicadminService.getUserInformation(value.toUpperCase()).subscribe(data => {
				if (data['result'] === null || data['result'] === '') {
					this.Noauthorized = true;
					this.showWslDetails = false;

				} else {
					data['result']['userInfo'].forEach(item => {
						this.wslID = item.wslID;
						this.FirstcustomerName = item.firstName;
						this.MiddlecustomerName = item.middleinitial;
						this.LastcustomerName = item.lastName;
						this.customerEmailAddressText = item.email;
						this.customerAliseEmailAddressText = item.email;
						this.customerPhoneNumber = item.phone;
						this.customerFaxNumber = item.fax;
						this.customerTitle = item.title;
						this.Mail = item.address1PostBox;
						this.Continued = item.address2;
						this.City = item.city;
						this.State = item.state;
						this.Postal = item.postal;
						this.salutationDropdownValue = item.salutation;
						this.countryDropdownValue = item.countryCode;
						this.updateID = item.updateID;
						this.organizationalUnit = item.organizationalUnit;
						this.countryId = item.countryCode;
						this.loadState(this.countryId);
					});

					this.Noauthorized = false;
					this.showWslDetails = true;

				}

			},
				(error: ApiError) => {
					this.errorTest = error;
				});
		}
	}


	submitUser() {
		this.formSubmitted = true;
		if (this.aliasEmail && (this.customerEmailAddressText !== this.customerAliseEmailAddressText)) {
			return false;
		}
		if (this.isFirstNameInValid || this.isMiddleNameInValid || this.isLastNameInValid || this.isPhoneNoInValid ||
			this.isFaxInValid) {
			return false;
		}

		if (this.CANFin) {
			if (this.isCanZipcodeInValid) {
				return false;
			}
		}

		if (this.USFin) {
			if (this.isUSZipcodeInValid) {
				return false;
			}
		}

		if (this.profileUpdate.valid && !this.isFirstNameInValid) {
			this.showSpinner = true;
			this.UserInformation = new UserInformationModel();
			this.UserInformation.wslID = this.wslID;
			this.UserInformation.firstName = this.FirstcustomerName;
			this.UserInformation.middleinitial = this.MiddlecustomerName;
			this.UserInformation.lastName = this.LastcustomerName;
			this.UserInformation.title = this.customerTitle;
			this.UserInformation.salutation = this.salutationDropdownValue;
			this.UserInformation.address1PostBox = this.Mail;
			this.UserInformation.address2 = this.Continued;
			this.UserInformation.city = this.City;
			this.UserInformation.state = this.State;
			this.UserInformation.postal = this.Postal;
			this.UserInformation.phone = this.customerPhoneNumber;
			this.UserInformation.fax = this.customerFaxNumber;
			this.UserInformation.email = this.customerEmailAddressText;
			this.UserInformation.updateID = this.updateID;
			this.UserInformation.countryCode = this.countryDropdownValue;

			this.fcicadminService.updateUserInformation(this.UserInformation).subscribe(updatedUser => {
				this.showSpinner = false;
				this.showErrorMsg(this.lblUpdate);
			}, error1 => {
				this.showSpinner = false;
				this.showErrorMsg(this.lblUpdateFail);
			});
		}
		return false;
	}

	onChangeWslid() {
		this.loadSalutation();
		this.loadCountry();
		this.loadState(this.countryId);
		this.loadUserInformation(this.userID);
		this.loadFinCode(this.userID);
	}

	checkSpecialCharacter(evt: Event, from) {
		const regexString = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]/;
		const regex = new RegExp(regexString);
		if (evt.toString()) {
			if (regex.test(evt.toString())) {
				if (from === 'firstname') {
					this.isFirstNameInValid = true;
				} else if (from === 'middlename') {
					this.isMiddleNameInValid = true;
				} else if (from === 'lastname') {
					this.isLastNameInValid = true;
				}
			} else {
				if (from === 'firstname') {
					this.isFirstNameInValid = false;
				} else if (from === 'middlename') {
					this.isMiddleNameInValid = false;
				} else if (from === 'lastname') {
					this.isLastNameInValid = false;
				}
			}
		} else {
			this.isFirstNameInValid = false;
			this.isMiddleNameInValid = false;
			this.isLastNameInValid = false;
		}
	}

	checkPhoneNumandFax(evt: Event, from) {
		const regexString = /[ A-Za-z!@#$%^&*_+\=\[\]{};':"\\|,.<>\/?]/;
		const regex = new RegExp(regexString);
		if (evt.toString()) {
			if (regex.test(evt.toString())) {
				if (from === 'phone') {
					this.isPhoneNoInValid = true;
				} else if (from === 'fax') {
					this.isFaxInValid = true;
				}
			} else {
				this.isPhoneNoInValid = false;
				this.isFaxInValid = false;
			}
		} else {
			this.isPhoneNoInValid = false;
			this.isFaxInValid = false;
		}
	}

	checkUSZipCode(evt) {
		const regexString = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\?A-Za-z]/;
		const regex = new RegExp(regexString);
		if (evt.toString()) {
			if (regex.test(evt.toString())) {
				this.isUSZipcodeInValid = true;
			} else {
				this.isUSZipcodeInValid = false;
			}
		} else {
			this.isUSZipcodeInValid = false;
		}
	}

	checkCanZipCode(evt) {
		const regexString = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\?]/;
		const regex = new RegExp(regexString);
		if (evt.toString()) {
			if (regex.test(evt.toString())) {
				this.isCanZipcodeInValid = true;
			} else {
				this.isCanZipcodeInValid = false;
			}
		} else {
			this.isCanZipcodeInValid = false;
		}
	}

}
