import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/index';
import {BatchOasis} from '../models/BatchOasis';


@Injectable()
export class BatchOasisService {
	_accessToken: string = sessionStorage.getItem('encodedAccessToken');
	fwsUrl = environment.APIEndpoint;
	batchOasisService=environment.batchOasisService;

	private options = {
		headers: new HttpHeaders({
			'Authorization': 'Bearer ' + this._accessToken,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'origin': window.location.origin
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient) {
	}

	getBatchOasisReport(recallRequestDetail): Observable<BatchOasis[]> {
		const opt = {
			headers: new HttpHeaders({
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}),
			withCredentials: true
		};
		return this.http.post<BatchOasis[]>(this.batchOasisService + 'api/batch-oasis-report', recallRequestDetail, this.options);
	}
	getDownloadExcelForBatchOasis(recallRequestDetail) {
		const headers = new HttpHeaders({
			'authorization': 'bearer ' + this._accessToken
		});
		return this.http.post(this.batchOasisService + 'api/batch-oasis-report/excel', recallRequestDetail,  {
			headers: headers,
			responseType: 'blob'

	});
}

}
