import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {DatePipe} from '@angular/common';
import {ErrorResponseData} from '../../app/models/ErrorResponseData';
import {ExportPDFService} from '../../app/services/exportPDF/export-pdf.service';
import {FinNamDetailService} from '../../app/services/finNamDetails.service';
import { AccountManagerSummaryArray } from '../../app/models/AccountManagerSummaryArray.model';
import { CountryEnum } from '../../app/common/countryenum.model';
import { saveAs } from 'file-saver';

@Component({
	selector: 'fin-nam-details',
	templateUrl: './fin-nam-details.component.html',
	styleUrls: ['./fin-nam-details.component.css']
})
export class FinNamDetailsComponent implements OnInit {

	cols: any[];
	accountManagerList: SelectItem[];
	todayDate = new Date();
	toDate: string;
	reportData: any[];
	finalReportData: any[];
	showReport = false;
	finEntitlementColheader = [];
	finEntitlementColheaderLabel = [];
	showSpinner: Boolean = false;
	selectedAccountManager: string;
	headerlength: number;
	errorResponse: ErrorResponseData;
	errorMessage: string;
	displayMessage: boolean;
	finDetails: any[];
	combineDetails: any[];
	Pagerows: number = 10;
	rowsOption = [10, 20, 30, 40, 50];
	inputRows: number;
	finEntitlementDesc: any[];
	myMap = new Map();
	accountTypes: SelectItem[];
	isDisabled: Boolean = false;
	reportDataType: any[];
	selectedAccountManagerType: string;
	finDetailsType: any[];
	finDataList: any[];
	countryCodes: SelectItem[];
	selectedCountryCode : string;
	isCountryCode : boolean;

	constructor(private datePipe: DatePipe, public accountManager: FinNamDetailService, public exportPDF: ExportPDFService) {
		this.toDate = this.datePipe.transform(this.todayDate, 'MM/dd/yyyy');
	}

	@ViewChild("countrydropdown", { read: ElementRef,static:false }) tref: ElementRef;

	ngOnInit() {
		this.showReport = false;
		this.cols = [
			{field: 'userID', header: 'User ID'},
			{field: 'userID', header: 'User ID'},
			{field: 'firstName', header: 'User First Name'},
			{field: 'lastName', header: 'User Last Name'},

		];

		this.loadAccountTypes();
		this.isDisabled = true;
		this.loadCountryCodes();
		this.isCountryCode = true;
	}

	showAll() {
		var h = this.tref.nativeElement;
		var text = document.createTextNode("All");
		h.childNodes[0].lastChild.lastChild.childNodes[1].childNodes[1].after(text);
	}

	changeRecords(rows) {
		if (rows == null) {
			return;
		} else {
			this.inputRows = rows;
			const inputValue = Number(rows);
			this.Pagerows = inputValue;
			let col = [];
			this.rowsOption.push(inputValue);
			col = this.rowsOption.filter((item, i, arr) => {
				return arr.indexOf(arr.find(t => t === item)) === i;
			});
			this.rowsOption = col.sort((a, b) => a - b);
		}

	}

	loadCountryCodes() {
		this.countryCodes = [
			{label: 'CAN', value:CountryEnum.CANADA},
			{label: 'USA', value:CountryEnum.USA},
		];
	}

	onchangeCountryCode(code) {
		console.log("afs code ", code)
		this.selectedAccountManagerType = null;
		this.selectedAccountManager = null;
		this.showReport = false;
		if(code === undefined || code === null || code.length === 0) {
			this.isCountryCode = true;
			return;
		} else {
			this.isCountryCode = false;
			this.showReport = false;
		}
	}

	loadAccountTypes() {
		this.showSpinner = true;
		this.accountManager.getAccountType().subscribe(data => {
		data = data['result']['accountManagerTypes'];
			const options = [];
			if (data === undefined || data === null) {
				this.errorResponse = new ErrorResponseData(data);
				this.showErrorMsg(this.errorResponse.error.messages[0]);
				this.showReport = false;
				this.showSpinner = false;
				return;
			} else {
				for (const val of data) {
					options.push({'label': val, 'value': val});
				}
				this.accountTypes = options;
				this.showSpinner = false;
				this.showReport = false;
			}

			this.showSpinner = false;
		});
	}

	onchangeType() {
		this.showSpinner = true;
		this.selectedAccountManager = null;
		if (this.selectedAccountManagerType === null || this.selectedAccountManagerType === undefined) {
			this.showErrorMsg('Please select the Account Manager Type to view the report');
			this.showReport = false;
			this.showSpinner = false;
			return;
		}
		const accountSummary : AccountManagerSummaryArray = {
			countryCodeList : this.selectedCountryCode,
			accountManagerTypeCode : this.selectedAccountManagerType,
			accountManagerCdsid : this.selectedAccountManager
		};
		this.accountManager.getAccountManagerByType(accountSummary).subscribe(data => {
			if (data['result'] === null && data['error'] !== null) {
				this.errorResponse = new ErrorResponseData(data);
				this.showErrorMsg(this.errorResponse.error.messages[0]);
				this.showReport = false;
				this.showSpinner = false;
				this.isDisabled = true;
				this.accountManagerList = [];
				this.selectedAccountManager = null;
				return;
			} else {
				const options = [];
				this.showSpinner = true;
				this.showReport = true;
				this.finDetailsType = data['result']['accountManagerByTypes'];
				for (const val of this.finDetailsType) {
					if (val.cdsid === 'NONE') {
						options.push({'label': val.cdsid, 'value': val.cdsid});
					} else {
						options.push({
							'label': val.cdsid + ' - ' + val.firstName + ' ' + val.lastName,
							'value': val.cdsid
						});
					}
				}
				this.accountManagerList = options;
				this.showSpinner = false;
				this.showReport = false;
				this.isDisabled = false;
			}
		});
		this.showSpinner = false;
	}

	loadReport() {
		this.showSpinner = true;
		this.showReport = false;
		if (this.selectedCountryCode === null || this.selectedCountryCode === undefined) {
			this.showErrorMsg('Please select the Account Manager Country Code, Account Manager Type, Account Manager to view the report');
			this.showReport = false;
			this.showSpinner = false;
			return;
		}
		if (this.selectedAccountManagerType === null || this.selectedAccountManagerType === undefined) {
			this.showErrorMsg('Please select the Account Manager Type, Account Manager to view the report');
			this.showReport = false;
			this.showSpinner = false;
			return;
		}
		if (this.selectedAccountManager === null || this.selectedAccountManager === undefined) {
			this.showErrorMsg('Please select the Account Manager to view the report');
			this.showReport = false;
			this.showSpinner = false;
			return;
		}
		const accountSummary : AccountManagerSummaryArray = {
			countryCodeList : this.selectedCountryCode,
			accountManagerTypeCode : this.selectedAccountManagerType,
			accountManagerCdsid : this.selectedAccountManager
		};
		this.accountManager.getFinNAMReport(accountSummary).subscribe(data => {

			if (data['result'] === null && data['error'] !== null) {
				this.errorResponse = new ErrorResponseData(data);
				this.showErrorMsg(this.errorResponse.error.messages[0]);
				this.showReport = false;
				this.showSpinner = false;
				return;
			} else {
				this.showSpinner = true;
				this.showReport = true;
				this.reportData = Array.of(data['result']['namMappedDetails']);
				this.finDetails = data['result']['namMappedDetails']['finMappedDetailsList'];
				let sampleData = this.reportData;
				this.finEntitlementColheader = [];
				let colHeader = [];
				this.toDate = this.reportData[0].asOnDate;
				this.accountManager.getFinIndicator().subscribe(data => {
					const options = [];
					if (data['result'] && data['result']['finIndicatorDescription']) {
						for (const val of data['result']['finIndicatorDescription']) {
							options.push({'label': val.finIndicatorDesc, 'value': val.finIndicator});
						}
						this.finEntitlementDesc = options;
						this.finEntitlementDesc = options.sort(function (a, b) {
							return a.finIndicator - b.finIndicator;
						});

					}

					sampleData.forEach(item => {
						item.finMappedDetailsList.forEach(items => {
							if (items.finEntitlementList && items.finEntitlementList.length > 0) {
								items.finEntitlementList.forEach(data => {
									for (const pitem of this.finEntitlementDesc) {
										if (data === pitem.value) {
											colHeader.push({field: pitem.value, value: pitem.label});
										}
									}
								});
							}
						});
					});

					colHeader = colHeader.sort(function (a, b) {
						return a.value > b.value ? 1 : a.value < b.value ? -1 : 0;
					});

					for (const data of colHeader) {
						this.myMap.set(data.field, data.value);
					}

					this.myMap.forEach((value: string, key: string) => {
						this.finEntitlementColheaderLabel.push(value);
						this.finEntitlementColheader.push(key);
					});

					this.finEntitlementColheaderLabel = this.finEntitlementColheaderLabel.filter((item, i, ar) => ar.indexOf(item) === i);
					this.headerlength = this.finEntitlementColheaderLabel.length * 200;

					sampleData.forEach(item => {
						item.finMappedDetailsList.forEach(items => {
							let finEntitleList = [];
							if (items.finEntitlementList && items.finEntitlementList.length > 0) {
								items.finEntitlementList.forEach(
									data => {
										this.finEntitlementColheader.forEach(
											finEnt => {
												if (finEnt === data) {
													finEntitleList.push({field: data, value: 1});
												}
											});
									});
							}
							items.finEntitlementList = [];
							items.finEntitlementList = finEntitleList;
						});

					});
				});
				this.finDataList = [];
				this.finDetails.forEach(fins => {
					if (fins.userDetailsList && fins.userDetailsList.length > 0) {
						fins.userDetailsList.forEach(data => {
							this.finDataList.push({
								'finCode': fins.finCode,
								'fleetName': fins.fleetName,
								'finCountryCode':fins.finCountryCode,
								'userID': data.userID,
								'firstName': data.firstName,
								'lastName': data.lastName,
								'lastLogon': data.lastLogon,
								'lastPwdSet': data.lastPwdSet,
								'finEntitlementList': fins.finEntitlementList
							});
						});
					}
					;
				});

				this.finalReportData = [];
				this.combineDetails = [];
				this.finalReportData = sampleData;
				this.combineDetails = this.finDataList;
				this.showSpinner = false;
			}
		});
	}

	clearReport() {
		this.showReport = false;
		this.selectedAccountManager = null;
		this.selectedAccountManagerType = null;
		this.inputRows = null;
		this.paginateValues();
		this.myMap.clear();
		this.finEntitlementColheaderLabel = [];
		this.finEntitlementColheader = [];
		this.isDisabled = true;
		this.isCountryCode = true;
		this.selectedCountryCode = null;
	}

	changeAccountManager() {
		this.showReport = false;
		this.paginateValues();
		this.myMap.clear();
		this.finEntitlementColheaderLabel = [];
		this.finEntitlementColheader = [];
	}

	paginateValues() {
		this.rowsOption = [10, 20, 30, 40, 50];
		this.inputRows = null;
		this.Pagerows = 10;
	}

	checkLength(index) {
		if (index + 1 === this.finalReportData.length) {
			return true;
		}
		return false;
	}

	displayValue(dataFin, col) {
		if (dataFin.some(e => e === col)) {
			return 1;
		} else {
			return '';
		}
	}

	displayTotal(col) {
		let count = 0;
		this.finalReportData.forEach(item => {
			item.finMappedDetailsList.forEach(items => {
				if (items.finEntitlementList && items.finEntitlementList.length > 0) {
					items.finEntitlementList.forEach(title => {
						if (title.field === col) {
							count = count + 1;
						}
					});
				}
			});
		});
		return count;
	}

	displayUserTotal(col) {
		let count = 0;
		let grandCount = 0;
		let userCount = 0;
		this.finalReportData.forEach(item => {
			item.finMappedDetailsList.forEach(items => {
				if (items.finEntitlementList && items.finEntitlementList.length > 0) {
					userCount = items.userDetailsList.length;
					count = 0;
					items.finEntitlementList.forEach(title => {
						if (title.field === col) {
							count = count + 1;
						}
					});
					count = count * userCount;
					grandCount = grandCount + count;
				}
			});

		});
		return grandCount;
	}

	getDownloadExcel() {
		const accountSummary : AccountManagerSummaryArray = {
			countryCodeList : this.selectedCountryCode,
			accountManagerTypeCode : this.selectedAccountManagerType,
			accountManagerCdsid : this.selectedAccountManager
		};
		this.showSpinner = true;
		this.accountManager.getDownloadExcel(accountSummary).subscribe(data => {
			if (data['result'] !== null) {
				const fileName = 'AccountManager-FINEntitlementSummary.xlsx';
				this.downloadFile(data, fileName);
			} else {
				this.errorResponse = new ErrorResponseData(data);
				this.showErrorMsg(this.errorResponse.error.messages[0]);
			}
			this.showSpinner = false;
		});
	}

	showErrorMsg(errorMsg) {
		this.displayMessage = true;
		this.errorMessage = errorMsg;
	}

	getFINEntitlementMappingPDF() {
		this.showSpinner = true;
		this.accountManager.getFINEntitlementMappingPDF().subscribe((data:any) => {
			if (data['result'] !== null) {
				const fileName = 'FINEntitlementCategory.pdf';
				this.downloadFile(data, fileName);
			} else {
				this.errorResponse = new ErrorResponseData(data);
				this.showErrorMsg(this.errorResponse.error.messages[0]);
			}
			this.showSpinner = false;
		});
	}

	getAccountManagerCategoryPDF() {
		this.showSpinner = true;
		this.accountManager.getAccountManagerCategoryPDF().subscribe((data:any) => {
			if (data['result'] !== null) {
				const fileName = 'AccountManagerCategory.pdf';
				this.downloadFile(data, fileName);
			} else {
				this.errorResponse = new ErrorResponseData(data);
				this.showErrorMsg(this.errorResponse.error.messages[0]);
			}
			this.showSpinner = false;
		});
	}

	downloadFile(data: BlobPart, fileName: string) {
		const ua: string = window.navigator.userAgent;
		const windowsBrowserRegex = /Trident|MSIE|Edge/;
		const isIE = ua.match(windowsBrowserRegex);
		const blob = new Blob([data], {type: 'application/pdf'});
		const a = document.createElement('a');
		a.href = URL.createObjectURL(blob);
		if (isIE) {
			saveAs.msSaveOrOpenBlob(blob, fileName);
		} else {
			a.setAttribute('target', '_blank');
			a.download = fileName;
			a.click();
		}
		a.remove();
	}

	paginate(event) {
		const el = document.getElementById('#' + event);
		el.scrollIntoView();
	}

	closeMessage() {
		this.displayMessage = false;
	}
}
