import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component ({
	template: `
		<h3>Redirecting to requested URL</h3>
	`
})

export class OAuthCallbackComponent implements OnInit {
	fromsite: string;
	site: string;
	language: string;
	namId: string;
	reqId: string;
	userType: string;
	url: string;
	constructor( private _router: Router, private route: ActivatedRoute) {}

	ngOnInit() {
		this.fromsite = this.route.snapshot.paramMap.get('fromsite');
		this.site = this.route.snapshot.paramMap.get('site');
		this.language = this.route.snapshot.paramMap.get('language');

		this.namId = this.route.snapshot.paramMap.get('namId');
		this.reqId = this.route.snapshot.paramMap.get('reqId');
		this.userType = this.route.snapshot.paramMap.get('userType');

		this.url = '';
		if (this.fromsite !== null && this.fromsite !== '' && this.fromsite !== undefined) {
			this.url = this.fromsite;
			if (this.url !== null && this.url !== '' && this.url !== undefined) {
				if (this.url.search('profile-update') !== -1) {
					if (this.site !== null && this.site !== '' && this.site !== undefined) {
						this.url = this.url + '/' + this.site;
					}
					if (this.language !== null && this.language !== '' && this.language !== undefined) {
						this.url = this.url + '/' + this.language;
					}
				} else if (this.url.search('fkc-approval') !== -1) {
					if (this.namId !== null && this.namId !== '' && this.namId !== undefined) {
						this.url = this.url + '/' + this.namId;
					}
					if (this.reqId !== null && this.reqId !== '' && this.reqId !== undefined) {
						this.url = this.url + '/' + this.reqId;
					}
					if (this.userType !== null && this.userType !== '' && this.userType !== undefined) {
						this.url = this.url + '/' + this.userType;
					}
				}
			}
		}

		const callbackResponse = (document.URL).split('#')[1];
		const responseParameters = (callbackResponse).split('&');
		const parameterMap: Array<string> = [];
		for (let i = 0; i < responseParameters.length; i++) {
			parameterMap[responseParameters[i].split('=')[0]] = responseParameters[i].split('=')[1];
		}
		if (parameterMap.indexOf('access_token') != null && parameterMap.indexOf('id_token') != null) {
			const expiration =  Number(parameterMap['expires_in']) + (Math.trunc(new Date().getTime() / 1000));
			sessionStorage.setItem('encodedIdToken', parameterMap['id_token']);
			sessionStorage.setItem('encodedAccessToken', parameterMap['access_token']);
			sessionStorage.setItem('tokenExp', String(expiration));

			let tokenInfo ;
			tokenInfo  =  this.getDecodedAccessToken(parameterMap['access_token']);
			sessionStorage.setItem('userType', tokenInfo.fordUserType);
			sessionStorage.setItem('userId', tokenInfo.uid);
			sessionStorage.setItem('siteCode', tokenInfo.fordSiteCode);
			sessionStorage.setItem('userFName', tokenInfo.givenName);
			sessionStorage.setItem('userLName', tokenInfo.sn);
			sessionStorage.setItem('userEmail', tokenInfo.mail);
			sessionStorage.setItem('fordSiteCodeType', tokenInfo.fordSiteCodeType);
			sessionStorage.setItem('fordSiteCode', tokenInfo.fordSiteCode);
			sessionStorage.setItem('country', tokenInfo.c);

			sessionStorage.setItem('redirectURL', this.url );
			if (sessionStorage.getItem('redirectURL') !== '' && sessionStorage.getItem('redirectURL') !== null && sessionStorage.getItem('redirectURL') !== undefined) {
				this._router.navigate([sessionStorage.getItem('redirectURL')]);
			} else {
				this._router.navigate(['/dashboard']);
			}
		} else {
			alert('Problem authenticating');
		}
	}

	getDecodedAccessToken(token:  string):  any {
		try {
			const accessToken =  atob(token.split('.')[1]);
			return JSON.parse(accessToken);
		} catch (Error) {
			return null;
		}
	}
}
