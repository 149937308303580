import {Component} from '@angular/core';

@Component({
	selector: 'fleet-admin',
	templateUrl: './fleet-admin.component.html',
	styleUrls: ['./fleet-admin.component.css']
})
export class FleetAdminComponent {

}
