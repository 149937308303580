import {Component, OnInit, ViewEncapsulation } from '@angular/core';
import {Message} from 'primeng/api';
import {Router} from '@angular/router';
import {ErrorResponseData} from '../models/ErrorResponseData';
import {FkcService} from '../services/fkc.service';
import {DropdownItem} from '../models/dropdownItem';
import {FinCodeModel} from '../models/FinCode.model';
import {VinList} from '../models/VinList';
import {FkcKeyCodeDetail} from '../models/FkcKeyCodeDetail';

@Component({
	selector: 'app-fkc-keycode',
	templateUrl: './fkc-keycode.component.html',
	styleUrls: ['./fkc-keycode.component.css'],
	encapsulation: ViewEncapsulation.None
})

export class FkcKeycodeComponent implements OnInit {
	fincodeList: FinCodeModel[];
	reportMsgs: Message[] = [];
	selectedfinCode;
	vinList: VinList[];
	showResult = false;
	finCodeDropdown: DropdownItem[];
	errorResponse: ErrorResponseData;
	userId: any;
	userType: any;
	siteCode: any;
	paCode: string;
	keycodeRetrivalList: FkcKeyCodeDetail[] = [];
	showInputBox: Boolean = false;
	finCode: string;
	showSpinner: Boolean = false;
	searchedFinCode: string;
	showErrMsg: string;
	showError: Boolean = false;
	constructor(public router: Router, public fkc: FkcService) {
		this.searchedFinCode =  '';
	}

	ngOnInit() {
		this.loadFincodeList();
		this.searchedFinCode = '';
	}

	loadFincodeList() {
		this.userId = sessionStorage.getItem('userId');
		this.userType = sessionStorage.getItem('userType');
		this.siteCode = sessionStorage.getItem('siteCode');
		if (this.userType === 'Dealer') {
			this.paCode = 	this.siteCode;
		}

		if (this.userType && this.userType !== 'Other') {
			this.showInputBox = true;
		} else {
			this.showInputBox = false;
		}
		if (this.userType === 'Other') {
			this.fkc.getFinCode(this.userId).subscribe(data => {
				if (data['result'] && data['result']['finCode']) {
					this.fincodeList = data['result']['finCode'];
					const options = [];
					options.push({'label': 'Select Fin', 'value': ''});
					for (const val of this.fincodeList) {
						options.push({'label': val['finCode'], 'value': val['finCode']});
					}
					this.finCodeDropdown = options;
				} else {
					this.errorResponse = new ErrorResponseData(data);
					if (this.errorResponse.error) {
						this.showErrorMsg(this.errorResponse.error.messages[0]);
					}
				}
			});
		}
		this.vinList = [{seqNo: '1', vin: ''}, {seqNo: '2', vin: ''}, {seqNo: '3', vin: ''}, {seqNo: '4', vin: ''}, {seqNo: '5', vin: ''}];
	}

	clear() {
		this.vinList = [{seqNo: '1', vin: ''}, {seqNo: '2', vin: ''}, {seqNo: '3', vin: ''}, {seqNo: '4', vin: ''}, {seqNo: '5', vin: ''}];
		this.keycodeRetrivalList = [];
		this.keycodeRetrivalList.length = 0;
		this.searchedFinCode = '';
		this.showResult = false;
		if (this.showInputBox) {
			this.finCode = '';
		} else {
			this.selectedfinCode = '';
		}
	}

	submit() {
		this.searchedFinCode = '';
		this.showSpinner = true;
		this.keycodeRetrivalList = [];
		this.keycodeRetrivalList.length = 0;
		if (this.showInputBox) {
			if (this.finCode === '' || this.finCode === null || this.finCode === undefined ) {
				this.showError = true;
				this.showErrMsg = 'Please select/enter the FIN.';
				this.showSpinner = false;
			} else if (this.finCode.length < 5) {
				this.showError = true;
				this.showErrMsg = 'Please enter the 5 digit FIN.';
				this.showSpinner = false;
			} else {
				this.showSpinner = false;
				this.loadKeyCodeRetrival(this.finCode);
			}
		} else {
			if (this.selectedfinCode) {
				this.loadKeyCodeRetrival(this.selectedfinCode);
			} else {
				this.showError = true;
				this.showErrMsg = 'Please choose the FIN.';
				this.showSpinner = false;
			}
		}
	}

	loadKeyCodeRetrival(fin) {
		this.showSpinner = true;
		if (this.vinList.find(item => item && item.vin !== null && item.vin !== ''  && item.vin !== undefined )) {
				this.showResult = true;
				const vins: string[] = [];
				this.vinList.forEach(items => {
					vins.push(items.vin);
				});

				let isInValidVin = false;
				let isFullVin = false;
				const fullVins: string[] = [];
				vins.forEach( vin => {
					if (vin !== null && vin !== '') {
						if (vin.length < 10 || (vin.length !=10 && vin.length < 17)) {
							this.showError = true;
							this.showErrMsg = 'You must enter 10 digits,the first 2 digits and last 8 digits of full VIN or 17 digit full VIN, to submit this query.';
							isInValidVin = true;
							this.showSpinner = false;
						}else if (vin.length === 17){
							vin = vin.substring(0,2)+vin.substring(9,17);
							fullVins.push(vin);
						}else if(vin.length === 10){
							fullVins.push(vin);
						}
					}
				});

				if (!isInValidVin) {
					let FkcKeyCodeRequest   = null;
					FkcKeyCodeRequest={ 'userId': this.userId, 'fin' : fin.trim() , 'vins': fullVins, 'userType': this.userType, 'dealerPACode' : this.paCode};
					this.fkc.getKeyCodeRetrival(FkcKeyCodeRequest).subscribe( data => {
						if (data['result'] && data['result']['fkcKeyCodeDetailApiList']) {
							this.keycodeRetrivalList = data['result']['fkcKeyCodeDetailApiList'];
							this.searchedFinCode =	fin;
						} else {
							this.errorResponse = new ErrorResponseData(data);
							if (this.errorResponse.error) {
								this.showErrorMsg(this.errorResponse.error.messages[0]);
							}
						}
						this.showSpinner = false;
					});
				}

		} else {
			this.showError = true;
			this.showErrMsg = 'Please enter at least one VIN';
			this.showSpinner = false;
		}
	}

	goToReports() {
		this.router.navigate(['fkc-keycode-reports']);
	}

	showErrorMsg(errorMsg) {
		if (errorMsg) {
			this.reportMsgs = [];
			this.reportMsgs.push({
				severity: 'error',
				summary: 'Error Message',
				detail: errorMsg
			});
		}
	}
}
