import {Component, OnInit, ViewEncapsulation } from '@angular/core';
import {environment} from '../../environments/environment';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
	url = sessionStorage.getItem('redirectURL');
	_endPoint = environment.endPoint + ((this.url !== null && this.url !== '' && this.url !== undefined) ? this.url : '');

	ngOnInit() {
		console.log(this._endPoint);
		window.location.href = this._endPoint;
	}
}

