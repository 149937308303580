import {Component, OnInit, ViewEncapsulation } from '@angular/core';
import {Message} from 'primeng/api';
import {Router} from '@angular/router';
import {FkcService} from '../services/fkc.service';
import {PersonalIndicatorConstants} from '../common/personalindicator.constants';
import {CommonService} from '../services/common.service';
import {ErrorResponseData} from '../models/ErrorResponseData';
import {FkcRequestInfo} from '../models/FkcRequestInfo';
import {PersonalIndicatorInfo} from '../models/PersonalIndicatorInfo';
import {FinCodeDetailAPI} from '../models/FinCodeDetailAPI';

@Component({
	selector: 'app-fkc-homepage',
	templateUrl: './fkc-homepage.component.html',
	styleUrls: ['./fkc-homepage.component.css'],
	encapsulation: ViewEncapsulation.None
})

export class FkcHomepageComponent implements OnInit {
	selectedKeycodeReq = 'Access';
	userId;
	userType;
	siteCode;
	showRequest: Boolean = false;
	showAccess: Boolean = false;
	isRequestRaised: Boolean = false;
	isRequestApproved: Boolean = false;
	public personalIndicatorList: PersonalIndicatorInfo[];
	errorResponse: ErrorResponseData;
	reportMsgs: Message[] = [];
	constructor(public router: Router, private common: CommonService ,  public fkc: FkcService) {
	}
	requestInfo: FkcRequestInfo;
	showSpinner: Boolean = false;
	fincodeDetailsList: FinCodeDetailAPI[];
	finCode: string;

	ngOnInit() {
		this.userId = sessionStorage.getItem('userId');
		this.userType = sessionStorage.getItem('userType');
		this.siteCode = sessionStorage.getItem('siteCode');
		this.selectedKeycodeReq = 'Access';
		this.showAccess = false;
		this.showRequest = false;
		this.isRequestRaised = false;
		this.isRequestApproved = false;
	}

	submit() {
		this.showSpinner = true;
		this.common.getPersonalIndicatorInfo().subscribe(data => {
			if (data['result']['personalIndicator'] && data['result']['personalIndicator'].length > 0) {
				this.personalIndicatorList = data['result']['personalIndicator'];
				console.log(this.personalIndicatorList);
				if (this.personalIndicatorList && this.personalIndicatorList.length > 0) {
					if (this.selectedKeycodeReq === 'Access') {
						if ((this.userType === 'Employee' || this.userType === 'Other') && (this.personalIndicatorList && this.personalIndicatorList.find(item => item && item.personCode.toUpperCase() === PersonalIndicatorConstants.fkcinternalOrFleetPersonalIndicator.toUpperCase()))) {
							this.router.navigate(['fkc-keycode']);
							this.showSpinner = false;
						} else if ((this.userType === 'Dealer' ) && (this.personalIndicatorList  && this.personalIndicatorList.find(item => item && item.personCode.toUpperCase() === PersonalIndicatorConstants.fkcidelarePaPersonalIndicator.toUpperCase()))) {
							this.router.navigate(['fkc-keycode']);
							this.showSpinner = false;
						} else {
							this.showAccess = true;
							this.showSpinner = false;
						}
					} else {
						if ((this.userType === 'Employee' || this.userType === 'Other') && (this.personalIndicatorList && this.personalIndicatorList.find(item => item && item.personCode.toUpperCase() === PersonalIndicatorConstants.fkcinternalOrFleetPersonalIndicator.toUpperCase()))) {
							this.showRequest = true;
							this.showSpinner = false;
						} else if (this.userType === 'Dealer') {
							// this.showRequest = true;
							// this.showSpinner = false;
							this.router.navigate(['/unauthorized'], {queryParams: {isDealerRequest: true}});
						} else {
							if (this.userType === 'Other') {
								this.checkRequestStatus();
								//this.loadFINCodes(this.userId);
							} else if (this.userType === 'Employee') {
								this.getRequestWithStatus(this.userId);
							}
						}
					}
				} else {
					this.showAccess = true;
					this.showSpinner = false;
				}
			} else {
				this.showSpinner = false;
				if (this.selectedKeycodeReq === 'Access') {
					this.showAccess = true;
				} else {
					if ((this.userType === 'Dealer')) {
						this.router.navigate(['/unauthorized'], {queryParams: {isDealerRequest: true}});
					} else if (this.userType === 'Other') {
						this.checkRequestStatus();
					//this.loadFINCodes(this.userId);
					} else if (this.userType === 'Employee') {
						this.getRequestWithStatus(this.userId);
					}
				}
			}
		});
	}

	checkRequestStatus() {
		this.fkc.getRequestWithStatus(this.userId.toLowerCase()).subscribe(data => {
			if (data['result']) {
				this.requestInfo = data['result'];
				if (this.requestInfo.getAllRequest.length > 0) {
					const reqStatus = this.requestInfo.getAllRequest[0].reqStatus;
					if (reqStatus && reqStatus.toUpperCase() === 'I') {
						//this.router.navigate(['/unauthorized'], {queryParams: {isFkcRequest: true}});
						this.isRequestRaised = true;
						this.showSpinner = false;
					} else {
						this.checkRequestActiveStatus();
						//this.loadFINCodes(this.userId.toLowerCase());
					}
				} else {
					this.checkRequestActiveStatus();
					//this.loadFINCodes(this.userId.toLowerCase());
				}
			} else {
				this.checkRequestActiveStatus();
				//this.loadFINCodes(this.userId.toLowerCase());
			}
		});
	}

	checkRequestActiveStatus() {
		this.fkc.getRequestWithActiveStatus(this.userId.toLowerCase()).subscribe(data => {
			if (data['result']) {
				this.requestInfo = data['result'];
				if (this.requestInfo.getAllRequest.length > 0) {
					const reqStatus = this.requestInfo.getAllRequest[0].reqStatus;
					if (reqStatus && reqStatus.toUpperCase() === 'A') {
						//this.router.navigate(['/unauthorized'], {queryParams: {isFkcRequest: true}});
						this.isRequestApproved = true;
						this.showSpinner = false;
					} else {
						this.loadFINCodes(this.userId.toLowerCase());
					}
				} else {
					this.loadFINCodes(this.userId.toLowerCase());
				}
			} else {
				this.loadFINCodes(this.userId.toLowerCase());
			}
		});
	}

	getRequestWithStatus(userId) {
		this.fkc.getRequestWithStatus(userId).subscribe(data => {
			if (data['result']) {
				this.requestInfo = data['result'];
				if (this.requestInfo.getAllRequest.length > 0) {
					const reqStatus = this.requestInfo.getAllRequest[0].reqStatus;
					if (reqStatus && reqStatus.toUpperCase() === 'I') {
						this.isRequestRaised = true;
						this.showSpinner = false;
					} else {
						this.loadInternalUserAccessDetail();
					}
				} else {
					this.loadInternalUserAccessDetail();
				}
			} else {
				this.errorResponse = new ErrorResponseData(data);
				if (this.errorResponse.error) {
					this.reportMsgs = [];
					this.reportMsgs.push({
						severity: 'error',
						summary: 'Error Message',
						detail: this.errorResponse.error.message
					});
				}
			}
		});
	}
	loadInternalUserAccessDetail() {
		this.fkc.getRequestWithActiveStatus(this.userId.toLowerCase()).subscribe(data => {
			if (data['result']) {
				this.requestInfo = data['result'];
				if (this.requestInfo.getAllRequest.length > 0) {
					const reqStatus = this.requestInfo.getAllRequest[0].reqStatus;
					if (reqStatus && reqStatus.toUpperCase() === 'A') {
						//this.router.navigate(['/unauthorized'], {queryParams: {isFkcRequest: true}});
						this.isRequestApproved = true;
						this.showSpinner = false;
					} else {
						this.isRequestApproved = false;
						if (this.personalIndicatorList && this.personalIndicatorList.find(item => item && item.personCode.toUpperCase() === PersonalIndicatorConstants.cpaStatementAccess.toUpperCase())) {
							sessionStorage.setItem('cpaStatementAccess', 'Y');
						} else {
							sessionStorage.setItem('cpaStatementAccess', 'N');
						}
						this.router.navigate(['fkc-request']);
						this.showSpinner = false;
					}
				} else {
					this.isRequestApproved = false;
					if (this.personalIndicatorList && this.personalIndicatorList.find(item => item && item.personCode.toUpperCase() === PersonalIndicatorConstants.cpaStatementAccess.toUpperCase())) {
						sessionStorage.setItem('cpaStatementAccess', 'Y');
					} else {
						sessionStorage.setItem('cpaStatementAccess', 'N');
					}
					this.router.navigate(['fkc-request']);
					this.showSpinner = false;
				}
			} else {
				this.isRequestApproved = false;
				if (this.personalIndicatorList && this.personalIndicatorList.find(item => item && item.personCode.toUpperCase() === PersonalIndicatorConstants.cpaStatementAccess.toUpperCase())) {
					sessionStorage.setItem('cpaStatementAccess', 'Y');
				} else {
					sessionStorage.setItem('cpaStatementAccess', 'N');
				}
				this.router.navigate(['fkc-request']);
				this.showSpinner = false;
			}
		});
	}

	loadFINCodes(userId) {
		this.fkc.getFinCodes(userId).subscribe( data => {
			if (data['result'] && data['result']['getFINdetails']) {
				this.fincodeDetailsList = data['result']['getFINdetails'];
				if (this.fincodeDetailsList.length > 0) {
					if (this.fincodeDetailsList.length > 1 ) {
						this.router.navigate(['/unauthorized'], {queryParams: {isMultipleFINMapped: true}});
					} else if (this.fincodeDetailsList.length === 1) {
						this.finCode = this.fincodeDetailsList[0].fincode;
						this.fkc.getMarketSegmentByFin(this.fincodeDetailsList[0].fincode).subscribe( data1 => {
							if (data1['result']['marketSegment'][0]['marketSegment'] === 'L') {
								this.router.navigate(['/unauthorized'], {queryParams: {isLeasingFIN: true}});
							} else {
								this.getNAMDetails(this.userId, this.finCode);
							}
						});
					}
				}
			} else {
				this.router.navigate(['/unauthorized'], {queryParams: {isMultipleFINMapped: true}});
			}
		});
	}

	getNAMDetails(userId, finCode) {
		//this.multipleFin = false;
		this.fkc.getNamInfo(userId, finCode).subscribe( data => {
			if ( data['result']) {
				if (data['result'] && data['result']['naminfoList']) {
					if (data['result']['naminfoList'] === null || data['result']['naminfoList'].length === 0 ) {
						this.router.navigate(['/unauthorized'], {queryParams: {NAMnotMapped: true}});
					} else {
						this.getRequestWithStatus(userId);
					}
				} else {
					this.router.navigate(['/unauthorized'], {queryParams: {NAMnotMapped: true}});
				}
			} else {
				this.router.navigate(['/unauthorized'], {queryParams: {NAMnotMapped: true}});
			}
		});
	}

	showErrorMsg(errorMsg) {
		if (errorMsg) {
			this.reportMsgs = [];
			this.reportMsgs.push({
				severity: 'error',
				summary: 'Error Message',
				detail: errorMsg
			});
		}
	}
}
