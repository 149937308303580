import { Injectable } from '@angular/core';
import {AccessCodeModel} from '../models/AccessCode.model';
import {UserInformationModel} from '../models/UserInformation.model';
import {FinCodeModel} from '../models/FinCode.model';
import {FincodeDetailsModel} from '../models/FincodeDetails.model';
import {Observable} from 'rxjs';
import {MessageService} from 'primeng/api';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {StateModel} from '../models/State.model';

@Injectable({
	providedIn: 'root'
})
export class FcicadminService {
	_accessToken: string = sessionStorage.getItem('encodedAccessToken');
	fwsUrl = environment.APIEndpoint;
    fleetAdminService = environment.fleetAdminService;

	private options = {
		headers: new HttpHeaders({
			'Authorization': 'Bearer ' + this._accessToken,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'origin': window.location.origin
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient,  private messageService: MessageService) { }

	getAccessCode(accesscode: string): Observable<AccessCodeModel> {
		let request = {
			"finCode":accesscode
		}

		return this.http.post<AccessCodeModel>(this.fleetAdminService + 'api/fcicadmin/accesscode', request , this.options);
	}

	getUserInformation(userid: string): Observable<UserInformationModel> {
		let request = {
			"wslID":userid
		}
		return this.http.post<UserInformationModel>(this.fleetAdminService + 'api/fcicadmin/userinfos' ,request, this.options);
	}

	updateUserInformation(UserInformationModel): Observable<UserInformationModel> {
		return this.http.put<UserInformationModel>(this.fwsUrl + 'api/fcicadmin/updateuserinfo' , UserInformationModel, this.options);
	}

	/*getFincode(userid: string): Observable<FinCodeModel[]> {
		return this.http.get<FinCodeModel[]>(this.fwsUrl + 'api/common/fincode/' + userid, this.options);
	}*/
	
	getFincode(userid: string): Observable<FinCodeModel[]> {
		let request = {
			"wslID":userid
		}
		return this.http.post<FinCodeModel[]>(this.fleetAdminService + 'api/common/fcic/fincode',request, this.options);
	}

	deleteFincode(FincodeDetailsModel): Observable<FincodeDetailsModel> {
		return this.http.post<FincodeDetailsModel>(this.fwsUrl + 'api/fcicadmin/deletefincode', FincodeDetailsModel, this.options);
	}

	addFincode(FincodeDetailsModel):Observable<FincodeDetailsModel> {
		return this.http.post<FincodeDetailsModel>(this.fwsUrl + 'api/fcicadmin/addfincode', FincodeDetailsModel, this.options);
	}

	getUserId(fincode: string): Observable<FinCodeModel[]> {
		let request = {
			"finCode":fincode
		}
		return this.http.post<FinCodeModel[]>(this.fleetAdminService + 'api/fcicadmin/wslbyfin',request, this.options);
	}

	getState(countrycode: string): Observable<StateModel[]> {
		return this.http.get<StateModel[]>(this.fleetAdminService + 'api/common/state/' + countrycode, this.options);
	}

	getPersonalindicator(userId): Observable<any[]> {
		let request = {
			"wslID":userId
		}
		return this.http.post<any>(this.fleetAdminService+ 'api/common/personalindicator' ,request, this.options);
	}

	getAccessCodeSec(accesscode: string): Observable<AccessCodeModel> {
		let request = {
			"finCode":accesscode
		}
		return this.http.post<AccessCodeModel>(this.fleetAdminService + 'api/fcicadmin/accesscode',request, this.options);
	}

}

