import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ViewEncapsulation} from '@angular/core';
import {FcicadminService} from '../services/fcicadmin.service';
import {FinIndicatorMappingModel} from '../models/FinIndicatorMapping';
import {DropdownItem} from '../models/dropdownItem';
import {LazyLoadEvent, Message, MessageService} from 'primeng/api';
import {FinIndicatorMaster} from '../models/FinIndicatorMaster';
import {CommonService} from '../services/common.service';
import {FinAdminService} from '../services/finadmin.service';
// import {DataTable} from 'primeng/primeng';


@Component({
	selector: 'app-fin-indicator',
	templateUrl: './fin-indicator.component.html',
	styleUrls: ['./fin-indicator.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class FinIndicatorComponent implements OnInit {
	countryCodeList = [];
	finIndicator: any;
	msgs: Message[] = [];
	countryCode: string;
	showReport = false;
	finalSelFINCode;
	finalMappedFINCode;
	finIndicatorDropdown: DropdownItem[];
	userId = sessionStorage.getItem('userId');
	totalRecords = 0;
	totalRecordsMapped = 0;
	fincodeList: FinIndicatorMappingModel[];
	newFincodeList: FinIndicatorMappingModel[];
	fincodeMappedList: FinIndicatorMappingModel[];
	newfincodeMappedList: FinIndicatorMappingModel[];
	public displaySpinner: Boolean = false;
	displayMessage = false;
	errorMessage: string;
	finCode: string;
	showFinCodeErrorMsg = false;
	showCountryCodeErrorMsg = false;
	showFinIndicatorErrorMsg = false;
	showNoRecords = false;
	disableFinIndicator = true;
	mappedFins = [];
	showmappedFin = false;
	showunmappedFin = false;
	@ViewChildren('row', { read: ElementRef }) rowElement: QueryList<ElementRef>;
	constructor(private finadminService: FinAdminService,  private messageService: MessageService, public common: CommonService) {
	}

	ngOnInit() {
		this.fincodeList = [];
		this.newFincodeList = [];
		this.fincodeMappedList = [];
		this.newfincodeMappedList = [];
		const countryCode = [];
		//countryCode.push({'label': 'USA', 'value': 'USA'});
		//countryCode.push({'label': 'CAN', 'value': 'CAN'});
		this.common.getCountryCode().subscribe(data => {
			if(data['result'] && data['result']['countryCodes'] && data['result']['countryCodes'].length >0) {
				data['result']['countryCodes'].forEach(item => {
					countryCode.push({'label': item.countryCode, 'value': item.countryCode});
				});
			}
		});
		this.countryCodeList = countryCode;

		this.getFINIndicator();
	}

	clear() {
		this.showReport = false;
		this.finIndicator = '';
		this.countryCode = '';
		this.finCode = '';
		this.disableFinIndicator = true;
		this.showunmappedFin = false;
		this.showmappedFin = false;
		this.mappedFins = [];
	}

	loadCountryCode() {
		this.disableFinIndicator = true;
		this.showReport = false;
	}

	getFINIndicator() {
		this.showReport = false;
		this.showunmappedFin = false;
		this.showmappedFin = false;
		this.finIndicatorDropdown = [];
		let finIndicator: FinIndicatorMaster[];
		this.finadminService.getFinIndicatorMaster().subscribe(data => {
			if (data['result'] && data['result']['finIndiatorMasterList']) {
				const options = [];
				options.push({'label': 'Select Fin Entitlement', 'value': ''});
				finIndicator = data['result']['finIndiatorMasterList'];
				for (const val of finIndicator) {
					options.push({'label': val.accountPrefCode + '-' + val.accountPrefDescription , 'value': val.accountPrefCode});
				}
				this.finIndicatorDropdown = options;
				this.disableFinIndicator = false;
			} else {
				// this.errorResponse = new ErrorResponseData(data);
				// if (this.errorResponse.error) {
				// 	this.showErrorMsg(this.errorResponse.error.messages[0]);
			}
		});
	}

	updateMappingFIN(dt1, dt) {
		dt.reset();
		dt1.reset();
		this.mappedFins = [];
		this.showunmappedFin = false;
		this.showmappedFin = false;
		if (!this.finalSelFINCode  || this.finalSelFINCode.length === 0) {
			//alert('Please select the  FIN to add');
			this.messageService.add({severity: 'info', summary: 'Info Message', detail: 'Please select the FIN to add'});
			return;
		}
		this.displaySpinner = true;
		const finIndicatorMappingModel = new FinIndicatorMappingModel();
		finIndicatorMappingModel.finCountryCode = this.countryCode;
		for (const val of this.finalSelFINCode) {
			finIndicatorMappingModel.finCode.push(val.finCode);
		}
		this.userId = sessionStorage.getItem('userId');
		finIndicatorMappingModel.userId = this.userId;
		//finIndicatorMappingModel.userId = 't-use764';
		finIndicatorMappingModel.status = 'A';
		finIndicatorMappingModel.searchFIN = this.finCode;
		finIndicatorMappingModel.finIndicatorName = this.finIndicator;
		this.finadminService.updateFinalFINbyCounCodeandFINCode(finIndicatorMappingModel).subscribe(data => {
			this.mappedFins = finIndicatorMappingModel.finCode;
			this.showmappedFin = true;
			// if (data['result'] && data['result']['finCodeMappingApi']) {
			// 	if (data['result']['finCodeMappingApi']['availableFincodes']) {
			// 		this.fincodeList = data['result']['finCodeMappingApi']['availableFincodes'];
			// 	}
			// 	if (data['result']['finCodeMappingApi']['mappedFincodes']) {
			// 		this.fincodeMappedList = data['result']['finCodeMappingApi']['mappedFincodes'];
			// 	}
			// }
			/*const event: LazyLoadEvent = {
				first : 0, rows : 10,
			};
			event.filters = null;
			this.loadMappedFinCodeLazy(event, 'update');
			this.loadFinCodeLazy(event, 'update');*/
			this.loadFIN();
			this.messageService.add({severity: 'success', summary: 'Success Message', detail: 'Added Successfully'});
		});
	}
	deleteMappingFIN(dt, dt1) {
		dt.reset();
		dt1.reset();
		this.mappedFins = [];
		this.showunmappedFin = false;
		this.showmappedFin = false;
		if (!this.finalMappedFINCode  || this.finalMappedFINCode.length === 0) {
			this.messageService.add({severity: 'info', summary: 'Info Message', detail: 'Please select the FIN to delete'});
			return;
		}
		this.displaySpinner = true;
		const finIndicatorMappingModel  = new FinIndicatorMappingModel();
		finIndicatorMappingModel.finCountryCode = this.countryCode;
		for (const val of this.finalMappedFINCode) {
			finIndicatorMappingModel.finCode.push(val.finCode);
		}
		this.userId = sessionStorage.getItem('userId');
		//finIndicatorMappingModel.userId = this.userId ;
		finIndicatorMappingModel.userId = this.userId ;
		//finIndicatorMappingModel.userId = 't-use764';
		finIndicatorMappingModel.status = 'I';
		finIndicatorMappingModel.searchFIN = this.finCode;
		finIndicatorMappingModel.finIndicatorName = this.finIndicator;
		this.finadminService.updateFinalFINbyCounCodeandFINCode(finIndicatorMappingModel).subscribe(data => {
			this.mappedFins = finIndicatorMappingModel.finCode;
			this.showunmappedFin = true;
			// if (data['result'] && data['result']['finCodeMappingApi']) {
			// 	if (data['result']['finCodeMappingApi']['availableFincodes']) {
			// 		this.fincodeList = data['result']['finCodeMappingApi']['availableFincodes'];
			// 	}
			// 	if (data['result']['finCodeMappingApi']['mappedFincodes']) {
			// 		this.fincodeMappedList = data['result']['finCodeMappingApi']['mappedFincodes'];
			// 	}
			// }
			/*const event: LazyLoadEvent = {
				first : 0, rows : 10,
			};
			event.filters = null;
			this.loadMappedFinCodeLazy(event, 'delete');
			this.loadFinCodeLazy(event, 'delete');*/
			this.loadFIN();
			this.messageService.add({severity: 'success', summary: 'Success Message', detail: 'Removed Successfully'});
		});
	}

	loadFIN() {
		this.finalSelFINCode = [];
		this.finalMappedFINCode = [];
		this.fincodeList = [];
		this.fincodeMappedList = [];
		if ((this.finCode !== null && this.finCode !== undefined && this.finCode !== '')
			&& (this.countryCode !== null && this.countryCode !== undefined && this.countryCode !== '')
			&& (this.finIndicator !== null && this.finIndicator !== undefined && this.finIndicator !== '')) {
			this.displaySpinner = true;
			const finIndicatorMappingModel = new FinIndicatorMappingModel();
			finIndicatorMappingModel.finCountryCode = this.countryCode;
			finIndicatorMappingModel.finIndicatorName = this.finIndicator;
			finIndicatorMappingModel.searchFIN = this.finCode;
			this.finadminService.avaFINbyCounCodeandFINIndicator(finIndicatorMappingModel).subscribe(data => {
				if (data['result']['finCodeMappingApi']['availableFincodes']) {
					this.fincodeList = data['result']['finCodeMappingApi']['availableFincodes'];
					this.totalRecords = this.fincodeList.length;
				}
				if (data['result']['finCodeMappingApi']['mappedFincodes']) {
					this.fincodeMappedList = data['result']['finCodeMappingApi']['mappedFincodes'];
					//let mappedwithsearch = data['result']['finCodeMappingApi']['mappedFincodes'].filter(item => String(item.finCode.toLowerCase()).startsWith(finIndicatorMappingModel.searchFIN.toLowerCase().toString()));
					//let mappedwithoutsearch = data['result']['finCodeMappingApi']['mappedFincodes'].filter(item => !String(item.finCode.toLowerCase()).startsWith(finIndicatorMappingModel.searchFIN.toLowerCase().toString()));

					//this.fincodeMappedList = [ ...mappedwithsearch, ...mappedwithoutsearch];
					this.totalRecordsMapped = this.fincodeMappedList.length;
				}

				//if (this.fincodeList.length > 0 || this.fincodeMappedList.length > 0) {
				if (this.fincodeList.length > 0 || this.fincodeMappedList.length > 0) {
					this.showReport = true;
					this.showNoRecords = false;
					if (this.fincodeMappedList.length > 0) {
						setTimeout(() => {
							const el = this.rowElement.find(r => r.nativeElement.id.toString().toUpperCase().startsWith(this.finCode.toString().toUpperCase()));
							el.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
						}, 200);
					}
				} else {
					this.showReport = false;
					this.showNoRecords = true;
				}

				/*const event: LazyLoadEvent = {
					first: 0, rows: 10,
				};
				this.loadFinCodeLazy(event, 'search');
				this.loadMappedFinCodeLazy(event, 'search');*/
				this.displaySpinner = false;
			});
		} else {
			if (this.finCode === null || this.finCode === undefined || this.finCode === '') {
				this.showFinCodeErrorMsg = true;
			}
			if (this.countryCode === null || this.countryCode === undefined || this.countryCode === '') {
				this.showCountryCodeErrorMsg = true;
			}
			if (this.finIndicator === null || this.finIndicator === undefined || this.finIndicator === '') {
				this.showFinIndicatorErrorMsg = true;
			}
		}
	}

	/*loadFinCodeLazy(event: LazyLoadEvent, from) {

		setTimeout(() => {
			if (this.fincodeList) {
				if (event.filters && event.filters.finCode && event.filters.finCode.value !== null && event.filters.finCode.value !== undefined && event.filters.finCode.value !== '') {
					const filter = event.filters.finCode.value.toString().toUpperCase();
					const finList = this.fincodeList.filter(item => item.finCode.includes(filter));
					this.newFincodeList = finList.slice(event.first, (event.first + event.rows));
					this.totalRecords = finList.length;
				} else {
					this.newFincodeList = this.fincodeList.slice(event.first, (event.first + event.rows));
				}
				this.displaySpinner = false;
				/!*if (from === 'delete') {
					this.showErrorMsg('Removed Successfully');
				} else if (from === 'update') {
					this.showErrorMsg('Added Successfully');
				}*!/
			}
		}, 500);
	}
*/
	/*loadMappedFinCodeLazy(event: LazyLoadEvent, from) {
		setTimeout(() => {
			if (this.fincodeMappedList) {
				if (event.filters && event.filters.finCode && event.filters.finCode.value !== null && event.filters.finCode.value !== undefined && event.filters.finCode.value !== '') {
					const filter = event.filters.finCode.value.toString().toUpperCase();
					const finList = this.fincodeMappedList.filter(item => item.finCode.includes(filter));
					this.newfincodeMappedList = finList.slice(event.first, (event.first + event.rows));
					this.totalRecordsMapped = finList.length;
				} else {
					this.newfincodeMappedList = this.fincodeMappedList.slice(event.first, (event.first + event.rows));
				}
				this.displaySpinner = false;
				/!*if (from === 'delete') {
					this.showErrorMsg('Removed Successfully');
				} else if (from === 'update') {
					this.showErrorMsg('Added Successfully');
				}*!/

			}
		}, 500);
	}*/

	showErrorMsg(errorMsg) {
		this.displayMessage = true;
		this.errorMessage = errorMsg;
	}


	removeErrorMsgCountry() {
		if (this.countryCode === null || this.countryCode === undefined || this.countryCode === '') {
			this.showCountryCodeErrorMsg = true;
		} else {
			this.showCountryCodeErrorMsg = false;
		}
	}

	removeErrorMsg() {
		if (this.finCode === null || this.finCode === undefined || this.finCode === '') {
			this.showFinCodeErrorMsg = true;
		} else {
			this.showFinCodeErrorMsg = false;
		}
	}

	removeErrorMsgFinIndicator() {
		if (this.finIndicator === null || this.finIndicator === undefined || this.finIndicator === '') {
			this.showFinIndicatorErrorMsg = true;
		} else {
			this.showFinIndicatorErrorMsg = false;
		}
	}



	/*updateFIN() {
		if (!this.fincodeMappedList  || this.fincodeMappedList.length === 0) {
			alert('Please select the FIN to add');
			return;
		}
		this.displaySpinner = true;
		const finIndicatorMappingModel = new FinIndicatorMappingModel();
		finIndicatorMappingModel.finCountryCode = this.countryCode;
		const value = [];
		for (const val of this.fincodeMappedList) {
			//finIndicatorMappingModel.finCode = val.finCode;
			value.push(val.finCode);
		}

		finIndicatorMappingModel.finCode = value ;
		this.userId = sessionStorage.getItem('userId');
		//finIndicatorMappingModel.userId = this.userId;
		finIndicatorMappingModel.userId = 't-use764';
		finIndicatorMappingModel.status = 'A';
		finIndicatorMappingModel.searchFIN = this.finCode;
		finIndicatorMappingModel.finIndicatorName = this.finIndicator;
		this.fcicadminService.updateFinalFINbyCounCodeandFINCode(finIndicatorMappingModel).subscribe(data => {
			if (data['result'] && data['result']['finCodeMappingApi']) {
				if (data['result']['finCodeMappingApi']['availableFincodes']) {
					this.fincodeList = data['result']['finCodeMappingApi']['availableFincodes'];
				}
				if (data['result']['finCodeMappingApi']['mappedFincodes']) {
					this.fincodeMappedList = data['result']['finCodeMappingApi']['mappedFincodes'];
				}
			}
			this.displaySpinner = false;
			this.showErrorMsg('Updated Successfully');
		});
	}*/
}
