import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'filter'
})
export class FilterPipe implements PipeTransform {
	arrayList: any = [];
	transform(items: any[], searchText: string): any[] {
		if (!items) {
			return [];
		}
		if (!searchText) {
			return items;
		}
		searchText = searchText.toLowerCase();
		this.arrayList = [];
		items.filter(item => {
			if (item.finCode.toLowerCase().includes(searchText)) {
				this.arrayList.push(item);
			}
		});
		return this.arrayList;
	}
}
