export class FileUploadAddPACode {
	pacode: string;
	fincode: string;
	countrycode: string;
	updatedby: string;
	overwrite: string;
	updatedDate: Date;

	constructor(fileUploadAddPACode?: FileUploadAddPACode) {
		this.pacode = '';
		this.fincode = '';
		this.countrycode = '';
		this.updatedby = '';
		this.overwrite = '';
		this.updatedDate = new Date;
	}

	if (fileUploadAddPACode) {
		Object.assign(this, fileUploadAddPACode);
	}
}
