import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'summary-nam-details',
	templateUrl: './summary-nam-details.component.html',
	styleUrls: ['./summary-nam-details.component.css']
})
export class SummaryNamDetailsComponent implements OnInit {

	radioItems: Array<string>;
	model = {option: ''};

	constructor( public router: Router) {
		this.radioItems = ['Personal Entitlement Summary', 'FIN Entitlement Summary'];
	}

	ngOnInit() {
	}

	goToAuditReports() {
		this.router.navigate(['fleet-audit-report']);
	}
}
