export class UserInformationModel {
	wslID: string;
	firstName: string;
	middleinitial: string;
	lastName: string;
	title: string;
	salutation: string;
	address1PostBox: string;
	address2: string;
	city: string;
	state: string;
	postal: string;
	phone: string;
	fax: string;
	email: string;
	updateID: string;
	countryCode: string;
	organizationalUnit: string;
	updateBy: string;
	status: string;

	constructor(userInformationModel?: UserInformationModel) {
		this.wslID = '';
		this.firstName = '';
		this.middleinitial = '';
		this.lastName = '';
		this.title = '';
		this.salutation = '';
		this.address1PostBox = '';
		this.address2 = '';
		this.city = '';
		this.state = '';
		this.postal = '';
		this.phone = '';
		this.fax = '';
		this.email = '';
		this.updateID = '';
		this.countryCode = '';
		this.organizationalUnit = '';
		this.updateBy = '';
		this.status = '';
		if (userInformationModel) {
			Object.assign(this, userInformationModel);
		}
	}
}
