import {Component, OnInit, ViewEncapsulation } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FimpsService} from '../services/fimps.service';
import {DatePipe} from '@angular/common';

@Component({
	selector: 'app-fim-stmt-def',
	templateUrl: './fim-stmt-def.component.html',
	styleUrls: ['./fim-stmt-def.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class FimStmtDefComponent implements OnInit {
	accountName;
	sDate;
	payeeCode;
	finCode;
	statementDate = [];
	statementDateDisplay = [];
	address1;
	city;
	postalCode;
	state;

	constructor(public router: Router, private route: ActivatedRoute, public fims: FimpsService, public datepipe: DatePipe) {
	}

	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			this.accountName = sessionStorage.getItem('accountName');
			this.address1 = sessionStorage.getItem('address1');
			this.city = sessionStorage.getItem('city');
			this.state = sessionStorage.getItem('state');
			this.postalCode = sessionStorage.getItem('postalCode');
			this.sDate = sessionStorage.getItem('sDate');
			this.finCode = sessionStorage.getItem('finCode');
			this.payeeCode = sessionStorage.getItem('payeeCode');
		});

		const formatStatementDate = this.sDate.split(',');
		this.statementDate = [];

		formatStatementDate.forEach(item => {
			this.statementDate.push(item);
		});
	}

	goToFims() {
		this.router.navigate(['fimps-search']);
	}

	goToPreviousPage() {
		this.router.navigate(['fimps-detail-cpa-stmt']);
	}
}
