import {Component, OnInit, ViewEncapsulation } from '@angular/core';
import {Message} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {FkcService} from '../services/fkc.service';
import {FkcRequestInfo} from '../models/FkcRequestInfo';
import {FkcWorkflowRequestInfo} from '../models/FkcWorkflowRequestInfo';
import {ErrorResponseData} from '../models/ErrorResponseData';
import {FkcWorkflowUser} from '../models/FkcWorkflowUser';
import {FinCodeDetailAPI} from '../models/FinCodeDetailAPI';
import {UserInformationModel} from '../models/UserInformation.model';
import {DatePipe} from '@angular/common';

@Component({
	selector: 'app-fkc-request-approval',
	templateUrl: './fkc-request-approval.component.html',
	styleUrls: ['./fkc-request-approval.component.css'],
	encapsulation: ViewEncapsulation.None
})

export class FkcRequestApprovalComponent implements OnInit {
	showRejectCommentBox = false;
	rejectedComments: string;
	display = false;
	approveORReject;
	displayRequest = false;
	showMsg = false;
	reportMsgs: Message[] = [];
	msg: string;
	namId: string;
	reqId: number;
	reqUserType: string;
	requestInfo: FkcRequestInfo;
	requestUserInfo: FkcWorkflowUser;
	showContent: Boolean = true;
	showRejected: Boolean = false;
	showApproved: Boolean = false;
	requestResponse: FkcRequestInfo;
	errorResponse: ErrorResponseData;
	userId: any;
	userType: any;
	showError: Boolean = false;
	showErrMsg: string;
	fincodeDetailsList: FinCodeDetailAPI[];
	isExternalUser = false ;
	requestorCdsId: string;
	requestNAMCdsId: string;
	userInfoList: UserInformationModel[];
	requestorName: string;
	isRequestorNameAvl: Boolean = false;
	userFirstName: string;
	userLastName: string;
	userName: string;
	aprvOrRejDate: string;
	constructor(public router: Router, public fkc: FkcService, private route: ActivatedRoute , private datePipe: DatePipe ) {
	}

	ngOnInit() {
		this.userId = sessionStorage.getItem('userId');
		this.userType = sessionStorage.getItem('userType');
		this.userFirstName = sessionStorage.getItem('userFName');
		this.userLastName = sessionStorage.getItem('userLName');
		this.userName =  this.userFirstName + ' ' + this.userLastName;
			this.namId = this.route.snapshot.paramMap.get('namId');
		    this.reqId = Number(this.route.snapshot.paramMap.get('reqId'));
			this.reqUserType = this.route.snapshot.paramMap.get('userType');
			if (this.reqId === null || this.reqId === undefined) {
				this.router.navigate(['unauthorized']);
			}
		if (this.reqUserType === 'Other' ) {
			if (this.userId.toString().toLowerCase() !== this.namId.toLowerCase()) {
				this.router.navigate(['/unauthorized'], {queryParams: {unauthorizedNAMmsg: true}});
			} else {
				this.getRequestDetails();
			}
		}

		if (this.reqUserType === 'Dealer' || this.reqUserType === 'Employee') {
			this.fkc.getUserForWorkFlow(this.userId.toString().toLowerCase()).subscribe( data => {
				if ( data['result'] && data['result']['fkcWorkFlowUserApi'] ) {
					const status = data['result']['fkcWorkFlowUserApi'].status;
					if ((status && status.toUpperCase() === 'I')) {
						this.router.navigate(['/unauthorized'], {queryParams: {unauthorizedNAMmsg: true}});
					} else {
						this.getRequestDetails();
					}
				} else {
					this.router.navigate(['/unauthorized'], {queryParams: {unauthorizedNAMmsg: true}});
				}
			});
		}
	}

	getRequestDetails() {
			this.fkc.getRequestDetails(this.reqId).subscribe( data => {
				if ( data['result']) {
					this.requestInfo = data['result'];
					const reqStatus = this.requestInfo.getAllRequest[0].reqStatus;
					this.requestorCdsId = this.requestInfo.getAllRequest[0].cdsId;
					this.requestNAMCdsId = this.requestInfo.getAllRequest[0].namCdsId;
					this.aprvOrRejDate = this.datePipe.transform(this.requestInfo.getAllRequest[0].lastUpdateDate , 'MM/dd/yyyy' );
					this.fkc.getUserInfo(this.requestorCdsId).subscribe( dataV => {
						if ( dataV['result']) {
							this.userInfoList = dataV['result']['userInfo'];
							this.requestorName = this.userInfoList[0].firstName + ' ' + this.userInfoList[0].lastName ;
							if (this.requestorName === null && this.requestorName === '') {
								this.isRequestorNameAvl = false;
							} else {
								this.isRequestorNameAvl = true;
							}
						}
					});

					if (reqStatus && reqStatus.toUpperCase() === 'E') {
						this.showContent = false;
					} else if ((reqStatus && reqStatus.toUpperCase() === 'R')  || (reqStatus && reqStatus.toUpperCase() === 'A')) {
						this.router.navigate(['/unauthorized'], {queryParams: {isFkcRequestAprrove: true, namcdsId : this.requestNAMCdsId , approvedOrRejectedDate : this.aprvOrRejDate}});
					} else {
						if (this.reqUserType === 'Other') {
							const reqNamId = this.requestInfo.getAllRequest[0].namCdsId;
							if (reqNamId.toLowerCase() === this.namId.toLowerCase()) {
								this.showContent = true;
								this.loadFINCodes(this.requestorCdsId);
								this.isExternalUser = true;
							} else {
								this.router.navigate(['/unauthorized'], {queryParams: {unauthorizedNAMmsg: true}});
							}
						} else {
							this.showContent = true;
						}
					}
				} else {
					this.showContent = false;
					this.errorResponse = new ErrorResponseData(data);
					if (this.errorResponse.error) {
						this.showErrorMsg(this.errorResponse.error.messages[0]);
					}
				}
			});
	}

	approve() {
		this.showRejectCommentBox = false;
		this.display = false;
	}

	reject() {
		this.showRejectCommentBox = true;
		this.display = false;
		this.rejectedComments = '';
	}

	submit() {
		this.showMsg = false;
		this.displayRequest = false;
		if (this.approveORReject === undefined) {
			this.displayRequest = true;
		} else {
			if (this.approveORReject === 'Approve') {
				this.approveOrRejectRequest('A', '');
			} else {
				if ((this.rejectedComments === '')) {
					this.display = true;
				} else {
					this.approveOrRejectRequest('R', this.rejectedComments);
				}
			}
		}
	}

	approveOrRejectRequest(status, rejectedComments) {
		const fkcAuthReqDetailApi  = new FkcWorkflowRequestInfo();
		fkcAuthReqDetailApi.reqStatus = status;
		fkcAuthReqDetailApi.authReqNumber = this.reqId;
		if (this.reqUserType === 'Other') {
			fkcAuthReqDetailApi.lastUpdateUser = this.namId;
		} else {
			fkcAuthReqDetailApi.lastUpdateUser = this.userId;
		}
		if (status === 'R') {
			fkcAuthReqDetailApi.workflowComments =  rejectedComments;
		}
		this.fkc.approveOrRejectRequest(fkcAuthReqDetailApi, this.reqUserType, this.userName).subscribe( data => {
			if (data['result']) {
				this.requestResponse = data['result'];
				if (this.requestResponse.createFkcAuthReqflag) {
					this.showMsg = true;
					if (status === 'R') {
						this.msg = 'Request has been Rejected Successfully';
					} else {
						this.msg = 'Request has been Approved Successfully';
					}
				} else {
					this.showError = true;
					this.showErrMsg = 'Requested has not been updated.';
				}
			} else {
				this.errorResponse = new ErrorResponseData(data);
				if (this.errorResponse.error) {
					this.showErrorMsg(this.errorResponse.error.messages[0]);
				}
			}
		});
	}

	showErrorMsg(errorMsg) {
		if (errorMsg) {
			this.reportMsgs = [];
			this.reportMsgs.push({
				severity: 'error',
				summary: 'Error Message',
				detail: errorMsg
			});
		}
	}

	loadFINCodes(userId) {
		this.fkc.getFinCodes(userId).subscribe( data => {
			if (data['result'] && data['result']['getFINdetails']) {
				this.fincodeDetailsList = data['result']['getFINdetails'];
			}
		});
	}
}
