import {Component, OnInit, ViewEncapsulation } from '@angular/core';
import {Message} from 'primeng/api';
import {Router} from '@angular/router';
import {CpaMessageMaintenanceService} from '../services/cpa-message-maintenance.service';

@Component({
	selector: 'app-cpa-lookup',
	templateUrl: './cpa-lookup.component.html',
	styleUrls: ['./cpa-lookup.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class CpaLookupComponent implements OnInit {
	finCode: string;
	isApproved: any;
	vin: any;
	reportMsgs: Message[] = [];
	showMsg: Boolean = false;
	showErrMsg: string ;
	cpaMessage: string;
	showCpaMessage: boolean = false;

	constructor(public router: Router,public messageMaintenanceService: CpaMessageMaintenanceService) {
	}

	ngOnInit() {
		this.loadCpaMessage();
	}

	loadCpaMessage(){
		this.messageMaintenanceService.loadCpaMessage().subscribe(response =>{
			if(response['result'] != null && response['result'] != undefined){
				var resultMessaage = response['result']['getCpaMsg']['message'];
				this.cpaMessage = resultMessaage.split("<br />").join("\n");
				this.showCpaMessage = true;
			}
			else{
				this.showCpaMessage = false;
			}
		});
	}

	clear() {
		this.finCode = null;
		this.vin = null;
		this.isApproved = false;
	}

	submit() {
		if (this.finCode === null || this.finCode === undefined || this.finCode === '') {
			this.showErrMsg = 'A valid 5-character FIN must be entered.(Example:XX123)';
			this.showMsg = true;
		} else if (this.finCode && this.finCode.length < 5) {
			this.showErrMsg = 'A 5-digit FIN must be entered ';
				this.showMsg = true;
		} else if (this.vin === null || this.vin === undefined || this.vin === '') {
			this.showErrMsg = 'A 17-digit VIN must be entered';
			this.showMsg = true;
		} else if (this.vin && this.vin.length < 17) {
			this.showErrMsg = 'A 17-digit VIN must be entered ';
			this.showMsg = true;
		} else if (this.isApproved === null || this.isApproved === undefined || this.isApproved === '' || !this.isApproved) {
			this.showErrMsg = 'You must check the Agreement box to proceed with a CPA look up.';
			this.showMsg = true;
		} else {
				this.router.navigate(['cpa-lookup-result'], { queryParams: { finCode: this.finCode, vin: this.vin }});
	
		}
	}

	showErrorMsg(errorMsg) {
		if (errorMsg) {
			this.reportMsgs = [];
			this.reportMsgs.push({
				severity: 'error',
				summary: 'Error Message',
				detail: errorMsg
			});
		}
	}

}
