import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CookieHomeService} from '../services/cookiehome.service';
import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import {ViewEncapsulation} from '@angular/core';
import {CookieHomeModel} from '../models/CookieHome.model';
import {FinListDetails} from '../models/FinListDetails';

@Component({
	selector: 'cookie-home',
	templateUrl: './cookie-home.component.html',
	styleUrls: ['./cookie-home.component.css']
})
export class CookieHomeComponent implements OnInit {

	userId: string;
	wslId: string;
	paCode: string;
	finIndicator: string;
	personalIndicator: string;
	brandImageName: string;
	finList: string;
	primaryFIN: string;
	primaryFinPACode: string;
	marketSegment: string;
	salesRep: string;
	redirect: string;
	redirects: string;
	role: string;
	otherEntitlements: string;
	otherGroups: string;
	CookieList = [];
	otherEntList = [];
	otherGrpList = [];
	public displaySpinner: boolean = false;
	public cookieshow: boolean = false;
	public finlistshow: boolean = false;
	public Dealershow: boolean = false;
	public Externalshow: boolean = false;
	findetails: string;
	errorMessage: string;
	public displayMessage: boolean = false;
	homefocus: ElementRef;
	@ViewChild('focus',{static:false}) focus: ElementRef;
	userid: string;

	finListDetails: FinListDetails[];

	cookieHomeModel: CookieHomeModel;

	constructor(private cookieHomeService: CookieHomeService, private messageService: MessageService, private confirmationService: ConfirmationService) {
	}

	ngOnInit() {
		//this.displaySpinner = true;
		this.cookieshow = false;
		//this.userId = sessionStorage.getItem('userId');
		//this.loadUserDetails(this.userId);

	}

	loadUserDetails(userid) {
		this.displaySpinner = true;
		if(userid == null || userid == undefined || userid.trim() == '') {
			this.displaySpinner = false;
			this.displayMessage = true;
			this.showErrorMsg("Please enter the User ID");
			this.homefocus = this.focus;
			return;
		}
		this.cookieHomeService.getCookieInfo(userid).subscribe(data => {
			this.displaySpinner = false;
			this.cookieshow = true;
			this.CookieList = [];
			this.otherEntList = [];
			this.otherGrpList = [];
			this.CookieList.push(data);
			this.wslId = this.validation(data['wslId']);
			this.paCode = this.validation(data['paCode']);
			this.finIndicator = this.validation(data['finIndicator']);
			this.primaryFIN = this.validation(data['primaryFIN']);
			this.primaryFinPACode = this.validation(data['primaryDealerPACode'])
			this.personalIndicator = this.validation(data['personalIndicator']);
			this.otherEntitlements = this.validation(data['otherEntitlements']);
			this.otherEntList = this.otherEntitlements.split(',');
			this.otherGroups = this.validation(data['otherGroups']);
			this.otherGrpList = this.otherGroups.split(',');
			this.brandImageName = 'null';
			//this.brandImageName = this.validation(data['brandImageName']);
			this.finList = this.validation(data['finList']);
			this.marketSegment = this.validation(data['marketSegment']);
			this.salesRep = this.validation(data['salesRep']);
			this.redirect = this.validation(data['redirect']);
			this.finListDetails = data['finDetail'];
			this.role = data['role'];


			if (this.finListDetails === null || this.finListDetails === undefined) {
				this.finlistshow = false;
			} else {
				this.finlistshow = true;
			}

			if (this.role !== null) {
				if (this.role !== undefined) {
					if (this.role.trim().toUpperCase() === 'Dealer'.toUpperCase()) {
						this.Dealershow = true;
					} else {
						this.Dealershow = false;
					}

					if (this.role.trim().toUpperCase() === 'Other'.toUpperCase()) {
						this.Externalshow = true;
					} else {
						this.Externalshow = false;
					}
				}
			}

			//alert(this.finListDetails[0].fincode);
		});


		// this.cookieHomeService.getPersonalindicator(userid).subscribe(datas => {
        //
		// 	this.personalIndicator = this.validation(datas['personCode']);
		// 	const testvar = [];
		// 	if (datas['result']['personalIndicator'] != null) {
        //
		// 		datas['result']['personalIndicator'].forEach((dataValue) => {
		// 			testvar.push(Object.assign({}, dataValue));
		// 		});
		// 	}
		// 	alert(testvar);
        //
		// });

	}

	reset() {
		this.userid = '';
		//this.showAccessCode = false;
	}

	validation(value) {
		this.redirects = 'null';
		if (value !== null) {
			this.redirects = value;
		}

		if (this.redirects === undefined || this.redirects === '') {
			this.redirects = 'null';
		}

		if (this.redirects !== 'null') {
			if (this.redirects.indexOf(',')) {
				this.redirects = this.redirects.replace(/,/g, ',\n');
			}
		}

		return this.redirects;
	}

	showErrorMsg(errorMsg) {
		this.displayMessage = true;
		this.errorMessage = errorMsg;
	}

	popupclose(focus) {
		this.displayMessage = false;
		setTimeout(() => {
			if (focus !== undefined)
				focus.nativeElement.focus();
		}, 200);
	}

}


