import {Component, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {Message} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import {CpaLookupService} from '../services/cpa-lookup.service';
import {ErrorResponseData} from '../models/ErrorResponseData';
import {CpaLookup} from '../models/CpaLookup';
import {CpaMessageMaintenanceService} from '../services/cpa-message-maintenance.service';

@Component({
	selector: 'app-cpa-lookup-searchresult',
	templateUrl: './cpa-lookup-searchresult.component.html',
	styleUrls: ['./cpa-lookup-searchresult.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class CpaLookupSearchresultComponent implements OnInit {
	finCode: string;
	vin: any;
	reportMsgs: Message[] = [];
	// lookupReport: CpaLookup[];
	lookupReport : any ;
	optionIncentive : any ;
	accountManger : any;
	recallDataApi : any;
	recallDataApiPrint : any;
	displayModal: boolean;
	jsonData : any;
	cpaAmountSearchCriteria4 : any
	paCode: any = '';
	userType: any;
	siteCode: any;
	errorMsg: string;
	showError: Boolean = false;
	errorResponse: ErrorResponseData;
	selectedDate: any;
	showSpinner: Boolean = false;
	lookupReportTest: string;
	userId: string;
	lookupTypeCode: string;
	userTypeCode: string;
	searchCriteria: Object[] = [];
	dateVal;
	showCpaMessage: boolean = false;
	cpaMessage: string;
	constructor(public router: Router, private route: ActivatedRoute, public datepipe: DatePipe, public cpa: CpaLookupService, public messageMaintenanceService: CpaMessageMaintenanceService) {
	}

	ngOnInit() {
		this.loadCpaMessage();
		setInterval(()=>{this.loadCpaMessage()},5000);
		this.route.queryParams.subscribe(params => {
			this.finCode = params['finCode'];
			this.vin = params['vin'];
		});
		this.loadCPAReport();
	}
	
	loadCpaMessage(){
		this.messageMaintenanceService.loadCpaMessage().subscribe(response =>{
			if(response['result'] != null && response['result'] != undefined){
				var resultMessaage = response['result']['getCpaMsg']['message'];
				this.cpaMessage = resultMessaage.split("<br />").join("\n");
				this.showCpaMessage = true;
			}
			else{
				this.showCpaMessage = false;
			}
		});
	}

	loadSearchData() {
		const cpaAmountSearchCriteria1 = {'searchLabel': 'FIN Code:', 'searchValue': this.finCode.toUpperCase()};
		const cpaAmountSearchCriteria2 = {'searchLabel': 'VIN: ', 'searchValue': this.vin.toUpperCase()};
		let cpaAmountSearchCriteria3;
		if (this.datepipe) {
			this.dateVal = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm:ss a');
			cpaAmountSearchCriteria3 = {
				'searchLabel': 'Date:',
				'searchValue': this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm:ss a')
			};
		}
		var cpaAmountSearchCriteria4 = {'searchLabel': 'Account Manager CDSID: ', 'searchValue': this.accountManger};
		this.searchCriteria.push(cpaAmountSearchCriteria1);
		this.searchCriteria.push(cpaAmountSearchCriteria4);
		this.searchCriteria.push(cpaAmountSearchCriteria2);
		this.searchCriteria.push(cpaAmountSearchCriteria3);
	}

	loadCPAReport() {
		this.showSpinner = true;
		this.lookupReport = [];
		this.userType = sessionStorage.getItem('userType');
		this.siteCode = sessionStorage.getItem('siteCode');
		this.userId = sessionStorage.getItem('userId');
		this.lookupTypeCode = 'C';
		if (this.userType === 'Dealer') {
			this.paCode = 	this.siteCode;
		}
		if (this.userId && this.userId.substring(1, 2) === '-') {
			this.userTypeCode = 'E';
		} else {
			this.userTypeCode = 'I';
		}
		const cpaAmountRequest = {'dealerPaCode' : this.paCode , 'fin' : this.finCode,  'vin' : this.vin, 'userId': this.userId, 'lookupTypeCode': this.lookupTypeCode, 'userTypeCode': this.userTypeCode };
		this.loadSearchData();	

		this.cpa.getCPALookupReport(cpaAmountRequest).subscribe( data => {
		
			if (data['result'] && data['result']['cpaLookupDetailApi']) {
				this.lookupReport = [data['result']['cpaLookupDetailApi']];
				this.optionIncentive = data['result']['cpaLookupDetailApi'].optionIncentive;
				this.accountManger=  data['result']['cpaLookupDetailApi'].accountManager;
				this.recallDataApi = [data['result']['cpaLookupDetailApi'].recallDataApi];
				this.recallDataApiPrint = data['result']['cpaLookupDetailApi'].recallDataApi;
				this.searchCriteria = [];
				this.loadSearchData();
				this.showSpinner = false;
				this.showError = false;
			
			} else {
				this.errorResponse = new ErrorResponseData(data);
				this.showError = true;
				this.showSpinner = false;
				if (this.errorResponse && this.errorResponse.error && this.errorResponse.error.messages) {
					this.errorMsg = this.errorResponse.error.messages[0];
				}
			}
		});

		this.selectedDate = new Date();
	}
	goToNewSearch() {
			this.router.navigate(['cpa-lookup']);
	}
}
