import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Message} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {RipsService} from '../services/rips.service';
import {DropdownItem} from '../models/dropdownItem';
import {ErrorResponseData} from '../models/ErrorResponseData';
import {RipsHeaderData} from '../models/RipsHeaderData';
import {StatementDates} from '../models/StatementDates';

@Component({
	selector: 'app-rips',
	templateUrl: './rips.component.html',
	styleUrls: ['./rips.component.css']
})
export class RipsComponent implements OnInit {
	selectedSearchCriteria = '1';
	singleVin: any;
	multipleVin: any;
	showStatement: Boolean = false;
	showVin: Boolean = true;
	finCode: DropdownItem[];
	payeeCode: DropdownItem[];
	disableFinCode: Boolean = true;
	statementDates: DropdownItem[];
	reports: DropdownItem[];
	selectedpayeeCode = 'All';
	selectedfinCode = '';
	selectedStatementDate: any;
	selectedReport = 'A';
	reportMsgs: Message[] = [];
	fincodeList: RipsHeaderData[];
	sDateList: StatementDates;
	payeeHeader: any;
	errorResponse: ErrorResponseData;
	@ViewChild('singleVinTxt',{static:false}) singleVinTxt: ElementRef;
	showSpinner = false;
	adminFinCode: any;
	isAdmin: boolean;
	fincodeListExists: boolean;
	payeecodeListExists: boolean;
	userType: string;
	errorMessage: string;
	norecords: boolean;
	viewMainPage: boolean;
	displayMessage: boolean;
	validate: string;
	payeeValue: any;

	constructor(public rips: RipsService, public router: Router, public datepipe: DatePipe, private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.adminFinCode = '';
		this.isAdmin = false;
		this.norecords = false;
		this.viewMainPage = false;

		this.clearSession();

		this.userType = sessionStorage.getItem('userType');
		if ((this.userType && this.userType === 'Employee')) {
			this.adminFinCode = sessionStorage.getItem('payeeHeaderMainRips');
			sessionStorage.setItem('payeeHeaderMainRips', '');

			this.isAdmin = true;
		}

		if ((this.userType && this.userType === 'Employee') && (this.adminFinCode === '' || this.adminFinCode === undefined || this.adminFinCode === null)) {
			this.isAdmin = true;
			this.router.navigate(['rips-search']);
			return;
		}

		this.loadPayeeCode();
		this.loadStatementDate();
		this.loadReports();
	}

	loadPayeeCode() {
		this.showSpinner = true;
		this.payeecodeListExists = true;
		this.rips.getPayeeCodeForRips(this.adminFinCode).subscribe(data => {
			const options = [];
			options.push({'label': '-Select-', 'value': ""});
			if (data['result'] !== null && data['result']['ripsHeaderData']) {
				this.fincodeList = data['result'];
				this.payeeHeader = data['result']['ripsHeaderData']['payeeHeader'] ? data['result']['ripsHeaderData']['payeeHeader'].slice(0, 5) : '';
				if (this.isAdmin && data['result']['ripsHeaderData']['finlist'].length <= 0) {
					this.payeecodeListExists = false;
					this.norecords = true;
					this.viewMainPage = false;
				} else if (data['result']['ripsHeaderData']['finlist'].length <= 0) {
					this.norecords = true;
				} else {
					this.norecords = false;
					this.viewMainPage = true;
				}

				for (const val of data['result']['ripsHeaderData']['finlist']) {
					options.push({'label': val.slice(0, 5), 'value': val.slice(0, 5)});
				}
				this.payeeCode = options;
				this.showSpinner = false;
			} else {
				this.showSpinner = false;
				this.errorResponse = new ErrorResponseData(data);
				this.errorMessage = this.errorResponse.error.messages[0];
				this.norecords = true;
			}
		});
	}

	onchangeType() {
		this.selectedfinCode = '';
		this.loadFinCode(this.selectedpayeeCode);

	}


	loadFinCode(selectedpayeeCode) {

		if(selectedpayeeCode !== "") {
		this.disableFinCode = false;
		this.showSpinner = true;
		this.fincodeListExists = true;

		this.rips.getFinCodeForRips(selectedpayeeCode).subscribe(data => {
			const options = [];
			options.push({'label': '-Select-', 'value': ''});
			options.push({'label': 'All', 'value': 'All'});
			if (data['result'] !== null && data['result']['ripsHeaderData']) {
				this.fincodeList = data['result'];
				this.payeeHeader = data['result']['ripsHeaderData']['payeeHeader'] ? data['result']['ripsHeaderData']['payeeHeader'].slice(0, 5) : '';
				if (this.isAdmin && data['result']['ripsHeaderData']['finlist'].length <= 0) {
					this.fincodeListExists = false;
					this.norecords = true;
					this.viewMainPage = false;
				} else if (data['result']['ripsHeaderData']['finlist'].length <= 0) {
					this.norecords = true;
				} else {
					this.norecords = false;
					this.viewMainPage = true;
				}

				for (const val of data['result']['ripsHeaderData']['finlist']) {
					options.push({'label': val.slice(0, 5), 'value': val.slice(0, 5)});
				}
				this.finCode = options;
				this.showSpinner = false;
			} else {
				this.showSpinner = false;
				this.errorResponse = new ErrorResponseData(data);
				this.errorMessage = this.errorResponse.error.messages[0];
				this.finCode =null;
				this.disableFinCode = true;
				this.norecords = true;
			}
		});

		} else {
			this.disableFinCode = true;
		}
	}

	loadStatementDate() {
		this.errorResponse = new ErrorResponseData();
		const options = [];
		this.rips.getStatementDateForRips().subscribe(data => {
			options.push({'label': '-Select-', 'value': ''});
			if (data['result'] !== null && data['result']['statementDateData'] !== null) {
				this.sDateList = data['result']['statementDateData'];
				for (const val of this.sDateList.statementDate) {
					options.push({'label': val, 'value': val});
				}
			} else {
				this.showSpinner = false;
				this.errorResponse = new ErrorResponseData(data);
				this.showErrorMsg(this.errorResponse.error.messages[0]);
			}
			this.statementDates = options;
			this.selectedStatementDate = this.statementDates && this.statementDates.length > 1 ? this.statementDates[1].value : '';
		});
	}

	loadReports() {
		const options1 = [];
		options1.push({'label': '-Select-', 'value': ''});
		options1.push({'label': 'All', 'value': 'A'});
		options1.push({'label': 'Payment', 'value': '1'});
		options1.push({'label': 'Adjustment', 'value': '2'});
		options1.push({'label': 'Chargeback/Collection', 'value': '3'});
		options1.push({'label': 'Ineligible', 'value': '4'});
		this.reports = options1;
	}

	goToRipsPage() {
		this.router.navigate(['rips-search']);
	}

	goToNewStatement() {
		if (this.userType && this.userType === 'Employee') {
			this.router.navigate(['rips-search']);
		}
	}

	sumbit() {
		if (this.selectedSearchCriteria && this.selectedSearchCriteria === '1') {
			if (this.selectedpayeeCode === '') {
				this.showErrorMsg('Please Select the PAYEE Code.');
			} else if (this.selectedfinCode === '') {
				this.showErrorMsg('Please Select the FIN Code.');
			} else if (this.selectedStatementDate === '') {
				this.showErrorMsg('Please Select the Statement Date.');
			} else if (this.selectedReport === '') {
				this.showErrorMsg('Please Select the Report Type');
			} else {
				sessionStorage.setItem('searchTypeRips', this.selectedSearchCriteria);
				sessionStorage.setItem('payeeHeaderRips', this.payeeHeader);
				sessionStorage.setItem('finCodeRips', this.selectedfinCode);
				sessionStorage.setItem('reportTypeRips', this.selectedReport);
				sessionStorage.setItem('dateTypeRips', this.selectedStatementDate);
				this.router.navigate(['rips-stmt-report']);
			}
		}
		if (this.selectedSearchCriteria && this.selectedSearchCriteria === '2') {
			if (this.selectedpayeeCode === '') {
				this.showErrorMsg('Please Select the PAYEE Code.');
			} else if (this.selectedfinCode === '') {
				this.showErrorMsg('Please Select the FIN Code.');
			} else if ((this.singleVin === undefined || this.singleVin === '') && (this.multipleVin === undefined || this.multipleVin === '')) {
				this.showErrorMsg('Please enter valid VIN with alphanumeric characters only');
			} else if ((this.singleVin !== undefined && this.singleVin !== '') && (this.multipleVin !== undefined && this.multipleVin !== '')) {
				this.showErrorMsg('Enter either Single VIN or Multiple VIN codes.');
				return false;
			} else if (this.singleVin !== undefined && this.singleVin !== '' && this.singleVin !== '') {
				if (this.singleVin.length > 0) {
					const isAlphaNum = /^[A-Za-z0-9]+$/.test(this.singleVin);
					if (!isAlphaNum) {
						this.showErrorMsg('Please enter valid VIN with alphanumeric characters only');
						return false;
					}
				}

				if (this.singleVin.length === 10 || this.singleVin.length === 17) {
					if (this.singleVin.length === 17) {
						if (!(isNaN(this.singleVin.charAt(6)))) {
							if (this.singleVin.charAt(9) === 'A' || this.singleVin.charAt(9) === 'U' || this.singleVin.charAt(9) === 'Z' || this.singleVin.charAt(9) === '0') {
								this.showErrorMsg('10th digit of VIN should be valid character other than A,Z,U,0.');
								this.singleVinTxt.nativeElement.focus();
								return false;
							}
						}
					}
				} else {
					this.showErrorMsg('Requires (First 2 + Last 8 of VIN) or 17 digits of VIN.');
					return false;
				}

				sessionStorage.setItem('searchTypeRips', this.selectedSearchCriteria);
				sessionStorage.setItem('payeeHeaderRips', this.payeeHeader);
				sessionStorage.setItem('isSingleVINRips', 'true');
				sessionStorage.setItem('VINRips', this.singleVin);
				sessionStorage.setItem('finCodeRips', this.selectedpayeeCode);
				sessionStorage.setItem('finCodeRips', this.selectedfinCode);
				sessionStorage.setItem('reportTypeRips', this.selectedReport);
				this.router.navigate(['rips-stmt-report']);
			} else if (this.multipleVin !== undefined && this.multipleVin !== '' && this.multipleVin !== '') {
				if (this.multipleVin.length > 0) {
					this.validate = this.multipleVin;
					const isAlphaNum = /^[A-Za-z0-9\s]+$/.test(this.multipleVin);
					this.validate = this.validate.replace(/ {2,}/g, '').trim();
					this.validate = this.validate.replace(/[\n\r]/g, '').trim();

					if (this.validate.indexOf(',') > 0) {
						this.showErrorMsg('Please delete the comma between the VINs.');
						return false;
					} else if (!isAlphaNum) {
						this.showErrorMsg('Please enter valid VIN with alphanumeric characters only');
						return false;
					} else if (this.validate.length < 17) {
						this.showErrorMsg('Each VIN should be 17 characters long');
						return false;
					} else if (this.validate.length > 425) {
						this.showErrorMsg('Maximum of 25 VINs only can be entered');
						return false;
					}
				}

				this.validate = this.validate.replace(/\s/g, '').trim();
				sessionStorage.setItem('searchTypeRips', this.selectedSearchCriteria);
				sessionStorage.setItem('payeeHeaderRips', this.payeeHeader);
				sessionStorage.setItem('isSingleVINRips', 'false');
				sessionStorage.setItem('VINRips', this.validate);
				sessionStorage.setItem('finCodeRips', this.selectedpayeeCode);
				sessionStorage.setItem('finCodeRips', this.selectedfinCode);
				sessionStorage.setItem('reportTypeRips', this.selectedReport);
				this.router.navigate(['rips-stmt-report']);
			}
		}
		return false;
	}

	onSearchCriteriaClicked(value) {
		if (value && value === '1') {
			this.showStatement = false;
			this.showVin = true;
			this.singleVin = '';
			this.multipleVin = '';
		} else if (value && value === '2') {
			this.showStatement = true;
			this.showVin = false;
			this.selectedReport = '';
			this.selectedStatementDate = '';
		}
	}

	clear() {
		this.selectedReport = '';
		this.singleVin = '';
		this.multipleVin = '';
		this.selectedfinCode = '';
		this.selectedpayeeCode ='';
		this.selectedStatementDate = '';
		this.disableFinCode = true;
	}

	showErrorMsg(errorMsg) {
		this.displayMessage = true;
		this.errorMessage = errorMsg;
	}

	clearSession() {
		sessionStorage.removeItem('searchTypeRips');
		sessionStorage.removeItem('payeeHeaderRips');
		sessionStorage.removeItem('isSingleVINRips');
		sessionStorage.removeItem('VINRips');
		sessionStorage.removeItem('finCodeRips');
		sessionStorage.removeItem('reportTypeRips');
		sessionStorage.removeItem('dateTypeRips');
	}
}
