import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {DatePipe} from '@angular/common';
import {ErrorResponseData} from '../models/ErrorResponseData';
import {ApiError} from '../models/ApiError';
import {FimpsService} from '../services/fimps.service';
import {FinIndicatorService} from '../services/finIndicator.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FinCodeModel} from '../models/FinCode.model';
import {FinIndicatorApi} from '../models/FinIndicatorApi';
import {DropdownItem} from '../models/dropdownItem';
import {PaginatorModule} from 'primeng/paginator';
import { FinEntitlementArray } from '../../app/models/FinEntitlementArray.model';
import { saveAs } from 'file-saver';

@Component({
	selector: 'app-fin-entitilement-list',
	templateUrl: './fin-entitilement-list.component.html',
	styleUrls: ['./fin-entitilement-list.component.css']
})
export class FinEntitilementListComponent implements OnInit {
	personalIndicatorList: SelectItem[];
	//finindicatorDropdown: DropdownItem[];
	finIndicatorDescription : DropdownItem[];
	finindicatorDropdown: SelectItem[];
	selectedfinIndicatorCode: any[];
	finCountryCode: DropdownItem[];
	selectedCountryCode: any[];
	finIndicator: string;
	todayDate = new Date();
	toDate: string;
	reportData: any[];
	reportData1: any[];
	showReport = false;
	showMsg = false;
	errorResponse: ErrorResponseData;
	errorTest: ApiError;
	fincodeTest: string;
	fincodeList: FinIndicatorApi[];
	showSpinner: Boolean = false;
	displayMessage: boolean = false;
	errorMessage: string;
	finIndicatorCode: string;
	totalRecords: number;
	cols: any[];
	Pageary: any[];
	Pagerows: number = 10;
	rowsOption = [10, 20, 30, 40, 50];
	inputRows: number ;
	isCountryCode: boolean;

	@ViewChild('myInput',{static:false}) myInput: ElementRef;
	@ViewChild("countrydropdown", { read: ElementRef ,static:false}) tref: ElementRef;
	@ViewChild("findropdown", { read: ElementRef,static:false }) finref: ElementRef;
	constructor(public router: Router, public datePipe: DatePipe, private route: ActivatedRoute, public fins: FinIndicatorService) {
		this.finindicatorDropdown = [
			{label: '1-X-PLAN', value: '1'},
			{label: '2-Rental Repurchase', value: '2'},
			{ value :  '3' , label: '3-QVM'  },
			{ value :  '4' , label: '4-Order Flow Report'  },
			{ value :  '5' , label: '5-Forecast Tool'  },
			{ value :  '7' , label: '7-CONCEPS'  },
			{ value :  '6' , label: '6-Taurus Pool Program'  },
			{ value :  '8' , label: '8-Warranty'  },
			{ value :  '9' , label: '9-Ford Vehicle Locator'  },
			{ value :  '10' , label: '10-GLOBAL FLEET'  },
			{ value :  '11' , label: '11-Advance Spec'  },
			{ value :  '12' , label: '12-NFPP Eligible'  },
			{ value :  '13' , label: '13-NFPP Enrolled'  },
			{ value :  '14' , label: '14-Non CPA'  },
			{ value :  '15' , label: '15-RV Pool Accounts'  },
			{ value :  '16' , label: '16-eSourceBook'  },
			{ value :  '17' , label: '17-Vehicle Locator'  },
			{ value :  '18' , label: '18-Vincent'  },
			{ value :  '19' , label: '19-Price Lists'  },
			{ value :  '20' , label: '20-eFCs'  },
			{ value :  '21' , label: '21-Incentive Matrix'  },
			{ value :  '22' , label: '22-Federal Gov'  },
			{ value :  '23' , label: '23-Batch OASIS'  },
			{ value :  '24' , label: '24-Automated Non Return Project'  },
			{ value :  '25' , label: '25-Large 9 FMCs'  },
			{ value :  '26' , label: '26-Rental Forecasting'  },
			{ value :  '27' , label: '27-CPA LOOK UP TOOL'  },
			{ value :  '84' , label: '84-Dealer Statements'  }];
		this.toDate = this.datePipe.transform(this.todayDate, 'dd/MM/yyyy');
		console.log('Constructor');
		this.finCountryCode = [
			{label: 'USA', value: 'USA'},
			{ value :  'CAN' , label: 'CAN'  }];

		//alert('Constructor')
		this.loadFinIndicator();
	}

	ngOnInit() {

		this.finIndicator = '';
		this.cols = [
			{ field: 'userId', header: 'User ID' },
			{ field: 'firstName', header: 'User First Name' },
			{ field: 'lastName', header: 'User Last Name' },
			//{ field: 'userStatus', header: 'User Status' },
			//{ field: 'nam', header: 'Account Manager' },
			//{ field: 'countryCode', header: 'User\'s Country Code' },
			{ field: 'countryCode', header: 'FIN Country Code' },
			{ field: 'finCode', header: 'FIN' },
			{ field: 'finIndicator', header: 'FIN Entitlement' },
			{ field: 'finType', header: 'FIN Type' },
			{ field: 'finDescription', header: 'FIN Entitlement Description' },
			{ field: 'lastLoginDate', header: 'Last Logon Date'},
			{ field: 'lastPasswordResetDate', header: 'Last Password Reset Date'},
			{ field: 'updatedBy', header: 'Last Updated By' },
			{ field: 'updatedDate', header: 'Last Updated Date' }
		];
		this.isCountryCode = true;
	}

	showAll() {
		var h = this.tref.nativeElement;
		var text = document.createTextNode("All");
		h.childNodes[0].lastChild.lastChild.childNodes[1].childNodes[1].after(text);
	}

	finshowAll() {
		var i = this.finref.nativeElement;
		var text = document.createTextNode("All");
		i.childNodes[0].lastChild.lastChild.childNodes[1].childNodes[1].after(text);
	}

	loadReport() {

		// this.reportData = [{ 'finType': 'Sales', 'updatedBy': 'd-davi53', 'finIndicator' :  '1', 'finIndicatorDesc': 'X-PLAN', 'userLName': 'Cengel', 'finStatus': 'A', 'namStatus': 'A', 'namCode': 'NAM', 'namId': 'BCLISSO1', 'userId': 'e-cass1', 'nam': 'BradClissold', 'userStatus': 'A', 'userName' : 'Eric', 'fleetaccName' : 'Q S I', 'fincode' : 'AF519', 'perIndicator': 'Fimps Statement', 'lastLogin': '07/12/2020' },
		// 	{ 'finType': 'Sales', 'updatedBy': 'd-davi53', 'finIndicator' :  '3' , 'finIndicatorDesc': 'QVM', 'userLName': 'Welch', 'finStatus': 'A', 'namStatus': 'A', 'namCode': 'NAM', 'namId': 'BCLISSO1', 'userId': 'd-hartm1', 'nam': 'BradClissold', 'userStatus': 'A', 'userName' : 'Debbie', 'fleetaccName' : 'The Cincinnati Insurance Company', 'fincode' : 'WD571', 'perIndicator': 'Fimps Statement', 'lastLogin': '06/11/2020'   },
		// 	{ 'finType': 'Sales', 'updatedBy': 'd-davi53', 'finIndicator' :  '4' , 'finIndicatorDesc': 'Order Flow Report', 'userLName': 'Smith', 'finStatus': 'A', 'namStatus': 'A', 'namCode': 'NAM', 'namId': 'COMMSTR1', 'userId': 'a-smi408', 'nam': 'COMMSTR1', 'userStatus': 'A', 'userName' : 'Amie', 'fleetaccName' : 'The Cincinnati Insurance Company', 'fincode' : 'WQ842', 'perIndicator': 'Fimps Statement', 'lastLogin': '08/26/2020'   },
		// 	{ 'finType': 'Sales', 'updatedBy': 'd-davi53', 'finIndicator' :  '6' , 'finIndicatorDesc': 'Taurus Pool Program', 'userLName': 'KRISTAL', 'finStatus': 'A', 'namStatus': 'A', 'namCode': 'CBM', 'namId': 'CHAUTZIN', 'userId': 'r-diede6', 'nam': 'CoreyHautzinger', 'userStatus': 'A', 'userName' : 'Robert', 'fleetaccName' : 'Puratos Corp', 'fincode' : 'VV708', 'perIndicator': 'RIPS', 'lastLogin': '11/12/2020'   },
		// 	{ 'finType': 'Sales', 'updatedBy': 'd-davi53', 'finIndicator' :  '8' , 'finIndicatorDesc': 'Warranty', 'userLName': 'Smith', 'finStatus': 'A', 'namStatus': 'A', 'namCode': 'CBM', 'namId': 'DOCONNO3', 'userId': 'a-smi408', 'nam': 'DanO\'Connor', 'userStatus': 'A', 'userName' : 'Amie', 'fleetaccName' : 'Antero Resources Corp', 'fincode' : 'NL762', 'perIndicator': 'RIPS', 'lastLogin': '06/30/2020'   },
		// 	{ 'finType': 'Sales', 'updatedBy': 'd-davi53', 'finIndicator' :  '14' , 'finIndicatorDesc': 'Non CPA' , 'userLName': 'Spiezio', 'finStatus': 'A', 'namStatus': 'A', 'namCode': 'NAM', 'namId': 'DOCONNO3', 'userId': 'e-cass1', 'nam': 'DanO\'Connor', 'userStatus': 'A', 'userName' : 'Eric', 'fleetaccName' : 'Antero Resources Corp', 'fincode' : 'AF519', 'perIndicator': 'Key Codes', 'lastLogin': '10/22/2019'   },
		// 	{ 'finType': 'Sales', 'updatedBy': 'd-davi53', 'finIndicator' :  '15' , 'finIndicatorDesc': 'RV Pool Accounts', 'userLName': 'KRISTAL', 'finStatus': 'A', 'namStatus': 'A', 'namCode': 'NAM', 'namId': 'DBEARDEN', 'userId': 'd-davi53', 'nam': 'DennisBearden', 'userStatus': 'A', 'userName' : 'Dan', 'fleetaccName' : 'Emcor Group Inc', 'fincode' : 'VK011' , 'perIndicator': 'Dealer Statements', 'lastLogin': '07/30/2019'  },
		// 	{ 'finType': 'Sales', 'updatedBy': 'd-davi53', 'finIndicator' :  '16' , 'finIndicatorDesc': 'eSourceBook' , 'userLName': 'Waldrop', 'finStatus': 'A', 'namStatus': 'A', 'namCode': 'NAM', 'namId': 'AHILL10', 'userId': 'r-spiezi', 'nam': 'AndyHill', 'userStatus': 'A', 'userName' : 'Ryan', 'fleetaccName' : 'IndusTREE Timber, Inc', 'fincode' : 'AI991' , 'perIndicator': 'Dealer Statements', 'lastLogin': '10/26/2019'  },
		// 	{ 'finType': 'Sales', 'updatedBy': 'd-davi53', 'finIndicator' :  '4' , 'finIndicatorDesc': 'Order Flow Report', 'userLName': 'Fallucca', 'finStatus': 'A', 'namStatus': 'A', 'namCode': 'CBM', 'namId': 'CCANNONS', 'userId': 'w-davi97', 'nam': 'CRAIGCANNONS', 'userStatus': 'A', 'userName' : 'William', 'fleetaccName' : 'Southeast Connections Inc', 'fincode' : 'WN237' , 'perIndicator': 'Fimps Statement', 'lastLogin': '02/16/2019'  },
		// 	{ 'finType': 'Sales', 'updatedBy': 'd-davi53', 'finIndicator' :  '6' , 'finIndicatorDesc': 'Taurus Pool Program', 'userLName': 'Spiezio', 'finStatus': 'A', 'namStatus': 'A', 'namCode': 'NAM', 'namId': 'CCANNONS', 'userId': 'e-waldr5', 'nam': 'CRAIGCANNONS', 'userStatus': 'A', 'userName' : 'Eddie', 'fleetaccName' : 'Blossman Gas Inc', 'fincode' : 'AQ619', 'perIndicator': 'Fimps Statement', 'lastLogin': '04/22/2019'   }
		// ];

		const finEntitlement: FinEntitlementArray = {
			finEntitlementList: this.selectedfinIndicatorCode,
			finCountryCodeList: this.selectedCountryCode
		};
		if (finEntitlement.finCountryCodeList === null || finEntitlement.finCountryCodeList === undefined) {
			this.showErrorMsg('Please select at least one FIN Country Code to view the report');
			return;
		}

		if (finEntitlement.finEntitlementList === null || finEntitlement.finEntitlementList === undefined) {
			this.showErrorMsg('Please select at least one FIN Entitlement to view the report');
			return;
		}
		console.log('Selected Value ' + this.selectedfinIndicatorCode );
		console.log('Selected Value ' + this.selectedCountryCode );
		this.showSpinner = true;

		// console.log('reportData' + this.reportData );
		// console.log('Selected Value' + this.selectedfinIndicatorCode );
		// this.totalRecords = this.reportData.length;
		//this.Pageary = this.Pagerows;
		this.Pagerows = 10;
		//const finIndicatorList = this.selectedfinIndicatorCode;

		this.finIndicatorCode = '8';
		this.showSpinner = true;
		if (finEntitlement.finEntitlementList !== null && finEntitlement.finEntitlementList !== undefined && finEntitlement.finCountryCodeList !== undefined && finEntitlement.finCountryCodeList !== null) {
			this.fins.getFinIndicatorDetails(finEntitlement).subscribe(data => {
				//this.fins.getFinIndicatorDetails(finIndicatorList).subscribe( data => {
				this.showReport = false;
				if (data['result'] === null && data['error'] !== null) {
					this.errorResponse = new ErrorResponseData(data);
					this.showErrorMsg(this.errorResponse.error.messages[0]);
					this.showReport = false;
					this.showSpinner = false;
					return;
				}
				if (data['result'] && data['result']['finDetail']) {
					this.reportData1 = data['result']['finDetail'];
					//console.log('reportData1' + this.reportData1 );
					this.toDate = this.datePipe.transform(this.reportData1[0].lastUpdateDate, 'MM/dd/yyyy');
					//console.log('reportData1 this.toDate' + this.toDate );

					this.showSpinner = false;
					this.showReport = true;
				} else if (data['result'] === null) {
					this.showSpinner = false;
					//this.noRecords = true;
				} else {
					this.errorResponse = new ErrorResponseData(data);
					if (this.errorResponse.error) {
						this.showErrorMsg(this.errorResponse.error.messages[0]);
					}
				}


				if (data['result'] === null && data['error'] !== null) {
					this.errorResponse = new ErrorResponseData(data);
					this.showErrorMsg(this.errorResponse.error.messages[0]);
				}

				this.showSpinner = false;
				this.paginateValues();

			});
		}	else {
			this.showSpinner = false;
			if (finEntitlement.finCountryCodeList === null || finEntitlement.finCountryCodeList === undefined) {
				this.showErrorMsg('Please select at least one FIN Country Code to view the report');
			}
			if (finEntitlement.finEntitlementList === null || finEntitlement.finEntitlementList === undefined) {
				this.showErrorMsg('Please select at least one FIN Entitlement to view the report');
			}

		}
	}

	paginate(event) {
		event.first = 1;
		event.rows = 10;
		event.page = 31;
		event.pageCount = 100;
	}

	printValue() {
		console.log('Selected Value' + this.selectedfinIndicatorCode );
	}
	clearReport() {
		this.selectedfinIndicatorCode = [];
		this.selectedCountryCode = null;
		this.showReport = false;
		this.paginateValues();
		this.isCountryCode = true;
	}
	loadFinIndicator() {
		this.showSpinner = true;
		this.fins.getFinIndicator().subscribe( data => {
				this.fincodeTest = JSON.stringify(data);
				this.errorResponse = new ErrorResponseData();
				const options = [];
				if (data['result'] && data['result']['finIndicatorDescription']) {
					// this.fincodeList = data['result']['finIndicatorDescription'];
					// for (const val of this.fincodeList) {
					for (const val of data['result']['finIndicatorDescription']) {
						// options.push({'label': val['finIndicator'] + '-' + val['finIndicatorDesc'], 'value': val['finIndicator']});
						options.push({'label': val.finIndicator + '-' + val.finIndicatorDesc, 'value': val.finIndicator});
					}
					this.finIndicatorDescription = options;
					this.finIndicatorDescription = options.sort(function(a, b) { return a.finIndicator - b.finIndicator; }) ;

				} else {
					this.showSpinner = false;
					this.errorResponse = new ErrorResponseData(data);
					if (this.errorResponse && this.errorResponse.error && this.errorResponse.error.messages) {
						this.showErrorMsg(this.errorResponse.error.messages[0]);
					}
				}

			},
			(error: ApiError) => {
				this.errorTest = error;
			});

		this.showSpinner = false;
	}
	showErrorMsg(errorMsg) {
		this.displayMessage = true;
		this.errorMessage = errorMsg;
	}

	closeMessage() {
		this.displayMessage = false;
	}
	getDownloadExcel() {
		this.showSpinner = true;
		this.downLoadFile('exportToExcel', 'FIN Entitlement Report.xlsx');
		//this.showSpinner = false;
	}

	downLoadFile(methodName, fileName) {

		//this.finIndicatorCode = '8';
		// this.finIndicatorCode = this.selectedfinIndicatorCode ;
		const finEntitlement: FinEntitlementArray = {
			finEntitlementList: this.selectedfinIndicatorCode,
			finCountryCodeList: this.selectedCountryCode
		};
		if (this.finIndicatorCode !== '' && this.finIndicatorCode !== undefined ) {
			this.fins.getDownloadExcel(finEntitlement).subscribe(data => {
				if (data['result'] !== null) {
					//this.showSpinner = false;
					this.saveToFileSystem(data, fileName);
					this.showSpinner = false;
				} else {
					//this.showSpinner = false;
					this.errorResponse = new ErrorResponseData(data);
					if (this.errorResponse && this.errorResponse.error && this.errorResponse.error.messages) {
						this.showErrorMsg(this.errorResponse.error.messages[0]);
					}
				}
			});
		}

	}

	saveToFileSystem(data, fileName) {
		const ua: string = window.navigator.userAgent;
		const windowsBrowserRegex = /Trident|MSIE|Edge/;
		const isIE = ua.match(windowsBrowserRegex);
		const blob = new Blob([data], {type: 'application/pdf'});
		const a = document.createElement('a');
		a.href = URL.createObjectURL(blob);
		if (isIE) {
			saveAs.msSaveOrOpenBlob(blob, fileName);
		} else {
			a.setAttribute('target', '_blank');
			a.download = fileName;
			a.click();
			a.remove();
		}
	}

	/*
		downloadFileForFinIndicator(methodName, type, name) {
			this.showSpinner = true;
			this.nfppAdmin.downloadExcelForNFPPAdmin(type).subscribe(data => {
				this.showSpinner = false;
				if (data['result'] !== null) {
					this.saveToFileSystem(data, name);
				} else {
					this.showSpinner = false;
					this.errorResponse = new ErrorResponseData(data);
					this.showErrorMsg(this.errorResponse.error.messages[0]);
				}
			});
		}
		saveToFileSystem(data, fileName) {
			fileName = fileName + '.xlsx';
			const ua: string = window.navigator.userAgent;
			const windowsBrowserRegex = /Trident|MSIE|Edge/;
			const isIE = ua.match(windowsBrowserRegex);
			const blob = new Blob([data], {type: 'application/pdf'});
			const a = document.createElement('a');
			a.href = URL.createObjectURL(blob);
			if (isIE) {
				window.navigator.msSaveOrOpenBlob(blob, fileName);
			} else {
				a.setAttribute('target', '_blank');
				a.download = fileName;
				a.click();
			}
			a.remove();
		}

		showErrorMsg(errorMsg) {
			if (errorMsg) {
				this.reportMsgs = [];
				this.reportMsgs.push({
					severity: 'error',
					summary: 'Error Message',
					detail: errorMsg
				});
			}
		}
	*/
	onChangingFinIndicator() {
		if (this.finIndicator === '0' || this.finIndicator === '' ) {
			this.showMsg = true;
		} else {
			this.showMsg = false;
		}
	}

	changeRecords(rows) {
		console.log("asd ", rows)
		this.inputRows = rows;
		if(rows !== '' || rows !== null || rows !== undefined) {
			this.Pagerows = rows;				const inputValue = Number(rows);
			this.Pagerows = inputValue;
			let col = [];
			this.rowsOption.push(inputValue);
			col = this.rowsOption.filter((item, i, arr) => {
				return arr.indexOf(arr.find(t => t === item)) === i
			});
			this.rowsOption = col.sort((a,b) => a - b);
		}

	}
	paginateValues() {
		this.rowsOption = [10, 20, 30, 40, 50];
		this.inputRows = null;
		this.Pagerows = 10
	}

	onchangeCountryCode(code) {
		this.selectedfinIndicatorCode = null;
		this.showReport = false;
		if (code.length === 0) {
			this.isCountryCode = true;
			return;
		} else {
			this.isCountryCode = false;
			this.showReport = false;
		}
	}



}
