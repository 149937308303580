

export class PersonalIndicatorConstants {
	// public static fimpsPersonalIndicator = "65";
	public static fimpsPersonalIndicator = 'FSA_CPA';
	// public static ripsPersonalIndicator = "87";
	public static ripsPersonalIndicator = 'FSA_RIPS';
	// public static fcicPersonalIndicator = "78";
	public static fcicPersonalIndicator = 'FSA_FCICADM';
	// public static batchOasisPersonalIndicator = "BOR";
	public static batchOasisPersonalIndicator = 'FSA_BOR';
	// public static nfppAdminPersonalIndicator = "NFPP";
	public static nfppAdminPersonalIndicator = 'FSA_NFPP';
	// public static fkcinternalOrFleetPersonalIndicator = "70";
	public static fkcinternalOrFleetPersonalIndicator = 'FSA_FKC';
	// public static fkcidelarePaPersonalIndicator = "key code";
	public static fkcidelarePaPersonalIndicator = 'FSA_FKC_DLR';
	// public static cpainternalOrFleetPersonalIndicator = "88";
	public static cpainternalOrFleetPersonalIndicator = 'FSA_CPA_LKUP';
	public static fcicPersonalIndicatorStatus = "1";
	public static cpaStatementAccess = "65";
	public static cpalookupFinIndicator = "27";
	// public static cpaPersonalIndicator = "MSGCPA";
	public static cpaPersonalIndicator = "FSA_CPA_LKUP_MSG";
	// public static fimpsMsgPersonalIndicator = "MSGFIMPS";
	public static fimpsMsgPersonalIndicator = "FSA_CPA_MSG";
	public static fsaFINAdmin = 'FSA_FIN_ADM';
	public static fsaFltPersonalIndicator = 'FSA_FLT_ADT';
}
