import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { AccountManagerList } from '../../app/models/NamList.model';
import { FinIndicatorApi } from '../../app/models/FinIndicatorApi';

@Injectable()
export class FinNamDetailService {
	_accessToken: string = sessionStorage.getItem('encodedAccessToken');
	fwsUrl = environment.APIEndpoint;
	userId = sessionStorage.getItem('userId');
	fleetAdminService = environment.fleetAdminService;
	private options = {
		headers: new HttpHeaders({
			'Authorization': 'Bearer ' + this._accessToken,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'origin': window.location.origin
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient) {

	}

	getFinNAMReport(accountManagerArray): Observable<any> {
		return this.http.post<any>(this.fleetAdminService + 'api/v1/accountManagerFinReport/accountManagerFinEntitlementReport', accountManagerArray, this.options);
	}

	getDownloadExcel(accountManagerArray) {
		const headers = new HttpHeaders({
			'Authorization': 'bearer ' + this._accessToken
		});

		return this.http.post(this.fleetAdminService + 'api/v1/accountManagerFinReport/accountManagerFinEntitlementExcel', accountManagerArray, {
			headers: headers,
			responseType: 'blob' 
				});
	}

	getFINEntitlementMappingPDF() {
		return this.http.get(this.fleetAdminService + 'api/v1/accountManagerFinReport/getFEMappingPDF', {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + this._accessToken,
				'Accept': 'application/octet-stream',
				'Content-type': 'text/plain'
			}), withCredentials: true, responseType: 'arraybuffer' as 'json' 
		});
	}
	
	getAccountManagerCategoryPDF() {
		return this.http.get(this.fleetAdminService + 'api/v1/accountManagerFinReport/getAMCategoryPDF', {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + this._accessToken,
				'Accept': 'application/octet-stream',
				'Content-type': 'text/plain'
			}), withCredentials: true, responseType: 'arraybuffer' as 'json'
		 });
	}

	getFinIndicator(): Observable<FinIndicatorApi> {
		return this.http.get<FinIndicatorApi>(this.fleetAdminService  + 'api/v1/finindicator/getfinindicatorDesc', this.options);
	}

	getAccountType(): Observable<any> {
		return this.http.get<any>(this.fleetAdminService + 'api/v1/accountManagerPES/accountManagerTypes', this.options);
	}
	
	getAccountManagerByType(accountManagerArray): Observable<any> {
		return this.http.post<any>(this.fleetAdminService + 'api/v1/accountManagerPES/accountManagersByType',accountManagerArray, this.options);
	}

}