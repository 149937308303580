import {Component, OnInit, ViewEncapsulation } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FimpsService} from '../services/fimps.service';
import {ErrorResponseData} from '../models/ErrorResponseData';
import {FinCodeModel} from '../models/FinCode.model';
import {ApiError} from '../models/ApiError';

@Component({
	selector: 'app-fimps-search',
	templateUrl: './fimps-search.component.html',
	styleUrls: ['./fimps-search.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class FimsSearchComponent implements OnInit {
	adminFinCode: any;
	userType: string;
	userId: any;
	siteCode: any;
	showErrorMsg: boolean;
	reportMsgs = [];
    showPage = false;
	errorResponse: ErrorResponseData;
	showSpinner: Boolean = false;
	fincodeList: FinCodeModel[];
	fincodeTest: string;
	errorTest: ApiError;
	displayMessage: Boolean = false;
	errorMessage: String;
	constructor(public fims: FimpsService, public router: Router, private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.checkUserType();
		sessionStorage.removeItem('adminFinCode');
		sessionStorage.removeItem('finCode');
		sessionStorage.removeItem('payeeCode');
		sessionStorage.removeItem('selectedMonths');
		sessionStorage.removeItem('selectedPayeeCode');

		sessionStorage.removeItem('accountName');
		sessionStorage.removeItem('address1');
		sessionStorage.removeItem('city');
		sessionStorage.removeItem('state');
		sessionStorage.removeItem('postalCode');
		sessionStorage.removeItem('sDate');

		sessionStorage.removeItem('indexDescription');
		sessionStorage.removeItem('summaryDesc');
		sessionStorage.removeItem('indexProgramCode');
		sessionStorage.removeItem('summaryReportCode');
		sessionStorage.removeItem('summaryCount');

		sessionStorage.removeItem('vinVehicleName');
		sessionStorage.removeItem('vcount');
	}

	checkUserType() {
		this.userType = sessionStorage.getItem('userType');
		this.userId = sessionStorage.getItem('userId');
		this.siteCode = sessionStorage.getItem('siteCode');

		this.adminFinCode = '';
		if (this.userType && this.userType !== 'Employee') {
			this.showPage = false;
			this.router.navigate(['fimps-cpa-stmt']);
		} else {
			this.showPage = true;
		}
	}
	someMethod(event: any) {
		if (event.keyCode === 13) {
			this.getFinCodeForAdmin();
		}
	}

	getFinCodeForAdmin() {
		if (!this.adminFinCode || this.adminFinCode === null || this.adminFinCode === undefined || this.adminFinCode ===  '') {
			this.showErrorMsgs('Please Enter the FIN Code.');
		} else {
			this.adminFinCode = this.adminFinCode.match(/[^ ,]+/g).join(',');
			this.showSpinner = true;
			sessionStorage.setItem('adminFinCode', this.adminFinCode);
			this.loadFinCode(this.adminFinCode);
		}
	}

	loadFinCode(fincode) {
		this.fims.getFinCode(fincode.toUpperCase()).subscribe( data => {
			this.fincodeTest = JSON.stringify(data);
			if (data['result'] && data['result']['getPayeeCodeInfo']) {
				this.fincodeList = data['result']['getPayeeCodeInfo'];
				if (this.fincodeList   && this.fincodeList .length > 0) {
					this.router.navigate(['fimps-cpa-stmt']);
				}
			} else {
				this.showSpinner = false;
				this.errorResponse = new ErrorResponseData(data);
				if (this.errorResponse && this.errorResponse.error && this.errorResponse.error.messages) {
					this.showErrorMsgs(this.errorResponse.error.messages[0]);
				}
			}
			this.showSpinner = false;
		},
		(error: ApiError) => {
				this.errorTest = error;
		});
	}

	showErrorMsgs(errorMsg) {
		this.displayMessage = true;
		if (errorMsg) {
			this.errorMessage = errorMsg;
		}
	}
}
