export class ErrorResponseData {
	constructor(obj?: any) {
		Object.assign(this, obj);
	}

	error: ErrorModel;
	result: any;
}

export class ErrorModel {
	errorCode: string;
	messages: string;
	dataErrors: string;
	attributes: any[];
	timestamp: string;
	appErrorCode: string;
	message: string;
	debugMessage: string;
}
