import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'fleet-audit-report',
  templateUrl: './fleet-audit-report.component.html',
  styleUrls: ['./fleet-audit-report.component.css']
})
export class FleetAuditReportComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
  }

  goToAuditReports() {
		this.router.navigate(['fleet-audit-report']);
	}
}
