import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common'

@Component({
	selector: 'app-unauthorized',
	templateUrl: './unauthorized.component.html',
	styleUrls: ['./unauthorized.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class UnauthorizedComponent implements OnInit {
	isError: Boolean = false;
	isErrorOccurs: Boolean = false;
	isFkcRequest: Boolean = false;
	isFkcRequestAprrove: Boolean = false;
	isUnderConstruction: Boolean = false;
	unauthorizedNAMmsg: Boolean = false;
	isMultipleFINMapped: Boolean = false;
	namcdsId: string;
	approvedOrRejectedDate: string;
	clpaUnAuthMsg: Boolean = false;
	otherUnAuthMsg: Boolean = false;
	isDealerRequest: Boolean = false;
	isLeasingFIN: Boolean = false;
	NAMnotMapped: Boolean = false;
	isFINAdmin: Boolean = false;
	isFkcRequestApproved: Boolean = false;

	constructor(private route: ActivatedRoute, public router: Router, public location: Location) {
		this.route.queryParams.subscribe(params => {
			if (params['isError']) {
				this.isError = params['isError'];
			}
			if (params['isErrorOccurs']) {
				this.isErrorOccurs = params['isErrorOccurs'];
				this.isError = true;
			}
			if (params['isFkcRequest']) {
				this.isFkcRequest = params['isFkcRequest'];
				this.isError = true;
			}
			if (params['isFkcRequestAprrove']) {
				this.isFkcRequestAprrove = params['isFkcRequestAprrove'];
				this.namcdsId = params['namcdsId'];
				this.approvedOrRejectedDate = params['approvedOrRejectedDate']
				this.isError = true;
			}
			if (params['isUnderConstruction']) {
				this.isUnderConstruction = params['isUnderConstruction'];
				this.isError = true;
			}
			if (params['unauthorizedNAMmsg']) {
				this.unauthorizedNAMmsg = params['unauthorizedNAMmsg'];
				this.isError = true;
			}
			if (params['isMultipleFINMapped']) {
				this.isMultipleFINMapped = params['isMultipleFINMapped'];
				this.isError = true;
			}
			if (params['clpaUnAuthMsg']) {
				this.clpaUnAuthMsg = params['clpaUnAuthMsg'];
				this.isError = true;
			}
			if (params['otherUnAuthMsg']) {
				this.otherUnAuthMsg = params['otherUnAuthMsg'];
				this.isError = true;
			}
			if (params['isDealerRequest']) {
				this.isDealerRequest = params['isDealerRequest'];
				this.isError = true;
			}
			if (params['isLeasingFIN']) {
				this.isLeasingFIN = params['isLeasingFIN'];
				this.isError = true;
			}
			if (params['NAMnotMapped']) {
				this.NAMnotMapped = params['NAMnotMapped'];
				this.isError = true;
			}
			if (params['isFINAdmin']){
				this.isFINAdmin = params['isFINAdmin'];
				this.isError = true;
			}
			if (params['isFkcRequestApproved']) {
				this.isFkcRequestApproved = params['isFkcRequestApproved'];
				this.isError = true;
			}

		});
	}

	ngOnInit() {
	}

	goToPreviousPage() {
		this.location.back();

	}
}
