import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';

@Component({
	selector: 'app-rips-search',
	templateUrl: './rips-search.component.html',
	styleUrls: ['./rips-search.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class RipsSearchComponent implements OnInit {
	adminFinCode: string;
	userType: string;
	userId: any;
	siteCode: any;
	showErrorMsg: boolean;
	reportMsgs = [];
	showPage = false;
	showSpinner: Boolean = false;
	errorMessage: string;
	displayMessage: boolean;
	@ViewChild('myInput',{static:false}) myInput: ElementRef;

	constructor(public router: Router) {
	}

	ngOnInit() {
		this.checkUserType();
	}

	checkUserType() {
		this.userType = sessionStorage.getItem('userType');
		this.userId = sessionStorage.getItem('userId');
		this.siteCode = sessionStorage.getItem('siteCode');
		sessionStorage.setItem('payeeHeaderMainRips', '');
		this.adminFinCode = '';
		if (this.userType && this.userType === 'Employee') {
			this.showPage = true;
		} else {
			this.showPage = false;
			this.router.navigate(['rips']);
		}
	}

	someMethod(event: any) {
		if (event.keyCode === 13) {
			this.goToRipsPage();
		}
	}

	closeMessage() {
		this.displayMessage = false;
	}

	goToRipsPage() {
		if (this.adminFinCode) {
			if (this.adminFinCode.length < 5) {
				this.showErrorMsgs('Enter a valid Payee FIN.');
				return false;
			}
			sessionStorage.setItem('payeeHeaderMainRips', this.adminFinCode.toUpperCase());
			this.router.navigate(['rips']);
			this.showSpinner = true;
		} else {
			this.showErrorMsgs('Enter a valid Payee FIN.');
		}
		return false;
	}

	showErrorMsgs(errorMsg) {
		this.displayMessage = true;
		this.errorMessage = errorMsg;
	}
}
