import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import {FcicadminService} from '../services/fcicadmin.service';
import {FinIndicatorMaster} from '../models/FinIndicatorMaster';
import {FinIndicatorDetailsModel} from '../models/FinIndicatorDetails';
import {MessageService} from 'primeng/api';;
import {Message} from 'primeng/api';
import {FinAdminService} from '../services/finadmin.service';

@Component({
	selector: 'app-fin-master',
	templateUrl: './fin-master.component.html',
	styleUrls: ['./fin-master.component.css']
})
export class FinMasterComponent implements OnInit {
	reportMsgs: Message[] = [];
	dateVal;
	finNameForEdit: number;
	finDescForDesc: string;
	showReport = false;
	showAccess: Boolean = false;
	showAccessEdit: Boolean = false;
	showAccessDelete: Boolean = false;
	public displayMessage: Boolean = false;
	showSpinner: Boolean = false;
	accountPrefDescription: string;
	accountPrefCode: string;
	finIndicatorMaster: FinIndicatorMaster[];
	userDeatilList;
	finIndicatorDetailsModel: FinIndicatorDetailsModel;
	indicatorName: number;
	indicatorDesc;
	editindicatorDesc;
	userId: string;
	showErrorMsg;
	showErrorMsgDesc: Boolean = false;
	showErrorMsgs: Boolean = false;
	showErrorMsgEditDesc: Boolean = false;
	showErrorMsgExist: Boolean = false;
	actionToBePerformed;
	public displaySpinner: Boolean = false;
	cols: any[];
	isExist = 0;
	constructor(public datepipe: DatePipe, private finadminService: FinAdminService, private messageService: MessageService) {
	}

	ngOnInit() {
		if (this.datepipe) {
			this.dateVal = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm:ss a');
		}
		this.userDeatilList = this.finadminService.getFinIndicatorMaster();
		this.cols = [
			{ field: 'accountPrefCode', header: 'FIN  Entitlement' },
			{ field: 'accountPrefDescription', header: 'FIN Entitlement Description'},
			{ field: '', header: 'Action To Perform' },
			{ field: 'updatedBy', header: 'Last Updated User ID' },
			{ field: 'updatedDate', header: 'User ID' },
			{ field: 'createBy', header: 'Date Last Updated' }
		];
		this.showFINMaster();
	}

	clear() {
		this.showReport = false;
	}

	showFINMaster() {
		this.displaySpinner = true;
		this.finadminService.getFinIndicatorMaster().subscribe(data => {
			this.displaySpinner = false;
			this.finIndicatorMaster = data['result']['finIndiatorMasterList'];
			this.finIndicatorMaster.forEach(item => {
				item.accountPrefCode = Number(item.accountPrefCode);
			});
		});
	}

	addNewFIN() {
		this.showSpinner = true;
		this.showAccess = true;
		this.indicatorName = null;
		this.indicatorDesc = '';
		this.showErrorMsgs = false;
		this.showErrorMsgExist = false;
		this.showErrorMsgDesc = false;
	}

	editFIN(finIndicatorMaster) {
		this.showSpinner = true;
		this.finNameForEdit = finIndicatorMaster.accountPrefCode;
		this.finDescForDesc = finIndicatorMaster.accountPrefDescription;
		this.showErrorMsgEditDesc = false;
		this.editpopup();
	}
	submitNewFIN() {
		if (this.indicatorName === null || this.indicatorName === undefined ) {
			this.removeErrorMsgEntilement();
			return;
		} else {
			this.removeErrorMsgEntilement();
			this.finadminService.getFinIndicatorMaster().subscribe(data => {
				if (data['result'] && data['result']['finIndiatorMasterList']) {
					this.finIndicatorMaster = data['result']['finIndiatorMasterList'];
					this.finIndicatorMaster.forEach(item => {
						item.accountPrefCode = Number(item.accountPrefCode);
					});
					this.isExist = 0;
					if (this.finIndicatorMaster) {
						this.isExist = this.finIndicatorMaster.filter(item =>  Number(item.accountPrefCode) === this.indicatorName).length > 0 ? 1 : 0;
						if (this.isExist > 0) {
							this.removeErrorMsgEntilementExist();
							this.indicatorName = null;
						} else if (this.indicatorDesc.trim() === null || this.indicatorDesc.trim() === '' || this.indicatorDesc.trim() === undefined) {
							this.removeErrorMsgEntilementDesc();
							return;
						} else {
							if ((this.indicatorName !== null && this.indicatorName !== undefined) && (this.indicatorDesc.trim() !== null && this.indicatorDesc.trim() !== '' && this.indicatorDesc.trim() !== undefined)) {
								this.finIndicatorDetailsModel = new FinIndicatorDetailsModel();
								this.finIndicatorDetailsModel.finIndicatorName = this.indicatorName != null ? this.indicatorName.toString() : '';
								this.finIndicatorDetailsModel.finIndicatorDescription = this.indicatorDesc;
								this.userId = sessionStorage.getItem('userId');
								this.finIndicatorDetailsModel.userId = this.userId;
								this.finIndicatorDetailsModel.actionToBePerformed = 'ADD';
								this.finadminService.addFinIndicator(this.finIndicatorDetailsModel).subscribe(data => {
									this.showFINMaster();
									this.messageService.add({severity: 'success', summary: this.finIndicatorDetailsModel.finIndicatorDescription.toUpperCase(), detail: 'FIN Entitlement added Successfully'});
									this.showAccess = false;
								}, error1 => {
									this.messageService.add({severity: 'error', summary: 'FIN Entitlement', detail: 'FIN Entitlement not added'});
								});
							}
						}
					}
				}
			});
		}

		this.displayMessage = false;
	}
	UpdateFIN() {
		this.editindicatorDesc = this.finDescForDesc;
		if (this.editindicatorDesc === null || this.editindicatorDesc === '' || this.editindicatorDesc === undefined) {
			this.removeErrorMsgEntilementEditDesc();
			return;
		}
		this.finIndicatorDetailsModel = new FinIndicatorDetailsModel();
		this.finIndicatorDetailsModel.finIndicatorName = this.finNameForEdit != null ? this.finNameForEdit.toString() : '';
		this.finIndicatorDetailsModel.finIndicatorDescription = this.finDescForDesc;
		this.userId = sessionStorage.getItem('userId');
		this.finIndicatorDetailsModel.userId = this.userId;
		this.finIndicatorDetailsModel.actionToBePerformed = 'EDIT';
		this.finadminService.addFinIndicator(this.finIndicatorDetailsModel).subscribe(data => {
			this.showFINMaster();
			this.messageService.add({severity: 'success', summary: this.finIndicatorDetailsModel.finIndicatorDescription.toUpperCase(), detail: 'FIN Entitlement updated Successfully'});
			this.showAccessEdit = false;
		}, error1 => {
			this.messageService.add({severity: 'error', summary: 'FIN Entitlement', detail: 'FIN Entitlement not updated'});
		});
		this.displayMessage = false;
	}
	deletepopup(finIndicatorMaster) {
		this.showSpinner = true;
		this.finNameForEdit = finIndicatorMaster.accountPrefCode;
		this.finDescForDesc = finIndicatorMaster.accountPrefDescription;
		this.deletepopups();
	}

	deleteFIN() {
		this.showAccessDelete = true;
		this.finIndicatorDetailsModel = new FinIndicatorDetailsModel();
		this.finIndicatorDetailsModel.finIndicatorName = this.finNameForEdit != null ? this.finNameForEdit.toString() : '';
		this.finIndicatorDetailsModel.finIndicatorDescription = this.finDescForDesc;
		this.userId = sessionStorage.getItem('userId');
		this.finIndicatorDetailsModel.userId = this.userId;
		this.finIndicatorDetailsModel.actionToBePerformed = 'DELETE';
		this.showSpinner = true;
						if ((this.finNameForEdit !== null && this.finNameForEdit !== undefined) && (this.finDescForDesc.trim() !== null && this.finDescForDesc.trim() !== '' && this.finDescForDesc.trim() !== undefined)) {
							this.finadminService.deleteFinIndicator(this.finIndicatorDetailsModel).subscribe(deleteuser => {
								if(deleteuser['result'] != null) {
								if (deleteuser['result']['sqlProcedureResponseApi']['outputStatus'] === 'Success') {
									this.messageService.add({
										severity: 'sucess',
										summary: this.finIndicatorDetailsModel.finIndicatorDescription.toUpperCase(),
										detail: 'FIN Entitlement deleted Successfully'
									});
									this.showFINMaster();
									this.showAccessDelete = false;
								} 
								else if(deleteuser['result']['sqlProcedureResponseApi']['outputStatus'] == null) {
									this.messageService.add({
										severity: 'info',
										summary: 'FIN Entitlement',
										detail: deleteuser['result']['sqlProcedureResponseApi']['errMsg']
									});
								} 
								} else {
									this.showSpinner = false;
									this.messageService.add({severity: 'error', summary: 'FIN Entitlement', detail: deleteuser['error']['messages'][0]});
								}
							}, error1 => {

							});

						}
		this.displayMessage = false;
	}
	deletepopups() {
		this.showAccessDelete = true;
	}
	editpopup() {
		this.showAccessEdit = true;
		this.showErrorMsgEditDesc = false;
	}
	onCancel() {
		this.showAccessDelete = false;
	}
	removeErrorMsgEntilement() {
		if (this.indicatorName === null || this.indicatorName === undefined) {
			this.showErrorMsgs = true;
		} else {
			this.showErrorMsgs = false;
		}
	}
	removeErrorMsgEntilementExist() {
		if (this.indicatorName === null || this.indicatorName === undefined || this.isExist > 0) {
			this.showErrorMsgExist = true;
		} else {
			this.showErrorMsgExist = false;
		}
	}
	removeErrorMsgEntilementDesc() {
		if (this.indicatorDesc === null || this.indicatorDesc === undefined || this.indicatorDesc === '') {
			this.showErrorMsgDesc = true;
		} else {
			this.showErrorMsgDesc = false;
		}
	}
	removeErrorMsgEntilementEditDesc() {
		if (this.editindicatorDesc === null || this.editindicatorDesc === undefined || this.editindicatorDesc === '') {
			this.showErrorMsgEditDesc = true;
		} else {
			this.showErrorMsgEditDesc = false;
		}
	}
}
