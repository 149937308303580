import {Component, OnInit} from '@angular/core';
import {Message} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {RipsService} from '../services/rips.service';
import {DatePipe} from '@angular/common';
import {Rips} from '../models/Rips';
import {ErrorResponseData} from '../models/ErrorResponseData';
import {DropdownItem} from '../models/dropdownItem';
import { saveAs } from 'file-saver';

@Component({
	selector: 'app-rips-stmt-report',
	templateUrl: './rips-stmt-report.component.html',
	styleUrls: ['./rips-stmt-report.component.css']
})
export class RipsStatementReportComponent implements OnInit {
	reportMsgs: Message[] = [];
	paymentReports: Rips[];
	chargeBackReports: Rips[];
	ineligibleReports: Rips[];
	adjustmentReports: Rips[];
	paymentReporttotalRecords = 0;
	chargeBackReporttotalRecords = 0;
	ineligibleReportstotalRecords = 0;
	adjustmentReporttotalRecords = 0;
	searchType: any;
	sDate: any;
	reportType: any;
	noRecords = false;
	isSingleVIN: any;
	VIN: any;
	vinHeader: any;
	finCode: any;
	isPaymentReport = false;
	isAdjustmentReport = false;
	isChargebackReport = false;
	isIneligibleReport = false;
	noVinRecords = false;
	statementDate: any;
	payeeCode: string;
	showSpinner = false;
	formattedChargeBackReports = [];
	newformattedChargeBackReports = [];
	errorResponse: ErrorResponseData;
	errorMsg: string;
	reports: DropdownItem[];
	reportTypeVal: any;
	userType: string;
	isAdmin: boolean;

	constructor(public rips: RipsService, public router: Router, private route: ActivatedRoute, public datepipe: DatePipe) {

		this.searchType = sessionStorage.getItem('searchTypeRips');
		this.payeeCode = sessionStorage.getItem('payeeHeaderRips');

		if (this.searchType === '1') {
			this.sDate = sessionStorage.getItem('dateTypeRips');
			this.statementDate = this.datepipe.transform(this.sDate, 'yyyy-MM-dd');
			this.reportType = sessionStorage.getItem('reportTypeRips');
			this.finCode = sessionStorage.getItem('finCodeRips');
		}
		if (this.searchType === '2') {
			this.isSingleVIN = sessionStorage.getItem('isSingleVINRips');
			this.VIN = sessionStorage.getItem('VINRips');
			this.vinHeader = sessionStorage.getItem('VINRips');
			this.reportType = sessionStorage.getItem('reportTypeRips');
			this.finCode = sessionStorage.getItem('finCodeRips');
		}

		if (this.reportType == null) {
			this.router.navigate(['rips']);
		}

	}

	ngOnInit() {
		const options1 = [];
		options1.push({'label': '-Select-', 'value': ''});
		options1.push({'label': 'All', 'value': 'A'});
		options1.push({'label': 'Payment', 'value': '1'});
		options1.push({'label': 'Adjustment', 'value': '2'});
		options1.push({'label': 'Chargeback/Collection', 'value': '3'});
		options1.push({'label': 'Ineligible', 'value': '4'});
		this.reports = options1;
		this.isAdmin = false;
		this.userType = sessionStorage.getItem('userType');
		if (this.userType && this.userType === 'Employee') {
			this.isAdmin = true;
		}
		this.loadRipsReport();
		const reportType = this.reports.filter(item => item.value === this.reportType);
		this.reportTypeVal = reportType[0].label;
	}

	loadRipsReport() {
		// this.payeeCode = 'BU010';
		/*this.finCode = 'All';
		this.reportType = 'A';
		this.statementDate = '2017-12-29';
		this.VIN = '3FA6P0LU6JR133762';*/

		this.showSpinner = true;
		this.noRecords = false;
		if (this.searchType === '1') {
			this.loadRipReportDataForStatementDates();
		} else if (this.searchType === '2') {
			this.loadRipReportDataForVIN();
		}
	}

	loadRipReportDataForStatementDates() {
		this.rips.getRipsReportData(this.payeeCode, this.finCode, this.reportType, this.statementDate).subscribe(data => {
			if (data['result'] === null || data['result'] === undefined) {
				this.noRecords = true;
				this.showSpinner = false;
				this.errorResponse = new ErrorResponseData();
				this.showSpinner = false;
				this.errorResponse = new ErrorResponseData(data);
				this.errorMsg = 'No Data Exists for the Selected Criteria.';
				if (this.errorResponse && this.errorResponse.error && this.errorResponse.error.messages) {
					this.errorMsg = this.errorResponse.error.messages[0];
				}
			}

			this.paymentReports = [];
			this.chargeBackReports = [];
			this.adjustmentReports = [];
			this.ineligibleReports = [];

			if (data['result'] && data['result']['queryData'] && data['result']['queryData']['Payment']) {
				this.paymentReports = data['result']['queryData']['Payment'];
				this.paymentReporttotalRecords = this.paymentReports ? this.paymentReports.length : 0;
			}

			if (data['result'] && data['result']['queryData'] && data['result']['queryData']['Chargeback/Collection']) {
				this.chargeBackReports = data['result']['queryData']['Chargeback/Collection'];
				this.chargeBackReporttotalRecords = this.chargeBackReports ? this.chargeBackReports.length : 0;
				if (this.chargeBackReports && this.chargeBackReports.length > 0) {
					this.formattedChargeBackReports = this.getFormattedChargeBackData(this.chargeBackReports);
				}
			}

			if (data['result'] && data['result']['queryData'] && data['result']['queryData']['Adjustment']) {
				this.adjustmentReports = data['result']['queryData']['Adjustment'];
				this.adjustmentReporttotalRecords = this.adjustmentReports ? this.adjustmentReports.length : 0;
			}

			if (data['result'] && data['result']['queryData'] && data['result']['queryData']['Ineligible']) {
				this.ineligibleReports = data['result']['queryData']['Ineligible'];
				this.ineligibleReportstotalRecords = this.ineligibleReports ? this.ineligibleReports.length : 0;
			}

			this.showSpinner = false;
		});

		if (this.reportType === 'A') {
			this.isPaymentReport = true;
			this.isAdjustmentReport = true;
			this.isChargebackReport = true;
			this.isIneligibleReport = true;
		} else if (this.reportType === '1') {
			this.isPaymentReport = true;
		} else if (this.reportType === '2') {
			this.isAdjustmentReport = true;
		} else if (this.reportType === '3') {
			this.isChargebackReport = true;
		} else if (this.reportType === '4') {
			this.isIneligibleReport = true;
		}
	}

	loadRipReportDataForVIN() {
		this.noVinRecords = false;
		this.paymentReports = [];
		this.adjustmentReports = [];
		this.chargeBackReports = [];
		this.ineligibleReports = [];

		this.isPaymentReport = true;
		this.isAdjustmentReport = true;
		this.isChargebackReport = true;
		this.isIneligibleReport = true;

		this.rips.getRipsReportDataForVin(this.payeeCode, this.finCode, this.VIN).subscribe(data => {
			if (data['result'] === null || data['result'] === undefined) {
				this.noVinRecords = true;
				this.noRecords = true;
				this.showSpinner = false;
				this.errorResponse = new ErrorResponseData();
				this.errorResponse = new ErrorResponseData(data);
				//this.showErrorMsg(this.errorResponse.error.messages[0]);
				this.errorMsg = this.errorResponse.error.messages[0];
			}

			if (data['result'] && data['result']['queryData'] && data['result']['queryData']['Payment']) {
				this.paymentReports = data['result']['queryData']['Payment'];
				this.paymentReporttotalRecords = this.paymentReports ? this.paymentReports.length : 0;
			}
			if (data['result'] && data['result']['queryData'] && data['result']['queryData']['Adjustment']) {
				this.adjustmentReports = data['result']['queryData']['Adjustment'];
				this.adjustmentReporttotalRecords = this.adjustmentReports ? this.adjustmentReports.length : 0;
			}
			if (data['result'] && data['result']['queryData'] && data['result']['queryData']['Chargeback/Collection']) {
				this.chargeBackReports = data['result']['queryData']['Chargeback/Collection'];
				this.formattedChargeBackReports = this.getFormattedChargeBackData(this.chargeBackReports);
				this.chargeBackReporttotalRecords = this.chargeBackReports ? this.chargeBackReports.length : 0;
			}
			if (data['result'] && data['result']['queryData'] && data['result']['queryData']['Ineligible']) {
				this.ineligibleReports = data['result']['queryData']['Ineligible'];
				this.ineligibleReportstotalRecords = this.ineligibleReports ? this.ineligibleReports.length : 0;
			}

			this.showSpinner = false;
		});
	}

	goToNewStatement() {
		if (this.userType && this.userType === 'Employee') {
			this.router.navigate(['rips-search']);
		} else {
			this.router.navigate(['rips']);
			sessionStorage.setItem('payeeHeaderMainRips', this.payeeCode);
			//this.router.navigate(['rips'], {queryParams: {adminFinCode: this.payeeCode}});
		}
	}

	goToModifiedCriteria() {
		this.router.navigate(['rips']);
		sessionStorage.setItem('payeeHeaderMainRips', this.payeeCode);
		//this.router.navigate(['rips'], {queryParams: {adminFinCode: this.payeeCode}});
	}

	getTotal(Reports) {
		if (Reports && Reports.length > 0) {
			return Reports.map(c => Number(c.amount)).reduce((sum, current) => sum + current);
		}
	}

	getFormattedChargeBackData(chargeBackReports) {
		chargeBackReports.forEach(item => {
			if (item.collectionSource === 'CHARGED BACK') {
				item.amount = Number('-' + item.amount);
			} else {
				item.amount = Number(item.amount);
			}

		});

		const uniqueVin = chargeBackReports.map(item => item.vin)
			.filter((value, index, self) => self.indexOf(value) === index);

		if (uniqueVin && uniqueVin.length > 0) {
			let formatChargeBack;
			this.newformattedChargeBackReports = [];
			uniqueVin.forEach(item => {
				formatChargeBack = chargeBackReports.filter(items => items.vin === item);
				formatChargeBack.forEach(item1 => {
					this.newformattedChargeBackReports.push(item1);
				});
				const newVal = [];
				newVal['payee'] = '';
				newVal['fin'] = '';
				newVal['programYear'] = '';
				newVal['modelYear'] = '';
				newVal['vechicleLine'] = '';
				newVal['vin'] = '';
				newVal['orderDate'] = '';
				newVal['saleDate'] = '';
				newVal['paymentType'] = '';
				newVal['reason'] = 'VIN Total';
				newVal['amount'] = this.newformattedChargeBackReports.map(c => c.amount).reduce((sum, current) => sum + current);
				newVal['collectionSource'] = '';
				newVal['repCode'] = '';
				this.newformattedChargeBackReports.push(newVal);
			});
		}
		return this.newformattedChargeBackReports;
	}

	getDownloadExcel() {
		this.showSpinner = true;
		if (this.searchType === '1') {
			this.rips.getDownloadExcelForRipsforFinCode(this.payeeCode, this.finCode, this.reportType, this.statementDate).subscribe(data => {
				this.showSpinner = false;
				if (data['result'] !== null) {
					this.downloadFileForRips(data);
				} else {
					this.errorResponse = new ErrorResponseData(data);
					//this.showErrorMsg(this.errorResponse.error.messages[0]);
					this.errorMsg = this.errorResponse.error.messages[0];
				}
			});
		} else {
			this.rips.getDownloadExcelForRipsforVin(this.payeeCode, this.finCode, this.VIN).subscribe(data => {
				this.showSpinner = false;
				if (data['result'] !== null) {
					this.downloadFileForRips(data);
				} else {
					this.errorResponse = new ErrorResponseData(data);
					//this.showErrorMsg(this.errorResponse.error.messages[0]);
					this.errorMsg = this.errorResponse.error.messages[0];
				}
			});
		}
	}

	downloadFileForRips(data: any) {
		const fileName = 'RIPSReport.xlsx';
		const ua: string = window.navigator.userAgent;
		const windowsBrowserRegex = /Trident|MSIE|Edge/;
		const isIE = ua.match(windowsBrowserRegex);
		const blob = new Blob([data], {type: 'application/pdf'});
		const a = document.createElement('a');
		a.href = URL.createObjectURL(blob);
		if (isIE) {
			saveAs.msSaveOrOpenBlob(blob, fileName);
		} else {
			a.setAttribute('target', '_blank');
			a.download = fileName;
			a.click();
		}

		a.remove();
	}

	ShowFormattedVin(VIN) {
		return VIN.match(/.{1,17}/g).toString().replace(/,/g, ', ');
	}

	showErrorMsg(errorMsg) {
		if (errorMsg) {
			this.reportMsgs = [];
			this.reportMsgs.push({
				severity: 'error',
				summary: 'Error Message',
				detail: errorMsg
			});
		}
	}

}
