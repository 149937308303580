import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {MessageService} from 'primeng/api';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CookieHomeModel} from '../models/CookieHome.model';

@Injectable({
	providedIn: 'root'
})
export class CookieHomeService {
	_accessToken: string = sessionStorage.getItem('encodedAccessToken');
	fwsUrl = environment.APIEndpoint;
	fleetAdminService = environment.fleetAdminService;
	private options = {
		headers: new HttpHeaders({
			'Authorization': 'Bearer ' + this._accessToken,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'origin': window.location.origin
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient,  private messageService: MessageService) { }

	getCookieInfo(userid: string): Observable<CookieHomeModel> {
		let request ={
			"wslID":userid
		}
		return this.http.post<CookieHomeModel>(this.fleetAdminService + 'api/common/login' ,request, this.options);
	}

	// getPersonalindicator(userId): Observable<any[]> {
	// 	return this.http.get<any>(this.fwsUrl + 'api/common/personalindicator/' + userId,  this.options);
	// }
}
