import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {ConfirmationService} from 'primeng/api';
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

	constructor(private confirmationService: ConfirmationService, public router: Router) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		return next.handle(request).pipe(map((event: HttpEvent<any>) => {
			if (event instanceof HttpResponse) {
				const response = String(event.body);
				if (response.indexOf('Redirect To OP') >= 0 ) {
					this.confirmationService.confirm({
						key: 'sessionDialog',
						message: 'Page Expired. Please click "Ok" button to Login.',
						accept: () => {
							window.location.reload();
						}
					});
				}
			}
			return event;
		}), catchError(this.handleError)
		);
	}

	public handleError = (error: Response) => {
		if (event instanceof HttpResponse) {
			// const response = String(event.body);
			const response = String((event as HttpResponse<any>).body);

			
			if (response.indexOf('Redirect To OP') >=0 ){
				this.confirmationService.confirm({
					key: 'sessionDialog',
					message: 'Page Expired. Please click "Ok" button to Login.',
					accept: () => {
						window.location.reload();
					}
				});
			}
		}

		if (error) {
			console.log("teja");
			console.log(error);
			this.router.navigate(['/unauthorized'], {queryParams: {isErrorOccurs: true}});
		}
		return throwError(error);
	}
}
