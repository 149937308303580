import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ConsolidatedReportData} from "../models/ConsolidatedReportData";
import {FcicadminService} from "../services/fcicadmin.service";
import {ConsolidatedreportService} from "../services/consolidatedreport.service";
import { Router } from '@angular/router';
import { FinIndicatorService } from '../../app/services/finIndicator.service';
import { DropdownItem } from '../../app/models/dropdownItem';
import { ErrorResponseData } from '../../app/models/ErrorResponseData';
import { ApiError } from '../../app/models/ApiError';
import { PersonalEntitlementService } from '../../app/services/personal-entitlement.service';
import { SelectItem } from 'primeng/api';
import { CountryEnum } from '../../app/common/countryenum.model';
import { saveAs } from 'file-saver';

@Component({
	selector: 'consolidated-report',
	templateUrl: './consolidated-report.component.html',
	styleUrls: ['./consolidated-report.component.css']
})
export class ConsolidatedReportComponent implements OnInit {

	reportedDate: any;
	showTableLayout: boolean;
	selectedStatusValue: any;
	selectedEntitlementValue: any;
	selectedAccountManager: any;
	displayMessage: boolean = false;
	errorMessage: any;
	fleetUserReports: ConsolidatedReportData[];
	homefocus: ElementRef;
	showSpinner: Boolean = false;
	pageOptions: any[];
	showFin: boolean;
	showPersonal: boolean;
	Pagerows: number = 10;
	inputRows: number ;
	finIndicatorDescription : DropdownItem[];
	personalEntitlementList: DropdownItem[];
	fincodeTest: string;
	errorResponse: ErrorResponseData;
	errorTest: ApiError;
	selectedfinIndicatorCode: any[];
	selectedEntitlement: any[];
	isPersonalDisabled: boolean;
	isFinDisabled: boolean;
	entitlementType : string;
	entitlements = [];
	countryCodes: SelectItem[];
	selectedCountryCode : any;

	@ViewChild("countrydropdown",  { read: ElementRef,static: false }) tref: ElementRef;
	@ViewChild("findropdown", { read: ElementRef,static: false }) finref: ElementRef;
	@ViewChild("personaldropdown", { read: ElementRef,static: false }) personalref: ElementRef;
	constructor(private consolidatedreportService: ConsolidatedreportService, public router: Router,
				public fins: FinIndicatorService, public personEntitlement: PersonalEntitlementService) {
		this.reportedDate = new Date().toLocaleDateString("en-US");
		this.showTableLayout = false;
		this.showFin = false;
		this.showPersonal = false;
		this.isPersonalDisabled = true;
		this.isFinDisabled = true;
		this.selectedStatusValue = 'active';
		this.loadFinIndicator();
		this.loadPersonEntitlement();
	}

	ngOnInit() {
		this.pageOptions = [10, 20, 30, 40, 50];
		this.setInitialFocus();
		this.loadCountryCodes();
	}

	showAll() {
		var h = this.tref.nativeElement;
		var text = document.createTextNode("All");
		h.childNodes[0].lastChild.lastChild.childNodes[1].childNodes[1].after(text);
	}

	finshowAll() {
		var i = this.finref.nativeElement;
		var text = document.createTextNode("All");
		i.childNodes[0].lastChild.lastChild.childNodes[1].childNodes[1].after(text);
	}

	personalshowAll() {
		var i = this.personalref.nativeElement;
		var text = document.createTextNode("All");
		i.childNodes[0].lastChild.lastChild.childNodes[1].childNodes[1].after(text);
	}

	loadCountryCodes() {
		this.countryCodes = [
			{label: 'CAN', value:CountryEnum.CANADA},
			{label: 'USA', value:CountryEnum.USA},
		];
	}

	onchangeCountryCode(code) {
		console.log("afs code ", code, "adfaf ", code.length)
		this.showTableLayout = false;
		this.selectedfinIndicatorCode = null;
		this.selectedEntitlement = null;
		if(code.length == 0 || code === undefined || code === null) {
			this.isFinDisabled = true;
			this.isPersonalDisabled = true;
			return;
		} else {
			this.isFinDisabled = false;
			this.isPersonalDisabled = false;
		}
	}

	onChangeStatus(event) {
		console.log("asdf ", event)
		this.showTableLayout = false;
	}

	changeRecords(rows) {
		console.log("asd ", rows)
		if(rows == null) {
			return;
		} else {
			this.inputRows = rows;
			const inputValue = Number(rows);
			this.Pagerows = inputValue;
			let col = [];
			this.pageOptions.push(inputValue);
			col = this.pageOptions.filter((item, i, arr) => {
				return arr.indexOf(arr.find(t => t === item)) === i
			});
			this.pageOptions = col.sort((a,b) => a - b);
		}

	}

	clear() :void{
		this.selectedAccountManager = undefined;
		this.showTableLayout = false;
		this.selectedStatusValue = 'active';
		this.paginateValues();
		this.showFin = false;
		this.showPersonal = false;
		this.selectedEntitlementValue = undefined;
		this.isPersonalDisabled = true;
		this.isFinDisabled = true;
		this.selectedfinIndicatorCode = null;
		this.selectedEntitlement = null;
		this.selectedCountryCode = null;
	}

	paginateValues() {
		this.pageOptions = [10, 20, 30, 40, 50];
		this.inputRows = null;
		this.Pagerows = 10
	}

	search() :void{
		this.inputRows = null; // Pagination search input value setting as empty

		if( this.selectedCountryCode == null) {
			this.showErrorMsg('Please select Country Code to view the report');
			return;
		}
		if(this.selectedfinIndicatorCode === undefined && this.selectedEntitlement === undefined){
			this.showErrorMsg('Please select at least one Entitlement to view the report');
			return;
		}

		if( !this.isPersonalDisabled && !this.isFinDisabled) {
			this.showErrorMsg('Please select at least one Entitlement to view the report');
			return;
		}


		this.showSpinner = true;

		console.log("dsfsdaf ", this.selectedStatusValue, "sdfsa " , this.selectedfinIndicatorCode, "sfdffdsf " , this.selectedEntitlement)

		if(!this.isFinDisabled) {
			this.entitlements =  this.selectedfinIndicatorCode;
			this.entitlementType = 'FIN'
		}
		if(!this.isPersonalDisabled) {
			this.entitlements =  this.selectedEntitlement;
			this.entitlementType = 'PERSONAL'
		}

		this.consolidatedreportService.getConsolidatedReportData(this.selectedStatusValue, this.entitlements, this.entitlementType, this.selectedCountryCode).subscribe(data => {
			data = data['result']['dataQuery'];
			if(this.selectedfinIndicatorCode != undefined){
				if(this.selectedfinIndicatorCode.length > 0){
					this.showFin = true;
					this.showPersonal = false;
				}
			}
			else if(this.selectedEntitlement != undefined){
				if(this.selectedEntitlement.length > 0){
					this.showFin = false;
					this.showPersonal = true;
				}
			}
			if (data.length === 0 ) {
				this.errorResponse = new ErrorResponseData(data);
				this.showErrorMsg("No Report Data Found !! ");
				this.showFin = false;
				this.showSpinner = false;
				return;
			} else {
				this.fleetUserReports = data;
				this.consolidatedreportService.getReportDate().subscribe(data => {
					this.reportedDate = data;

					this.showTableLayout = true;
					this.showSpinner = false;
				});

			}
		});
		this.paginateValues();
		// this.isPersonalDisabled = false;
		// this.isFinDisabled = false;
	}

	showErrorMessage(messageToDsiplay){
		this.displayMessage = true;
		this.errorMessage = messageToDsiplay;
	}

	popupclose(focus) {
		this.displayMessage = false;
		setTimeout(() => {
			focus.containerViewChild.nativeElement.click();
			focus.hide();
		}, 200);
	}

	setInitialFocus(): void{
		setTimeout(() =>{

		},200);
	}

	focustInitialComponent(focus){
		focus.containerViewChild.nativeElement.click();
		focus.hide();
	}

	getDownloadExcel() {
		this.showSpinner =true;
		this.consolidatedreportService.getDownloadExcelForBatchOasis(this.selectedStatusValue, this.entitlements, this.entitlementType, this.selectedCountryCode).subscribe(data => {
			this.downloadFileforBatch(data);
			this.showSpinner = false;

		});
	}

	disableOrEnablePersonal(){
		this.showTableLayout = false;
		if(this.selectedfinIndicatorCode.length > 0){
			this.isPersonalDisabled = true;
			this.selectedEntitlement = null;
		}
		else{
			this.isPersonalDisabled = false;
		}
	}

	disableOrEnableFin(){
		this.showTableLayout = false;
		if(this.selectedEntitlement.length > 0){
			this.isFinDisabled = true;
			this.selectedfinIndicatorCode = null;
		}
		else{
			this.isFinDisabled = false;
		}
	}

	downloadFileforBatch(data: BlobPart) {
		const fileName = 'FleetUserReport.xls';
		const ua: string = window.navigator.userAgent;
		const windowsBrowserRegex = /Trident|MSIE|Edge/;
		const isIE = ua.match(windowsBrowserRegex);
		const blob = new Blob([data], {type: 'application/pdf'});
		const a = document.createElement('a');
		a.href = URL.createObjectURL(blob);
		if (isIE) {
			saveAs(blob, fileName);
		} else {
			a.setAttribute('target', '_blank');
			a.download = fileName;
			a.click();
		}
		a.remove();
	}

	goToAuditReports() {
		this.router.navigate(['fleet-audit-report']);
	}

	loadFinIndicator() {
		this.showSpinner = true;
		this.fins.getFinIndicator().subscribe( data => {
				this.fincodeTest = JSON.stringify(data);
				this.errorResponse = new ErrorResponseData();
				const options = [];
				if (data['result'] && data['result']['finIndicatorDescription']) {
					// this.fincodeList = data['result']['finIndicatorDescription'];
					// for (const val of this.fincodeList) {
					for (const val of data['result']['finIndicatorDescription']) {
						// options.push({'label': val['finIndicator'] + '-' + val['finIndicatorDesc'], 'value': val['finIndicator']});
						options.push({'label': val.finIndicator + '-' + val.finIndicatorDesc, 'value': val.finIndicator});
					}
					this.finIndicatorDescription = options;
					this.finIndicatorDescription = options.sort(function(a, b) { return a.finIndicator - b.finIndicator; }) ;

				} else {
					this.showSpinner = false;
					this.errorResponse = new ErrorResponseData(data);
					if (this.errorResponse && this.errorResponse.error && this.errorResponse.error.messages) {
						this.showErrorMsg(this.errorResponse.error.messages[0]);
					}
				}

			},
			(error: ApiError) => {
				this.errorTest = error;
			});

		this.showSpinner = false;
	}

	showErrorMsg(errorMsg) {
		this.displayMessage = true;
		if (errorMsg) {
			this.errorMessage = errorMsg;
		}
	}

	loadPersonEntitlement() {
		this.showSpinner = true;
		this.personEntitlement.getPersonalEntitlementList().subscribe(data => {
			const options = [];
			if (data['result'] === null && data['error'] !== null) {
				this.errorResponse = new ErrorResponseData(data);
				this.showErrorMsg('Error: Method ' + this.errorResponse.error.messages[0]);
				this.showSpinner = false;
				return;
			}
			//options.push({'label': '-Select-', 'value': ''});
			if (data['result']['personalEntitlementApi']['personalEntitlementList'].length > 0) {
				for (const val of data['result']['personalEntitlementApi']['personalEntitlementList']) {
					options.push({'label': val.RoleDescription, 'value': val.RoleName});
				}
				const sortedArray = options.sort(function (a, b) {
					return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
				});
				this.personalEntitlementList = sortedArray;
				this.showSpinner = false;
			}

			this.showSpinner = false;
		});
	}
}
