import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {RipsHeaderData} from '../models/RipsHeaderData';
import {Rips} from '../models/Rips';
import {StatementDates} from '../models/StatementDates';

@Injectable()
export class RipsService {
	_accessToken: string = sessionStorage.getItem('encodedAccessToken');
	fwsUrl = environment.APIEndpoint;
	paymentReportService=environment.paymentReportsService;
	userId = sessionStorage.getItem('userId');
	private options = {
		headers: new HttpHeaders({
			'Authorization': 'Bearer ' + this._accessToken,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'origin': window.location.origin
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient) {
	}

	getPayeeCodeForRips(adminFinCode): Observable<RipsHeaderData[]> {
		if(adminFinCode){
			let request = {
				"payeeCode":adminFinCode
			}
			return this.http.post<any[]>(this.paymentReportService + 'api/rips/getpayeeforfin',request, this.options);
		}  else {
			let request = {
				"userId":this.userId
			}
			 
			return this.http.post<any[]>(this.paymentReportService + 'api/rips/getPayeeList',request, this.options);

		}
	}


	getFinCodeForRips(adminFinCode): Observable<RipsHeaderData[]> {
		let request = {
			"payeeCode":adminFinCode
		}
		// if (adminFinCode) {
			return this.http.post<any[]>(this.paymentReportService + 'api/rips/getbypayeecode',request, this.options);
		// }
	}

	getStatementDateForRips(): Observable<StatementDates> {
		return this.http.get<StatementDates>(this.paymentReportService + 'api/rips/statement', this.options);
	}

	getRipsReportData(payeecode, fincode, reportType, date): Observable<Rips[]> {
		let request = {
			"payeeCode":payeecode,
			"payeeFin":fincode,
			"strReportTypes":reportType,
			"strReportDate":date
			}
		return this.http.post<Rips[]>(this.paymentReportService + 'api/rips/queryrips' ,request, this.options);
	}

	getRipsReportDataForVin(payeecode, fincode, vin): Observable<Rips[]> {
		let request ={
			"payeeCode":payeecode,
			"payeeFin":fincode,
			"strVins":vin
		}
	
		return this.http.post<Rips[]>(this.paymentReportService + 'api/rips/queryripsvin' ,request, this.options);
	}

	getDownloadExcelForRipsforFinCode(payeecode, fincode, reportType, date) {
		const headers = new HttpHeaders({
			'authorization': 'bearer ' + this._accessToken
		});
		let request = {
			"payeeCode":payeecode,
			"payeeFin":fincode,
			"strReportTypes":reportType,
			"strReportDate":date
		}
		
		return this.http.post(this.paymentReportService + 'api/rips/ripsexceldownload' ,request, {
			headers: headers,
			responseType: 'blob'
		});
	}

	getDownloadExcelForRipsforVin(payeecode, fincode, VIN) {
		const headers = new HttpHeaders({
			'authorization': 'bearer ' + this._accessToken
		});
		let request = {
			"payeeCode":payeecode,
			"payeeFin":fincode,
			"strVins":VIN}
		 
		return this.http.post(this.paymentReportService + 'api/rips/ripsvinexceldownload',request, {
			headers: headers,
			responseType: 'blob'
		});
	}

}
