import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {ConfirmationService} from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import {MessageMaintenanceDetail} from '../models/MessageMaintenanceDetail.model';
import {MessageMaintenanceService} from '../services/message-maintenance.service';


@Component({
  selector: 'msg-maint',
  templateUrl: './msg-maint.component.html',
  styleUrls: ['./msg-maint.component.css']
})
export class MsgMaintComponent implements OnInit {

  @ViewChild('clearFile',{static:false}) clearFile: any;
  @ViewChild('focus1',{static:false}) focus1: ElementRef;
  @ViewChild('focus2',{static:false}) focus2: ElementRef;
  @ViewChild('focus3',{static:false}) focus3: ElementRef;
  @ViewChild('focus4',{static:false}) focus4: ElementRef;
  @ViewChild('focus5',{static:false}) focus5: ElementRef;
  @ViewChild('focus6',{static:false}) focus6: ElementRef;

  currentDate: any;
  beginDateMax: any;
  endDateMax: any;
  previousYear: any;
  maximumYear: any;
  statementMonthDropdown = [];
  statementYearDropdown = [];
  selectedStatusValue: any;
  createdDate: any;
	createdUser: any;
  fileContent: any;
  finList: any;
  displayMessage: boolean = false;
  flagToFindUpdate: boolean = false;
  errorMessage: any;

  message: string;
  statementMonth: any;
  statementYear: any;
  beginDate: any;
  endDate: any;

  homefocus: ElementRef;
  messageMaintenanceDetail: MessageMaintenanceDetail;


  constructor(public messageMaintenanceService: MessageMaintenanceService, private confirmationService: ConfirmationService) {
    this.previousYear = new Date().getFullYear()-1;
    this.maximumYear = new Date().getFullYear() + 2;
    this.beginDateMax = new Date(new Date().setFullYear(this.maximumYear));
    this.endDateMax = new Date(new Date().setFullYear(this.maximumYear));
    this.selectedStatusValue = "active";
    this.setInitialFocus();
  }

  ngOnInit() {
    this.loadMonths();
    this.loadYear();
  }

  loadMonths(): void {
    let optionModelStatementMonth = [];
    optionModelStatementMonth.push({'label': '-Select-', 'value': ''})
    optionModelStatementMonth.push({ 'label': 'January', 'value': 1 });
    optionModelStatementMonth.push({ 'label': 'February', 'value': 2 });
    optionModelStatementMonth.push({ 'label': 'March', 'value': 3 });
    optionModelStatementMonth.push({ 'label': 'April', 'value': 4 });
    optionModelStatementMonth.push({ 'label': 'May', 'value': 5 });
    optionModelStatementMonth.push({ 'label': 'June', 'value': 6 });
    optionModelStatementMonth.push({ 'label': 'July', 'value': 7 });
    optionModelStatementMonth.push({ 'label': 'August', 'value': 8 });
    optionModelStatementMonth.push({ 'label': 'September', 'value': 9 });
    optionModelStatementMonth.push({ 'label': 'October', 'value': 10 });
    optionModelStatementMonth.push({ 'label': 'November', 'value': 11 });
    optionModelStatementMonth.push({ 'label': 'December', 'value': 12 });
    this.statementMonthDropdown = optionModelStatementMonth;
  }

  loadYear(): void {
    let optionModelStatementYear = [];
    optionModelStatementYear.push({'label': '-Select-', 'value': ''});
    optionModelStatementYear.push({ 'label': (new Date().getFullYear() - 1), 'value': (new Date().getFullYear() - 1) });
    optionModelStatementYear.push({ 'label': (new Date().getFullYear()), 'value': (new Date().getFullYear()) });
    optionModelStatementYear.push({ 'label': (new Date().getFullYear() + 1), 'value': (new Date().getFullYear() + 1) });
	optionModelStatementYear.push({ 'label': (new Date().getFullYear() + 2), 'value': (new Date().getFullYear() + 2) });
    this.statementYearDropdown = optionModelStatementYear;
  }

  readFile(event, clearFile) : void{
    let errorFileContent = false;
    let file = event.files[0];
    let fileReader: FileReader = new FileReader();
    let self = this;
    fileReader.onloadend = function (response) {
      self.fileContent = fileReader.result;
      let eachLine = self.fileContent.split('\n');
		eachLine = eachLine.filter((element) => {
			return /\S/.test(element);
		});
      let lineLength = eachLine.length;
      console.log(lineLength);
      if(lineLength<=1000){
        for (let i = 0; i <=eachLine.length; i++) {
          if(!(i === lineLength)){
            eachLine[i] = eachLine[i].replace(/(\r\n|\n|\r)/gm, "");
          }
          if(!(i === lineLength)){
            if(eachLine[i].length === 5){
              eachLine[i] = eachLine[i] + ",";
            }
            else{
              eachLine = [];
              errorFileContent = true;
            }
          }
        }
        if(!errorFileContent){
          eachLine[eachLine.length-1] = eachLine[eachLine.length-1].replace(/,/g,"");
          self.finList = eachLine.join("\n");
        }
        else{
          self.showErrorMessage("There is an error in file.");
        }
      }
      else{
        self.showErrorMessage("The Maximum Limit for No of FIN(1000) is Exceeded.");
      }
    }
    fileReader.readAsText(file);
    clearFile.clear();
  }

  getMessageDetails(){
    if(!(this.statementMonth === null || this.statementMonth === '' || this.statementMonth === undefined)){
      if(!(this.statementYear === null || this.statementYear === '' || this.statementYear === undefined)){
        this.messageMaintenanceService.getMessageDetails(this.statementMonth, this.statementYear).subscribe(responseDetails =>{
          if(!(this.message === null || this.message === '' || this.message === undefined)){
            this.showConfirmDialogue(responseDetails);
          }
          else if(!(this.finList === null || this.finList === '' || this.finList === undefined)){
            this.showConfirmDialogue(responseDetails);
          }
          else if(!(this.beginDate === null || this.beginDate === undefined)){
            this.showConfirmDialogue(responseDetails);
          }
          else if(!(this.endDate === null || this.endDate === undefined)){
			  this.showConfirmDialogue(responseDetails);
		  }
		  else {
			  this.showConfirmDialogue(responseDetails);
		  }
        });
      }
    }
  }


  showConfirmDialogue(responseDetails){
    if((responseDetails['error'] === null ||responseDetails['error'] === undefined)||!(responseDetails['error']['messages'][0] === 'Record Not Found for the input data')){
      this.confirmationService.confirm({
        message: `<span class=alpha-color>For the selected statement month and statement year message is already available. Are you sure you want to replace with already available message ?</span>`,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: ()=>{
          this.message = responseDetails['result']['getMsgByMonthAndYear']['message'];
          this.finList = responseDetails['result']['getMsgByMonthAndYear']['finList'];
          this.beginDate = new Date(responseDetails['result']['getMsgByMonthAndYear']['beginDate']);
          if(responseDetails['result']['getMsgByMonthAndYear']['endDate']){
            this.endDate = new Date(responseDetails['result']['getMsgByMonthAndYear']['endDate']);
          }
          this.selectedStatusValue = responseDetails['result']['getMsgByMonthAndYear']['status']==="Y"?'active':'inactive';
          this.createdDate = responseDetails['result']['getMsgByMonthAndYear']['createdDate'];
          this.createdUser = responseDetails['result']['getMsgByMonthAndYear']['createdUser'];
          this.flagToFindUpdate = true;
        }
      });
      this.flagToFindUpdate = true;
    }
  }

  submit() {
    let emptyComponentList = [];
    if(this.statementMonth === null || this.statementMonth === '' || this.statementMonth === undefined){
      emptyComponentList.push(3);
    }
    if(this.statementYear === null || this.statementYear === '' || this.statementYear === undefined){
      emptyComponentList.push(4);
    }
    if(this.message === null || this.message === '' || this.message === undefined){
      emptyComponentList.push(1);
    }
    if(this.finList === null || this.finList === '' || this.finList === undefined){
      emptyComponentList.push(2);
    }
    if(this.beginDate === null || this.beginDate === undefined){
      emptyComponentList.push(5)
    }
	if(this.message.length > 500) {
	  emptyComponentList.push(6);
	}
	if (this.validateFinCode()) {
		emptyComponentList.push(7);
	}

    if(emptyComponentList.length>=1){
      if(emptyComponentList.length==1){
        switch(emptyComponentList[0]){
            case 1:
              this.showErrorMessage("Please Enter a Message.");
              this.homefocus = this.focus1;
            break;
            case 2:
              this.showErrorMessage("Please Enter FIN List.");
              this.homefocus = this.focus2;
            break;
            case 3:
              this.showErrorMessage("Please Select Statement Month.");
              this.homefocus = this.focus3;
            break;
            case 4:
              this.showErrorMessage("Please Select Statement Year.");
              this.homefocus = this.focus4;
            break;
            case 5:
              this.showErrorMessage("Please Select Begin Date.");
              this.homefocus = this.focus5;
            break;
			case 6:
				this.showErrorMessage("Message should not exceed 500 characters.");
				this.homefocus = this.focus1;
				break;
			case 7:
				this.showErrorMessage("Invalid FIN code exists in FIN list.");
				this.homefocus = this.focus2;
				break;
        }
      }
      else{
        this.showErrorMessage("Please Select All the Required Fields.");
      }
    }
    else{
      if(this.endDate != null && this.endDate != undefined){
        if(this.beginDate > this.endDate){
          this.showErrorMessage("Please Enter End Date Greater than Begin Date.");
          this.homefocus = this.focus6;
        }
        else{
          this.createModelInsertData();
        }
      }
      else{
        this.createModelInsertData();
      }
    }
  }

  fileUploadFailed(){
    this.showErrorMessage("File Upload Failed.");
    this.homefocus = undefined;
  }

  createModelInsertData(){
    this.messageMaintenanceDetail = new MessageMaintenanceDetail();
        this.messageMaintenanceDetail.message = this.message;
        this.messageMaintenanceDetail.finList = this.finList;
        this.messageMaintenanceDetail.year = this.statementYear;
        this.messageMaintenanceDetail.month = this.statementMonth;
	  	this.messageMaintenanceDetail.beginDate = this.beginDate.getFullYear()+'-'+(this.beginDate.getMonth()+1)+'-'+this.beginDate.getDate();
		  if(this.endDate != null && this.endDate != undefined){
			  this.messageMaintenanceDetail.endDate = this.endDate.getFullYear()+'-'+(this.endDate.getMonth()+1)+'-'+this.endDate.getDate();
		  }
		  else{
			  this.messageMaintenanceDetail.endDate = this.endDate;
		  }
        this.messageMaintenanceDetail.status = this.selectedStatusValue  === 'active' ? 'Y' : 'N';
        this.messageMaintenanceDetail.createdDate = this.createdDate;
       // this.messageMaintenanceDetail.createdUser = this.createdUser;
	  this.messageMaintenanceDetail.createdUser = sessionStorage.getItem('userId');
	    this.messageMaintenanceDetail.lastUpdateUser = sessionStorage.getItem('userId');
        //this.messageMaintenanceDetail.userId = sessionStorage.getItem('userId');
        this.messageMaintenanceService.insertMessageDetails(this.messageMaintenanceDetail, this.flagToFindUpdate).subscribe(response =>{
          if(response['result'] != null) {
          if(response['result']['msgExists'] === "Submitted Successfully" ){
            if(this.flagToFindUpdate){
              this.showErrorMessage("Message Updated Successfully.");
              this.homefocus = undefined;
            }
            else{
              this.showErrorMessage("Message Added Successfully.");
            this.homefocus = undefined;
            }
            this.createdDate = response['result']['savedData']['createdDate'];
            this.createdUser = response['result']['savedData']['createdUser'];
            this.flagToFindUpdate = true;
          }
        }
          else{
            this.showErrorMessage("Failed to Add Message.");
            this.homefocus = undefined;
          }
        });
  }

  clear(): void{
    this.message = null;
    this.statementMonth = '';
    this.statementYear = '';
    this.beginDate = null;
    this.endDate = null;
    this.finList = null;
    this.previousYear = new Date().getFullYear()-1;
    this.maximumYear = new Date().getFullYear() + 2;
    this.beginDateMax = new Date(new Date().setFullYear(this.maximumYear));
    this.endDateMax = new Date(new Date().setFullYear(this.maximumYear));
    this.selectedStatusValue = "active";
    this.flagToFindUpdate = false;
    this.clearFile.clear();
    this.setInitialFocus();
  }

  showErrorMessage(messageToDsiplay){
    this.displayMessage = true;
      this.errorMessage = messageToDsiplay;
  }

  popupclose(focus) {
		this.displayMessage = false;
		setTimeout(() => {
			if (focus.nativeElement !== undefined){
        focus.nativeElement.focus();
      }
      else if(focus.focusViewChild !== undefined){
        focus.focusViewChild.nativeElement.focus();
      }
      else if(focus !== undefined){
        focus.inputfieldViewChild.nativeElement.focus();
      }
		}, 200);
  }

  setInitialFocus(): void{
    setTimeout(() =>{
        this.focustInitialComponent(this.focus3);
    },200);
  }

  focustInitialComponent(focus){
    focus.focusViewChild.nativeElement.focus();
  }

	validateFinCode(): boolean {
  		const fins = this.finList.toString().split(',');
		for (let i = 0; i < fins.length; i++) {
			let value = fins[i].trim().length;
			console.log(fins[i] + '  ' + value);
			if (value !== 5) {
				return true;
			}
		}

		return false;
	}
}
