export class FinIndicatorDetailsModel {
	finIndicatorName: string;
	finIndicatorDescription: string;
	userId: string;
	actionToBePerformed: string;

	constructor(finindicatorDetailsModel?: FinIndicatorDetailsModel) {
		if (finindicatorDetailsModel) {
			this.finIndicatorName = finindicatorDetailsModel.finIndicatorName;
			this.finIndicatorDescription = finindicatorDetailsModel.finIndicatorDescription;
			this.userId = finindicatorDetailsModel.userId;
			this.actionToBePerformed = finindicatorDetailsModel.actionToBePerformed;
			// if (finindicatorDetailsModel) {
			// 	Object.assign(this, finindicatorDetailsModel);
			// }
		}
	}
}
