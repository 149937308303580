import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
	selector: '[OnlyAlphabets]'
})
export class OnlyAlphabetsDirective {
	@Output() ngModelChange: EventEmitter<any> = new EventEmitter();
	constructor() {	}

	@HostListener('keydown', ['$event'])
	onKeyDown(e: KeyboardEvent) {
		if (
			[46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
			(e.keyCode === 65 && e.ctrlKey === true) ||
			(e.keyCode === 67 && e.ctrlKey === true) ||
			(e.keyCode === 86 && e.ctrlKey === true) ||
			(e.keyCode === 88 && e.ctrlKey === true) ||
			(e.keyCode === 65 && e.metaKey === true) ||
			(e.keyCode === 67 && e.metaKey === true) ||
			(e.keyCode === 86 && e.metaKey === true) ||
			(e.keyCode === 88 && e.metaKey === true) ||
			(e.keyCode >= 35 && e.keyCode <= 39)
		) {
			return;
		}

		if (e.keyCode === 188 && e.shiftKey || e.keyCode === 188) {
			e.preventDefault();
		} else if (!(e.keyCode >= 65 && e.keyCode <= 120) && (e.keyCode !== 32 && e.keyCode !== 0)) {
			e.preventDefault();
		} else if (e.keyCode === 32) {
			e.preventDefault();
		} else if (e.keyCode >= 96 && e.keyCode <= 111) {
			e.preventDefault();
		}
	}

	@HostListener('paste', ['$event'])
	onPaste(event) {
		const regexString = /[A-Za-z]+$/;
		const regex = new RegExp(regexString);
		const pastedInput: string = event.clipboardData
			.getData('text/plain');
		if (regex.test(pastedInput)) {
			return true;
		}
		event.preventDefault();
		return false;
	}
}
