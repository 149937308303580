import {Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-fim-monthly-report',
	templateUrl: './fim-monthly-report.component.html',
	styleUrls: ['./fim-monthly-report.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class FimMonthlyReportComponent implements OnInit {
	@Input() programSummary;
	@Input() reportyear;
	@Output() loadIndividualSum: EventEmitter<any> = new EventEmitter<any>();
	@Input() totalAmount;
	@Input() summaryCount;

	ngOnInit() {
	}

	goToIndividualReport(indexDescription, summaryDesc, indexProgramCode, summaryReportCode, summaryCount ) {
		const prgSummary: any = [];
		prgSummary.indexDescription = indexDescription;
		prgSummary.summaryDesc = summaryDesc;
		prgSummary.indexProgramCode = indexProgramCode;
		prgSummary.summaryReportCode = summaryReportCode;
		prgSummary.summaryCount = summaryCount;
		this.loadIndividualSum.emit(prgSummary);
	}

}
