import {Component, OnInit} from '@angular/core';
import {Message} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {PtsfileuploadService} from '../services/ptsfileupload.service';
import {FileUploadModel} from "../models/FileUpload.model";
import {FileUploadAddPACode} from "../models/FileUploadAddPACode";
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ConfirmationService} from 'primeng/api';
// import {GrowlModule} from 'primeng/growl';
import {Router} from '@angular/router';

@Component({
  selector: 'pts-file-upload',
  templateUrl: './pts-file-upload.component.html',
  styleUrls: ['./pts-file-upload.component.css']
})
export class PtsFileUploadComponent implements OnInit {

	userId: string;
	//uploadedFiles: any[] = [];
	reportMsgs: Message[] = [];
	fileUploadModelList: FileUploadModel[];
	fileUploadAddPACode: FileUploadAddPACode;
	pacodeModelList: FileUploadAddPACode[];
	pacodeForEdit: FileUploadAddPACode;
	cols: any[];
	fincode: string;
	fincodes: string;
	pacode: string;
	countrycode: string;
	errcode: string;
	errdescription: string;
	outputstatus: string
	totalRecord: number;
	file: File;
	public show: boolean = false;
	public displaySpinner: boolean = false;
	public fileupload: boolean = false;
	public pacodeupload: boolean = false;
	public Noauthorized: boolean = false;
	val2: any;
	display: boolean = false;
	headerValue: string;
	title: string;

	selectedCountryForEdit: string;
	searchFincodeForEdit: string;
	searchPAcodeForEdit: string;
	isValidEditPAcode: boolean;

	isValidCountry: boolean;
	isValidFincode: boolean;
	isValidPAcode: boolean;
	selectedCountry: any;
	searchFincode: string;
	searchPAcode: string;
	Country: any;
	adderrdescription: string;
	existpacode: number;

  constructor(public router: Router, private messageService: MessageService, private ptsfileuploadService: PtsfileuploadService) { }

  ngOnInit() {
	  this.val2 = 'fin';
	  this.title = 'Add/Edit PACode';
	  this.pacodeupload = true;
	  this.fileupload = false;
	  this.Noauthorized = false;
	  this.userId = sessionStorage.getItem('userId');
	  this.fileUploadModelList = [];
	  this.loadCountry();
	  this.loadPacodeList();

	  this.cols = [
		  { field: 'countrycode', header: 'countrycode' },
		  { field: 'fincode', header: 'fincode' },
		  { field: 'pacode', header: 'pacode' },
		  { field: 'updatedby', header: 'Last Updated User ID' },
		  { field: 'updatedDate', header: 'Last Updated Date' },
		  { field: 'action', header: 'Action To Perform' }
	  ];
  }

	onClear(){
		this.show = false;
	}


	myUploader(event) {
		this.Noauthorized = false;
		this.show = false;
		this.totalRecord = 0;
		this.fileUploadModelList = [];
		this.displaySpinner = true;
  	this.ptsfileuploadService.uploadFile(event.files[0], this.userId).subscribe(data => {
		this.displaySpinner = false;
		this.totalRecord = data['result']['ptsoutput'].length;
			if ((data['result']['ptsoutput'] != null) && (this.totalRecord !== 0)) {
				this.show = true;
				data['result']['ptsoutput'].forEach((dataValue) => {
					this.fileUploadModelList.push(Object.assign({}, dataValue));
				});

				this.messageService.add({severity: 'success', summary: 'Success', detail: 'File Uploaded Successfully'});
			} else {
				this.Noauthorized = true;
				//this.messageService.add({severity: 'error', summary: 'PACode', detail: 'Invalid Data. Kindly ensure the Data'});
			}
	});


		//event.files == files to upload
	}


	setpacode(value) {
		if (value === 'bulk') {
			this.Noauthorized = false;
			this.show = false;
			this.fileupload = true;
			this.pacodeupload = false;
			this.title = 'PACode Bulk Upload';

		} else {
			this.pacodeupload = true;
			this.loadPacodeList();
			this.fileupload = false;
			this.title = 'Add/Edit PACode';
		}
	}

/*
	onSelectionChange(value) {
		if (value === 'c') {
			this.isValidCountry = !this.selectedCountry;
		} else if (value === 'E') {
			this.isValidEditCountry = !this.selectedCountryForEdit;
		}
	}
*/

	addpacode() {

/*		alert(this.selectedCountry);
		alert(this.searchFincode);
		alert(this.searchPAcode);*/

		this.isValidCountry = false;
		this.isValidFincode = false;
		this.isValidPAcode = false;

		this.isValidCountry = !this.selectedCountry;

		if ((this.searchFincode === '') || (this.searchFincode === null) || (this.searchFincode === undefined)) {
			this.isValidFincode = true;
		}

		if ((this.searchPAcode === '') || (this.searchPAcode === null) || (this.searchPAcode === undefined)) {
			this.isValidPAcode = true;
		}

		if (this.isValidCountry || this.isValidFincode || this.isValidPAcode) {
			return;
		}

		this.displaySpinner = true;
		this.fileUploadAddPACode = new FileUploadAddPACode;
		this.fileUploadAddPACode.fincode = this.searchFincode;
		this.fileUploadAddPACode.pacode = this.searchPAcode;
		this.fileUploadAddPACode.countrycode = this.selectedCountry;
		this.fileUploadAddPACode.updatedby = this.userId;
		this.fileUploadAddPACode.overwrite = 'N';

		this.existpacode = this.findIndexById(this.fileUploadAddPACode.countrycode, this.fileUploadAddPACode.fincode);

		//alert('existpacode = ' + this.existpacode);
		if (this.existpacode !== -1) {
			this.displaySpinner = false;
			this.messageService.add({severity: 'info', summary: 'PACode', detail: 'Fincode already Mapped'});

		} else {

			this.ptsfileuploadService.addPACode(this.fileUploadAddPACode).subscribe(addpacode => {

				this.adderrdescription = addpacode['result']['ptsoutputadd']['errdescription'];
				this.displaySpinner = false;
				if (this.adderrdescription === null || this.adderrdescription === 'null') {
					this.messageService.add({severity: 'Success', summary: 'PACode', detail: 'PACode added Successfully'});
					this.pacodeModelList.push(this.fileUploadAddPACode);
					this.pacodeModelList = [...this.pacodeModelList];
					this.clear();
				} else {
					this.messageService.add({severity: 'error', summary: 'PACode', detail: '(' + this.fileUploadAddPACode.fincode + ') ' + this.adderrdescription});
				}
			});

		}
	}
	clear() {
		this.selectedCountry = '';
		this.searchFincode = '';
		this.searchPAcode = '';
	}

	loadCountry() {
		let optionModelCountry = [];
		optionModelCountry.push({'label': '-- Countries --', 'value': ''});
		optionModelCountry.push({'label': 'USA', 'value': 'USA'});
		optionModelCountry.push({'label': 'CAN', 'value': 'CAN'});
		this.Country = optionModelCountry;
	}

	loadPacodeList() {
		this.pacodeModelList = [];
		this.displaySpinner = true;
		this.ptsfileuploadService.getPacodeInformation().subscribe(data => {
			this.displaySpinner = false;

			if (data['result']['pacodeinfo'] != null) {
				const testvar = [];
				data['result']['pacodeinfo'].forEach((dataValue) => {
					testvar.push(Object.assign({}, dataValue));

				});
				this.pacodeModelList = testvar;
			}
		});
	}

	editPACode(pacodeModelList) {
		this.isValidEditPAcode = false;

		this.pacodeForEdit = new FileUploadAddPACode();
		this.pacodeForEdit.fincode = pacodeModelList.fincode;
		this.pacodeForEdit.pacode = pacodeModelList.pacode;
		this.pacodeForEdit.countrycode = pacodeModelList.countrycode;
		this.pacodeForEdit.updatedby = this.userId;

		this.selectedCountryForEdit = pacodeModelList.countrycode;
		this.searchFincodeForEdit = pacodeModelList.fincode;
		this.searchPAcodeForEdit = pacodeModelList.pacode;

/*		this.selectedCountry = pacodeModelList.countrycode;
		this.searchFincode = pacodeModelList.fincode;
		this.searchPAcode = pacodeModelList.pacode;*/
		this.headerValue = "Edit PACode";

		this.display = true;

	}

	submitPacodeDetails() {

		if ((this.searchPAcodeForEdit === '') || (this.searchPAcodeForEdit === null) || (this.searchPAcodeForEdit === undefined)) {
			this.isValidEditPAcode = true;
			return;
		}

		this.displaySpinner = true;

		this.fileUploadAddPACode = new FileUploadAddPACode;
		this.fileUploadAddPACode.fincode = this.searchFincodeForEdit;
		this.fileUploadAddPACode.pacode = this.searchPAcodeForEdit;
		this.fileUploadAddPACode.countrycode = this.selectedCountryForEdit;
		this.fileUploadAddPACode.updatedby = this.userId;
		this.fileUploadAddPACode.overwrite = 'Y';

		this.ptsfileuploadService.addPACode(this.fileUploadAddPACode).subscribe(addpacode => {
			this.displaySpinner = false;
			this.messageService.add({severity: 'success', summary: 'PACode', detail: 'PACode Updated Successfully'});

			this.pacodeModelList[this.findIndexById(this.fileUploadAddPACode.countrycode, this.fileUploadAddPACode.fincode)] = this.fileUploadAddPACode;
			this.pacodeModelList = [...this.pacodeModelList];
		});

		this.display = false;
	}

	findIndexById(countrycode: string, fincode: string): number {
		let index = -1;
		for (let i = 0; i < this.pacodeModelList.length; i++) {
			if (this.pacodeModelList[i].countrycode.toUpperCase() === countrycode.toUpperCase() && this.pacodeModelList[i].fincode.toUpperCase() === fincode.toUpperCase()) {
				index = i;
				break;
			}
		}

		return index;
	}

	opendocumentfile(file) {
		//this.downloadPDF(file).subscribe(res => {
		//	const fileURL = URL.createObjectURL(res);
		window.open('assets/documents/' + file, '_blank');
		//});
	}

}
