import { Component, OnInit } from '@angular/core';
import {CookieHomeService} from '../services/cookiehome.service';
import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import {ViewEncapsulation} from '@angular/core';
import {CookieHomeModel} from '../models/CookieHome.model';
import {FinListDetails} from '../models/FinListDetails';

@Component({
  selector: 'user-cookie-home',
  templateUrl: './user-cookie-home.component.html',
  styleUrls: ['./user-cookie-home.component.css']
})
export class UserCookieHomeComponent implements OnInit {


	userId: string;
	wslId: string;
	paCode: string;
	finIndicator: string;
	primaryFIN: string;
	primaryFinPACode: string;
	personalIndicator: string;
	brandImageName: string;
	finList: string;
	marketSegment: string;
	salesRep: string;
	redirect: string;
	redirects: string;
	CookieList = [];
	public displaySpinner: boolean = false;
	public finlistshow: boolean = false;
	public Dealershow: boolean = false;
	public Externalshow: boolean = false;
	finListDetails: FinListDetails[];

	cookieHomeModel: CookieHomeModel;

	userType: string;
	siteCode: string;
	userFName: string;
	userLName: string;
	userEmail: string;
	permissionTypeMap: string;
	aciGroup: string;
	fordSiteCodeType: string;
	fordSiteCode: string;
	country: string;

  constructor(private cookieHomeService: CookieHomeService, private messageService: MessageService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
	  this.displaySpinner = true;
	  this.userId = this.validation(sessionStorage.getItem('userId'));
	  this.aciGroup = this.validation(sessionStorage.getItem('userType'));
	  this.siteCode = this.validation(sessionStorage.getItem('siteCode'));
	  this.userFName = this.validation(sessionStorage.getItem('userFName'));
	  this.userLName = this.validation(sessionStorage.getItem('userLName'));
	  this.userEmail = this.validation(sessionStorage.getItem('userEmail'));
	  this.fordSiteCodeType = this.validation(sessionStorage.getItem('fordSiteCodeType'));
	  this.fordSiteCode = this.validation(sessionStorage.getItem('fordSiteCode'));
	  this.country = this.validation(sessionStorage.getItem('country'));



	  this.permissionTypeMap = this.validation(this.permissionTypeMap);
	  this.userType = this.validation(this.userType);

	  this.loadUserDetails(this.userId);
  }

	loadUserDetails(userid) {
		this.displaySpinner = true;
		this.cookieHomeService.getCookieInfo(userid).subscribe(data => {
			this.displaySpinner = false;
			this.CookieList = [];
			this.CookieList.push(data);
			this.wslId = this.validation(data['wslId']);
			this.paCode = this.validation(data['paCode']);
			this.finIndicator = this.validation(data['finIndicator']);
			this.primaryFIN = this.validation(data['primaryFIN']);
			this.primaryFinPACode = this.validation(data['primaryDealerPACode'])
			this.personalIndicator = this.validation(data['personalIndicator']);
			// this.personalIndicator = this.validation(data['entitlement/role/personalIndicator']);
			this.brandImageName = 'null';
			//this.brandImageName = this.validation(data['brandImageName']);
			this.finList = this.validation(data['finList']);
			this.marketSegment = this.validation(data['marketSegment']);
			this.salesRep = this.validation(data['salesRep']);
			this.redirect = this.validation(data['redirect']);
			this.finListDetails = data['finDetail'];


			if (this.finListDetails === null || this.finListDetails === undefined) {
				this.finlistshow = false;
			} else {
				this.finlistshow = true;
			}

			if (this.aciGroup !== null || this.aciGroup !== undefined) {
				if (this.aciGroup.trim().toUpperCase() === 'Dealer'.toUpperCase()) {
					this.Dealershow = true;
				}

				if (this.aciGroup.trim().toUpperCase() === 'Other'.toUpperCase()) {
					this.aciGroup = this.aciGroup + ' - ' + this.fordSiteCodeType + ' - ' + this.country + ' - ' + this.fordSiteCode ;
					this.Externalshow = true;
				}
			}
			//alert(this.finListDetails[0].fincode);
		});
	}

	validation(value) {
		this.redirects = 'null';
		if (value !== null) {
			this.redirects = value;
		}

		if (this.redirects === undefined || this.redirects === '') {
			this.redirects = 'null';
		}

		if (this.redirects !== 'null') {
			if (this.redirects.indexOf(',')) {
				this.redirects = this.redirects.replace(/,/g, ',\n');
			}
		}

		return this.redirects;
	}


}
