import {Component, OnInit, ViewEncapsulation } from '@angular/core';
import {Message, SelectItem} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {FimpsService} from '../services/fimps.service';
import {PayeeAssignee} from '../models/PayeeAssignee';
import {FinCodeModel} from '../models/FinCode.model';
import {DropdownItem} from '../models/dropdownItem';
import {ErrorResponseData} from '../models/ErrorResponseData';
import {ApiError} from '../models/ApiError';
import {FimpsInvalidChargeBack} from '../models/FimpsInvalidChargeBack';
import {MessageMaintenanceService} from '../services/message-maintenance.service';
// import {equal} from 'assert';

@Component({
	selector: 'app-fim-cpa-stmt',
	templateUrl: './fim-cpa-stmt.component.html',
	styleUrls: ['./fim-cpa-stmt.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class FimCpaStmtComponent implements OnInit {
	finCodeDropdown: DropdownItem[];
	fincodeList: FinCodeModel[];
	fincodeListforUser: FinCodeModel[];
	accountNameList: FinCodeModel[];
	payeeAssigneeforPayeecode: PayeeAssignee[];
	payeeAssigneeforMonths: PayeeAssignee[];
	payeeAssigneeforReports: PayeeAssignee[];
	payeeCode: any[];
	months: SelectItem[];
	selectedfinCode: any;
	selectedpayeeCode: any;
	selectedMonth: any;
	selectedMonthfromBack: string[] = [];
	reportMsgs: Message[] = [];
	fimpsInvalidChargeBack: FimpsInvalidChargeBack[];
	defaultLabel = 'Choose';
	showSpinner: Boolean = false;
	accountName: string;
	address1: string;
	city: string;
	state: string;
	postalCode: string;
	adminFinCode: any;
	getFinCode: any;
	disabled = false;
	display = false;
	userId: string;
	userType: string;
	siteCode: string;
	errorResponse: ErrorResponseData;
	errorTest: ApiError;
	fincodeTest: string;
	userFincodeTest: string;
	accountNameTest: string;
	monthsListTest: string;
	payeeCodeListTest: string;
	displayMessage: boolean = false;
	errorMessage: string;
	bannerMessage: boolean = false;
	novMessage: boolean = false;
	contentOfBannerMessage: string;
	constructor(public fims: FimpsService, public router: Router, public datepipe: DatePipe, private route: ActivatedRoute, public messageMaintenanceService: MessageMaintenanceService) {
	}
	/*constructor(public fims: FimpsService, public router: Router, public datepipe: DatePipe, private route: ActivatedRoute) {
	}*/

	ngOnInit() {

		this.userId = sessionStorage.getItem('userId');
		this.userType = sessionStorage.getItem('userType');
		this.siteCode = sessionStorage.getItem('siteCode');
		this.adminFinCode = sessionStorage.getItem('adminFinCode');

		if (this.adminFinCode === null || this.adminFinCode === undefined || this.adminFinCode === '') {
			if (this.userType && this.userType === 'Employee') {
				this.router.navigate(['fimps-search']);
			} else {
				this.router.navigate(['fimps-cpa-stmt']);
			}
		}
		this.selectedfinCode = sessionStorage.getItem('finCode');
		this.selectedMonthfromBack = JSON.parse(sessionStorage.getItem('selectedMonths'));
		this.selectedpayeeCode = JSON.parse(sessionStorage.getItem('selectedPayeeCode'));

		this.loadFimpsPage();
	}

	loadFimpsPage() {
		this.showSpinner = true;
		const isValidUser = '';
		const fincode = [];

		if (this.adminFinCode && this.adminFinCode !== null && this.adminFinCode !== undefined && this.adminFinCode !== '' ) {
			let adminFinCodeVal = this.adminFinCode;
			adminFinCodeVal = adminFinCodeVal.replace(' ', ',');
			this.loadFinCode(adminFinCodeVal);
		} else {
			this.loadFinCodeByUser();
		}
	}

	someMethod(event:any){
		if(event.keyCode == 13){
			this.goToMonthlyReports();
		}
	}

	loadFinCode(fincode) {
		this.fims.getFinCode(fincode.toUpperCase()).subscribe( data => {
				this.fincodeTest = JSON.stringify(data);
				this.errorResponse = new ErrorResponseData();
				const options = [];
				if (data['result'] && data['result']['getPayeeCodeInfo']) {
					this.fincodeList = data['result']['getPayeeCodeInfo'];
					for (const val of this.fincodeList) {
						options.push({'label': val['finCode'] + '-' + val['acctName'], 'value': val['finCode']});
					}
					this.finCodeDropdown = options;
					if (this.finCodeDropdown  && this.finCodeDropdown.length > 0) {
						if (this.selectedfinCode === null || this.selectedfinCode === '' || this.selectedfinCode === undefined) {
							this.selectedfinCode = this.finCodeDropdown[0].value;
							this.loadPayeeCode(this.finCodeDropdown[0].value);
						} else {
							this.loadPayeeCode(this.selectedfinCode);
						}
					}
				} else {
					this.showSpinner = false;
					this.errorResponse = new ErrorResponseData(data);
					if (this.errorResponse && this.errorResponse.error && this.errorResponse.error.messages) {
						this.showErrorMsg(this.errorResponse.error.messages[0]);
					}
				}
			},
			(error: ApiError) => {
				this.errorTest = error;
			});
	}

	loadFinCodeByUser() {
		const fincode = [];
		let finCodes;
		this.fims.getFinCodeByUser(this.userId).subscribe(data => {
			this.userFincodeTest = JSON.stringify(data);
			this.errorResponse = new ErrorResponseData();
			if (data['result'] && data['result']['getFinCode']) {
				this.fincodeListforUser = data['result']['getFinCode'];
				for (const val of this.fincodeListforUser) {
					fincode.push(val.finCode);
				}
				if (fincode && fincode.length > 0) {
					finCodes = fincode.join(',');
					this.loadFinCode(finCodes);
				}
			} else {
				this.showSpinner = false;
				this.errorResponse = new ErrorResponseData(data);
				if (this.errorResponse && this.errorResponse.error && this.errorResponse.error.messages) {
					this.showErrorMsg(this.errorResponse.error.messages[0]);
				}
			}
		});
	}

	loadPayeeCode(payeecode) {
		this.getPayeeCodeInfo(payeecode);
	}

	getPayeeCodeInfo(payeecode) {
		this.showSpinner = true;
		this.errorResponse = new ErrorResponseData();
		const options = [];
		this.payeeCode = [];
		this.fims.getPayeeCode(payeecode).subscribe( data => {
			this.payeeCodeListTest = JSON.stringify(data);
			this.payeeAssigneeforPayeecode = [];
			if (data['result'] && data['result']['getPayeeAssignee']) {
				this.payeeAssigneeforPayeecode = data['result']['getPayeeAssignee'];
				for (const payee of this.payeeAssigneeforPayeecode) {
					if (payee.indexAssigneeCode && (payee.indexAssigneeCode.trim() !== '' && payee.indexAssigneeCode !== null)) {
						options.push({
							'label': payee.indexAssigneeCode + '-' + payee.payeename ,
							'value': payee.indexAssigneeCode + '-' + payeecode
						});
					} else {
						options.push({ 'label': 'none', 'value': 'none' + '-' + payeecode});
					}
				}
				this.payeeCode = options;
				if (this.payeeCode && this.payeeCode.length > 0) {
					this.payeeCode = Array.from(new Set(this.payeeCode.map(item => item.value)))
						.map(value => {
							return {
								value: value,
								label: this.payeeCode.find(s => s.value === value).label
							};
						});
					if (this.selectedpayeeCode === null || this.selectedpayeeCode === undefined || this.selectedpayeeCode === '') {
						this.selectedpayeeCode = [this.payeeCode[0]];
						this.loadMonth(payeecode, this.selectedpayeeCode);
					} else {
						this.loadMonth(payeecode, this.selectedpayeeCode);
					}
				}
			} else {
				options.push({ 'label': 'none', 'value': 'none' + '-' + payeecode});
				this.payeeCode = options;
				if (this.payeeCode.length > 0) {
					if (this.selectedpayeeCode === null || this.selectedpayeeCode === undefined || this.selectedpayeeCode === '') {
						this.selectedpayeeCode = [this.payeeCode[0]];
					}
				}
				this.selectedMonth = [];
				this.months = [];
				this.defaultLabel = 'No Data';
				this.showSpinner = false;
				this.errorResponse = new ErrorResponseData(data);
				if (this.errorResponse && this.errorResponse.error) {
					if (this.errorResponse && this.errorResponse.error && this.errorResponse.error.messages) {
						if (this.errorResponse.error.messages[0].toString() !== 'none') {
							this.showErrorMsg(this.errorResponse.error.messages[0]);
						}
					}
				}
			}
		}, err => {
			console.log(err);
		});
	}

	getAccountName(): any {
		this.fims.getAccountName(this.selectedfinCode).subscribe( data => {
			this.accountNameTest = JSON.stringify(data);
			this.showSpinner = false;
			if (data['result'] && data['result']['getAccoutName']) {
				this.accountNameList = data['result']['getAccoutName'];
				if (this.accountNameList && this.accountNameList.length > 0) {
					return this.accountNameList[0].acctName;
				} else {
					return '';
				}
			}
			return '';
		});
	}

	loadMonth(fincode, payeecode) {
		if (fincode && (fincode === null || fincode === '' || fincode === undefined || fincode.length === 0 )) {
			this.showSpinner = false;
		} else if (payeecode && (payeecode === null || payeecode === '' || payeecode === undefined)) {
			this.showSpinner = false;
		} else {
			this.showSpinner = true;
			const options = [];
			this.months = [];
			this.selectedMonth = [];
			this.errorResponse = new ErrorResponseData();
			this.fims.getPayeeCode(fincode).subscribe(data => {
				this.monthsListTest = JSON.stringify(data);
				this.payeeAssigneeforMonths = [];
				if (data['result'] && data['result']['getPayeeAssignee']) {
					this.payeeAssigneeforMonths = data['result']['getPayeeAssignee'];
					for (const payee of this.payeeAssigneeforMonths) {
						if (payee) {
							if (payee.indexStatementDate && (payee.indexStatementDate.trim() !== '' && payee.indexStatementDate !== null)) {
								if (payeecode) {
									let assigneecode;
									assigneecode = payeecode[0].value.substring(0, payeecode[0].value.indexOf('-'));
									if (assigneecode === 'none') {
										if (payee['indexAssigneeCode'].trim() === '') {
											options.push({
												'label': payee.indexStatementDate,
												'value': this.selectedpayeeCode + '-' + payee.indexStatementDate
											});
										}
									} else {
										if (payee['indexAssigneeCode'] && (payee['indexAssigneeCode'].trim() !== '' && payee['indexAssigneeCode'] !== null && payee['indexAssigneeCode'] === assigneecode)) {
											options.push({
												'label': payee.indexStatementDate,
												'value': this.selectedpayeeCode + '-' + payee.indexStatementDate
											});
										}
									}
								} else {
									options.push({
										'label': payee.indexStatementDate,
										'value': this.selectedpayeeCode + '-' + payee.indexStatementDate
									});
								}
							}
						}
					}
					this.months = options;
					if (this.months && this.months.length > 0) {
						this.months = Array.from(new Set(this.months.map(item => item.value)))
							.map(value => {
								return {
									value: value,
									label: this.months.find(s => s.value === value).label
								};
							});
					}
					if (this.selectedMonthfromBack && this.selectedMonthfromBack.length > 0) {
						this.selectedMonth = this.selectedMonthfromBack;
					} else if (this.months && this.months.length > 0) {
						this.selectedMonth = [this.months[0]];
					} else {
						this.defaultLabel = 'No Data';
					}
					this.selectedMonthfromBack = [];
				} else {
					this.showSpinner = false;
					this.errorResponse = new ErrorResponseData(data);
					if (this.errorResponse && this.errorResponse.error && this.errorResponse.error.messages && this.errorResponse.error.messages[0] !== "none") {
						this.showErrorMsg(this.errorResponse.error.messages[0]);
					}
				}
				this.showSpinner = false;
			});
		}
	}

	goToMonthlyReports() {
		this.showSpinner = true;
		this.disabled = false;
		this.display = false;

		if (this.selectedfinCode && this.selectedfinCode.length === 0) {
			this.showSpinner = false;
			this.showErrorMsg('Please Select the FIN Code.');
		} else if (this.selectedpayeeCode && this.selectedpayeeCode.length === 0) {
			this.showSpinner = false;
			this.showErrorMsg('Please Select the Payee Code.');
		} else if (this.selectedMonth && this.selectedMonth.length > 6) {
			this.showSpinner = false;
			this.showErrorMsg('System won\'t allow more than 6 Statement Date.');
		} else if (this.months && this.months.length === 0) {
			this.showSpinner = false;
			this.showErrorMsg('There are no statements for this FIN to view, please choose another FIN.');
		} else if ((this.months && this.months.length > 0) && (this.selectedMonth && this.selectedMonth.length === 0)) {
			this.showSpinner = false;
			this.showErrorMsg('Please Select the Statement Dates.');
		} else {
			this.showSpinner = false;
			this.disabled = false;
			this.display = false;
			const str = [];
			var strDate1 = '';
			this.fims.getChargeBackFinList().subscribe(data => {
				this.errorResponse = new ErrorResponseData();
				if (data['result'] && data['result']['getFimpsInvalidChargeBacks']) {
					this.fimpsInvalidChargeBack = data['result']['getFimpsInvalidChargeBacks'];
					for (const val of this.fimpsInvalidChargeBack) {
						str.push(val.finCode);
						strDate1 = val.chargeBackDate;
					}

					const str2 = str.join();
					const str1 = this.selectedfinCode;
					const n = str2.indexOf(str1);
					const formattedMonths = [];

					this.selectedMonth.forEach(item => {
						let itemValue: any;
						itemValue = item;
						formattedMonths.push(itemValue.value.substring(itemValue.value.lastIndexOf('-') + 1, itemValue.length));
					});
					const n1 = formattedMonths.indexOf(strDate1);

					if (n >= 0 && n1 >= 0) {
						this.display = true;
					} else {
						// alert("k");
						this.display = false;
						this.disabled = true;
						this.showSpinner = false;
						this.submitForm();
						//this.showSpinner = false;
					}
				} else {
					this.showSpinner = false;
					this.errorResponse = new ErrorResponseData(data);
					if (this.errorResponse && this.errorResponse.error && this.errorResponse.error.messages) {
						this.showErrorMsg(this.errorResponse.error.messages[0]);
					}
				}
			});
		}
	}

	clear() {
		this.selectedfinCode = [];
		this.selectedpayeeCode = [];
		this.selectedMonth = [];
		this.defaultLabel = 'Select Statement dates';
		sessionStorage.removeItem('adminFinCode');
		sessionStorage.removeItem('finCode');
		sessionStorage.removeItem('payeeCode');
		sessionStorage.removeItem('selectedMonths');
		sessionStorage.removeItem('selectedPayeeCode');

		sessionStorage.removeItem('accountName');
		sessionStorage.removeItem('address1');
		sessionStorage.removeItem('city');
		sessionStorage.removeItem('state');
		sessionStorage.removeItem('postalCode');
		sessionStorage.removeItem('sDate');

		sessionStorage.removeItem('indexDescription');
		sessionStorage.removeItem('summaryDesc');
		sessionStorage.removeItem('indexProgramCode');
		sessionStorage.removeItem('summaryReportCode');
		sessionStorage.removeItem('summaryCount');

		sessionStorage.removeItem('vinVehicleName');
		sessionStorage.removeItem('vcount');
	}

	submitReport() {
		this.display = false;
		this.disabled = true;
		this.submitForm();
	}

	isBannerDate(value): boolean {
		let date1 = null;
		let date;
		let month;
		let year;
		let flag = false;
		this.selectedMonth.forEach(item => {
			date1 = new Date(item.label);
			date = date1.getDate();
			month = date1.getMonth() + 1;
			year = date1.getFullYear().toString();
			if (month === value && year === '2020') {
				if (value === 11 && date === 30) {
					flag = true;
				} else if (value === 10) {
					flag = true;
				}
			}
		});

		return flag;
	}

	isBannerFIN() : boolean {
		const finList: string[] = new Array('NG688', 'NR790', 'BQ740', 'XE410', 'NR565', 'VK306', 'XG287', 'NK937', 'AB956', 'XF966', 'FE001', 'AB525', 'NI249', 'WS862', 'XJ018', 'WD571', 'WB539', 'XJ014', 'XL885', 'WB728', 'XR010', 'VJ001', 'LF077', 'XA982', 'VY634', 'NL311', 'AU014', 'XD810',
			'WY598', 'XK946', 'VW308', 'WW145', 'WW665', 'VX155', 'VK716', 'XP792', 'XK314', 'AF519', 'AC799', 'NR274', 'WS968', 'WG875', 'AB289', 'WH001', 'WP165', 'VM710', 'NL714', 'NL762', 'AD463', 'WO239', 'AD652', 'WQ095', 'WQ377', 'NS239', 'WN285', 'BP906', 'AD410', 'XB502', 'WN293', 'NK660',
			'XI666', 'NU245', 'VB461', 'VM461', 'XY704', 'WN237', 'AQ693', 'UH382', 'NR593', 'WU696', 'WK386', 'LF019', 'XA869', 'XF052', 'VT378', 'XD338', 'XX508', 'NE435', 'XG036', 'VM851', 'XA755', 'XL204', 'LF015', 'XA737', 'UH146', 'WL326', 'AI546', 'WG844', 'XW110', 'XC849', 'UH071', 'WJ395',
			'NT351', 'XE071', 'UH140', 'XQ717', 'UH143', 'UI260', 'UH166', 'NU870', 'XA654', 'XA790', 'XF224', 'AF360', 'WQ632', 'VF412', 'NE664', 'VH780', 'VN231', 'VK011', 'XY840', 'WV323', 'VL638', 'XI575', 'XA984', 'WF765', 'XX940', 'VI897', 'WU681', 'WM446', 'UH053', 'XH016', 'XK049', 'AQ013',
			'UI073', 'AX332', 'XG484', 'NB690', 'XA931', 'XE453', 'XE031', 'UH137', 'WU105', 'VC506', 'XG749', 'AQ778', 'XU972', 'XN589', 'XE594', 'WF081', 'BP910', 'AD140', 'XA460', 'WX846', 'XA448', 'UH478', 'AI637', 'UH045', 'UH409', 'XG107', 'XZ436', 'XD378', 'AH890', 'XC232', 'XR215', 'AX540',
			'XK477', 'AX170', 'WP256', 'WU881', 'XG532', 'WQ611', 'NH433', 'NC609', 'BL588', 'NE373', 'WS238', 'XG604', 'AU673', 'XY503', 'WC954', 'XE726', 'AB739', 'NW866', 'WX699', 'NN122', 'NU192', 'XB447', 'VP767', 'WT700', 'XK487', 'WR408', 'NZ927', 'WQ186', 'AD549', 'AQ585', 'XF522', 'XY315',
			'WR498', 'NN210', 'XC937', 'AQ212', 'WU444', 'WP055', 'XK564', 'XA733', 'XN888', 'WV169', 'AI006', 'AW134', 'BQ746', 'XG938', 'LF082', 'AO361', 'VC226', 'XA938', 'XY254', 'AU886', 'XE434', 'NX860', 'XU091', 'XA290', 'WD406', 'WD594', 'XU093', 'WU739', 'GY001', 'WX279', 'XU088', 'XA113',
			'XA924', 'AB510', 'AW777', 'XG230', 'AW530', 'VI822', 'NT060', 'XT372', 'AA443', 'XM311', 'XU103', 'VL469', 'NX277', 'NT673', 'XX907', 'VI327', 'AX995', 'XD141', 'UH392', 'WL483', 'VC648', 'UH226', 'XA174', 'VO135', 'AE797', 'AK276', 'XL107', 'AW223', 'XI431', 'AN748', 'AQ972', 'XK977',
			'WC475', 'NL059', 'WR394', 'XU434', 'AQ723', 'AF976', 'AQ829', 'XC301', 'BN883', 'AO776', 'BP055', 'UH123', 'NP202', 'XK546', 'NP265', 'XN065', 'NS583', 'AE837', 'XA121', 'WV911', 'NK576', 'HM001', 'UI186', 'XF496', 'XQ569', 'LF059', 'XG715', 'XH361', 'VI603', 'AZ786', 'XQ971', 'NP968',
			'XA123', 'NM520', 'XA404', 'XA442', 'AT917', 'HM002', 'XI749', 'XP893', 'XB459', 'WD848', 'AW956', 'AZ458', 'WV515', 'NG988', 'XM799', 'XF021', 'AE772', 'BA570', 'AE892', 'WB988', 'XX088', 'NI613', 'NQ625', 'XF659', 'XG796', 'AH906', 'AC535', 'NW768', 'AB062', 'NX548', 'AZ374', 'WT121',
			'WB928', 'XB859', 'XB832', 'NG752', 'AH480', 'NH560', 'YA459', 'AH595', 'NM482', 'XM020', 'NV524', 'UI181', 'XD673', 'YD060', 'RV021', 'NL560', 'XS472', 'NS745', 'VF118', 'WG686', 'VP897', 'NZ987', 'VI488', 'AD906', 'NY229', 'NR927', 'UI179', 'XI516', 'XJ527', 'VK296', 'NZ557', 'AE288',
			'NB883', 'XY749', 'BP994', 'XG956', 'NR432', 'XA871', 'XJ068', 'XC007', 'ST101', 'WC304', 'WD872', 'XL408', 'AC817', 'XB893', 'XZ459', 'XB636', 'XF445', 'XI349', 'XC025', 'XL684', 'XH074', 'AT427', 'XG106', 'XJ408', 'XH257', 'WB514', 'WP128', 'AD716', 'VX356', 'VV722', 'NY679', 'NZ957',
			'AB261', 'NK818', 'CP010', 'XA206', 'UH185', 'XI628', 'VK528', 'WA254', 'XB900', 'AX854', 'XU345', 'XS357', 'XB919', 'XG306', 'XT138', 'XB822', 'NM351', 'XJ714', 'NY895', 'AK275', 'AY006', 'VV192', 'WB226', 'NB341', 'AA095', 'VC933', 'WQ620', 'WT773', 'WF207', 'WV005', 'XE404', 'BL260',
			'VT806', 'WP036', 'VT129', 'VK555', 'WD749', 'VG104', 'AQ266', 'BN059', 'WB093', 'VC861', 'WZ796', 'AU677', 'AD568', 'NY378', 'NH728', 'XX695', 'NX263', 'WO109', 'WG505', 'NY596', 'XC382', 'XR050', 'AY068', 'AB863', 'XR595', 'VI632', 'AD931', 'AD302', 'WM150', 'NF959', 'VE497', 'AA951',
			'VT513', 'BP773', 'UH212', 'AU402', 'BA299', 'XH269', 'BB900', 'XQ140', 'VI139', 'WM158', 'AX127', 'NU155', 'VR681', 'VW283', 'AB479', 'XG161', 'BQ143', 'WP608', 'AQ689', 'AX878', 'UH594', 'XK494', 'AE173', 'BL256', 'AW271', 'LF017', 'XH449', 'VK371', 'VV358', 'BL993', 'XR139', 'WN294',
			'WU232', 'XF839', 'AK909', 'WL936', 'XR661', 'VV924', 'AI870', 'NX785', 'NU088', 'BB137', 'NQ408', 'AA504', 'XR584', 'NY193', 'NS717', 'XF362', 'BU642', 'WJ760', 'AI107', 'AE115', 'XY100', 'BP613', 'XC877', 'XJ228', 'NT923', 'XC133', 'NR902', 'NN688', 'XG646', 'WI101', 'NJ366', 'NP646',
			'AA180', 'WF030', 'XC340', 'NX619', 'AF796', 'AH205', 'AX126', 'UH163', 'NJ499', 'AD892', 'WN858', 'BN004', 'WX805', 'VQ963', 'AA226', 'AU359', 'XY836', 'YD085', 'VB443', 'AQ902', 'BA638', 'XQ420', 'BA086', 'BA034', 'BA260', 'AY057', 'NC004', 'AF695', 'AQ182', 'WG535', 'AZ210', 'XK175',
			'AU660', 'AZ106', 'VK540', 'VI689', 'XY680', 'XZ911', 'AA725', 'XD487', 'WQ628', 'XC726', 'WC960', 'UH077', 'XB271', 'XJ274', 'XC818', 'XB292', 'WP595', 'WC082', 'WS462', 'XA882', 'XB489', 'WI062', 'WS864', 'NC676', 'XX429', 'XD209', 'XD189', 'AY257', 'XA306', 'XB414', 'XY955', 'XL283',
			'XB396', 'BQ989', 'UH263', 'NU974', 'BQ024', 'XY039', 'UH752', 'AZ601', 'AT946', 'VE612', 'BA628', 'XU979', 'BL241', 'ND542', 'XC002', 'XX086', 'AW542', 'WB960', 'AD157', 'NQ155', 'AK811', 'NW177', 'BQ356', 'AT673', 'AX606', 'AB903', 'VX434', 'AF759', 'NT976', 'BA020', 'VC715', 'VL235',
			'AQ253', 'AB687', 'NR080', 'BP141', 'AQ273', 'XY570', 'AX958', 'XY747', 'NP690', 'WA988', 'AY462', 'BB034', 'AY528', 'AZ259', 'NT261', 'AX289', 'AC303', 'AB653', 'WB034', 'AW425', 'BQ594', 'ND314', 'NL263', 'AE342', 'VP585', 'AI025', 'WQ296', 'AA577', 'AK707', 'WB523', 'AX496', 'BB083',
			'AB840', 'AY276', 'BB635', 'AC794', 'WN021', 'XM231', 'AD554', 'UH156', 'VI344', 'AK052', 'BN173', 'BQ682', 'BP833', 'NX539', 'NS421', 'WY092', 'XA058', 'WJ651', 'AN427', 'AY504', 'WV115', 'WK231', 'UH194', 'VF713', 'NS525', 'VC817', 'XB351', 'UH299', 'WM489', 'XB350', 'XZ718', 'NN810',
			'XJ358', 'XD108', 'VQ474', 'XB304', 'XC413', 'XP457', 'XL459', 'XS249', 'WO285', 'BL442', 'WB310', 'XE563', 'AF361', 'XC776', 'NM753', 'XJ085', 'WP356', 'XE742', 'XB294', 'WN292', 'AY056', 'WW979', 'VK647', 'BA633', 'NK311', 'XQ799', 'UH291', 'AI346', 'VF759', 'NU709', 'XT333', 'AO438',
			'AZ185', 'XB033', 'AB490', 'UH062', 'WL322', 'VT939', 'AC884', 'WO304', 'WF941', 'XE151', 'NL451', 'WJ263', 'WG384', 'AK262', 'XJ591', 'NP523', 'XH122', 'NM817', 'NY076', 'VX721', 'NE174', 'VW706', 'WL701', 'WB250', 'XH511', 'AD046', 'XE469', 'XZ574', 'WD993', 'AW496', 'XX615', 'WO603',
			'WU392', 'WT394', 'VJ735', 'AB327', 'XC449', 'WF264', 'XG575', 'WF807', 'VN289', 'XJ012', 'XX383', 'NY798', 'XP148', 'WG839', 'LF063', 'NJ168', 'NI669', 'AU634', 'NM540', 'XE346', 'WI982', 'NR034', 'WT230', 'AW432', 'XA443', 'XB461', 'NK590', 'AC512', 'WN223', 'XN097', 'AB979', 'XV295',
			'WJ212', 'VE120', 'WY960', 'XE971', 'BA004', 'BA111', 'BA067', 'BA558', 'BA113', 'XE507', 'BA049', 'VM475', 'BA040', 'BA371', 'BA154', 'BA042', 'BA017', 'YB118', 'BB534', 'NN056', 'NN317', 'XC432', 'AF583', 'XE806', 'AF555', 'WI858', 'CP004', 'XG694', 'XK373', 'CP005', 'VL798', 'XX017',
			'NR357', 'NT364', 'AB654', 'NY062', 'YA246', 'NI047', 'XZ150', 'VL370', 'XE805', 'VL091', 'AY718', 'WY369', 'XZ164', 'AO845', 'XZ522', 'XD652', 'WP461', 'WI528', 'AC138', 'WP956', 'XZ207', 'AU892', 'UH366', 'XC119', 'VN611', 'XX645', 'VL845', 'UH033', 'AC613', 'VT763', 'XB549', 'WV073',
			'XX289', 'WQ935', 'AH919', 'NH152', 'NX036', 'AE836', 'XB654', 'AY463', 'XF535', 'WT608', 'WS286', 'NY138', 'WV304', 'AA887', 'XC120', 'WX622', 'UI328', 'NJ786', 'XB908', 'XG880', 'WT217', 'XX415', 'WB031', 'XW466', 'XB296', 'XF467', 'VZ801', 'WT740', 'XC398', 'XC822', 'XB552', 'AE169',
			'XC105', 'VW255', 'AC981', 'AN654', 'AK435', 'XY219', 'XY593', 'XX554', 'AB574')

		return finList.indexOf(this.selectedfinCode) > -1;
	}

	isNovFIN(): boolean {
		const finList: string[] = new Array('AW432', 'AX127', 'AZ293', 'BA042', 'BA633', 'BL442', 'FE001', 'LF059',
			'NG734', 'NN317', 'NS525', 'NY229', 'VF759', 'VM475', 'VP897', 'WF941', 'WY092', 'XB292', 'XD672', 'XF535', 'XG715');
		return finList.indexOf(this.selectedfinCode) > -1;
	}

	/*submitForm() {
		this.novMessage = false;
		if (this.isNovFIN() && this.isBannerDate(11)) {
			this.bannerMessage = true;
			this.novMessage = true;
		} else if (this.isBannerFIN() && this.isBannerDate(10)) {
			this.bannerMessage = true;
		} else {
			this.getStatement();
		}
*/

		submitForm() {
			this.novMessage = false;
			var selectedMonthArray = [];
			this.selectedMonth.forEach(function (value){
				const valStr = value["label"];
				selectedMonthArray.push(value["label"]);
			});
			const newStr =  selectedMonthArray.toString();
			this.messageMaintenanceService.getMsgsByDates(this.selectedfinCode, selectedMonthArray).subscribe(responseDetails => {
				//this.messageMaintenanceService.getMsgsByDates(this.selectedfinCode,JSON.stringify(selectedMonthArray)).subscribe(responseDetails => {
				if((responseDetails['error'] === null || responseDetails['error'] === undefined)){
					this.contentOfBannerMessage = responseDetails['result']['getMsgByDates'][0]['message'];
					this.contentOfBannerMessage = this.contentOfBannerMessage.replace(/\n/g, '<br/>');
					this.bannerMessage = true;
					this.novMessage = true;
					// this.showSpinner = false;

				}
				else {
					this.getStatement();
				}
			});
			/*if (this.isNovFIN() && this.isBannerDate(11)) {
				this.selectedMonth.forEach(function (value){
					const valStr = value["label"];
					selectedMonthArray.push(value["label"]);
				});
				//const newStr = JSON.stringify(selectedMonthArray).substring(1, JSON.stringify(selectedMonthArray).length - 1);
				const newStr =  selectedMonthArray.toString();
				//JSON.parse(newStr)
				this.messageMaintenanceService.getMsgsByDates(this.selectedfinCode, newStr).subscribe(responseDetails => {
				//this.messageMaintenanceService.getMsgsByDates(this.selectedfinCode,JSON.stringify(selectedMonthArray)).subscribe(responseDetails => {
					if((responseDetails['error'] === null || responseDetails['error'] === undefined)){
						this.contentOfBannerMessage = responseDetails['result']['getMsgByDates'][0]['message'];
						this.bannerMessage = true;
						this.novMessage = true;
					}
				});
			}
			else if (this.isBannerFIN() && this.isBannerDate(10)) {
				this.selectedMonth.forEach(function (value){
					selectedMonthArray.push(value["label"]);
				});
				 const newStr =  selectedMonthArray.toString();
				this.messageMaintenanceService.getMsgsByDates(this.selectedfinCode, newStr).subscribe(responseDetails => {
					if((responseDetails['error'] === null || responseDetails['error'] === undefined)){
						this.contentOfBannerMessage = responseDetails['result']['getMsgByDates'][0]['message'];
						this.bannerMessage = true;
					}
				});
			} else {
				this.getStatement();
			} */
	}

	getStatement() {
		this.showSpinner = true;
		const fincode = this.selectedfinCode;
		let payeecode;
		if (this.selectedpayeeCode && this.selectedpayeeCode.length > 0 && this.selectedpayeeCode[0].value.indexOf('-') > -1) {
			payeecode = this.selectedpayeeCode[0].value.substring(0, this.selectedpayeeCode[0].value.indexOf('-'));
		}

		const months = '';
		const formattedMonths = [];

		this.selectedMonth.forEach(item => {
			let itemValue: any;
			itemValue = item;
			formattedMonths.push(itemValue.value.substring(itemValue.value.lastIndexOf('-') + 1, itemValue.length));
		});

		formattedMonths.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
		const commaSeperatedMonths =  formattedMonths.join(', ');
		this.fims.getPayeeCode(this.selectedfinCode).subscribe( data => {
			this.payeeAssigneeforReports = [];
			if (data['result'] && data['result']['getPayeeAssignee']) {
				this.payeeAssigneeforReports = data['result']['getPayeeAssignee'];
				if (payeecode !== undefined && payeecode !== '' && payeecode !== null ) {
					let singlePayeeCodeDetails;
					if (payeecode === 'none') {
						singlePayeeCodeDetails = this.payeeAssigneeforReports && this.payeeAssigneeforReports[0] ? this.payeeAssigneeforReports[0] : [];
					} else {
						for (const payee of this.payeeAssigneeforReports) {
							if (payee.indexAssigneeCode && (payee.indexAssigneeCode.trim() !== '' && payee.indexAssigneeCode !== null && payee.indexAssigneeCode === payeecode)) {
								singlePayeeCodeDetails = payee;
							}
						}
					}
					if (singlePayeeCodeDetails) {
						this.showSpinner = false;
						this.address1 = singlePayeeCodeDetails.address1;
						this.city = singlePayeeCodeDetails.city;
						this.state = singlePayeeCodeDetails.state;
						this.postalCode = singlePayeeCodeDetails.postalCode;

						sessionStorage.setItem('accountName', singlePayeeCodeDetails.payeename);
						sessionStorage.setItem('address1', this.address1);
						sessionStorage.setItem('city', this.city);
						sessionStorage.setItem('state', this.state);
						sessionStorage.setItem('postalCode', this.postalCode);
						sessionStorage.setItem('sDate', commaSeperatedMonths);

						sessionStorage.setItem('finCode', fincode);
						sessionStorage.setItem('payeeCode', payeecode);
						sessionStorage.setItem('selectedPayeeCode', JSON.stringify(this.selectedpayeeCode));
						sessionStorage.setItem('selectedMonths', JSON.stringify(this.selectedMonth));

						this.router.navigate(['fimps-detail-cpa-stmt']);
					}
				}
			} else {
				this.showSpinner = false;
				this.errorResponse = new ErrorResponseData(data);
				if (this.errorResponse && this.errorResponse.error && this.errorResponse.error.messages) {
					this.showErrorMsg(this.errorResponse.error.messages[0]);
				}
			}
		});
	}

	showBanner() {
		this.getStatement();
	}

	showErrorMsg(errorMsg) {
		this.displayMessage = true;
		if (errorMsg) {
			this.errorMessage = errorMsg;
		}
	}

	goToPreviousPage() {
		this.router.navigate(['fimps-search']);
	}

	clearSession(from) {
		if (from === 'fin') {
			this.selectedpayeeCode = null;
			sessionStorage.removeItem('selectedPayeeCode');
		}
		if (from === 'payee') {
			this.selectedMonth = null;
			sessionStorage.removeItem('selectedMonths');
		}
	}
}