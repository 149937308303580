import {Component, OnInit, ViewEncapsulation } from '@angular/core';
import {Message} from 'primeng/api';
import {Router} from '@angular/router';
import {FkcService} from '../services/fkc.service';
import {DatePipe} from '@angular/common';
import {ErrorResponseData} from '../models/ErrorResponseData';
import {DropdownItem} from '../models/dropdownItem';
import {FinCodeModel} from '../models/FinCode.model';
import { saveAs } from 'file-saver';

@Component({
	selector: 'app-fkc-keycode-report',
	templateUrl: './fkc-keycode-report.component.html',
	styleUrls: ['./fkc-keycode-report.component.css'],
	encapsulation: ViewEncapsulation.None
})

export class FkcKeycodeReportComponent implements OnInit {
	fincodeList: FinCodeModel[];
	reportMsgs: Message[] = [];
	selectedfinCode: any;
	userIds: any;
	vin: any;
	fromDate: Date;
	toDate: Date;
	keycodeReports = [];
	errorResponse: ErrorResponseData;
	showSpinner: Boolean = false;
	noRecords: Boolean = false;
	finCodeDropdown: DropdownItem[];
	showReport: Boolean = false;
	fromDateMax: any;
	toDateMax: any;
	toDateMin: any;
	userId: any;
	userType: any;
	siteCode: any;
	paCode: string;
	showPACode: Boolean = false;
	showFinInput: Boolean = false;
	isInternalUser: Boolean = false;
	showError: Boolean = false;
	showErrMsg: string;
	excelFromDate:  any;
	excelToDate:  any;
	excelDealerId: any;
	excelUserType: any;
	excelUserId: any;
	excelSearchDate: any;
	excelCreatedbyId: any;
	excelLoginId:  any;
	excelFinId: any;
	excelVinNum: any;
	excelCreatedTime: any;
	currentYear: any;

	constructor(public router: Router, public fkc: FkcService, public datepipe: DatePipe) {
		this.fromDateMax = new Date();
		this.toDateMax = new Date();
		this.toDateMin = new Date('01/01/1990');
		this.currentYear = new Date().getFullYear();
	}

	ngOnInit() {
		this.loadFinCode();
		if (this.userType === 'Employee') {
			this.userIds = this.userId;
			this.isInternalUser = true;
		}  else {
			this.userIds = this.userId;
			this.isInternalUser = false;
		}
		this.fromDate = new Date();
		this.toDate = new Date();
		this.excelFromDate = null;
		this.excelToDate = null;
		this.excelDealerId = null;
		this.excelUserType = null;
		this.excelUserId = null;
		this.excelSearchDate = null;
		this.excelCreatedbyId = null;
		this.excelLoginId = null;
		this.excelFinId = null;
		this.excelVinNum = null;
		this.excelCreatedTime = null;
	}

	loadFinCode() {
		this.userId = sessionStorage.getItem('userId');
		this.userType = sessionStorage.getItem('userType');
		this.siteCode = sessionStorage.getItem('siteCode');
		if (this.userType !== 'Other') {
			this.showFinInput = true;
		} else {
			this.showFinInput = false;
		}

		if (this.userType === 'Dealer') {
			this.paCode = 	this.siteCode;
			this.showPACode = true;

		} else {
			this.showPACode = false;
		}
		if (this.userType === 'Other') {
			this.fkc.getFinCode(this.userId).subscribe(data => {
				if (data['result'] && data['result']['finCode']) {
					this.fincodeList = data['result']['finCode'];
					const options = [];
					options.push({'label': 'Select Fin', 'value': ''});
					for (const val of this.fincodeList) {
						options.push({'label': val['finCode'], 'value': val['finCode']});
					}
					this.finCodeDropdown = options;
				} else {
					this.errorResponse = new ErrorResponseData(data);
					if (this.errorResponse.error) {
						this.showErrorMsg(this.errorResponse.error.messages[0]);
					}
				}
			});
		}
	}

	goToReports() {
		this.router.navigate(['fkc-keycode']);
	}

	goToRunReports() {
		this.showReport = false;
		this.keycodeReports = [];
		this.showSpinner = true;
		this.noRecords = false;
		let formattedFromDate = '';
		let formattedToDate = '';

		if ((this.fromDate === null || this.fromDate === undefined)) {
			this.showReport = false;
			this.showSpinner = false;
			this.showError = true;
			this.showErrMsg = 'Please Select Report From Date';
		} else if ((this.toDate === null || this.toDate === undefined)) {
			this.showReport = false;
			this.showSpinner = false;
			this.showError = true;
			this.showErrMsg = 'Please Select Report To Date';
		} else {
			formattedFromDate = this.datepipe.transform(this.fromDate, 'yyyy-MM-dd');
			let sdate = new Date(this.toDate);
			sdate.setDate(sdate.getDate() + 1);
			formattedToDate = this.datepipe.transform(sdate, 'yyyy-MM-dd');
			this.keycodeReports = [];
			let fullVin=null;
			if(this.vin !== null && this.vin !== ''  && this.vin !== undefined && this.vin.length==17){
				fullVin=this.vin.substring(0,2)+this.vin.substring(9,17);;
			}else{
				fullVin=this.vin;
			}

			const fleetKeyLogApi   = { 'fromDate': formattedFromDate,
				'toDate': formattedToDate,
				'dealerId': this.paCode,
				'userType': this.userType,
				'userId': this.userIds,
				'searchDate': null,
				'createdbyId': null,
				'loginId':  this.userId,
				'finId': this.selectedfinCode,
				'vinNum': fullVin,
				'createdTime': null	};
			this.excelFromDate = formattedFromDate;
			this.excelToDate = formattedToDate;
			this.excelDealerId = this.paCode;
			this.excelUserType = this.userType;
			this.excelUserId = this.userIds;
			this.excelSearchDate = null;
			this.excelCreatedbyId = null;
			this.excelLoginId = this.userId;
			this.excelFinId = this.selectedfinCode;
			this.excelVinNum = fullVin;
			this.excelCreatedTime = null;

			this.fkc.getkeycodeReport(fleetKeyLogApi).subscribe( data => {
				this.showReport = false;
				if (data['result'] && data['result']['fleetKeyLogs']) {
					this.keycodeReports = data['result']['fleetKeyLogs'];
					this.showReport = true;
				} else if (data['result'] === null) {
					this.showSpinner = false;
					this.noRecords = true;
				} else {
					this.errorResponse = new ErrorResponseData(data);
					if (this.errorResponse.error) {
						this.showErrorMsg(this.errorResponse.error.messages[0]);
					}
				}
				this.showSpinner = false;
			});
		}
	}

	getDownloadExcel() {
		this.downLoadFile('exportToExcel', 'FKCReport.xlsx');
	}

	downLoadFile(methodName, fileName) {
		this.showSpinner = true;
			const fleetKeyLogApi = {
				'fromDate': this.excelFromDate,
				'toDate': this.excelToDate,
				'dealerId': this.excelDealerId,
				'userType': this.excelUserType,
				'userId': 	this.excelUserId,
				'searchDate': this.excelSearchDate,
				'createdbyId': this.excelCreatedbyId,
				'loginId':	this.excelLoginId,
				'finId': this.excelFinId,
				'vinNum': 	this.excelVinNum,
				'createdTime': 	this.excelCreatedTime
			};

			this.fkc.getDownloadExcel(fleetKeyLogApi).subscribe(data => {
				this.showSpinner = false;
				if (data['result'] !== null) {
					this.saveToFileSystem(data, fileName);
				} else {
					this.errorResponse = new ErrorResponseData(data);
					if (this.errorResponse.error) {
						this.showErrorMsg(this.errorResponse.error.messages[0]);
					}
				}
			});
	}

	saveToFileSystem(data, fileName) {
		const ua: string = window.navigator.userAgent;
		const windowsBrowserRegex = /Trident|MSIE|Edge/;
		const isIE = ua.match(windowsBrowserRegex);
		const blob = new Blob([data], {type: 'application/pdf'});
		const a = document.createElement('a');
		a.href = URL.createObjectURL(blob);
		if (isIE) {
			saveAs.msSaveOrOpenBlob(blob, fileName);
		} else {
			a.setAttribute('target', '_blank');
			a.download = fileName;
			a.click();
			a.remove();
		}
	}

	removeFromYearErrorMessage() {
		this.toDateMin =  new Date(this.fromDate);
		if (this.fromDate <  this.toDate) {
			this.fromDateMax =  new Date(this.toDate);
		}

		if (this.fromDate >  this.toDate) {
			this.fromDate = null;
			this.fromDateMax =  new Date(this.toDate);
		}
	}

	showErrorMsg(errorMsg) {
		if (errorMsg) {
			this.reportMsgs = [];
			this.reportMsgs.push({
				severity: 'error',
				summary: 'Error Message',
				detail: errorMsg
			});
		}
	}
}
