import {Directive, ElementRef, HostListener, Output, EventEmitter, Renderer2, Input} from '@angular/core';

@Directive({
	selector: '[ValidateFinCode]'
})
export class ValidateFinCodeDirective {
	constructor(private el: ElementRef, private renderer: Renderer2) { }
	@Output() ngModelChange: EventEmitter<any> = new EventEmitter();
	salesCodeLength: any = 6;
	public value: string;
	@HostListener('keyup', ['$event']) onKeyup(event) {
		this.onInputChange(event);
	}

	@HostListener('keypress', ['$event']) keypress(event) {
		this.onInputChange(event);
	}

	@HostListener('focusout', ['$event']) onfocusout(event) {
		this.onInputChange(event);
	}

	@HostListener('blur', ['$event']) onblur(event) {
		this.onInputChange(event);
	}
	onInputChange(event) {
		if (event) {
			if ((event.currentTarget.selectionStart % 6 === 0) || (event.currentTarget.selectionStart === 1) || ((event.currentTarget.selectionStart - 1) % 6 === 0)) {
				if (event.key) {
					let isAlpha = /^[A-Za-z]+$/.test(event.key);
					if (event.key === 'Spacebar') {
						isAlpha = false;
					}
					if (!isAlpha) {
						event.preventDefault();
					}
				}
			} else if ((event.currentTarget.selectionStart === 5) || ((event.currentTarget.selectionStart + 1) % 6 === 0)) {
				let isSpace = /^ /.test(event.key);
					if (event.key === 'Spacebar') {
						isSpace = true;
					}
				if (!isSpace) {
					event.preventDefault();
				}
			} else {
				let isNumber = /[0-9]/.test(event.key);
				if (event.key === 'Spacebar') {
					isNumber = false;
				}
				if (!isNumber) {
					event.preventDefault();
				}
			}
		}
	}
	@HostListener('paste', ['$event'])
	onPaste(event) {
		const oldValue = event.currentTarget.value;
		const regexString = /[A-Za-z{2}0-9{3}\s*]+$/;
		const regex = new RegExp(regexString);
		const clipboardData = event.clipboardData || window['clipboardData'];
		const value: string = clipboardData.getData('Text');
		
		if (value) {
			const currVal = oldValue.slice(0, event.currentTarget.selectionStart) + value +
				oldValue.slice(event.currentTarget.selectionEnd);
			if (!regex.test(currVal)) {
				event.preventDefault();
			} else {
				this.ngModelChange.emit(currVal);
				event.preventDefault();
			}
		} else {
			event.preventDefault();
			event.stopPropagation();
		}
	}
}
