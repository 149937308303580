import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CpaLookup} from '../models/CpaLookup';

@Injectable()
export class CpaLookupService {
	_accessToken: string = sessionStorage.getItem('encodedAccessToken');
	fwsUrl = environment.APIEndpoint;
	cpaLookUpService=environment.cpaLookUpService

	private options = {
		headers: new HttpHeaders({
			'Authorization': 'Bearer ' + this._accessToken,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'origin': window.location.origin
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient) {}

	getCPALookupReport(cpaAmountRequest): Observable<CpaLookup[]> {
		return this.http.post<CpaLookup[]>(this.cpaLookUpService + 'api/v1/CPALookup/retrieveCpaLookupDetails', cpaAmountRequest, this.options);
	}
}
