import {DropdownItem} from './dropdownItem';
// import {any} from 'codelyzer/util/function';

export class FinIndicatorMappingModel {
	finIndicatorName: string;
	finCode: string[];
	status: string;
	finCountryCode: string;
	userId: string;
	searchFIN: string;
	//sourceFin: any[];
	//targetFin: any[];
	map;
	push;

	constructor(finIndicatorMappingModel?: FinIndicatorMappingModel) {
		this.finIndicatorName = '';
		this.finCode = [];
		this.status = '';
		this.finCountryCode = '';
		this.userId = '';
		this.searchFIN = '';
		//this.sourceFin = [];
		//this.targetFin = [];
		if (finIndicatorMappingModel) {
			Object.assign(this, FinIndicatorMappingModel);
		}
	}
}
