import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ViewEncapsulation} from '@angular/core';
import {FcicadminService} from '../services/fcicadmin.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiError} from '../models/ApiError';
import {DatePipe} from '@angular/common';
import {FinIndicatorMaster} from '../models/FinIndicatorMaster';
import {FinIndicatorDetailsModel} from '../models/FinIndicatorDetails';
import {FinIndicatorMappingModel} from '../models/FinIndicatorMapping';
import {Message} from 'primeng/api';
import { MessagesModule } from 'primeng/messages';

import {CommonService} from '../services/common.service';
import {FinAdminService} from '../services/finadmin.service';
import { MessageService } from 'primeng/api';

@Component({
	selector: 'app-fin-indicator-list',
	templateUrl: './fin-indicator-list.component.html',
	styleUrls: ['./fin-indicator-list.component.css']
})
export class FinIndicatorListComponent implements OnInit {
	countryCodeList = [];
	msgs: MessagesModule[] = [];
	finCodeSearch: string;
	countryCode: string;
	showReport = false;
	userId = sessionStorage.getItem('userId');
	public displaySpinner: Boolean = false;
	displayMessage = false;
	errorMessage: string;
	showFinCodeErrorMsg = false;
	showCountryCodeErrorMsg = false;
	finList = [];
	showPopUp: Boolean = false;
	indicatorDescList = [];
	finCode: string;
	accessVal: Boolean = false;
	indicatorDesc: string;
	finIndicatorDetails: any;
	paCode: string;
	assignee: string;
	loading = false;
	showNoRecords = false;
	namList = [];
	showFinIndicatorErrorMsg = false;
	showFinCodeErrorMsgPopUp = false;
	isReadonly = false;
	from = '';
	cols: any[];
	namCols: any[];
	finNameList: any[];
	finCodeList: any[];
	finName;
	assigneeGAMDetail: string;
	showFINError = false;
	showfinnamemsg = false;
	constructor(private finadminService: FinAdminService, private http: HttpClient, public datepipe: DatePipe, public common: CommonService,private messageService: MessageService) {
	}


	ngOnInit() {
		this.finCodeSearch = '';
		this. countryCode = '';
		const countryCode = [];
		/*	countryCode.push({'label': 'USA', 'value': 'USA'});
			countryCode.push({'label': 'CAN', 'value': 'CAN'});*/
		this.common.getCountryCode().subscribe(data => {
			if(data['result'] && data['result']['countryCodes'] && data['result']['countryCodes'].length >0) {
				data['result']['countryCodes'].forEach(item => {
					countryCode.push({'label': item.countryCode, 'value': item.countryCode});
				});
			}
		});
		this.countryCodeList = countryCode;
		this.loadFinIndicator();
		this.cols = [
			{ field: '', header: 'Action' },
			{ field: 'finCode', header: 'FIN' },
			{ field: 'finIndicatorCode', header: 'Entitlement' },
			{ field: 'status', header: 'Access' },
			{ field: 'updatedBy', header: 'Last Updated User ID' },
			{ field: 'updatedDate', header: 'Date Last Updated' }
		];
		this.namCols = [
			{ field: 'accountName', header: 'Account Name' },
			{ field: 'fin', header: 'FIN' },
			{ field: 'programYear', header: 'Program Year' },
			{ field: 'proposalAssignee', header: 'Proposal Assignee' },
			{ field: 'metricsAssignee', header: 'Metrics Assignee' },
			{ field: 'contactAssignee', header: 'Contact Info Assignee' }
		];
	}

	clear() {
		this.showReport = false;
		this.countryCode = '';
		this.finCodeSearch = '';
		this.showNoRecords = false;
		this.finNameList = [];
		this.finCodeList = [];
		this.finName = '';
		this.showCountryCodeErrorMsg = false;
		this.showFINError = false;
		this.showfinnamemsg = false;
	}


	showErrorMsg(errorMsg) {
		this.displayMessage = true;
		this.errorMessage = errorMsg;
	}

	searchFinCodeData() {
		this.showReport = false;
		this.showfinnamemsg = false;
		this.finList = [];
		this.namList = [];
		this.finIndicatorDetails = [];
		this.paCode = '';
		this.assignee = '';
		if ((this.finCodeSearch !== null && this.finCodeSearch !== undefined && this.finCodeSearch !== '') && (this.countryCode !== null && this.countryCode !== undefined && this.countryCode !== ''))  {
			this.showFINError = false;
			this.loading = true;
			this.finadminService.loadFinIndicatorMappingData(this.finCodeSearch.trim(), this.countryCode).subscribe( data => {
					if ( data['result'] && data['result']['finCodeWithFinIndDetailApi'] && data['result']['finCodeWithFinIndDetailApi']['finCodeWithFinIndDetailApiList']
						&& data['result']['finCodeWithFinIndDetailApi']['finCodeWithFinIndDetailApiList'].length > 0) {
						this.finList = data['result']['finCodeWithFinIndDetailApi']['finCodeWithFinIndDetailApiList'];
						this.finList.forEach(item => item.finIndicatorCode = item.finIndicatorCode);
						//this.loading  = false;
						this.showReport = true;
						//this.showNoRecords = false;
					}
					/*else {
						//this.loading = false;
						this.showNoRecords = true;
						this.showReport = false;
					}*/
					if ( data['result'] && data['result']['finCodeWithFinIndDetailApi'] && data['result']['finCodeWithFinIndDetailApi']['assigneeNAMDetailApiList']
						&& data['result']['finCodeWithFinIndDetailApi']['assigneeNAMDetailApiList'].length > 0) {
						this.namList = data['result']['finCodeWithFinIndDetailApi']['assigneeNAMDetailApiList'];
						this.namList.forEach(item => item.programYear = item.programYear);
						this.showReport = true;

						//this.showNoRecordsNam = false;
					}
					/*else {
						//this.showNoRecordsNam = true;
					}*/
					if (data['result'] && data['result']['finCodeWithFinIndDetailApi'] && data['result']['finCodeWithFinIndDetailApi']['finDetailsApi']) {
						this.finIndicatorDetails =  data['result']['finCodeWithFinIndDetailApi']['finDetailsApi'];
						this.finName = this.finIndicatorDetails.finCode + ' - '+ this.finIndicatorDetails.finAccountName;
						this.showReport = true;
						this.showNoRecords = false;
					} else {
						this.showNoRecords = true;
						this.finName = '';
					}
					if (data['result'] && data['result']['finCodeWithFinIndDetailApi']) {
						this.paCode = data['result']['finCodeWithFinIndDetailApi'].pacode;
						this.assignee = data['result']['finCodeWithFinIndDetailApi'].assigneeDetail;
						this.assigneeGAMDetail = data['result']['finCodeWithFinIndDetailApi'].assigneeGAMDetail;
						this.showReport = true;
					}
					this.loading = false;
				},
				(error: ApiError) => {
					//this.errorTest = error;
				});
		} else {
			if ((this.finCodeSearch === null || this.finCodeSearch === undefined || this.finCodeSearch === '') &&
				(this.finName === null || this.finName === undefined || this.finName === '')) {
				//this.showFinCodeErrorMsg = true;
				this.showFINError = true;
				//this.msgs.push({severity: 'info', summary: 'Info Message', detail: 'Please Select Either FIN Code or FIN Name'});
			} else {
				this.showFINError = false;
			}
			if (this.countryCode === null || this.countryCode === undefined || this.countryCode === '') {
				this.showCountryCodeErrorMsg = true;
			}
		}
	}

	removeErrorMsgCountry() {
		if (this.countryCode === null || this.countryCode === undefined || this.countryCode === '') {
			this.showCountryCodeErrorMsg = true;
		} else {
			this.showCountryCodeErrorMsg = false;
		}
	}

	removeErrorMsg() {
		if (this.finCodeSearch === null || this.finCodeSearch === undefined || this.finCodeSearch === '') {
			this.showFinCodeErrorMsg = true;
		} else {
			this.showFinCodeErrorMsg = false;
		}
	}


	loadDate(date) {
		/*if (date === '0001-01-01')  {
			date = '2001-01-01';
		}*/
		if (date) {
			return this.datepipe.transform(date, 'MM/dd/yyyy');
		}
		return null;

	}

	removeErrorMsgFinCode() {
		if (this.finCode === null || this.finCode === undefined || this.finCode === '') {
			this.showFinCodeErrorMsgPopUp = true;
		} else {
			this.showFinCodeErrorMsgPopUp = false;
		}
	}

	removeErrorMsgFinIndicator() {
		if (this.indicatorDesc === null || this.indicatorDesc === undefined || this.indicatorDesc === '') {
			this.showFinIndicatorErrorMsg = true;
		} else {
			this.showFinIndicatorErrorMsg = false;
		}
	}

	loadFinIndicator() {
		const indicatorDesc = [];
		let finIndicatorMaster: FinIndicatorMaster[];
		finIndicatorMaster =  [];
		this.finadminService.getFinIndicatorMaster().subscribe(data => {
			indicatorDesc.push({'label': 'Select Fin Entitlement', 'value': ''});
			if (data['result'] && data['result']['finIndiatorMasterList']) {
				finIndicatorMaster = data['result']['finIndiatorMasterList'];
				if (finIndicatorMaster) {
					finIndicatorMaster.forEach(item => {
						indicatorDesc.push({'label': item.accountPrefCode + '-' + item.accountPrefDescription, 'value': item.accountPrefCode });
					});
				}
			}
		});
		this.indicatorDescList = indicatorDesc;
	}

	addNewFIN() {
		if (this.finCode === null || this.finCode === undefined || this.finCode === '') {
			this.showFinCodeErrorMsgPopUp = true;
		}
		if (this.indicatorDesc === null || this.indicatorDesc === undefined || this.indicatorDesc === '') {
			this.showFinIndicatorErrorMsg = true;
		}
		if (this.finCode && this.indicatorDesc) {
			this.loading = true;
			const finIndicatorMappingModel = new FinIndicatorMappingModel();
			finIndicatorMappingModel.finCountryCode = this.countryCode;
			finIndicatorMappingModel.finCode.push(this.finCode);
			this.userId = sessionStorage.getItem('userId');
			finIndicatorMappingModel.userId = this.userId;
			//finIndicatorMappingModel.userId = 't-use764';
			finIndicatorMappingModel.finIndicatorName = this.indicatorDesc;

			if (this.accessVal) {
				finIndicatorMappingModel.status = 'A';
			} else {
				finIndicatorMappingModel.status = 'I';
			}
			this.finadminService.updateFinalFINbyCounCodeandFINCode(finIndicatorMappingModel).subscribe(data => {
				this.loading = true;
				if (this.from === 'add') {
					this.showPopUp = false;
					//this.showErrorMsg('Added Successfully');
					this.messageService.add({severity: 'success', summary: 'Success Message', detail: 'Added Successfully'});

					this.searchFinCodeData();
				} else {
					this.showPopUp = false;
					if (this.accessVal) {
						//this.showErrorMsg('Added Successfully');
						this.messageService.add({severity: 'success', summary: 'Success Message', detail: 'Added Successfully'});
					} else {
						//this.showErrorMsg('Removed Successfully');
						this.messageService.add({severity: 'success', summary: 'Success Message', detail: 'Removed Successfully'});
					}

					this.searchFinCodeData();
				}
			}, error1 => {
				if (this.from === 'add') {
					//this.showErrorMsg('Not Added');
					this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'Not Added'});
				} else {
					//this.showErrorMsg('Not Removed');
					this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'Not Removed'});
				}
			});
		}
	}

	showDialog(from, record) {
		this.showFinCodeErrorMsgPopUp  = false;
		this.showFinIndicatorErrorMsg = false;
		this.isReadonly = false;
		this.finCode = '';
		this.indicatorDesc = '';
		this.accessVal = false;
		this.from = from;
		this.showPopUp = true;
		if (from === 'add') {
			this.finCode = this.finCodeSearch;
			this.isReadonly = false;
			this.accessVal = true;
		} else {
			this.finCode = record.finCode;
			this.indicatorDesc = record.finIndicatorCode;
			if (record.status === 'A') {
				this.accessVal = true;
			} else {
				this.accessVal = false;
			}
			this.isReadonly = true;
			this.showFinCodeErrorMsgPopUp  = false;
			this.showFinIndicatorErrorMsg = false;
		}
	}

	search() {
		this.finadminService.getFINnameList(this.countryCode, this.finName).subscribe(data => {
			if (data['result'] && data['result']['finCodeAccountDetailApi'] && data['result']['finCodeAccountDetailApi'].length > 0) {
				const finNameArray = data['result']['finCodeAccountDetailApi'];
				this.finNameList = [];
				finNameArray.forEach( item => {
					this.finNameList.push(item.finCode + ' - ' + item.accountName);
				});
			} else {
				this.finNameList = [];
			}
		});
	}

	updateFIN() {
		let arrayval = [];
		if (this.finName) {
			arrayval  = this.finName.split('-');
			this.finCodeSearch = arrayval[0].trim();
			//	this.showFinCodeErrorMsg = false;
		}
		this.showReport = false;
	}

	removeErrorMsgFIN(){
		if ((this.finCodeSearch === null || this.finCodeSearch === undefined || this.finCodeSearch === '') &&
			(this.finName === null || this.finName === undefined || this.finName === '')) {
			//this.showFinCodeErrorMsg = true;
			this.showFINError = true;
			//this.msgs.push({severity: 'info', summary: 'Info Message', detail: 'Please Select Either FIN Code or FIN Name'});
		} else {
			this.showFINError = false;
		}
	}

	getFinNameList() {
		if(this.finName && this.finName.length >= 3 && this.countryCode) {
			this.finadminService.getFINnameList(this.countryCode, this.finName).subscribe(data => {
				if (data['result'] && data['result']['finCodeAccountDetailApi'] && data['result']['finCodeAccountDetailApi'].length > 0) {
					const finNameArray = data['result']['finCodeAccountDetailApi'];
					this.finNameList = [];
					finNameArray.forEach( item => {
						this.finNameList.push(item.finCode + ' - ' + item.accountName);
					});
					this.showfinnamemsg = false;
				} else {
					this.showfinnamemsg = true;
					this.finNameList = [];
					this.finName = '';
					this.finCodeSearch = '';
				}
			});
		}
	}

	getFINcodeList() {
		if (this.finCodeSearch && this.finCodeSearch.length >= 3 && this.countryCode) {
			this.finadminService.getFINcodeList(this.countryCode, this.finCodeSearch.trim()).subscribe(data => {
				if (data['result'] && data['result']['finCodeAccountDetailApi'] && data['result']['finCodeAccountDetailApi'].length > 0) {
					const finNameArray = data['result']['finCodeAccountDetailApi'];
					this.finCodeList = [];
					finNameArray.forEach( item => {
						this.finCodeList.push(item.finCode + ' - ' + item.accountName);
					});
					this.showfinnamemsg = false;
				} else {
					this.showfinnamemsg = true;
					this.finCodeList = [];
					this.finCodeSearch = '';
					this.finName = '';
				}
			});
		}
	}

	updateFINname() {
		let arrayval = [];
		if (this.finCodeSearch) {
			arrayval  = this.finCodeSearch.split('-');
			this.finName = arrayval[1];
			this.finCodeSearch = arrayval[0].trim();
			//	this.showFinCodeErrorMsg = false;
		}
		this.showReport = false;
	}

	addMarkedFin() {
		if((this.finIndicatorDetails.finSaleStatus==='Active(A)') || (this.finIndicatorDetails.finPartsStatus==='Active(A)') || (this.finIndicatorDetails.finServiceStatus==='Active(A)')) {
			this.finadminService.addMarkedFin(this.finCodeSearch.trim(), this.countryCode, this.userId).subscribe(data => {
				console.log("some Error");
				this.loading = true;
				this.messageService.add({severity: 'success', summary: 'Success Message', detail: 'FIN Marked Successfully'});
				console.log("some Error 1");
				this.loading = false;
				setTimeout(() => {
					this.msgs.shift();
				  }, 3000)
		});
		}
		else {
			console.log("some warning");
			this.messageService.add({severity: 'error', summary: 'Inactive FIN', detail: 'Entered FIN is Inactive'});
			console.log("some warning 1");
			setTimeout(() => {
				this.msgs.shift();
			  }, 3000)
		}
	}

}



