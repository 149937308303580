import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiError} from '../models/ApiError';
import {DatePipe} from '@angular/common';
import {Message} from 'primeng/api';
import {CommonService} from '../services/common.service';
import {FinAdminService} from '../services/finadmin.service';

@Component({
	selector: 'app-marked-fin-list',
	templateUrl: './marked-fin-list.component.html',
	styleUrls: ['./marked-fin-list.component.css']
})
export class FinMarkedListComponent implements OnInit {
	countryCodeList = [];
	msgs: Message[] = [];
	finCodeSearch: string;
	fin: string;
	countryCode: string;
	showReport = false;
	userId = sessionStorage.getItem('userId');
	showFinCodeErrorMsg = false;
	showCountryCodeErrorMsg = false;
	loading = false;
	showNoRecords = false;
	cols: any[];
	finList: any[];
	constructor(private finadminService: FinAdminService, private http: HttpClient, public datepipe: DatePipe, public common: CommonService) {
	}


	ngOnInit() {
		this.finCodeSearch = '';
		this.fin = '';
		this. countryCode = '';
		const countryCode = [];
		/*	countryCode.push({'label': 'USA', 'value': 'USA'});
			countryCode.push({'label': 'CAN', 'value': 'CAN'});*/
		this.common.getCountryCode().subscribe(data => {
			if(data['result'] && data['result']['countryCodes'] && data['result']['countryCodes'].length > 0) {
				data['result']['countryCodes'].forEach(item => {
					countryCode.push({'label': item.countryCode, 'value': item.countryCode});
				});
			}
		});
		this.countryCodeList = countryCode;
		this.cols = [
			{ field: 'country', header: 'Country' },
			{ field: 'fincode', header: 'FIN' },
			{ field: 'accountName', header: 'Account Name' },
			{ field: 'createDate', header: 'Requested Date' },
			{ field: 'createUser', header: 'Requested By' }
		];
		this.searchFinCodeData();
	}

	clear() {
		this.showReport = false;
		this.countryCode = '';
		this.finCodeSearch = '';
		this.showNoRecords = false;
		this.showCountryCodeErrorMsg = false;
		this.fin = '';
		this.searchFinCodeData();
	}


	/*showErrorMsg(errorMsg) {
		this.displayMessage = true;
		this.errorMessage = errorMsg;
	}*/

	searchFinCodeData() {
		this.showReport = false;
				this.loading = true;
						this.showReport = true;
		this.finList = [];
						// this.finList = [{'country': 'USA', 'fin': 'ER001', 'accountName': 'ENTERPRISE HOLDINGS INC', 'requestedDate': '12/29/2020', 'requestedBy': 't-tes331'},
						// 	{'country': 'USA', 'fin': 'HZ001', 'accountName': 'HERTZ CORPORATION', 'requestedDate': '04/22/2020', 'requestedBy': 't-tes332'},
						// 	{'country': 'USA', 'fin': 'ER001', 'accountName': 'ENTERPRISE HOLDINGS INC', 'requestedDate': '12/29/2020', 'requestedBy': 't-tes331'},
						// 	{'country': 'USA', 'fin': 'HZ001', 'accountName': 'HERTZ CORPORATION', 'requestedDate': '04/22/2020', 'requestedBy': 't-tes332'},
						// 	{'country': 'USA', 'fin': 'ER001', 'accountName': 'ENTERPRISE HOLDINGS INC', 'requestedDate': '12/29/2020', 'requestedBy': 't-tes331'},
						// 	{'country': 'USA', 'fin': 'HZ001', 'accountName': 'HERTZ CORPORATION', 'requestedDate': '04/22/2020', 'requestedBy': 't-tes332'},
						// 	{'country': 'USA', 'fin': 'ER001', 'accountName': 'ENTERPRISE HOLDINGS INC', 'requestedDate': '12/29/2020', 'requestedBy': 't-tes331'},
						// 	{'country': 'USA', 'fin': 'HZ001', 'accountName': 'HERTZ CORPORATION', 'requestedDate': '04/22/2020', 'requestedBy': 't-tes332'}];
		if (this.finCodeSearch === null || this.finCodeSearch === undefined || this.finCodeSearch === '') {
			this.fin = 'GETALL';
		} else {
			this.fin = this.finCodeSearch;
		}
		if (this.countryCode === null || this.countryCode === undefined || this.countryCode === '') {
			this.countryCode = 'GETALL';
		}

		if ((this.fin !== null && this.fin !== undefined && this.fin !== '') && (this.countryCode !== null && this.countryCode !== undefined && this.countryCode !== ''))  {
			this.finadminService.getAdminMarkedFinDetails( this.countryCode, this.fin).subscribe( data => {
				if (data['result'] && data['result']['adminMarkedFinApi'] && data['result']['adminMarkedFinApi']
					&& data['result']['adminMarkedFinApi'].length > 0) {
					this.finList = data['result']['adminMarkedFinApi'];
				}
				/*else {
					//this.loading = false;
					this.showNoRecords = true;
					this.showReport = false;
				}*/
			});
		}
		this.loading = false;
	}

	removeErrorMsgCountry() {
		if (this.countryCode === null || this.countryCode === undefined || this.countryCode === '') {
			this.showCountryCodeErrorMsg = true;
		} else {
			this.showCountryCodeErrorMsg = false;
		}
	}

	removeErrorMsg() {
		if (this.finCodeSearch === null || this.finCodeSearch === undefined || this.finCodeSearch === '') {
			this.showFinCodeErrorMsg = true;
		} else {
			this.showFinCodeErrorMsg = false;
		}
	}

}



