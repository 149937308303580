import {Component, Input} from '@angular/core';
import {Rips} from '../models/Rips';
import {DatePipe} from '@angular/common';

@Component({
	selector: 'app-rips-report',
	templateUrl: './rips-reports.component.html',
	styleUrls: ['./rips-reports.component.css']
})
export class RipsReportComponent {
	@Input() ripsReports: Rips[];
	@Input() ripsReporttotalRecords = 0;
	@Input() reportType;

	constructor(public datepipe: DatePipe) {
	}

	getTotal(Reports) {
		if (Reports && Reports.length > 0) {
			return Reports.map(c => Number(c.amount)).reduce((sum, current) => sum + current);
		}
	}

	paginate(event) {
		let el = document.getElementById('#' + event);
		el.scrollIntoView();
	}

}
