import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {FkcService} from '../services/fkc.service';
import {FinCodeDetailAPI} from '../models/FinCodeDetailAPI';
import {ErrorResponseData} from '../models/ErrorResponseData';
import {Message} from 'primeng/api';
import {FkcRequestInfo} from '../models/FkcRequestInfo';
import {FkcWorkflowRequestInfo} from '../models/FkcWorkflowRequestInfo';
import {PersonalIndicatorConstants} from '../common/personalindicator.constants';
import {PersonalIndicatorInfo} from '../models/PersonalIndicatorInfo';
import {CommonService} from '../services/common.service';

@Component({
	selector: 'app-fkc-level-authorization',
	templateUrl: './levels-of-approval-page.component.html',
	styleUrls: ['./levels-of-approval-page.component.css'],
	// encapsulation: ViewEncapsulation.None
})
export class LevelsOfApprovalPageComponent implements OnInit {

	finCode: string;
	comments: string;
	userFirstName: string;
	userLastName: string;
	paCodeORFinCode: string;
	marketSegment: string;
	userId: string;
	userType: string;
	email: string;
	userMailId: string;
	userName: string;
	fincodeDetailsList: FinCodeDetailAPI[];
	errorResponse: ErrorResponseData;
	reportMsgs: Message[] = [];
	msg: Message[] = [];
	requestResponse: FkcRequestInfo;
	cpaStatementAccess: string;
	isExternalUser = false ;
	personalIndicatorList: PersonalIndicatorInfo[];
	requestInfo: FkcRequestInfo;
	isPACodeExist = false ;
	showError: Boolean = false;
	showErrMsg: string;
	showRejError: Boolean = false;
	showRejErrorMsg: string;
	emailSplt: string[];

	constructor(public fkc: FkcService, public router: Router, private common: CommonService) {
	}

	ngOnInit() {
		this.userFirstName = sessionStorage.getItem('userFName');
		this.userLastName = sessionStorage.getItem('userLName');
		this.email = sessionStorage.getItem('userEmail');
		this.userName =  this.userFirstName + ' ' + this.userLastName;
		this.userId = sessionStorage.getItem('userId');
		this.userMailId = sessionStorage.getItem('userEmail');
		console.log('User Mail Id' + this.userMailId);
		this.userType = sessionStorage.getItem('userType');
//		this.paCode = sessionStorage.getItem('siteCode');
//		this.isPACodeExist = true;
		if (this.userType === 'Dealer') {
			this.router.navigate(['/unauthorized'], {queryParams: {isDealerRequest: true}});

		} else {
			this.common.getPersonalIndicatorInfo().subscribe(data => {
				if (data['result']['personalIndicator'] && data['result']['personalIndicator'].length > 0) {
					this.personalIndicatorList = data['result']['personalIndicator'];
					if (this.personalIndicatorList && this.personalIndicatorList.length > 0) {
						if ((this.userType === 'Employee' || this.userType === 'Other') && this.personalIndicatorList.find(item => item && item.personCode.toUpperCase() === PersonalIndicatorConstants.fkcinternalOrFleetPersonalIndicator.toUpperCase())) {
							this.router.navigate(['unauthorized']);
						} else {
							this.checkRequestStatus();
						}
					} else {
						this.checkRequestStatus();
					}
				} else {
						this.checkRequestStatus();
				}
			});
		}
	}

	checkRequestStatus() {
		this.fkc.getRequestWithStatus(this.userId.toLowerCase()).subscribe(data => {
			if (data['result']) {
				this.requestInfo = data['result'];
				if (this.requestInfo.getAllRequest.length > 0) {
					const reqStatus = this.requestInfo.getAllRequest[0].reqStatus;
					if (reqStatus && reqStatus.toUpperCase() === 'I') {
						this.router.navigate(['/unauthorized'], {queryParams: {isFkcRequest: true}});
					} else {
						// this.cpaStatementAccess = sessionStorage.getItem('cpaStatementAccess');
						// if (this.userType === 'Other') {
						// 	this.isExternalUser = true;
						// 	this.loadFINCodes(this.userId.toLowerCase());
						// }
						this.checkRequestActiveStatus();
					}
				} else {
					// this.cpaStatementAccess = sessionStorage.getItem('cpaStatementAccess');
					// if (this.userType === 'Other') {
					// 	this.isExternalUser = true;
					// 	this.loadFINCodes(this.userId.toLowerCase());
					// }
					this.checkRequestActiveStatus();
				}
			} else {
				// this.cpaStatementAccess = sessionStorage.getItem('cpaStatementAccess');
				// if (this.userType === 'Other') {
				// 	this.isExternalUser = true;
				// 	this.loadFINCodes(this.userId.toLowerCase());
				// }
				this.checkRequestActiveStatus();
			}
		});
	}

	loadFINCodes(userId) {
		this.fkc.getFinCodes(userId).subscribe( data => {
			if (data['result'] && data['result']['getFINdetails']) {
				this.fincodeDetailsList = data['result']['getFINdetails'];
				if (this.fincodeDetailsList.length > 0) {
					if (this.fincodeDetailsList.length > 1 ) {
						this.router.navigate(['/unauthorized'], {queryParams: {isMultipleFINMapped: true}});
					} else if (this.fincodeDetailsList.length === 1) {
						this.fkc.getMarketSegmentByFin(this.fincodeDetailsList[0].fincode).subscribe( data1 => {
							this.paCodeORFinCode = this.fincodeDetailsList[0].fincode;
							this.marketSegment = data1['result']['marketSegment'][0]['marketSegment'];
							if (data1['result']['marketSegment'][0]['marketSegment'] === 'L') {
								this.router.navigate(['/unauthorized'], {queryParams: {isLeasingFIN: true}});
							}
						});
					}
				}
			} else {
				this.router.navigate(['/unauthorized'], {queryParams: {isMultipleFINMapped: true}});
			}
		});
	}

	submitRequest() {
		 const fkcAuthReqDetailApi = new FkcWorkflowRequestInfo();
		 fkcAuthReqDetailApi.cdsId = this.userId.toLowerCase();
		 fkcAuthReqDetailApi.createdUser = this.userId.toLowerCase();
		 fkcAuthReqDetailApi.lastUpdateUser = this.userId.toLowerCase();
		 fkcAuthReqDetailApi.reqComments = this.comments;
		this.emailSplt = this.userMailId.split('@');
		console.log('After Split of mail Id ' + this.emailSplt[0]);
		this.fkc.createRequest(fkcAuthReqDetailApi, this.userType , this.userName , this.paCodeORFinCode, this.marketSegment, this.emailSplt[0]).subscribe( data => {
			if (data['result']) {
				this.requestResponse = data['result'];
				if (this.requestResponse.createFkcAuthReqflag) {
					this.showRejError = true;
					this.showRejErrorMsg = 'Request Created Successfully.';
				} else {
					this.showError = true;
					this.showErrMsg = 'Request not Created Successfully.';
				}
			} else {
				this.errorResponse = new ErrorResponseData(data);
				if (this.errorResponse.error) {
					this.showErrorMsg(this.errorResponse.error.messages[0]);
				}
			}
		});
	}

	showErrorMsg(errorMsg) {
		if (errorMsg) {
			this.reportMsgs = [];
			this.reportMsgs.push({
				severity: 'error',
				summary: 'Error Message',
				detail: errorMsg
			});
		}
	}

	showSuccessMsg(errorMsg) {
		if (errorMsg) {
			this.msg = [];
			this.msg.push({
				severity: 'success',
				summary: 'Success Message',
				detail: errorMsg
			});
		}
	}

	checkRequestActiveStatus() {
		this.fkc.getRequestWithActiveStatus(this.userId.toLowerCase()).subscribe(data => {
			if (data['result']) {
				this.requestInfo = data['result'];
				if (this.requestInfo.getAllRequest.length > 0) {
					const reqStatus = this.requestInfo.getAllRequest[0].reqStatus;
					if (reqStatus && reqStatus.toUpperCase() === 'A') {
						this.router.navigate(['/unauthorized'], {queryParams: {isFkcRequestApproved: true}});
					} else {
						this.cpaStatementAccess = sessionStorage.getItem('cpaStatementAccess');
						if (this.userType === 'Other') {
							this.isExternalUser = true;
							this.loadFINCodes(this.userId.toLowerCase());
						}
					}
				} else {
					this.cpaStatementAccess = sessionStorage.getItem('cpaStatementAccess');
					if (this.userType === 'Other') {
						this.isExternalUser = true;
						this.loadFINCodes(this.userId.toLowerCase());
					}
				}
			} else {
				this.cpaStatementAccess = sessionStorage.getItem('cpaStatementAccess');
				if (this.userType === 'Other') {
					this.isExternalUser = true;
					this.loadFINCodes(this.userId.toLowerCase());
				}
			}
		});
	}
}
