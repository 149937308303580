import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {MenuItem} from 'primeng/api';

@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class NavComponent {
	@Output() closeClicked = new EventEmitter<boolean>();
	@Input() navBarList: MenuItem[];

	closeHamMenu = () => {
		this.closeClicked.emit(false);
	};

	constructor() {
	}
}
