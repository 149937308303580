import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {MessageService} from 'primeng/api';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {FileUploadModel} from '../models/FileUpload.model';
import {FileUploadAddPACode} from "../models/FileUploadAddPACode";

@Injectable({
	providedIn: 'root'
})
export class PtsfileuploadService {
	_accessToken: string = sessionStorage.getItem('encodedAccessToken');
	fwsUrl = environment.APIEndpoint;
	fleetAdminService = environment.fleetAdminService;

	private options = {
		headers: new HttpHeaders({
			'Authorization': 'Bearer ' + this._accessToken,
			'Accept': 'application/json',
			//'Content-Type': 'multipart/form-data',
			'origin': window.location.origin
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient,  private messageService: MessageService) { }

	// getAccessCode(accesscode: string): Observable<AccessCodeModel> {
	// 	return this.http.get<AccessCodeModel>(this.fwsUrl + 'api/fcicadmin/accesscode/' + accesscode, this.options);
	// }
    //
	// getUserInformation(userid: string): Observable<UserInformationModel> {
	// 	return this.http.get<UserInformationModel>(this.fwsUrl + 'api/fcicadmin/userinfos/' + userid, this.options);
	// }

	// uploadFile(file) {
	// 	// const formData: FormData = new FormData();
	// 	// formData.append('file', file, file.name);
    //
	// 	alert(this.fwsUrl + 'api/pts/test');
	// 	//append any other key here if required
	// 	return this.http.post(this.fwsUrl + 'api/pts/test', this.options);
	// }

	uploadFile(fileObj : File, userid: string): Observable<FileUploadModel[]> {
		// const formData: FormData = new FormData();
		// formData.append('file', file, file.name);
		let formData = new FormData();
		formData.append('file', fileObj);
		formData.append('userid',userid);
		//append any other key here if required
		return this.http.post<FileUploadModel[]>(this.fleetAdminService + 'api/pts/fileupload',formData, this.options);
	}

	addPACode(fileUploadAddPACode): Observable<FileUploadAddPACode> {
		return this.http.post<FileUploadAddPACode>(this.fleetAdminService + 'api/pts/addpacode', fileUploadAddPACode , this.options);
	}

	getPacodeInformation(): Observable<FileUploadAddPACode> {
		return this.http.get<FileUploadAddPACode>(this.fleetAdminService + 'api/pts/getpacodeinfo' , this.options);
	}


}
