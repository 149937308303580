import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MessageMaintenanceDetail} from '../models/MessageMaintenanceDetail.model';
import { JsonPipe } from '@angular/common';
// import { stringify } from '@angular/core/src/util';

@Injectable({
  providedIn: 'root'
})
export class MessageMaintenanceService {

    _accessToken: string = sessionStorage.getItem('encodedAccessToken');
	fwsUrl = environment.APIEndpoint;
	fleetAdminService = environment.fleetAdminService;
	paymentReportService=environment.paymentReportsService;


	private options = {
		headers: new HttpHeaders({
			'Authorization': 'Bearer ' + this._accessToken,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'origin': window.location.origin
		}),
		withCredentials: true
	};

  constructor(private http: HttpClient) {}

  insertMessageDetails(messageMaintenanceDetail,flagToFindUpdate): Observable<any> {

	    const result = flagToFindUpdate ? 'UPDATE' : 'SAVE';
		return this.http.post<any>(this.fleetAdminService + 'api/msgMaintenance/saveMessages/' + result, messageMaintenanceDetail, this.options);
	}

	getMessageDetails(statementMonth, statementYear): Observable<any>{
		return this.http.get<any>(this.fleetAdminService + 'api/msgMaintenance/getMsgsByMonthAndYear/' + statementMonth + '/' + statementYear, this.options);
	}
	getMsgsByDates(selectedfinCode, selectedMonth): Observable<any> {
		let request = {
			"finCode":selectedfinCode,
			"dates":selectedMonth
			}
		 
		return this.http.post<any>(this.paymentReportService + 'api/msgMaintenance/getMsgsByDates' ,request, this.options);
	}
}
