import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { DatePipe } from '@angular/common';
import {FimpsService} from '../services/fimps.service';
import {FimpsSummary} from '../models/FimpsSummary';
import {ErrorResponseData} from '../models/ErrorResponseData';
import {ApiError} from '../models/ApiError';
import { saveAs } from 'file-saver';

@Component({
	selector: 'app-fim-detail-cpa-stmt',
	templateUrl: './fim-detail-cpa-stmt.component.html',
	styleUrls: ['./fim-detail-cpa-stmt.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class FimDetailCpaStmtComponent implements OnInit {
	monthlySummary: FimpsSummary[];
	subscribe: any[];
	accountName: any;
	address1: any;
	city: any;
	state: any;
	postalCode: any;
	sDate: any;
	formattedMonthlySummary: any [];
	finalMonthlySummary: any [];
	summaryCount: any;
	finalListSummary: any [];
	statementDate = [];
	statementDateDisplay = [];
	finCode: any;
	payeeCode: any;
	showSpinner: Boolean = false;
	reportMsgs = [];
	errorResponse: ErrorResponseData;
	errorTest: ApiError;
	reportsDataTest: string;
	userType: string;
	selectedMonth: string[] = [];
	selectedpayeeCode: any;
	adminFinCode: any;
	displayMessage: Boolean = false;
	errorMessage: String;
	constructor(public router: Router, public fims: FimpsService,  private route: ActivatedRoute, public datepipe: DatePipe) {
	}

	ngOnInit() {
		this.userType = sessionStorage.getItem('userType');
		this.accountName = sessionStorage.getItem('accountName');
		this.address1 = sessionStorage.getItem('address1');
		this.city = sessionStorage.getItem('city');
		this.state = sessionStorage.getItem('state');
		this.postalCode = sessionStorage.getItem('postalCode');
		this.sDate = sessionStorage.getItem('sDate');

		this.finCode = sessionStorage.getItem('finCode');
		this.payeeCode = sessionStorage.getItem('payeeCode');

		if (this.finCode === null || this.finCode === undefined || this.finCode === '') {
			if (this.userType && this.userType === 'Employee') {
				this.router.navigate(['fimps-search']);
			} else {
				this.router.navigate(['fimps-cpa-stmt']);
			}
		}

		this.loadFimpsReport();
	}

	loadFimpsReport() {
		this.showSpinner = true;
		this.formattedMonthlySummary = [];
		let formatStatementDate;
		if (this.sDate) {
			formatStatementDate = this.sDate.split(',');
		}
		let uniquePgmCode = [];
		const uniqueDesc = [];

		const formatMonthlySummary = [];

		if (formatStatementDate) {
			formatStatementDate.forEach(item => {
				this.statementDate.push(item.toString().trim());
			});
		}

		this.fims.getMonthlyReports(this.finCode, this.payeeCode, this.statementDate.toString()).subscribe( data => {
			this.reportsDataTest = JSON.stringify(data);
			this.monthlySummary = [];
			if (data['result'] && data['result']['getAssigneeStatementDetails']) {

				this.monthlySummary = data['result']['getAssigneeStatementDetails'];

				uniquePgmCode = this.monthlySummary.map(item => item.indexProgramCode)
					.filter((value, index, self) => self.indexOf(value) === index);

				if (uniquePgmCode && uniquePgmCode.length > 0) {
					uniquePgmCode.forEach(item => {
						this.formattedMonthlySummary.push(this.monthlySummary.filter(items => items.indexProgramCode === item));
					});
				}

				this.finalMonthlySummary = [];
				this.finalListSummary = [];
				this.formattedMonthlySummary.forEach(summary => {
					if (summary && summary.length > 0) {
						summary.forEach( sum => {
							if (sum.summaryDesc.trim() === 'PROGRAM VEHICLE PAYMENTS' || sum.summaryDesc.trim() === 'PROGRAM PAYMENTS') {
								sum.summaryDesc = 'PROGRAM VEHICLE PAYMENTS';
								this.finalMonthlySummary.push({
									summaryDesc: 'PAYMENTS',
									isHeader: true,
									indexProgramCode: sum.indexProgramCode,
									indexDescription: sum.indexDescription,
									summaryAmount: 0,
									summaryCount: 0
								});
								const paymentItem = [];
								paymentItem['summaryAmount'] = sum.summaryAmount;
								paymentItem['summaryCount'] = sum.summaryCount;
								paymentItem['summaryDesc'] = sum.summaryDesc;
								paymentItem['indexProgramCode'] = sum.indexProgramCode;
								paymentItem['indexDescription'] = sum.indexDescription;
								paymentItem['summaryReportCode'] = sum.summaryReportCode;
								paymentItem['netAmount'] = sum.netAmount;
								this.finalMonthlySummary.push(paymentItem);

							} else if (sum.summaryDesc.trim() === 'PRIOR PAYMENTS CHARGED BACKED' || sum.summaryDesc.trim() === 'CHARGEBACKS' || sum.summaryDesc.trim() === 'PRIOR PAYMENTS CHARGED BACKED') {
								sum.summaryDesc = 'PRIOR PAYMENTS CHARGED BACKED';
								this.finalMonthlySummary.push({
									summaryDesc: 'CHARGEBACKS',
									isHeader: true,
									indexProgramCode: sum.indexProgramCode,
									indexDescription: sum.indexDescription,
									summaryAmount: 0,
									summaryCount: 0
								});
								const chrgBackItem = [];
								chrgBackItem['summaryAmount'] = sum.summaryAmount;
								chrgBackItem['summaryCount'] =  sum.summaryCount;
								chrgBackItem['summaryDesc'] = sum.summaryDesc;
								chrgBackItem['indexProgramCode'] = sum.indexProgramCode;
								chrgBackItem['indexDescription'] = sum.indexDescription;
								chrgBackItem['summaryReportCode'] =  sum.summaryReportCode;
								chrgBackItem['netAmount'] = sum.netAmount;
								this.finalMonthlySummary.push(chrgBackItem);
							} else if (sum.summaryDesc.trim() === 'INELIGIBLE VEHICLES' || sum.summaryDesc.trim()  === 'VEHICLES NOT ELIGIBLE IN THIS PROGRAM') {
								sum.summaryDesc = 'VEHICLES NOT ELIGIBLE IN THIS PROGRAM';
								this.finalMonthlySummary.push({
									summaryDesc: 'INELIGIBLE',
									isHeader: true,
									indexProgramCode: sum.indexProgramCode,
									indexDescription: sum.indexDescription,
									summaryAmount: 0,
									summaryCount: 0
								});
								const ineligibleItem = [];
								ineligibleItem['summaryAmount'] = sum.summaryAmount;
								ineligibleItem['summaryCount'] = sum.summaryCount;
								ineligibleItem['summaryDesc'] = sum.summaryDesc;
								ineligibleItem['indexProgramCode'] = sum.indexProgramCode;
								ineligibleItem['indexDescription'] = sum.indexDescription;
								ineligibleItem['summaryReportCode'] = sum.summaryReportCode;
								ineligibleItem['netAmount'] = sum.netAmount;
								this.finalMonthlySummary.push(ineligibleItem);
							} else {
								if (sum.summaryDesc.trim() === 'PRICE PROTECTION') {
									sum.summaryDesc = 'PRICE PROTECTION PAYMENTS';
								} else if (sum.summaryDesc.trim() === 'ADJUSTMENT PAYMENTS' || sum.summaryDesc.trim() === 'ADJUSTMENT TO PREVIOUSLY PAID VEHICLES') {
									sum.summaryDesc = 'ADJUSTMENTS TO PREVIOUSLY PAID VEHICLES';
								} else {
									sum.summaryDesc = sum.summaryDesc;
								}
								const items = [];
								items['summaryAmount'] = sum.summaryAmount;
								items['summaryCount'] = sum.summaryCount;
								items['summaryDesc'] = sum.summaryDesc;
								items['indexProgramCode'] = sum.indexProgramCode;
								items['indexDescription'] = sum.indexDescription;
								items['summaryReportCode'] = sum.summaryReportCode;
								items['netAmount'] = sum.netAmount;
								this.finalMonthlySummary.push(items);
							}
						});
					}
				});

				this.finalMonthlySummary.forEach( item => {
					if (item.summaryReportCode) {
						if (((item.summaryReportCode.trim() === '10' || item.summaryReportCode.trim() === '15' || item.summaryReportCode.trim() === '20' || item.summaryReportCode.trim() === '25'
							|| item.summaryReportCode.trim() === '30' ||  item.summaryReportCode.trim() === '35' ||  item.summaryReportCode.trim() === '45') && (item.summaryCount !== 0))) {
							item['isLink'] = true;
						} else {
							item['isLink'] = false;
						}
					}
				});

				if (uniquePgmCode && uniquePgmCode.length > 0) {
					uniquePgmCode.forEach(item => {
						const finalVal = [];
						finalVal['totalCount'] = 0;
						finalVal['pgmSummary'] = this.finalMonthlySummary.filter(items => items.indexProgramCode === item);
						finalVal['pgmSummary'].forEach(summaryItem => {
							if (summaryItem.summaryReportCode) {
								if (((summaryItem.summaryReportCode.trim() === '10' || summaryItem.summaryReportCode.trim() === '15'
									|| summaryItem.summaryReportCode.trim() === '20' || summaryItem.summaryReportCode.trim() === '25'
									||  summaryItem.summaryReportCode.trim() === '30') && (summaryItem.summaryCount !== 0))
									|| summaryItem.summaryReportCode.trim() === '35' || summaryItem.summaryReportCode.trim() === '45')							{
									finalVal['totalCount'] += summaryItem.summaryCount;
								}
							}
						});

						if (finalVal['pgmSummary'].filter(t => t.netAmount).length > 1) {
							finalVal['netAmount']  = finalVal['pgmSummary'].filter(t => t.netAmount)[0].netAmount;
						} else {
							finalVal['netAmount'] = 0;
						}
						this.finalListSummary.push(finalVal);
					});
				}
			} else {
				this.showSpinner = false;
				this.errorResponse = new ErrorResponseData(data);
				if (this.errorResponse && this.errorResponse.error && this.errorResponse.error.messages) {
					this.showErrorMsg(this.errorResponse.error.messages[0]);
				}
			}
			this.showSpinner = false;
		},
			(error: ApiError) => {
				this.errorTest = error;
			});
	}

	goToFims() {
		this.router.navigate(['fimps-search']);
	}

	loadIndividualSummary(programSummary) {
		const indexDescription = programSummary.indexDescription;
		const summaryDesc = programSummary.summaryDesc;
		const indexProgramCode = programSummary.indexProgramCode;
		const summaryReportCode = programSummary.summaryReportCode;
		const summaryCount = programSummary.summaryCount;
		sessionStorage.setItem('summaryCount', summaryCount);
		sessionStorage.setItem('indexDescription', indexDescription);
		sessionStorage.setItem('summaryDesc', summaryDesc);
		sessionStorage.setItem('indexProgramCode', indexProgramCode);
		sessionStorage.setItem('summaryReportCode', summaryReportCode);
		this.router.navigate(['fimps-cpa-pgm-summary']);
	}

	loadStatementDefinition() {
		this.router.navigate(['fimps-cpa-stmt-def']);
	}

	getDownloadExcel() {
		this.downLoadFile('exportToExcel', 'index_d.xlsx');
	}

	downLoadFile(methodName, fileName) {
		this.showSpinner = true;
		if (this.finCode !== '' && this.finCode !== undefined && this.payeeCode !== undefined && this.payeeCode !== null && this.statementDate !== null	&& this.statementDate !== undefined && this.accountName !== null && this.accountName !== undefined) {
			this.fims.getDownloadExcel(this.finCode, this.payeeCode, this.statementDate, this.accountName).subscribe(data => {
				if (data['result'] !== null) {
					this.showSpinner = false;
					this.saveToFileSystem(data, fileName);
				} else {
					this.showSpinner = false;
					this.errorResponse = new ErrorResponseData(data);
					if (this.errorResponse && this.errorResponse.error && this.errorResponse.error.messages) {
						this.showErrorMsg(this.errorResponse.error.messages[0]);
					}
				}
			});
		}
	}

	saveToFileSystem(data, fileName) {
		const ua: string = window.navigator.userAgent;
		const windowsBrowserRegex = /Trident|MSIE|Edge/;
		const isIE = ua.match(windowsBrowserRegex);
		const blob = new Blob([data], {type: 'application/pdf'});
		const a = document.createElement('a');
		a.href = URL.createObjectURL(blob);
			if (isIE) {
				alert("d");
				saveAs.msSaveOrOpenBlob(blob, fileName);
			} else {
				a.setAttribute('target', '_blank');
				a.download = fileName;
				a.click();
				a.remove();
			}
	}

	showErrorMsg(errorMsg) {
		this.displayMessage = true;
		if (errorMsg) {
			this.errorMessage = errorMsg;
		}
	}

		goToPreviousPage() {
			this.router.navigate(['fimps-cpa-stmt']);

		}

}
