import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {FinCodeModel} from '../models/FinCode.model';
import {PayeeAssignee} from '../models/PayeeAssignee';
import {FimpsSummary} from '../models/FimpsSummary';
import {FimpsDataInfo} from '../models/FimpsDataInfo';
import {FimpsVehicleDataStatements} from '../models/FimpsVehicleDataStatements';
import {FimpsInvalidChargeBack} from '../models/FimpsInvalidChargeBack';

@Injectable()
export class FimpsService {
	_accessToken: string = sessionStorage.getItem('encodedAccessToken');
	fwsUrl = environment.APIEndpoint;
	paymentReportService=environment.paymentReportsService;
	userId = sessionStorage.getItem('userId');

	private options = {
		headers: new HttpHeaders({
			'Authorization': 'Bearer ' +  this._accessToken,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'origin': window.location.origin
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient) {
	}

	getFinCode(finCode): Observable<FinCodeModel[]> {
		let request = {"strFinCode":finCode}
		// return this.http.get<any[]>("http://localhost:8080/api/common/personalindicator/SADEPU1",this.options);
		return this.http.post<FinCodeModel[]>(this.paymentReportService  + 'api/v1/getPayeeCodeInfo',request, this.options);
	}

	getFinCodeByUser(username): Observable<FinCodeModel[]> {
		let request = {
			"userId":username
		}
		return this.http.post<FinCodeModel[]>(this.paymentReportService + 'api/v1/getPayeeCodeInfo/getFINCode',request, this.options);
	}

	getAccountName(fincode): Observable<FinCodeModel[]> {
		let request = {"strFinCode":[fincode]}
		return this.http.post<FinCodeModel[]>(this.paymentReportService + 'api/v1/getPayeeCodeInfo/getAccoutNameInfo',request, this.options);
	}

	getPayeeCode(payeeCode): Observable<PayeeAssignee[]> {
		let request = {
			"strFinCode": payeeCode
		}
		return this.http.post<PayeeAssignee[]>(this.paymentReportService + 'api/v1/getPayeeAssignee',request, this.options);
	}

	getMonthlyReports(finCode, payeeCode, sDate): Observable<FimpsSummary[]> {
		let request = {
			"strFinCode":finCode,
			"strIndexAssigneeCode":payeeCode,
			"dtIndexStatementDate":sDate
		}
		return this.http.post<FimpsSummary[]>(this.paymentReportService + 'api/v1/getAssigneeStatementDetails',request, this.options);
	}

	getVehicleReports(finCode, payeeCode, statementDate, indexProgramCode, summaryReportCode): Observable<FimpsDataInfo[]> {
		if (summaryReportCode && summaryReportCode === '35') {
			let request = {
				"strFinCode":finCode,
				"assignCode":payeeCode,
				"programCode":indexProgramCode,
				"rptcode":summaryReportCode,
				"dtIndexStatementDate":statementDate.toString()
			}
			return this.http.post<FimpsDataInfo[]>(this.paymentReportService  + 'api/v1/getFimpsDataInfo',request, this.options);
		} else {
			let request = {
				"strFinCode":finCode,
    			"assignCode":payeeCode,
    			"programCode":indexProgramCode,
    			"rptCode":summaryReportCode,
    			"dtIndexStatementDate":statementDate.toString()
			}
			return this.http.post<FimpsDataInfo[]>(this.paymentReportService + 'api/v1/getFimpsDataInfo/getFimpsDataInfoWithOutSign',request, this.options);
		}
	}

	getIndividualVehicleReports(vinVehicleName, finCode, payeeCode, statementDate, indexProgramCode, summaryReportCode): Observable<FimpsVehicleDataStatements[]> {
		let request = {
			"strFinCode":finCode,
			"strIndexAssigneeCode":payeeCode,
			"strIndexProgramCode":indexProgramCode,
			"strSummaryReportCode":summaryReportCode,
			"strVinVehicleName":vinVehicleName,
			"dtIndexStatementDate":statementDate.toString()
		}
		return this.http.post<FimpsVehicleDataStatements[]>(this.paymentReportService + 'api/v1/getFimpsDataInfo/getFimpsVehicleDataStatements',request, this.options);
	}

	getDownloadExcel(finCode, payeeCode, statementDate, accountName) {
		let request = {
			"strFinCode":finCode,
			"strIndexAssigneeCode":payeeCode,
			"strCompanyName":accountName,
			"dtIndexStatementDate":statementDate.toString()
		}
		return this.http.post(this.paymentReportService + 'api/v1/getFimpsDataInfo/getDownloadExcel',request, 
		{
			headers: new HttpHeaders({
				'Accept': 'application/octet-stream',
				'Content-type': 'application/json',
				'authorization': 'bearer ' + this._accessToken
			}), withCredentials: true, responseType: 'arraybuffer' as 'json'
		}
		);
	}

	getChargeBackFinList(): Observable<FimpsInvalidChargeBack[]> {
		return this.http.get<FimpsInvalidChargeBack[]>(this.paymentReportService + 'api/v1/getFimpsInvalidChargeBacks/getInvalidChargeBacksfinlist', this.options);
	}
}
