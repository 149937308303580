import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {MessageService} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ConsolidatedreportService {

	_accessToken: string = sessionStorage.getItem('encodedAccessToken');
	fwsUrl = environment.APIEndpoint;
	fleetAdminService = environment.fleetAdminService;
	private options = {
		headers: new HttpHeaders({
			'Authorization': 'Bearer ' + this._accessToken,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'origin': window.location.origin
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient,  private messageService: MessageService) { }

	getConsolidatedReportData(reportStatus, entitlements, entitlementType, countryCode): Observable<any[]>{
		console.log(reportStatus)
		return this.http.get<any>(this.fleetAdminService + 'api/v1/lookup/consolidatedreport?status=' + reportStatus + '&entitlementList=' + entitlements + '&entitlementType='+ entitlementType + '&countryCode=' + countryCode, this.options);
	}

	getDownloadExcelForBatchOasis(reportStatus, entitlements, entitlementType, countryCode) {
		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + this._accessToken
		});
		return this.http.get(this.fleetAdminService + 'api/v1/lookup/consolidatedreport/excel?status=' + reportStatus + '&entitlementList=' + entitlements + '&entitlementType='+ entitlementType + '&countryCode=' + countryCode,  {
			headers: headers,
			responseType: 'blob'

		});
	}

	getReportDate(): Observable<any>{
		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + this._accessToken
		});
		return this.http.get(this.fleetAdminService + 'api/v1/lookup/consolidatedreport/getJobRunDate', {
			headers: headers,
			responseType: 'text'

		});
	}
}
