import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'entitlement-list',
  templateUrl: './entitlement-list.component.html',
  styleUrls: ['./entitlement-list.component.css']
})
export class EntitlementListComponent implements OnInit {

  radioItems: Array<string>;
  model   = {option: ''};

  constructor(public router: Router) {
    this.radioItems = ['Personal Entitlement', 'FIN Entitlement'];
   }

  ngOnInit() {
  }

  goToAuditReports() {
		this.router.navigate(['fleet-audit-report']);
	}

}
