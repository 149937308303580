/*
EXAMPLE of how to use this service from a component:

//install pdfMake (this will be taken care with the package.json npm install
npm i pdfmake

//Dependency Inject the ExportPDFService into component
constructor(private exportPDFService: ExportPDFService) {
}

//create an array to hold the content of the export
const tableContent = [];

//push the headings
tableContent.push([
	{text: 'Booking Confirmation Number', bold: true},
	{text: 'Customer Name', bold: true},
	{text: 'Adventure', bold: true},
	{text: 'Start Date', bold: true},
	{text: 'End Date', bold: true}
]);

// push the body contents
bookings.forEach((booking) => {
	tableContent.push([booking.confirmationNumber, booking.customerName, booking.adventureName, booking.startDate, booking.endDate]);
});

//call the service with the content and desired file name
this.exportPDFService.exportPDF(tableContent, 'bookings.pdf');
 */


import {Injectable} from '@angular/core';
import * as jspdf from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';

@Injectable({
	providedIn: 'root'
})
export class ExportPDFService {

	constructor() {
		jspdf.vfs = pdfFonts.pdfMake.vfs;
	}

	exportPDF(tableContent: any[], fileName: string) {

		const widths: string[] = [];
		for (let i = 0; i < tableContent[0].length; i++) {
			widths.push('auto');
		}

		const docDefinition = {
			content: [
				{
					table: {
						headerRows: 1,
						widths: widths,
						body: tableContent
					}
				}
			],
			styles: {
				header: {
					bold: true
				}
			}
		};
		jspdf.createPdf(docDefinition).download(fileName);
	}
}
