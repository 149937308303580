import {Component, OnInit} from '@angular/core';
import {ErrorResponseData} from '../models/ErrorResponseData';
import {NfppAdminService} from '../services/nfppadmin.service';
import { saveAs } from 'file-saver';

@Component({
		selector: 'app-nfpp-admin',
		templateUrl: './nfpp-admin.component.html',
		styleUrls: ['./nfpp-admin.component.css']
})
export class NfppAdminComponent implements OnInit {
	errorResponse: ErrorResponseData;
	reportMsgs = [];
	showSpinner: Boolean = false;

		constructor(public nfppAdmin: NfppAdminService) {
		}

		ngOnInit() {
		}

		downloadExcelForNFPPAdmin(type, name) {
			this.downloadFileForNFPPAdmin('exportToExcel', type, name);
		}

		downloadFileForNFPPAdmin(methodName, type, name) {
			this.showSpinner = true;
			this.nfppAdmin.downloadExcelForNFPPAdmin(type).subscribe(data => {
				this.showSpinner = false;
				if (data['result'] !== null) {
					this.saveToFileSystem(data, name);
				} else {
					this.showSpinner = false;
					this.errorResponse = new ErrorResponseData(data);
					this.showErrorMsg(this.errorResponse.error.messages[0]);
				}
			});
		}

		saveToFileSystem(data, fileName) {
			fileName = fileName + '.xlsx';
			const ua: string = window.navigator.userAgent;
			const windowsBrowserRegex = /Trident|MSIE|Edge/;
			const isIE = ua.match(windowsBrowserRegex);
			// const blob = new Blob([data], {type: 'application/pdf'});
			const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
			const a = document.createElement('a');
			a.href = URL.createObjectURL(blob);
			if (isIE) {
				saveAs.msSaveOrOpenBlob(blob, fileName);
			} else {
				a.setAttribute('target', '_blank');
				a.download = fileName;
				a.click();
			}
			a.remove();
		}

		showErrorMsg(errorMsg) {
			if (errorMsg) {
				this.reportMsgs = [];
				this.reportMsgs.push({
					severity: 'error',
					summary: 'Error Message',
					detail: errorMsg
				});
			}
		}
}
