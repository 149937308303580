import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {FinIndicatorApi} from '../models/FinIndicatorApi';
import {PayeeAssignee} from '../models/PayeeAssignee';
import {FimpsSummary} from '../models/FimpsSummary';
import {FimpsDataInfo} from '../models/FimpsDataInfo';
import {FimpsVehicleDataStatements} from '../models/FimpsVehicleDataStatements';
import {FimpsInvalidChargeBack} from '../models/FimpsInvalidChargeBack';
import {FinCodeModel} from '../models/FinCode.model';
import {FinIndicatorDetails} from '../models/FinIndicatorDetailsList';
import {CpaLookup} from '../models/CpaLookup';

@Injectable()
export class FinIndicatorService {
	_accessToken: string = sessionStorage.getItem('encodedAccessToken');
	fwsUrl = environment.APIEndpoint;
	userId = sessionStorage.getItem('userId');
	fleetAdminService = environment.fleetAdminService;
	private options = {
		headers: new HttpHeaders({
			'Authorization': 'Bearer ' +  this._accessToken,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'origin': window.location.origin
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient) {
	}

	getFinIndicator(): Observable<FinIndicatorApi> {
		return this.http.get<FinIndicatorApi>(this.fleetAdminService  + 'api/v1/finindicator/getfinindicatorDesc', this.options);
	}
/*	getFinIndicatorDetails(finEntitlementArray): Observable<FinIndicatorDetails> {
		console.log('service started ', finEntitlementArray);
		return this.http.get<FinIndicatorDetails>(this.fwsUrl  + 'api/v1/finindicator/finEntitlementSearch' + finEntitlementArray, this.options);
	} */
	getFinIndicatorDetails(finEntitlementArray): Observable<FinIndicatorDetails> {
		console.log('service started ', finEntitlementArray);
		return this.http.post<FinIndicatorDetails>(this.fleetAdminService  + 'api/v1/finindicator/finEntitlementSearchfrmDB' , finEntitlementArray, this.options);
	}

	/*getFinIndicatorDetails(finIndicatorlst): Observable<FinIndicatorDetails[]> {
		console.log('service started');
		return this.http.post<FinIndicatorDetails[]>(this.fwsUrl  + 'api/v1/finindicator/getfinDetail1/' + finIndicatorlst, this.options);
	}*/

/*getDownloadExcel(finEntitlementArray) {
	return this.http.post(this.fwsUrl + 'api/v1/finindicator/finEntitlementExcelfrmDB' , finEntitlementArray , {
		headers: new HttpHeaders({
			'Accept': 'application/octet-stream',
			'Content-type': 'text/plain',
			'authorization': 'bearer ' + this._accessToken
		}), withCredentials: true, responseType: 'arraybuffer' as 'json'
	});
}
*/
	getDownloadExcel(finEntitlementArray) {
		const headers = new HttpHeaders({
			'Authorization': 'bearer ' + this._accessToken
		});
		return this.http.post(this.fleetAdminService + 'api/v1/finindicator/finEntitlementExcel', finEntitlementArray, {
			headers: headers,
			responseType: 'blob'
		});
	}

	getPayeeCode(finIndicator): Observable<PayeeAssignee[]> {
		return this.http.get<PayeeAssignee[]>(this.fwsUrl + 'api/v1/finindicator/getfinDetail/' + finIndicator, this.options);
	}
/*
	getMonthlyReports(finCode, payeeCode, sDate): Observable<FimpsSummary[]> {
		return this.http.get<FimpsSummary[]>(this.fwsUrl + 'api/v1/getAssigneeStatementDetails/' + finCode + '/' + payeeCode +  '?dtIndexStatementDate=' + sDate, this.options);
	}

	getVehicleReports(finCode, payeeCode, statementDate, indexProgramCode, summaryReportCode): Observable<FimpsDataInfo[]> {
		if (summaryReportCode && summaryReportCode === '35') {
			return this.http.get<FimpsDataInfo[]>(this.fwsUrl  + 'api/v1/getFimpsDataInfo/' + finCode + '/' + payeeCode  + '/' + indexProgramCode + '/' + summaryReportCode +  '?dtIndexStatementDate=' + statementDate, this.options);
		} else {
			return this.http.get<FimpsDataInfo[]>(this.fwsUrl + 'api/v1/getFimpsDataInfo/getFimpsDataInfoWithOutSign/' + finCode + '/' + payeeCode +  '/' + indexProgramCode + '/' + summaryReportCode + '?dtIndexStatementDate=' + statementDate, this.options);
		}
	}

	getIndividualVehicleReports(vinVehicleName, finCode, payeeCode, statementDate, indexProgramCode, summaryReportCode): Observable<FimpsVehicleDataStatements[]> {
		return this.http.get<FimpsVehicleDataStatements[]>(this.fwsUrl + 'api/v1/getFimpsDataInfo/getFimpsVehicleDataStatements/' + finCode + '/' + payeeCode  + '/' + indexProgramCode + '/' + summaryReportCode + '?strVinVehicleName=' + vinVehicleName + '&dtIndexStatementDate='  + statementDate, this.options);
	}



	getChargeBackFinList(): Observable<FimpsInvalidChargeBack[]> {
		return this.http.get<FimpsInvalidChargeBack[]>(this.fwsUrl + 'api/v1/getFimpsInvalidChargeBacks/getInvalidChargeBacksfinlist', this.options);
	}*/
}
