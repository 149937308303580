import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {PersonEntitlementList} from '../models/PersonEntitlementList.model';
import {PersonEntitlementReport} from '../models/PersonEntitlementReport.model';
import {PersonEntitlementArray} from '../models/PersonEntitlementArray.model';

@Injectable()
export class PersonalEntitlementService {
	_accessToken: string = sessionStorage.getItem('encodedAccessToken');
	fwsUrl = environment.APIEndpoint;
	userId = sessionStorage.getItem('userId');
	private options = {
		headers: new HttpHeaders({
			'Authorization': 'Bearer ' + this._accessToken,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'origin': window.location.origin
		}),
		withCredentials: true
	};
	fleetAdminService = environment.fleetAdminService;

	constructor(private http: HttpClient) {

	}

	getPersonalEntitlementList(): Observable<PersonEntitlementList> {
		return this.http.get<PersonEntitlementList>(this.fleetAdminService + 'api/v1/personalEntitlementReport/listOfPersonalEntitlement?entityNames=NAFSA', this.options);
	}

	getPersonalEntitlementReport(personEntitlementArray): Observable<PersonEntitlementReport> {
		return this.http.post<PersonEntitlementReport>(this.fleetAdminService + 'api/v1/personalEntitlementReport/personalEntitlementSearch', personEntitlementArray, this.options);
	}
	
	getDownloadExcel(personEntitlementArray) {
		const headers = new HttpHeaders({
			'Authorization': 'bearer ' + this._accessToken
		});
		return this.http.post(this.fleetAdminService + 'api/v1/personalEntitlementReport/personalEntitlementExcel', personEntitlementArray, {
			headers: headers,
			responseType: 'blob'
		});
	}

}