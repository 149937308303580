import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {PersonalIndicatorInfo} from '../models/PersonalIndicatorInfo';
import {AccessCodeModel} from '../models/AccessCode.model';
import {FinIndicatorInfo} from '../models/FinIndicatorInfo';

@Injectable()
export class CommonService {
	_accessToken: string = sessionStorage.getItem('encodedAccessToken');
	fwsUrl = environment.APIEndpoint;
	userId: any;
    fleetAdminService = environment.fleetAdminService;

	constructor(private http: HttpClient) {
		this.userId = sessionStorage.getItem('userId');
	}

	private options = {
		headers: new HttpHeaders({
			'Authorization': 'Bearer ' + this._accessToken,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'origin': window.location.origin
		}),
		withCredentials: true
	};

	getPersonalIndicatorInfo(): Observable<PersonalIndicatorInfo[]> {
		let request = {
			"wslID":this.userId
		}
		return this.http.post<PersonalIndicatorInfo[]>(this.fleetAdminService + 'api/common/personalindicator' ,request, this.options);
	}

	getMarketSegment(userId): Observable<AccessCodeModel> {
		let request = {
			"userId":userId
		}
		return this.http.post<AccessCodeModel>(this.fleetAdminService + 'api/common/market' ,request, this.options);
	}

	getFinIndicatorInfo(userId): Observable<FinIndicatorInfo[]> {
		let request = {
			"userId":userId
		}
		return this.http.post<FinIndicatorInfo[]>(this.fleetAdminService + 'api/common/finindicator',request, this.options);
	}

	getCountryCode(): Observable<any> {
		return this.http.get<any>(this.fleetAdminService + 'api/common/countrycodes', this.options);
	}
}
