import {Component, OnInit, ViewEncapsulation } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { DatePipe } from '@angular/common';
import {FimpsService} from '../services/fimps.service';
import {FimpsDataInfo} from '../models/FimpsDataInfo';
import {ErrorResponseData} from '../models/ErrorResponseData';
import {ApiError} from '../models/ApiError';

@Component({
	selector: 'app-fim-cpa-pgm-summary',
	templateUrl: './fim-cpa-pgm-summary.component.html',
	styleUrls: ['./fim-cpa-pgm-summary.component.css'],
	// encapsulation: ViewEncapsulation.None
})
export class FimCpaPgmSummaryComponent implements OnInit {
	detailedSummary: FimpsDataInfo[];
	showAmount: Boolean = true;
	accountName: string;
	address1: string;
	city: string;
	state: string;
	postalCode: string;
	sDate: any;
	finCode: any;
	payeeCode: any;
	indexDescription: any;
	summaryDesc: any;
	indexProgramCode: any;
	summaryReportCode: any;
	footerText: string;
	totalCount: any;
	totalAmount: any;
	summaryCount: any;
	showSpinner: Boolean = false;
	errorResponse: ErrorResponseData;
	reportMsgs = [];
	reportsDataTest: string;
	errorTest: ApiError;
	statementDate = [];
	statementDateDisplay = [];
	userType: string;
	displayMessage: boolean = false;
	errorMessage: string;
	constructor(public router: Router, public fims: FimpsService, private route: ActivatedRoute, public datepipe: DatePipe) {
	}

	ngOnInit() {
		this.userType = sessionStorage.getItem('userType');
			this.summaryCount = sessionStorage.getItem('summaryCount');
			this.indexDescription =  sessionStorage.getItem('indexDescription');
			this.summaryDesc =  sessionStorage.getItem('summaryDesc');
			this.indexProgramCode = sessionStorage.getItem('indexProgramCode');
			this.summaryReportCode = sessionStorage.getItem('summaryReportCode');

			this.accountName = sessionStorage.getItem('accountName');
			this.address1 = sessionStorage.getItem('address1');
			this.city = sessionStorage.getItem('city');
			this.state = sessionStorage.getItem('state');
			this.postalCode = sessionStorage.getItem('postalCode');
			this.sDate = sessionStorage.getItem('sDate');

			this.finCode = sessionStorage.getItem('finCode');
			this.payeeCode = sessionStorage.getItem('payeeCode').trim();

		if (this.finCode === null || this.finCode === undefined || this.finCode === '') {
			if (this.userType && this.userType === 'Employee') {
				this.router.navigate(['fimps-search']);
			} else {
				this.router.navigate(['fimps-cpa-stmt']);
			}
		}
		this.setFooterText();
		this.loadVehicleReport();
	}

	setFooterText() {
		this.showAmount = true;
		if ( this.summaryDesc.trim() === 'PROGRAM VEHICLE PAYMENTS' ) {
			this.footerText = 'TOTAL VEHICLE PAYMENTS';
		} else if ( this.summaryDesc.trim() === 'PRICE PROTECTION PAYMENTS') {
			this.footerText = 'TOTAL ELIGIBLE VEHICLES';
		} else if ( this.summaryDesc.trim() === 'ADJUSTMENTS TO PREVIOUSLY PAID VEHICLES' ) {
			this.footerText = 'TOTAL VEHICLES ADJUSTED';
		} else if ( this.summaryDesc.trim() ===  'PRIOR PAYMENTS CHARGED BACK'  || this.summaryDesc.trim() === 'PRIOR PAYMENTS CHARGED BACKED' ) {
			this.footerText = 'TOTAL VEHICLES CHARGED BACK';
		} else if ( this.summaryDesc.trim() === 'VEHICLES NOT ELIGIBLE IN THIS PROGRAM' )	{
			this.showAmount = false;
			this.footerText = 'TOTAL INELIGIBLE VEHICLES';
		} else	{
			this.footerText = 'TOTAL VEHICLES';
		}
	}

	loadVehicleReport() {
		let formatStatementDate;
		if (this.sDate) {
			formatStatementDate = this.sDate.split(',');
		}

		this.statementDate = [];
		if (formatStatementDate) {
			formatStatementDate.forEach(item => {
				this.statementDate.push(item.toString().trim());
			});
		}

		this.showSpinner = true;
		this.fims.getVehicleReports(this.finCode, this.payeeCode, this.statementDate, this.indexProgramCode, this.summaryReportCode).subscribe( data => {
			this.detailedSummary = [];
				this.reportsDataTest = JSON.stringify(data);
			if (data['result'] !== null) {
				if (this.summaryReportCode && this.summaryReportCode === '35') {
					this.detailedSummary = data['result']['getFimpsDataInfo'];
				} else {
					if (data['result'] && data['result']['getFimpsDataInfoWithOutSign']) {
						this.detailedSummary = data['result']['getFimpsDataInfoWithOutSign'];
					}
				}

				if (this.detailedSummary && this.detailedSummary.length > 0) {
					this.totalCount = this.detailedSummary.map(c => c.cnt).reduce((sum, current) => sum + current);
					this.totalAmount = this.detailedSummary.map(c => c.tot).reduce((sum, current) => sum + current);
				}
			} else {
				this.showSpinner = false;
				this.errorResponse = new ErrorResponseData(data);
				this.showErrorMsg(this.errorResponse.error.messages[0]);
			}
			this.showSpinner = false;
		},
			(error: ApiError) => {
				this.errorTest = error;
			});
	}

	goToFims() {
		this.router.navigate(['fimps-search']);
	}

	loadSingleVehicle(vinVehicleName, vcount) {
		sessionStorage.setItem('vinVehicleName', vinVehicleName);
		sessionStorage.setItem('vcount', vcount);
		this.router.navigate(['fimps-cpa-vehi-summary']);
	}

	showErrorMsg(errorMsg) {
		this.displayMessage = true;
		if (errorMsg) {
			this.errorMessage = errorMsg;
		}
	}

	goToPreviousPage() {
		this.router.navigate(['fimps-detail-cpa-stmt']);
	}
}
