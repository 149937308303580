export class MessageMaintenanceDetail {
	message: string;
	finList: string;
	beginDate: string;
	endDate: string;
	month: number;
	year: number;
	status: string;
	//userId: string;
	createdDate: Date;
	createdUser: string;
	lastUpdateDate: Date;
	lastUpdateUser: string;
}
