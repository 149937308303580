import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class CpaMessageMaintenanceService {

	_accessToken: string = sessionStorage.getItem('encodedAccessToken');
	fwsUrl = environment.APIEndpoint;
	fleetAdminService = environment.fleetAdminService;


	private options = {
		headers: new HttpHeaders({
			'Authorization': 'Bearer ' + this._accessToken,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'origin': window.location.origin
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient) {}


	getCpaMessageDetails(): Observable<any>{
		return this.http.get<any>(this.fleetAdminService + 'api/msgMaintenance/getCpaMsg', this.options);
	}

	insertCpaMessageDetails(messageMaintenanceDetail,flagToFindCpaUpdate): Observable<any> {
		const result = flagToFindCpaUpdate ? 'UPDATE' : 'SAVE';
		return this.http.post<any>(this.fleetAdminService + 'api/msgMaintenance/saveCpaMessage/' + result, messageMaintenanceDetail, this.options);
	}

	getPersonalindicator(userId): Observable<any[]> {
		let request = {
			"wslID":userId
		}
		return this.http.post<any>(this.fleetAdminService + 'api/common/personalindicator',request,  this.options);
	}

	loadCpaMessage(): Observable<any[]>{
		return this.http.get<any>(this.fleetAdminService + 'api/msgMaintenance/getActiveCpaMsg', this.options);
	}

}