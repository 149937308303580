import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {MessageService} from 'primeng/api';;
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {FinIndicatorMaster} from '../models/FinIndicatorMaster';
import {FinIndicatorDetailsModel} from '../models/FinIndicatorDetails';
import {FINMappingFINCodeModel} from '../models/FINMappingFINCodeModel';
import {FinCodeWithFinIndDetailApi} from '../models/FinIndicatorFINCodeMapping';
@Injectable({
	providedIn: 'root'
})
export class FinAdminService {
	_accessToken: string = sessionStorage.getItem('encodedAccessToken');
	fwsUrl = environment.APIEndpoint;
    fleetAdminService = environment.fleetAdminService;

	private options = {
		headers: new HttpHeaders({
			'Authorization': 'Bearer ' + this._accessToken,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'origin': window.location.origin
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient,  private messageService: MessageService) { }

	getFinIndicatorMaster(): Observable<FinIndicatorMaster> {
		return this.http.get<FinIndicatorMaster>(this.fleetAdminService + 'api/common/getAllFinIndicatorMaster', this.options);
	}

	addFinIndicator(FinIndicatorDetailsModel):Observable<FinIndicatorDetailsModel> {
		return this.http.post<FinIndicatorDetailsModel>(this.fleetAdminService + 'api/common/executeFinIndicatorProc', FinIndicatorDetailsModel, this.options);
	}

	deleteFinIndicator(FinIndicatorDetailsModel): Observable<FinIndicatorDetailsModel> {
		return this.http.post<FinIndicatorDetailsModel>(this.fleetAdminService + 'api/common/executeFinIndicatorProc', FinIndicatorDetailsModel, this.options);
	}

	loadFinIndicatorMappingData(fincode, countryCode): Observable<FinCodeWithFinIndDetailApi> {
		let request = {
			"finCode":fincode,
			"countryCode":countryCode
		}
		return this.http.post<FinCodeWithFinIndDetailApi>(this.fleetAdminService + 'api/common/finandindicatordetails',request, this.options);
	}

	updateFinalFINbyCounCodeandFINCode(FINMappingFINCodeModel): Observable<FINMappingFINCodeModel> {
		return this.http.post<FINMappingFINCodeModel>(this.fleetAdminService + 'api/common/executeFinIndicatorMapping' , FINMappingFINCodeModel, this.options);
	}

	getFINnameList(countrycode, finName): Observable<any> {
		let request = {
			"countryCode":countrycode,
			"accountName":finName
		}
		return this.http.post<any>(this.fleetAdminService + 'api/common/finAccountDetails',request , this.options);
	}

	avaFINbyCounCodeandFINIndicator(FINMappingFINCodeModel): Observable<FINMappingFINCodeModel> {
		return this.http.post<FINMappingFINCodeModel>(this.fleetAdminService + 'api/common/getAvailableAndMappedFins' , FINMappingFINCodeModel, this.options);
	}
	getFINcodeList(countrycode, finCodeSearch): Observable<any> {
		let request = {
			"finCode":finCodeSearch,
			"countryCode":countrycode
		}
		return this.http.post<any>(this.fleetAdminService + 'api/common/finAccountDetailsForAdmin' ,request , this.options);
	}

	addMarkedFin(finCode, countrycode, user): Observable<any> {
		let request = {
			"finCode":finCode,
			"country":countrycode,
			"user":user
		}
		return this.http.post<any>(this.fleetAdminService + 'api/common/addFINMarkedByAdmins' , request , this.options);
	}

	getAdminMarkedFinDetails(countrycode, finCode ): Observable<any> {
		let request ={
			"finCode":finCode,
			"countryCode":countrycode
		}
		return this.http.post<any>(this.fleetAdminService + 'api/common/adminMarkedFinDetail',request , this.options);
	}
}

