export class FkcWorkflowRequestInfo {
	authReqNumber: any;
	cdsId: string;
	namCdsId: string;
	reqStatus: string;
	reqComments: string;
	workflowComments: string;
	createdUser: string;
	createDate: Date;
	lastUpdateUser: string;
	lastUpdateDate: Date;
}



