import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {FinCodeModel} from '../models/FinCode.model';
import {FkcKeyCodeDetail} from '../models/FkcKeyCodeDetail';
import {FkcRequestInfo} from '../models/FkcRequestInfo';
import {FinCodeDetailAPI} from '../models/FinCodeDetailAPI';
import {FkcDetailInfo} from '../models/FkcDetailInfo';
import {FkcWorkflowUser} from '../models/FkcWorkflowUser';
import {UserInformationModel} from '../models/UserInformation.model';
import {PersonalIndicatorInfo} from '../models/PersonalIndicatorInfo';
import {AccessCodeModel} from '../models/AccessCode.model';

@Injectable()
export class FkcService {
	_accessToken: string = sessionStorage.getItem('encodedAccessToken');

	fwsUrl = environment.APIEndpoint;
	fleetKeyCode=environment.fleetKeyCode;
	fleetAdminService = environment.fleetAdminService;

	private options = {
		headers: new HttpHeaders({
			'Authorization': 'Bearer ' + this._accessToken,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'origin': window.location.origin
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient) {}
	getFinCode(userId): Observable<FinCodeModel[]> {
		let request = {
			"wslID":userId
		}
		return this.http.post<FinCodeModel[]>(this.fleetKeyCode + 'api/common/fincode' , request, this.options);
	}

	getkeycodeReport(fleetKeyLogApi ): Observable<FkcDetailInfo[]> {
		return this.http.post<FkcDetailInfo[]>(this.fleetKeyCode + 'api/fkc/report/getReport', fleetKeyLogApi, this.options);
	}
	
	getDownloadExcel(fleetKeyLogApi) {
		return this.http.post(this.fleetKeyCode + 'api/fkc/report/exportFkcReportXLS', fleetKeyLogApi, {
			headers: new HttpHeaders({
				'Accept': 'application/octet-stream',
				'Content-type': 'application/json',
				'authorization': 'bearer ' + this._accessToken
			}), withCredentials: true, responseType: 'arraybuffer' as 'json'
		});
	}

	getKeyCodeRetrival(fkcKeyCodeRequestFromCall): Observable<FkcKeyCodeDetail[]> {
		return this.http.post<FkcKeyCodeDetail[]>(this.fleetKeyCode + 'api/v1/fkc/keyCode/retrieveKeyCodesForFin', fkcKeyCodeRequestFromCall, this.options);
	}

	getRequestDetails(reqId): Observable<FkcRequestInfo[]> {
		return this.http.get<FkcRequestInfo[]>(this.fleetKeyCode + 'api/v1/fkc/automatedWorkflow/getrequest/' + reqId, this.options);
	}

	approveOrRejectRequest(fkcAuthReqDetailApi, userType , userNAMName): Observable<FkcRequestInfo[]> {
		return this.http.post<FkcRequestInfo[]>(this.fleetKeyCode + 'api/v1/fkc/automatedWorkflow/updaterequest/' + userType + '/' + userNAMName , fkcAuthReqDetailApi, this.options);
	}

	getFinCodes(userId): Observable<FinCodeDetailAPI[]> {
		let request = {
			"wslID":userId
		}
		return this.http.post<FinCodeDetailAPI[]>(this.fleetKeyCode + 'api/v1/fkc/automatedWorkflow/getFINdetails' ,request, this.options);
	}


	createRequest(fkcAuthReqDetailApi, userType, userName, paCode , marketSegment, userMailId ): Observable<FkcRequestInfo[]> {
		let request ={

			"cdsId": fkcAuthReqDetailApi.cdsId,
			"createdUser": fkcAuthReqDetailApi.createdUser,
			"lastUpdateUser": fkcAuthReqDetailApi.lastUpdateUser,
			"reqComments": fkcAuthReqDetailApi.reqComments,
				"userType":userType,
				"userName":userName,
				"paCode":paCode,
				"userMailId":userMailId
			}
		return this.http.post<FkcRequestInfo[]>(this.fleetKeyCode + 'api/v1/fkc/automatedWorkflow/createrequest', request, this.options);
		// return this.http.post<FkcRequestInfo[]>(this.fleetKeyCode + 'api/v1/fkc/automatedWorkflow/createrequest/' + userType + '/' + userName + '/' + paCode + '/' + marketSegment + '/' + userMailId, fkcAuthReqDetailApi, this.options);
	}

	getRequestWithStatus(cdsId): Observable<FkcRequestInfo[]> {
		let request = {
			"cdsId":cdsId
		}
		return this.http.post<FkcRequestInfo[]>(this.fleetKeyCode + 'api/v1/fkc/automatedWorkflow/getRequestWithStatus' ,request, this.options);
	}

	getUserForWorkFlow(cdsId): Observable<FkcWorkflowUser> {
		let request = {
			"wslID":cdsId
		}
		return this.http.post<FkcWorkflowUser>(this.fleetKeyCode + 'api/v1/fkc/automatedWorkflow/getUserForWorkFlow' ,request, this.options);
	}

	getNamInfo(userId, finCode): Observable<FkcRequestInfo[]> {
		let request = {
			"wslID":userId,
			"finCode":finCode
		}
		return this.http.post<FkcRequestInfo[]>(this.fleetKeyCode + 'api/v1/fkc/automatedWorkflow/naminfoForUser' ,request, this.options);
	}

	getUserInfo(wslID): Observable<UserInformationModel> {
		let request = {
			"wslID":wslID
		}
		return this.http.post<UserInformationModel>(this.fleetKeyCode + 'api/v1/fkc/automatedWorkflow/userinfo' ,request, this.options);
	}


	getMarketSegment(userId): Observable<AccessCodeModel> {
		let request = {
			"userId":userId
		}
		return this.http.post<AccessCodeModel>(this.fleetAdminService + 'api/common/market',request, this.options);
	}

	getRequestWithActiveStatus(cdsId): Observable<FkcRequestInfo[]> {
		let request = {
			"cdsId":cdsId
		}
		return this.http.post<FkcRequestInfo[]>(this.fleetKeyCode + 'api/v1/fkc/automatedWorkflow/getRequestWithActiveStatus' ,request, this.options);
	}

	getMarketSegmentByFin(fincode): Observable<AccessCodeModel> {
		let request = { "finCode":fincode}
		return this.http.post<AccessCodeModel>(this.fleetKeyCode + 'api/common/getMarketSegment' ,request, this.options);
	}
}

