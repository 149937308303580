import {Component, OnInit, ViewEncapsulation } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { DatePipe } from '@angular/common';
import {FimpsService} from '../services/fimps.service';
import {FimpsVehicleDataStatements} from '../models/FimpsVehicleDataStatements';
import {ErrorResponseData} from '../models/ErrorResponseData';
import {ApiError} from '../models/ApiError';

@Component({
	selector: 'app-fim-cpa-vehi-summary',
	templateUrl: './fim-cpa-vehi-summary.component.html',
	styleUrls: ['./fim-cpa-vehi-summary.component.css'],
	// encapsulation: ViewEncapsulation.None
})
export class FimCpaVehiSummaryComponent implements OnInit {
	detailedVehicleSummary: FimpsVehicleDataStatements[];
	accountName: string;
	address1: string;
	city: string;
	state: string;
	postalCode: string;
	sDate: any;
	finCode: any;
	payeeCode: any;
	vinVehicleName: string;
	indexDescription: any;
	summaryDesc: any;
	indexProgramCode: any;
	summaryReportCode: any;
	totalAmount: any;
	footerText: string;
	showSpinner: Boolean = false;
	isrptIVR: Boolean = false;
	isrptPEV: Boolean = false;
	isrptCB: Boolean = false;
	isrptOnly: Boolean = false;
	showFooter = false;
	vcount: any;
	total: any;
	isrptPA: Boolean = false;
	errorResponse: ErrorResponseData;
	reportMsgs = [];
	errorTest: ApiError;
	reportsDataTest: string;
	statementDate = [];
	statementDateDisplay = [];
	userType: string;
	displayMessage: Boolean = false;
	errorMessage: String;
	constructor(public router: Router, public fims: FimpsService, private route: ActivatedRoute, public datepipe: DatePipe) {
	}

	ngOnInit() {
		this.userType = sessionStorage.getItem('userType');
			this.indexDescription =  sessionStorage.getItem('indexDescription');
			this.summaryDesc =  sessionStorage.getItem('summaryDesc');
			this.indexProgramCode = sessionStorage.getItem('indexProgramCode');
			this.summaryReportCode = sessionStorage.getItem('summaryReportCode');
			this.accountName = sessionStorage.getItem('accountName');
			this.address1 = sessionStorage.getItem('address1');
			this.city = sessionStorage.getItem('city');
			this.state = sessionStorage.getItem('state');
			this.postalCode = sessionStorage.getItem('postalCode');
			this.sDate = sessionStorage.getItem('sDate');
			this.finCode = sessionStorage.getItem('finCode');
			this.payeeCode = sessionStorage.getItem('payeeCode').trim();
			this.vinVehicleName = sessionStorage.getItem('vinVehicleName');
			this.vcount = sessionStorage.getItem('vcount');
		if (this.finCode === null || this.finCode === undefined || this.finCode === '') {
			if (this.userType && this.userType === 'Employee') {
				this.router.navigate(['fimps-search']);
			} else {
				this.router.navigate(['fimps-cpa-stmt']);
			}
		}
		this.setFooterText();
		this.loadVehicleDetails();
	}

	setFooterText() {
		this.footerText = '';
		if ( this.summaryReportCode === '10' ) {
			this.footerText = 'TOTAL ' + this.vinVehicleName;
		} else if ( this.summaryReportCode === '15' ) {
			this.footerText = 'TOTAL ' + this.vinVehicleName + ' PRICE PROTECTION VEHICLES';
		} else if ( this.summaryReportCode === '20' ) {
			this.footerText = 'TOTAL ' + this.vinVehicleName + ' POTENTIALLY ELIGIBLE FOR ESP VEHICLES';
		} else if ( this.summaryReportCode === '25' ) {
			let year  = this.indexDescription.substring(0,4);
			if (Number(year) < 2006) {
				if(this.summaryDesc == 'PRICE PROTECTION PAYMENTS') {
					this.footerText = 'TOTAL ' + this.vinVehicleName + ' ELIGIBLE PROGRAM VEHICLES';
				} else {
					this.footerText = 'TOTAL ' + this.vinVehicleName + ' INELIGIBLE PROGRAM VEHICLES';
				}
			} else {
				this.footerText = 'TOTAL ' + this.vinVehicleName + ' ELIGIBLE PROGRAM VEHICLES';
			}
		} else if ( this.summaryReportCode === '45' ) {
			this.footerText = 'TOTAL ' + this.vinVehicleName + ' INELIGIBLE PROGRAM VEHICLES';
		} else if ( this.summaryReportCode === '30' ) {
			this.footerText = 'TOTAL ' + this.vinVehicleName + ' VEHICLES ADJUSTED';
		} else if ( this.summaryReportCode === '35' ) {
			this.footerText = 'TOTAL VEHICLES';
		} else {
			this.footerText = 'No Report Available';
		}
	}

	goToFims() {
		this.router.navigate(['fimps-search']);
	}

	loadVehicleDetails() {
		let formatStatementDate;
		if (this.sDate) {
			formatStatementDate = this.sDate.split(',');
		}
		this.statementDate = [];
		if (formatStatementDate) {
			formatStatementDate.forEach(item => {
				this.statementDate.push(item.toString().trim());
			});
		}

		if (this.summaryReportCode === '25') {
			this.isrptIVR = true;
			this.isrptPEV = false;
			this.isrptCB = false;
			this.isrptOnly = false;
			this.isrptPA = false;
		} else if (this.summaryReportCode === '20') {
			this.isrptIVR = false;
			this.isrptPEV = true;
			this.isrptCB = false;
			this.isrptOnly = false;
			this.isrptPA = false;
		} else if (this.summaryReportCode === '35') {
			this.isrptIVR = false;
			this.isrptPEV = false;
			this.isrptCB = true;
			this.isrptOnly = false;
			this.isrptPA = false;
		}  else if (this.summaryReportCode === '30') {
			this.isrptIVR = false;
			this.isrptPEV = false;
			this.isrptCB = false;
			this.isrptOnly = false;
			this.isrptPA = true;
		}  else {
			this.isrptOnly = true;
			this.isrptIVR = false;
			this.isrptPEV = false;
			this.isrptCB = false;
			this.isrptPA = false;
		}
		this.showSpinner = true;
		if (this.vinVehicleName) {
			this.vinVehicleName = this.vinVehicleName.trim();
		}
		this.fims.getIndividualVehicleReports(this.vinVehicleName, this.finCode, this.payeeCode, this.statementDate, this.indexProgramCode, this.summaryReportCode).subscribe( data => {
			this.detailedVehicleSummary = [];
			this.reportsDataTest = JSON.stringify(data);
			if (data['result'] && data['result']['getFimpsVehicleDataStatements']) {
				this.detailedVehicleSummary = data['result']['getFimpsVehicleDataStatements'];
				if (this.detailedVehicleSummary.length > 0) {
					this.totalAmount = this.detailedVehicleSummary.map(c => c.dataAmount).reduce((sum, current) => sum + current);

					if (this.summaryReportCode === '35') {
						let uniqueVin;
						const vehicleSum = [];
						uniqueVin = this.detailedVehicleSummary.map(item => item.dataVINCode)
							.filter((value, index, self) => self.indexOf(value) === index);

						if (uniqueVin && uniqueVin.length > 0) {
							uniqueVin.forEach(item => {
								vehicleSum.push(this.detailedVehicleSummary.filter(items => items.dataVINCode === item));
							});
							this.total = 0;
							this.detailedVehicleSummary = vehicleSum;

							vehicleSum.forEach(i => {
								if (i) {
									i.forEach(val => {
										if (val.dataAmount < 0) {
											this.total = this.total + 1;
										}
									});
								}
							});
						}
					} else if (this.summaryReportCode === '30') {
						let uniqueVin;
						const vehicleSum = [];
						uniqueVin = this.detailedVehicleSummary.map(item => item.dataVINCode)
							.filter((value, index, self) => self.indexOf(value) === index);

						if (uniqueVin && uniqueVin.length > 0) {
							uniqueVin.forEach(item => {
								vehicleSum.push(this.detailedVehicleSummary.filter(items => items.dataVINCode === item));
							});
							this.detailedVehicleSummary = vehicleSum;
						}
					}
				}

				const evt = {first: 0, rows: 10};
				this.paginate(evt, this.detailedVehicleSummary);
				this.showSpinner = false;
			} else {
				this.showSpinner = false;
				this.errorResponse = new ErrorResponseData(data);
				if (this.errorResponse && this.errorResponse.error && this.errorResponse.error.messages) {
					this.showErrorMsg(this.errorResponse.error.messages[0]);
				}
			}
		},
			(error: ApiError) => {
				this.errorTest = error;
			});
	}

	loadDate(date) {
		if (date === '0001-01-01')  {
			date = '2001-01-01';
		}
		return this.datepipe.transform(date, 'MM/dd/yyyy');
	}

	getTotal(detailedVehicleSummary) {
		if (detailedVehicleSummary.length > 0) {
			return detailedVehicleSummary.map(c => c.dataAmount).reduce((sum, current) => sum + current);
		}
	}

	getTotalForPaReport(detailedVehicleSummary) {
		let val = 0;
		if (detailedVehicleSummary.length > 0) {
			detailedVehicleSummary.forEach( item => {
				if (item) {
						val = val + item.map(c => c.dataAmount).reduce((sum, current) => sum + current);
				}
			});
		}
		return val;
	}

	paginate(event, reportList) {
		this.showFooter = false;
		if (reportList && reportList.length > 0) {
			const page = reportList.length / event.rows;
			const totalNoOfpages = Math.ceil(page);
			if (event.first === ((totalNoOfpages * event.rows) - event.rows)) {
				this.showFooter = true;
			} else {
				this.showFooter = false;
			}
		} else {
			this.showFooter = false;
		}
	}

	showErrorMsg(errorMsg) {
		this.displayMessage = true;
		if (errorMsg) {
			this.errorMessage = errorMsg;
		}
	}

	goToPreviousPage() {
		this.router.navigate(['fimps-cpa-pgm-summary']);
	}

}
