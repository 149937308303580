import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { AccountManagerList } from '../../app/models/NamList.model';
import { PersonEntitlementList } from '../../app/models/PersonEntitlementList.model';

@Injectable()
export class NamDetailService {
	_accessToken: string = sessionStorage.getItem('encodedAccessToken');
	fwsUrl = environment.APIEndpoint;
	userId = sessionStorage.getItem('userId');
	fleetAdminService = environment.fleetAdminService;

	private options = {
		headers: new HttpHeaders({
			'Authorization': 'Bearer ' + this._accessToken,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'origin': window.location.origin
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient) {

	}

	getNAMReport(accountManagerArray): Observable<any> {
		return this.http.post<any>(this.fleetAdminService + 'api/v1/accountManagerPES/searchResult', accountManagerArray, this.options);
	}

	getDownloadExcel(accountManagerArray) {
		const headers = new HttpHeaders({
			'Authorization': 'bearer ' + this._accessToken
		});

		return this.http.post(this.fleetAdminService + 'api/v1/accountManagerPES/downloadExcel', accountManagerArray, {
			headers: headers,
			responseType: 'blob' 
				});
	}

	getPersonalEntitlementMappingPDF() {
		return this.http.get(this.fleetAdminService + 'api/v1/accountManagerPES/getPEMappingPDF', {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + this._accessToken,
				'Accept': 'application/octet-stream',
				'Content-type': 'text/plain'
			}), withCredentials: true, responseType: 'arraybuffer' as 'json' 
		});
	}
	
	getAccountManagerCategoryPDF() {
		return this.http.get(this.fleetAdminService + 'api/v1/accountManagerPES/getAMCategoryPDF', {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + this._accessToken,
				'Accept': 'application/octet-stream',
				'Content-type': 'text/plain'
			}), withCredentials: true, responseType: 'arraybuffer' as 'json'
		 });
	}

	getPersonalEntitlementList(): Observable<PersonEntitlementList> {
		return this.http.get<PersonEntitlementList>(this.fleetAdminService + 'api/v1/personalEntitlementReport/listOfPersonalEntitlement?entityNames=NAFSA', this.options);
	}

	getAccountType(): Observable<any> {
		return this.http.get<any>(this.fleetAdminService + 'api/v1/accountManagerPES/accountManagerTypes', this.options);
	}
	
	getAccountManagerByType(accountManagerArray): Observable<any> {
		return this.http.post<any>(this.fleetAdminService + 'api/v1/accountManagerPES/accountManagersByType',accountManagerArray, this.options);
	}

}