import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ViewEncapsulation} from '@angular/core';
import {FcicadminService} from '../services/fcicadmin.service';

@Component({
	selector: 'app-fcic-admin',
	templateUrl: './access-code.component.html',
	styleUrls: ['./access-code.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class AccessCodeComponent implements OnInit {
	finCode;
	user_id;
	showAccessCode = false;
	totalRecord;
	accessVal;
	AccessCodelList = [];
	accessCodeValue: string;
	accesscode: string;
	errorMessage: string;
	public displaySpinner: Boolean = false;
	public displayMessage: Boolean = false;
	showErrorMsg: Boolean = false;
	showErrorMessgs: Boolean = false;
	@ViewChild('focus1', { static: false }) focus1: ElementRef;

	// @ViewChild('focus1', { static: false }) focus1: ElementRef;
	userId = sessionStorage.getItem('userId');
	public Noauthorized: Boolean = false;
	public showsearchDetails: Boolean = true;
	constructor(private fcicadminService: FcicadminService) {
		if (this.userId) {
			if (this.userId.indexOf('-') !== -1) {
				this.Noauthorized = true;
				this.showsearchDetails = false;
			}
		}
	}

	ngOnInit() {
	}

	getAccessCode() {
		if (this.finCode != null && this.finCode !== '' && this.finCode !== undefined ) {
			this.loadAccessCode(this.finCode);
			this.showErrorMsgs();
			this.user_id = '';
			//this.homefocus = this.focus1;
			this.showAccessCode = true;
		} else {
			this.showErrorMsgs();
			this.totalRecord = 2;
		}
	}
	getUserId() {
		if (this.user_id != null && this.user_id !== '' && this.user_id !== undefined ) {
			window.open('https://wwwedu.dsps.dealerconnection.com/Sps/viewUserProfile.do?UserId=' + this.user_id, '_blank');
			this.reset();
		} else {
			this.showErrorMesgs();
		}
	}
	omit_special_char(event) {
		let k;
		k = event.charCode;  //         k = event.keyCode;  (Both can be used)
		return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
	}


	reset() {
		this.finCode = '';
		this.showAccessCode = false;
		this.showErrorMsg = false;
		this.totalRecord = 2;
	}
	resets() {
		this.user_id = '';
		this.showErrorMessgs = false;
		}

	showErrorMsgs() {
		if (this.finCode != null && this.finCode !== '' && this.finCode !== undefined ) {
			this.showErrorMsg = false;
		} else {
			this.showErrorMsg = true;
		}
	}

	showErrorMesgs() {
		if (this.user_id != null && this.user_id !== '' && this.user_id !== undefined ) {
			this.showErrorMessgs = false;
		} else {
			this.showErrorMessgs = true;
		}
	}

	loadAccessCode(value) {
		this.displaySpinner = true;
		this.fcicadminService.getAccessCodeSec(value.toUpperCase()).subscribe(data => {
			this.displaySpinner = false;
			//this.accessresultsetTest = data;

			if (data['result'] === null ||  data['result']['finCode'].length === 0) {
				this.totalRecord = 0;
				this.accessCodeValue = value.toString();
			} else {
				this.accesscode = data['result']['finCode'][0]['accesscode'];
				this.accessCodeValue = value.toString();
				this.AccessCodelList = [];
				this.AccessCodelList.push(data);
				this.totalRecord = 1;
				if (this.accesscode) {
					const accessCodeList = this.accesscode.split('');
					this.accessVal = '';
					accessCodeList.forEach(item => {
						if (/\d+/.exec(item)) {
							this.accessVal += '<span class="numeric-color"><b>' + item + '</b></span>';
						} else {
							this.accessVal += '<span class="alpha-color"><b>' + item + '</b></span>';
						}
					});
					this.accessVal = '<span><b>Access Code for ' + value.toUpperCase() + ' is </b></span>' + this.accessVal;
				}
			}
			//this.showSpinner = false;
		});
	}

	clear() {
	}

	popupclose() {
		this.displayMessage = false;
		this.finCode = '';
		setTimeout(() => {
			if (this.focus1 !== undefined) {
				this.focus1.nativeElement.focus();
			}
		}, 200);
	}
}
