import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class loginService{
  tokenExp: number;



  constructor(private router: Router) {}

  parseSessionStorage() {
    if (sessionStorage.length > 0) {
      this.tokenExp = sessionStorage['tokenExp'];
    }
  }

  isTokenExpired(): boolean {
    const epoch = Math.trunc(new Date().getTime() / 1000);

    if (!this.tokenExp) { return true; }
    return (epoch < this.tokenExp) ? false : true;
  }

  checkLogin(): boolean {
    this.parseSessionStorage();

    if (this.isTokenExpired()) {
      sessionStorage['tokenExp'] = null;
      sessionStorage['encodedIdToken'] = null;
      sessionStorage['encodedAccessToken'] = null;
      sessionStorage['personalIndicatorList'] = null;
      return false;
    }
    return true;
  }
}
