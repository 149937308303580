import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {NavComponent} from './nav/nav.component';
import { MenubarModule } from 'primeng/menubar';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { PickListModule } from 'primeng/picklist';
import { AutoCompleteModule } from 'primeng/autocomplete';

// import { GrowlModule } from 'primeng/growl';
import {MessageService} from 'primeng/api';
import {AppRoutingModule} from './app-routing.module';
import { BatchOasisReportComponent } from './batch-oasis-report/batch-oasis-report.component';
import { FimCpaStmtComponent } from './fim-cpa-stmt/fim-cpa-stmt.component';
import { FcicAdminComponent } from './fcic-admin/fcic-admin.component';
import { RipsComponent } from './rips/rips.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import {FormsModule} from '@angular/forms';
import {FimpsService} from './services/fimps.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { DatePipe } from '@angular/common';
import {TableModule} from 'primeng/table';
import {FimDetailCpaStmtComponent} from './fim-detail-cpa-stmt/fim-detail-cpa-stmt.component';
import {FimMonthlyReportComponent} from './fim-monthly-report/fim-monthly-report.component';
import {FimCpaPgmSummaryComponent} from './fim-cpa-pgm-summary/fim-cpa-pgm-summary.component';
import {FimCpaVehiSummaryComponent} from './fim-cpa-vehi-summary/fim-cpa-vehi-summary.component';
import {FimStmtDefComponent} from './fim-stmt-def/fim-stmt-def.component';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RipsStatementReportComponent} from './rips-stmt-report/rips-stmt-report.component';
import {PanelModule} from 'primeng/panel';
import {CpaLookupComponent} from './cpa-lookup/cpa-lookup.component';
import {CheckboxModule} from 'primeng/checkbox';
import {CpaLookupSearchresultComponent} from './cpa-lookup-searchresult/cpa-lookup-searchresult.component';
import {FkcKeycodeComponent} from './fkc-keycode/fkc-keycode.component';
import {FkcKeycodeReportComponent} from './fkc-keycode-report/fkc-keycode-report.component';
import {CalendarModule} from 'primeng/calendar';
import {HttpErrorInterceptor} from './services/httpErrorInterceptor';
import {ConfirmationService} from 'primeng/api';
import {AlphaNumericDirective} from './directives/alphaNumeric';
import {OAuthCallbackComponent} from './oauth-callback.component';
import {LoginModule} from './login/login.module';
import {LoginRoutingModule} from './login/login-routing.module';
import {DialogModule} from 'primeng/dialog';
import {FcicadminService} from './services/fcicadmin.service';
import {BlockUIModule} from 'primeng/blockui';
import {NfppAdminComponent} from './nfpp-admin/nfpp-admin.component';
import {RipsService} from './services/rips.service';
import {CpaLookupService} from './services/cpa-lookup.service';
import {FimsSearchComponent} from './fimps-search/fimps-search.component';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {AuthGuardService} from './auth-guard.service';
import {FkcService} from './services/fkc.service';
import {ValidateFinCodeDirective} from './directives/validateFinCode';
import {CommonService} from './services/common.service';
import {NfppAdminService} from './services/nfppadmin.service';
import {BatchOasisService} from './services/batchOasis.service';
import {FkcHomepageComponent} from './fkc-home/fkc-homepage.component';
import {LevelsOfApprovalPageComponent} from './levelsOfAuthorization/levels-of-approval-page.component';
import {FkcRequestApprovalComponent} from './fkc-request-approval/fkc-request-approval.component';
import {RipsReportComponent} from './rips-reports/rips-reports.component';
import {loginService} from './login.service';
import {NumericDirective} from './directives/numeric';
import {FleetAdminComponent} from './fleet-admin/fleet-admin.component';
import { FilterPipe} from './services/filter.pipe';
import {RipsSearchComponent} from './rips-search/rips-search.component';
import {NumericSymbolDirective} from './directives/numericSymbol';
import { AppCalendarComponent } from './app-calendar/app-calendar.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ListboxModule} from 'primeng/listbox';
import {OnlyAlphabetsDirective} from './directives/onlyAlphabets';
import {AlphaNumericSymbolDirective} from './directives/alphaNumericSymbol';
import {MsgMaintComponent} from './msg-maint/msg-maint.component';
import { CpaMsgMaintComponent } from './cpa-msg-maint/cpa-msg-maint.component';
import {AccessCodeComponent} from './access-code/access-code.component';
import {FinIndicatorComponent} from './fin-indicator/fin-indicator.component';
import { FinMasterComponent } from './fin-master/fin-master.component';
//import { MarketSegmentComponent } from './market-segment/market-segment.component';
import { CookieHomeComponent } from './cookie-home/cookie-home.component';
import { PtsFileUploadComponent } from './pts-file-upload/pts-file-upload.component';
import { ToastModule } from 'primeng/toast';
import {FinIndicatorListComponent} from './fin-indicator-list/fin-indicator-list.component';
import { UserCookieHomeComponent } from './user-cookie-home/user-cookie-home.component';
import {FinAdminService} from './services/finadmin.service';
// import { UserIdleModule } from 'angular-user-idle';
import {FinMarkedListComponent} from './marked-fin-list/marked-fin-list.component';
import {PersonalEntitlementListComponent} from './personal-entitlement-list/personal-entitlement-list.component';
import {PersonalEntitlementService} from './services/personal-entitlement.service';
import { NamDetailsComponent } from './nam-details/nam-details.component';
import { NamDetailService } from './services/namDetails.service';
import { EntitlementListComponent } from './entittlements-list/entitlement-list.component';
import {FinEntitilementListComponent} from './fin-entitlement-list/fin-entitilement-list.component';
import {FinIndicatorService} from './services/finIndicator.service';
import {ConsolidatedReportComponent} from './consolidated-report/consolidated-report.component';
import {ConsolidatedreportService} from './services/consolidatedreport.service';
import { FinNamDetailsComponent } from './fin-nam-details/fin-nam-details.component';
import { FinNamDetailService } from './services/finNamDetails.service';
import { SummaryNamDetailsComponent } from './summary-nam-details/summary-nam-details.component';
import { FleetAuditReportComponent } from './fleet-audit-report/fleet-audit-report.component';

@NgModule({
	declarations: [
		AppComponent,
		NavComponent,
		BatchOasisReportComponent,
		FimCpaStmtComponent,
		FcicAdminComponent,
		RipsComponent,
		UpdateProfileComponent,
		FimDetailCpaStmtComponent,
		FimMonthlyReportComponent,
		FimCpaPgmSummaryComponent,
		FimCpaVehiSummaryComponent,
		FimStmtDefComponent,
		RipsStatementReportComponent,
		CpaLookupComponent,
		CpaLookupSearchresultComponent,
		FkcKeycodeComponent,
		FkcKeycodeReportComponent,
		AlphaNumericDirective,
		OAuthCallbackComponent,
		NfppAdminComponent,
		FimsSearchComponent,
		UnauthorizedComponent,
		ValidateFinCodeDirective,
		FkcHomepageComponent,
		LevelsOfApprovalPageComponent,
		FkcRequestApprovalComponent,
		RipsReportComponent,
		NumericDirective,
		FleetAdminComponent,
		FilterPipe,
		RipsSearchComponent,
		NumericSymbolDirective,
		AppCalendarComponent,
		NumericSymbolDirective,
		DashboardComponent,
		OnlyAlphabetsDirective,
		AlphaNumericSymbolDirective,
		MsgMaintComponent,
		CpaMsgMaintComponent,
		AccessCodeComponent,
		FinIndicatorComponent,
		FinMasterComponent,
		//MarketSegmentComponent,
		CookieHomeComponent,
		PtsFileUploadComponent,
		FinIndicatorListComponent,
		UserCookieHomeComponent,
		CpaMsgMaintComponent,
		PersonalEntitlementListComponent,
		NamDetailsComponent,
		EntitlementListComponent,
		FinEntitilementListComponent,
		ConsolidatedReportComponent,
		FinNamDetailsComponent,
		SummaryNamDetailsComponent,
		FleetAuditReportComponent,
		FinMarkedListComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		AppRoutingModule,
		MenubarModule,
		// GrowlModule,
		SidebarModule,
		DropdownModule,
		FormsModule,
		MultiSelectModule,
		ProgressSpinnerModule,
		HttpClientModule,
		ButtonModule,
		TableModule,
		RadioButtonModule,
		InputTextareaModule,
		TooltipModule,
		PanelModule,
		CheckboxModule,
		CalendarModule,
		LoginModule,
		LoginRoutingModule,
		DialogModule,
		ConfirmDialogModule,
		BlockUIModule,
		ListboxModule,
		FileUploadModule,
		PickListModule,
		FileUploadModule,
		ToastModule,
		AutoCompleteModule
		// UserIdleModule.forRoot({idle: 120, timeout: 1680, ping: 60})
	],
	providers: [
		MessageService,
		FimpsService,
		ConfirmationService,
		DatePipe,
		FcicadminService,
		RipsService,
		CpaLookupService,
		AuthGuardService,
		FkcService,
		CommonService,
		NfppAdminService,
		BatchOasisService,
		PersonalEntitlementService,
		NamDetailService,
		FinIndicatorService,
		ConsolidatedreportService,
		FinNamDetailService,
		loginService,
		FinAdminService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true
		}],
	bootstrap: [AppComponent]
})
export class AppModule {
}
