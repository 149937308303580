import {Component, HostListener, OnInit, VERSION} from '@angular/core';
import {environment} from '../environments/environment';
// import { UserIdleService } from 'angular-user-idle';
import {NavigationEnd, Router} from '@angular/router';
import {MenuItem} from 'primeng/api';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
	demoProp: string;
	hamMenu: boolean;
	version = VERSION.full;
	envProd = environment.production;
	reportMsgs = [];
	display: boolean = false;
	errorMsg;
	userId;
	showNavBar = false;
	navBarListValue: MenuItem[];
	CurrentDate = new Date();
	constructor(private router: Router
		// ,private userIdle: UserIdleService
		) {
	}

	@HostListener('click', ['$event.target']) onClick(btn) {
		localStorage.setItem('logout-reset', 'true');
		this.restart();
	}

	@HostListener('window:resize', ['$event']) onResize(event?) {
		if (event.target.innerWidth > 767) {
			this.hamMenu = false;
		}
	}

	@HostListener('window:storage', ['$event']) onStorageChange(event: StorageEvent) {
		if (event.key == 'logout-reset') {
			this.restart();
		} else if (event.key == 'logout-event') {
			sessionStorage.clear();
			sessionStorage.setItem('isAuth', 'false');
			window.open(environment.logoutURL, '_self');
		}
	}

	ngOnInit() {
		this.navBarListValue = [];
		setTimeout(() => {
			this.userId = sessionStorage.getItem('userId');
		}, 100);
		// this.userIdle.startWatching();
		// Start watching when user idle is starting.
		// this.userIdle.onTimerStart().subscribe(count => { console.log('onTimerStart') });
		// Start watch when time is up.
		//this.userIdle.onTimeout().subscribe(() => { localStorage.setItem('logout-event', 'logout' + Math.random()) });
		// this.userIdle.onTimeout().subscribe(() => { this.logout();});

		this.router.events.subscribe((e) => {
			if (e instanceof NavigationEnd) {
				if(e.url === '/fcic-admin'|| e.url === '/cookie-home'){
					this.showNavBar = true;
					this.navBarListValue = [
						{
							label: 'Fleet User Admin',
							routerLink: '/fcic-admin'
						},
						{
							label: 'Admin User Cookies',
							routerLink: '/cookie-home'
						}
					];
				} else if(e.url === '/fin-access-security-update' || e.url === '/fin-admin' || e.url === '/pacode-upload' || e.url === '/fin-access-security-master' || e.url === '/fin-marked-list'){
					this.showNavBar = true;
					this.navBarListValue = [
						{
							label: 'FIN Code Master',
							routerLink: '/fin-admin'
						},
						{
							label: 'FIN Access Security Master',
							routerLink: '/fin-access-security-master'
						},
						{
							label: 'FIN Access Security Update',
							routerLink: '/fin-access-security-update'
						},
						{
							label: 'PACode Upload',
							routerLink: '/pacode-upload'
						},
						{
							label: 'SPS Activated FIN By Admin',
							routerLink: '/fin-marked-list'
						}
					];
				} else {
					this.showNavBar = false;
				}
			}
		});
	}

	restart() {
		// this.userIdle.resetTimer();
	}

	logout() {
		localStorage.setItem('logout-event', 'logout' + Math.random());
		let logoutUrl = environment.logoutURL + sessionStorage.getItem('redirectURL');
		sessionStorage.removeItem('encodedIdToken');
		sessionStorage.removeItem('encodedAccessToken');
		sessionStorage.removeItem('tokenExp');
		sessionStorage.removeItem('userType');
		sessionStorage.removeItem('userId');
		sessionStorage.removeItem('siteCode');
		sessionStorage.removeItem('userFName');
		sessionStorage.removeItem('userLName');
		sessionStorage.removeItem('userEmail');
		sessionStorage.removeItem('redirectURL');
		sessionStorage.removeItem('previousUrl');
		localStorage.clear();
		sessionStorage.setItem('isAuth', 'false');
		window.open(logoutUrl, '_self');

	}

}
