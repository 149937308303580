import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {CommonService} from './services/common.service';
import {PersonalIndicatorConstants} from './common/personalindicator.constants';
import { Observable } from 'rxjs';
import {loginService} from './login.service';
import {PersonalIndicatorInfo} from './models/PersonalIndicatorInfo';
import {FinIndicatorInfo} from './models/FinIndicatorInfo';
import {environment} from '../environments/environment';

@Injectable()
export class AuthGuardService implements CanActivate {
	tokenExp: number;
	public personalIndicatorList: PersonalIndicatorInfo[];
	public finIndicatorList: FinIndicatorInfo[];
	fordUserType = sessionStorage.getItem('userType');
	userId = sessionStorage.getItem('userId');
	constructor(private router: Router, private common: CommonService, public login: loginService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		sessionStorage.setItem('previousUrl', document.referrer);
		sessionStorage.setItem('redirectURL', state.url);
		if(sessionStorage.getItem('isAuth') === 'false') {
			sessionStorage.clear();
			localStorage.clear();
			sessionStorage.setItem('isAuth', 'true');
			sessionStorage.setItem('redirectURL', state.url);
			let logoutUrl = environment.logoutURL + sessionStorage.getItem('redirectURL');
			window.open(logoutUrl, '_self');
		}
		return Observable.create(observer => {
			if (!this.login.checkLogin()) {
				this.router.navigate(['/login']);
				return observer.next(true);
			} else {
				if ((state.url.indexOf('fim') > -1) || (state.url.indexOf('nfpp') > -1) ||  (state.url.indexOf('cpa') > -1)  || (state.url.indexOf('rips') > -1) || (state.url.indexOf('batch') > -1) || (state.url.indexOf('fkc-keycode') > -1) || (state.url.indexOf('fkc-keycode-reports') > -1) || (state.url.indexOf('fkc-request') > -1) ||(state.url.indexOf('cpa-msg-maint') > -1) || (state.url.indexOf('msg-maint') > -1)
					|| (state.url.indexOf('user-cookie-home') > -1) || (state.url.indexOf('cookie-home') > -1) || (state.url.indexOf('fin-access') > -1 ) || (state.url.indexOf('fin-admin') > -1 )
						|| (state.url.indexOf('pacode-upload') > -1) || (state.url.indexOf('fin-marked-list') > -1) || (state.url.indexOf('fin-code-master') > -1) || state.url.indexOf('personal-entitlement-list') > -1 
					||  state.url.indexOf('nam-details') > -1 || state.url.indexOf('entitlement-list') > -1 || state.url.indexOf('fin-entitlement') > -1 ||
							state.url.indexOf('consolidated-report') > -1 || state.url.indexOf('fin-nam-details') > -1 ||
							state.url.indexOf('summary-nam-details') > -1 || state.url.indexOf('fleet-audit-report') > -1) {
					this.common.getPersonalIndicatorInfo().subscribe(data => {
						if (data['result']['personalIndicator'] && data['result']['personalIndicator'].length > 0) {
							this.personalIndicatorList = data['result']['personalIndicator'];
							if (this.personalIndicatorList.length > 0) {
								if (state.url.indexOf('fim') > -1) {
									if (this.personalIndicatorList.find(item => item && item.personCode === PersonalIndicatorConstants.fimpsPersonalIndicator)) {
										return observer.next(true);
									} else {
										this.router.navigate(['unauthorized']);
										return observer.next(false);
									}
								}  else if ((state.url.indexOf('user-cookie-home') > -1)) {
									if (this.fordUserType === 'Employee' || this.fordUserType === 'Other' || this.fordUserType === 'Dealer') {
										return observer.next(true);
									} else {
										this.router.navigate(['unauthorized']);
										return observer.next(false);
									}
								} else if (state.url.indexOf('cookie-home') > -1) {
									if (this.fordUserType === 'Employee') {
										return observer.next(true);
									} else {
										this.router.navigate(['unauthorized']);
										return observer.next(false);
									}
								} else if (state.url.indexOf('rips') > -1) {
									if (this.personalIndicatorList.find(item => item && item.personCode === PersonalIndicatorConstants.ripsPersonalIndicator)) {
										return observer.next(true);
									} else {
										this.router.navigate(['unauthorized']);
										return observer.next(false);
									}
								}else if (state.url.indexOf('cpa-msg-maint') > -1) {
									if (this.personalIndicatorList.find(item => item && item.personCode === PersonalIndicatorConstants.cpaPersonalIndicator)) {
										return observer.next(true);
									} else {
										this.router.navigate(['unauthorized']);
										return observer.next(false);
									}
								}else if (state.url.indexOf('msg-maint') > -1) {
									if (this.personalIndicatorList.find(item => item && item.personCode === PersonalIndicatorConstants.fimpsMsgPersonalIndicator)) {
										return observer.next(true);
									} else {
										this.router.navigate(['unauthorized']);
										return observer.next(false);
									}
								} else if (state.url.indexOf('batch') > -1) {
									if (this.personalIndicatorList.find(item => item && item.personCode.toUpperCase() === PersonalIndicatorConstants.batchOasisPersonalIndicator.toUpperCase())) {
										return observer.next(true);
									} else {
										this.router.navigate(['unauthorized']);
										return observer.next(false);
									}
								} else if (state.url.indexOf('nfpp') > -1) {
									if (this.personalIndicatorList.find(item => item && item.personCode.toUpperCase() === PersonalIndicatorConstants.nfppAdminPersonalIndicator.toUpperCase())) {
										return observer.next(true);
									} else {
										this.router.navigate(['unauthorized']);
										return observer.next(false);
									}
								} else if ((state.url.indexOf('fkc-keycode') > -1) || (state.url.indexOf('fkc-keycode-reports') > -1)) {
									if ((this.fordUserType === 'Employee' || this.fordUserType === 'Other') && this.personalIndicatorList.find(item => item && item.personCode.toUpperCase() === PersonalIndicatorConstants.fkcinternalOrFleetPersonalIndicator.toUpperCase())) {
										return observer.next(true);
									} else if ((this.fordUserType === 'Dealer' ) && this.personalIndicatorList.find(item => item && item.personCode.toUpperCase() === PersonalIndicatorConstants.fkcidelarePaPersonalIndicator.toUpperCase())) {
										return observer.next(true);
									} else {
										this.router.navigate(['unauthorized']);
										return observer.next(false);
									}
								} else if ((state.url.indexOf('fkc-request') > -1)) {
									if ((this.fordUserType === 'Employee' || this.fordUserType === 'Other') && this.personalIndicatorList.find(item => item && item.personCode.toUpperCase() === PersonalIndicatorConstants.fkcinternalOrFleetPersonalIndicator.toUpperCase())) {
										this.router.navigate(['unauthorized']);
										return observer.next(false);
									} else if (this.fordUserType === 'Dealer' ) {
										//this.router.navigate(['unauthorized']);
										this.router.navigate(['/unauthorized'], {queryParams: {isDealerRequest: true}});
										return observer.next(false);
									} else {
										return observer.next(true);
									}
								}  else if (state.url.indexOf('fin-admin') > -1 ||  state.url.indexOf('fin-access') > -1 || state.url.indexOf('pacode-upload') > -1 || state.url.indexOf('fin-code-master') > -1 || (state.url.indexOf('fin-marked-list') > -1)) {
									if (this.fordUserType === 'Employee' && this.personalIndicatorList.find(item => item && item.personCode.toUpperCase() === PersonalIndicatorConstants.fsaFINAdmin.toUpperCase())) {
										return observer.next(true);
									} else {
										this.router.navigate(['unauthorized'], {queryParams: {isFINAdmin: true}});
										return observer.next(false);
									}
								}
								else if (state.url.indexOf('personal-entitlement-list') > -1 ||  state.url.indexOf('nam-details') > -1 || 
									state.url.indexOf('entitlement-list') > -1 || state.url.indexOf('fin-entitlement') > -1 ||
									state.url.indexOf('consolidated-report') > -1 || state.url.indexOf('fin-nam-details') > -1 ||
									state.url.indexOf('summary-nam-details') > -1 || state.url.indexOf('fleet-audit-report') > -1) {
								if (this.personalIndicatorList.find(item => item && item.personCode.toUpperCase() === PersonalIndicatorConstants.fsaFltPersonalIndicator.toUpperCase())) {
									return observer.next(true);
								} else {
									this.router.navigate(['unauthorized']);
									return observer.next(false);
								}
							}

								else if (state.url.indexOf('cpa') > -1) {
									if ((this.fordUserType === 'Employee') && this.personalIndicatorList.find(item => item && item.personCode.toUpperCase() === PersonalIndicatorConstants.cpainternalOrFleetPersonalIndicator.toUpperCase())) {
										return observer.next(true);
									} else if ((this.fordUserType === 'Dealer')) {
										return observer.next(true);
									} else {
										if (this.fordUserType === 'Other') {
											this.common.getMarketSegment(this.userId).subscribe( result => {
												this.common.getFinIndicatorInfo(this.userId).subscribe( dataset => {
													this.finIndicatorList = dataset['result']['finIndicator'];
													if ((this.finIndicatorList.length > 0 && this.finIndicatorList.find(item => item && item.finIndicator.toUpperCase() === PersonalIndicatorConstants.cpalookupFinIndicator.toUpperCase()))  && (result['result'] && result['result']['marketSegment'].find(seg => seg && seg.marketSegment === 'L'))) {
														return observer.next(true);
													} else {
														this.router.navigate(['/unauthorized'], {queryParams: {otherUnAuthMsg: true}});
														return observer.next(false);
													}
												});
											});
										} else {
											this.router.navigate(['/unauthorized'], {queryParams: {clpaUnAuthMsg: true}});
											return observer.next(false);
										}
									}
								}
							}  else {
								if ((state.url.indexOf('fkc-request') > -1)) {
									if ((this.fordUserType === 'Dealer')) {
										this.router.navigate(['/unauthorized'], {queryParams: {isDealerRequest: true}});
										return observer.next(false);
									} else {
										return observer.next(true);
									}
								} else if (state.url.indexOf('cpa') > -1) {
									if ((this.fordUserType === 'Dealer')) {
										return observer.next(true);
									} else {
										if (this.fordUserType === 'Other') {
											this.common.getMarketSegment(this.userId).subscribe( result => {
												this.common.getFinIndicatorInfo(this.userId).subscribe( dataset => {
													this.finIndicatorList = dataset['result']['finIndicator'];
													if ((this.finIndicatorList.length > 0 && this.finIndicatorList.find(item => item && item.finIndicator.toUpperCase() === PersonalIndicatorConstants.cpalookupFinIndicator.toUpperCase()))  && (result['result'] && result['result']['marketSegment'].find(seg => seg && seg.marketSegment === 'L'))) {
														return observer.next(true);
													} else {
														this.router.navigate(['/unauthorized'], {queryParams: {otherUnAuthMsg: true}});
														return observer.next(false);
													}
												});
											});
										} else {
											this.router.navigate(['/unauthorized'], {queryParams: {clpaUnAuthMsg: true}});
											return observer.next(false);
										}
									}
								} else {
									this.router.navigate(['unauthorized']);
									return observer.next(false);
								}
							}
						}  else {
							if ((state.url.indexOf('fkc-request') > -1)) {
								if ((this.fordUserType === 'Dealer')) {
									this.router.navigate(['/unauthorized'], {queryParams: {isDealerRequest: true}});
									return observer.next(false);
								} else {
									return observer.next(true);
								}
							} else if ((state.url.indexOf('user-cookie-home') > -1)) {
									if (this.fordUserType === 'Employee' || this.fordUserType === 'Other' || this.fordUserType === 'Dealer') {
										return observer.next(true);
									} else {
										this.router.navigate(['unauthorized']);
										return observer.next(false);
									}
							} else if (state.url.indexOf('cookie-home') > -1) {
								if (this.fordUserType === 'Employee') {
									return observer.next(true);
								} else {
									this.router.navigate(['unauthorized']);
									return observer.next(false);
								}
							} else if (state.url.indexOf('cpa') > -1) {
								if ((this.fordUserType === 'Dealer')) {
									return observer.next(true);
								} else {
									if (this.fordUserType === 'Other') {
										this.common.getMarketSegment(this.userId).subscribe( result => {
											this.common.getFinIndicatorInfo(this.userId).subscribe( dataset => {
												this.finIndicatorList = dataset['result']['finIndicator'];
												if ((this.finIndicatorList.length > 0 && this.finIndicatorList.find(item => item && item.finIndicator.toUpperCase() === PersonalIndicatorConstants.cpalookupFinIndicator.toUpperCase()))  && (result['result'] && result['result']['marketSegment'][0]['marketSegment'] === 'L')) {
													return observer.next(true);
												} else {
													this.router.navigate(['/unauthorized'], {queryParams: {otherUnAuthMsg: true}});
													return observer.next(false);
												}
											});
										});
									} else {
										this.router.navigate(['/unauthorized'], {queryParams: {clpaUnAuthMsg: true}});
										return observer.next(false);
									}
								}
							} else {
								this.router.navigate(['unauthorized']);
								return observer.next(false);
							}
						}
					}, err => {
						console.log(err);
						this.router.navigate(['/unauthorized'], {queryParams: {isError: false}});
						return observer.next(false);
					});
				} else {
						return observer.next(true);
				}
			}
		});
	}
}
