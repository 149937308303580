import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserInformationModel} from '../models/UserInformation.model';
import {FincodeDetailsModel} from '../models/FincodeDetails.model';
import {FcicadminService} from '../services/fcicadmin.service';
import {MessageService} from 'primeng/api';
import {FinCodeModel} from '../models/FinCode.model';
import {StateModel} from '../models/State.model';
import {ConfirmationService} from 'primeng/api';
import {Message} from 'primeng/api';
import {NgForm} from '@angular/forms';
import {PersonalIndicatorConstants} from '../common/personalindicator.constants';
import {ApiError} from '../models/ApiError';
import {AccessCodeModel} from '../models/AccessCode.model';
import {TooltipModule} from 'primeng/tooltip';
import { FilterPipe} from '../services/filter.pipe';
import {ViewEncapsulation} from '@angular/core';
import {CommonService} from '../services/common.service';
import {environment} from '../../environments/environment';

@Component({
	selector: 'fcic-admin',
	templateUrl: './fcic-admin.component.html',
	styleUrls: ['./fcic-admin.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class FcicAdminComponent implements OnInit {

	reportMsgs: Message[] = [];
	public showAccessCodeDetails: boolean = false;
	public showWslDetails: boolean = false;
	public showByFin: boolean = false;
	public showsearchDetails: boolean = true;
	showSpinner: boolean = false;
	salutationDropdown = [];
	countryDropdown = [];
	stateDropdown = [];
	selectedType;
	AccessCodelList = [];
	accessCodeValue: string;
	accesscode: string;
	totalRecord: number;
	UserInformation: UserInformationModel;
	wslID: string;
	FirstcustomerName: string;
	MiddlecustomerName: string;
	LastcustomerName: string;
	customerEmailAddressText: string;
	customerPhoneNumber: string;
	customerFaxNumber: string;
	customerTitle: string;
	Mail: string;
	Continued: string;
	City: string;
	State: string;
	Postal: string;
	salutationDropdownValue: string;
	countryDropdownValue: string;
	updateID: string;
	organizationalUnit: string;
	public finresultset: FinCodeModel[];
	public stateresultset: StateModel[];

	finCodes;
	fincode: string;
	headerValue: string;
	fincodeDetailsModel: FincodeDetailsModel;
	display: boolean = false;
	updatedBy: string;
	status: string;
	countryId: string;
	confirmmsg: string;
	summarys: string;
	summarydetail: string;
	formSubmitted = false;
	showNotAvailable = false;
	inValidUser: string;
	showFinNotAvailable = false;
	isAlreadyAvailable = false;
	fincodeDialog;
	isAdmin = false;
	isFinValid = false;
	allFinCodes: string;
	personCode: string;
	personStatus: string;
	fincodeStatus: string;
	stateresultsetTest: StateModel[];
	userresultsetTest: string;
	errorTest: ApiError;
	finCodesTest: FinCodeModel[];
	accessresultsetTest: AccessCodeModel;
	accessVal: string;
	usershow: string;
	displayUserId: string;
	fincodecol: boolean = true;
	searchText: string;
	public Noauthorized: boolean = false;

	accessvalue: string;
	userbyvalue: string;
	finvalue: string;
	homefocus: ElementRef;
	errorMessage: string;
	public displayMessage: boolean = false;
	public USFin = false;
	public CANFin = false;
	isFirstNameInValid: boolean = false;
	isLastNameInValid: boolean = false;
	isMiddleNameInValid: boolean = false;
	isPhoneNoInValid: boolean = false;
	isFaxInValid: boolean = false;
	isUSZipcodeInValid: boolean = false;
	isCanZipcodeInValid: boolean = false;

	spsEndpointUser: string;
	spsEndpoint: string;

	@ViewChild('focus',{static:false}) focus: ElementRef;
	@ViewChild('focus1',{static:false}) focus1: ElementRef;
	@ViewChild('focus2',{static:false}) focus2: ElementRef;
	@ViewChild('focus3',{static:false}) focus3: ElementRef;
	@ViewChild('focus5',{static:false}) focus5: ElementRef;

/*	showDialog() {
		this.display = true;
		this.fincode = '';
		this.headerValue = 'Add a FIN';
		this.isAlreadyAvailable = false;
		setTimeout(() => {
			if (this.focus !== undefined)
				this.focus.nativeElement.focus();
		}, 200);
	}*/

	@ViewChild('fcicAdmin',{static:false}) fcicAdmin: NgForm;
	userId = sessionStorage.getItem('userId');

	//userId = 't-tes331';

	constructor(private fcicadminService: FcicadminService, private messageService: MessageService, private confirmationService: ConfirmationService) {
		if (this.userId) {
			if (this.userId.indexOf('-') !== -1) {
				this.Noauthorized = true;
				this.showsearchDetails = false;
			}
		}

		this.updatedBy = this.userId;
		this.isAdmin = false;

		this.spsEndpointUser = environment.spsEndpointUser;
		this.spsEndpoint = environment.spsEndpoint;
	}

	onkeypressMethod() {
		this.showByFin = false;
	}

	ngOnInit() {
		this.formSubmitted = false;

		setTimeout(() => {
			if (this.focus1 !== undefined)
				this.focus1.nativeElement.focus();
		}, 200);

		this.getPersonalIndication();
	}

	getPersonalIndication(): Boolean {
		this.isAdmin = false;
		this.fcicadminService.getPersonalindicator(this.userId).subscribe(data => {
			data['result']['personalIndicator'].forEach(item => {
				// if ((item['personCode'] === PersonalIndicatorConstants.fcicPersonalIndicator) && (item['personStatus'] === PersonalIndicatorConstants.fcicPersonalIndicatorStatus)) {
				if ((item['personCode'] === PersonalIndicatorConstants.fcicPersonalIndicator)) {
						this.isAdmin = true;
				}
			});
		});
		return this.isAdmin;
	}

/*	loadSalutation() {
		let optionModelSalutation = [];
		optionModelSalutation.push({'label': 'Mr.', 'value': '1'});
		optionModelSalutation.push({'label': 'Mrs.', 'value': '2'});
		optionModelSalutation.push({'label': 'Ms.', 'value': '3'});
		this.salutationDropdown = optionModelSalutation;
	}*/


/*	loadCountry() {
		let optionModelCountry = [];
		optionModelCountry.push({'label': '-- Countries --', 'value': ''});
		optionModelCountry.push({'label': 'USA', 'value': 'USA'});
		optionModelCountry.push({'label': 'CAN', 'value': 'CAN'});
		this.countryDropdown = optionModelCountry;
	}*/

	showErrorMsg(errorMsg) {
		this.displayMessage = true;
		this.errorMessage = errorMsg;
	}

	/*loadState(country) {
			let optionModelState = [];
			if (country === 'CAN') {
				this.USFin = false;
				this.CANFin = true;
				optionModelState.push({'label': '-- Provinces --', 'value': ''});
				optionModelState.push({'label': 'AB - Alberta', 'value': 'AB'});
				optionModelState.push({'label': 'BC - British Columbia', 'value': 'BC'});
				optionModelState.push({'label': 'MB - Manitoba', 'value': 'MB'});
				optionModelState.push({'label': 'NB - New Brunswick', 'value': 'NB'});
				optionModelState.push({'label': 'NF - Newfoundland and Labrador', 'value': 'NF'});
				optionModelState.push({'label': 'NS - Nova Scotia', 'value': 'NS'});
				optionModelState.push({'label': 'NV - Nunavut', 'value': 'NV'});
				optionModelState.push({'label': 'ON - Ontario', 'value': 'ON'});
				optionModelState.push({'label': 'PE - Prince Edward Island', 'value': 'PE'});
				optionModelState.push({'label': 'PQ - Quebec', 'value': 'PQ'});
				optionModelState.push({'label': 'SK - Saskatchewan', 'value': 'SK'});
				optionModelState.push({'label': 'NT - Northwest Territories', 'value': 'NT'});
				optionModelState.push({'label': 'YT - Yukon', 'value': 'YT'});
			} else {
				this.USFin = true;
				this.CANFin = false;
				optionModelState.push({'label': '-- States --', 'value': ''});
				optionModelState.push({'label': 'AL-Alabama', 'value': 'AL'});
				optionModelState.push({'label': 'AK-Alaska', 'value': 'AK'});
				optionModelState.push({'label': 'AZ-Arizona', 'value': 'AZ'});
				optionModelState.push({'label': 'AR-Arkansas', 'value': 'AR'});
				optionModelState.push({'label': 'CA-California', 'value': 'CA'});
				optionModelState.push({'label': 'CO-Colorado', 'value': 'CO'});
				optionModelState.push({'label': 'CT-Connecticut', 'value': 'CT'});
				optionModelState.push({'label': 'DC-Washington D.C.', 'value': 'DC'});
				optionModelState.push({'label': 'DE-Delaware', 'value': 'DE'});
				optionModelState.push({'label': 'FL-Florida', 'value': 'FL'});
				optionModelState.push({'label': 'GA-Georgia', 'value': 'GA'});
				optionModelState.push({'label': 'HI-Hawaii', 'value': 'HI'});
				optionModelState.push({'label': 'ID-Idaho', 'value': 'ID'});
				optionModelState.push({'label': 'IL-Illinois', 'value': 'IL'});
				optionModelState.push({'label': 'IN-Indiana', 'value': 'IN'});
				optionModelState.push({'label': 'IA-Iowa', 'value': 'IA'});
				optionModelState.push({'label': 'KS-Kansas', 'value': 'KS'});
				optionModelState.push({'label': 'KY-Kentucky', 'value': 'KY'});
				optionModelState.push({'label': 'LA-Louisiana', 'value': 'LA'});
				optionModelState.push({'label': 'ME-Maine', 'value': 'ME'});
				optionModelState.push({'label': 'MD-Maryland', 'value': 'MD'});
				optionModelState.push({'label': 'MA-Massachusetts', 'value': 'MA'});
				optionModelState.push({'label': 'MI-Michigan', 'value': 'MI'});
				optionModelState.push({'label': 'MN-Minnesota', 'value': 'MN'});
				optionModelState.push({'label': 'MS-Mississippi', 'value': 'MS'});
				optionModelState.push({'label': 'MO-Missouri', 'value': 'MO'});
				optionModelState.push({'label': 'MT-Montana', 'value': 'MT'});
				optionModelState.push({'label': 'NE-Nebraska', 'value': 'NE'});
				optionModelState.push({'label': 'NV-Nevada', 'value': 'NV'});
				optionModelState.push({'label': 'NH-New Hampshire', 'value': 'NH'});
				optionModelState.push({'label': 'NJ-New Jersey', 'value': 'NJ'});
				optionModelState.push({'label': 'NM-New Mexico', 'value': 'NM'});
				optionModelState.push({'label': 'NY-New York', 'value': 'NY'});
				optionModelState.push({'label': 'NC-North Carolina', 'value': 'NC'});
				optionModelState.push({'label': 'ND-North Dakota', 'value': 'ND'});
				optionModelState.push({'label': 'OH-Ohio', 'value': 'OH'});
				optionModelState.push({'label': 'OK-Oklahoma', 'value': 'OK'});
				optionModelState.push({'label': 'OR-Oregon', 'value': 'OR'});
				optionModelState.push({'label': 'PA-Pennsylvania', 'value': 'PA'});
				optionModelState.push({'label': 'PR-Puerto Rico', 'value': 'PR'});
				optionModelState.push({'label': 'RI-Rhode Island', 'value': 'RI'});
				optionModelState.push({'label': 'SC-South Carolina', 'value': 'SC'});
				optionModelState.push({'label': 'SD-South Dakota', 'value': 'SD'});
				optionModelState.push({'label': 'TN-Tennessee', 'value': 'TN'});
				optionModelState.push({'label': 'TX-Texas', 'value': 'TX'});
				optionModelState.push({'label': 'UT-Utah', 'value': 'UT'});
				optionModelState.push({'label': 'VT-Vermont', 'value': 'VT'});
				optionModelState.push({'label': 'VA-Virginia', 'value': 'VA'});
				optionModelState.push({'label': 'WA-Washington', 'value': 'WA'});
				optionModelState.push({'label': 'WV-West Virginia', 'value': 'WV'});
				optionModelState.push({'label': 'WI-Wisconsin', 'value': 'WI'});
				optionModelState.push({'label': 'WY-Wyoming', 'value': 'WY'});
			}

			this.stateDropdown = optionModelState;
	}*/

	loadAccessCode(value) {
		this.fcicadminService.getAccessCode(value.toUpperCase()).subscribe(data => {
			this.accessresultsetTest = data;

			if (data['result'] === null) {
				this.totalRecord = 0;
				this.accessCodeValue = this.accessvalue.toString();
			} else {
				this.accesscode = data['result']['finCode'][0]['accesscode'];
				this.accessCodeValue = this.accessvalue.toString();
				this.AccessCodelList = [];
				this.AccessCodelList.push(data);
				this.totalRecord = 1;
				if (this.accesscode) {
					const accessCodeList = this.accesscode.split('');
					this.accessVal = '';
					accessCodeList.forEach(item => {
						if (/\d+/.exec(item)) {
							this.accessVal += '<span class="numeric-color"><b>' + item + '</b></span>';
						} else {
							this.accessVal += '<span class="alpha-color"><b>' + item + '</b></span>';
						}
					});
					this.accessVal = '<span><b>Access Code for ' + value + ' is </b></span>' + this.accessVal;
				}
			}
			this.showSpinner = false;
		});
	}

	popupclose(focus) {
		this.displayMessage = false;
		setTimeout(() => {
			if (focus !== undefined)
				focus.nativeElement.focus();
		}, 200);
	}

	submitAccessCode() {
		this.showAccessCodeDetails = false;
		if (this.accessvalue === null || this.accessvalue === '' || this.accessvalue === undefined) {
			this.showErrorMsg('Please Enter a FIN Code.');
			this.homefocus = this.focus1;
		} else {
			this.showAccessCodeDetails = false;
			this.showSpinner = true;
			this.loadAccessCode(this.accessvalue.toString().toUpperCase());
			this.showAccessCodeDetails = true;
			this.showNotAvailable = false;
			this.showFinNotAvailable = false;
			this.showWslDetails = false;
			this.showByFin = false;
			this.userbyvalue = '';
			this.finvalue = '';
			this.selectedType = 'acode';
		}
	}

	submitUserByID() {
		this.showNotAvailable = false;
		if (this.userbyvalue === null || this.userbyvalue === '' || this.userbyvalue === undefined) {
			this.showErrorMsg('Please Enter User ID.');
			this.homefocus = this.focus2;
		} else {
			this.showSpinner = true;
			//this.loadSalutation();
			//this.loadCountry();
			this.loadUserInformation(this.userbyvalue.toString().toUpperCase());
			this.showSpinner = true;
			this.loadFinCode(this.userbyvalue.toString().toUpperCase());
		}

		this.accessvalue = '';
		this.finvalue = '';
		this.selectedType = 'uesl';
	}

	submitUserByFincode() {
		this.showFinNotAvailable = false;
		if (this.finvalue === null || this.finvalue === '' || this.finvalue === undefined) {
			this.showErrorMsg('Please Enter a FIN Code.');
			this.homefocus = this.focus3;
		} else {
			this.showSpinner = true;
			this.loaduserid(this.finvalue.toString().toUpperCase());
			this.showAccessCodeDetails = false;
			this.showWslDetails = false;
			this.showNotAvailable = false;
		}

		this.accessvalue = '';
		this.userbyvalue = '';
		this.selectedType = 'ufin';
	}

	loadUserInformation(value) {
		this.fincodecol = false;
		this.fcicadminService.getUserInformation(value.toUpperCase()).subscribe(data => {
				this.userresultsetTest = JSON.stringify(data);
				if (data['result'] === null || data['result']['userInfo'][0]['status'] !== 'on') {
					this.showByFin = false;
					this.inValidUser = this.userbyvalue;
					this.showNotAvailable = true;
					this.showAccessCodeDetails = false;
					this.showFinNotAvailable = false;
					this.showWslDetails = false;
					this.showsearchDetails = true;
					this.showSpinner = false;
				} else {
					if (data['result']['userInfo']) {
						data['result']['userInfo'].forEach(item => {
							this.wslID = item.wslID;
							this.FirstcustomerName = item.firstName;
							this.MiddlecustomerName = item.middleinitial;
							this.LastcustomerName = item.lastName;
							this.customerEmailAddressText = item.email;
							this.customerPhoneNumber = item.phone;
							this.customerFaxNumber = item.fax;
							this.customerTitle = item.title;
							this.Mail = item.address1PostBox;
							this.Continued = item.address2;
							this.City = item.city;
							this.State = item.state;
							this.Postal = item.postal;
							this.salutationDropdownValue = item.salutation;
							this.countryDropdownValue = item.countryCode;
							this.updateID = item.updateID;
							this.organizationalUnit = item.organizationalUnit;
							this.countryId = item.countryCode;
							this.status = item.status;
							//this.loadState(this.countryId);
						});
						this.showsearchDetails = false;
						this.showNotAvailable = false;
						this.showAccessCodeDetails = false;
						this.showWslDetails = true;
						this.showByFin = false;
					}
				}

			},
			(error: ApiError) => {
				this.errorTest = error;
			});
	}

	userSPS(wslID) {
		if (wslID === null || wslID === '' || wslID === undefined) {
			this.showErrorMsg('Please Enter User ID.');
			this.homefocus = this.focus2;
		} else {
			window.open(this. spsEndpointUser + '?UserId=' + wslID, '_blank');
		}
	}

	fincodeSPS() {
		window.open(this. spsEndpoint , '_blank');
	}

	loaduserid(value) {

		this.displayUserId = value;

		this.usershow = 'View';

		this.fcicadminService.getUserId(value.toUpperCase()).subscribe(userid => {

			if (userid['result'] === null) {
				this.showByFin = false;
				this.showFinNotAvailable = true;
			} else {
				this.finresultset = userid['result']['finCode'];
				this.showByFin = true;
				this.showFinNotAvailable = false;
			}

			this.showSpinner = false;

		});
	}

	loadFinCode(value) {

		this.allFinCodes = null;

		let fincode = [];
		let newVal = value;
		if (value) {
			newVal = value.toUpperCase();
		}



		this.fcicadminService.getFincode(newVal).subscribe(data => {
			this.finCodesTest = data;
			this.finCodes = data['result']['finCode'];
			if (this.finCodes !== null || this.finCodes !== '') {
				let fincodes = [];
				for (const val of data['result']['finCode']) {
					fincodes.push(val.finCode);
				}

				// if (fincodes.length === 1 && this.isAdmin) {
				// 	this.isFinValid = true;
				// } else {
				// 	this.isFinValid = false;
				// }


				if (fincodes && fincodes.length > 0) {
					/*if (!this.isAdmin) {
						this.allFinCodes = fincodes.join(',  ');
					}*/

					this.allFinCodes = fincodes.join(',  ');
				}

			}
			this.fincodecol = true;
			this.showSpinner = false;
		});

	}



/*	clearBack(value) {
		this.showsearchDetails = true;
		this.showAccessCodeDetails = false;
		this.showWslDetails = false;
		this.showByFin = false;
		this.allclear();
		this.showNotAvailable = false;
		this.showFinNotAvailable = false;

		if (value === 'uesl') {
			this.accessvalue = '';
			this.finvalue = '';
			setTimeout(() => {
				if (this.focus2 !== undefined)
					this.focus2.nativeElement.focus();
			}, 200);
		} else if (value === 'ufin') {
			this.accessvalue = '';
			this.userbyvalue = '';
			this.showByFin = true;
			setTimeout(() => {
				if (this.focus3 !== undefined)
					this.focus3.nativeElement.focus();
			}, 200);
		}
	}*/


	clear(value) {
		this.showSpinner = false;
		this.showAccessCodeDetails = false;
		this.showWslDetails = false;
		this.showByFin = false;
		this.allclear();
		this.showsearchDetails = true;
		this.showNotAvailable = false;
		this.showFinNotAvailable = false;

		let focuses;
		if (value === 'acode') {
			this.accessvalue = '';
			focuses = this.focus1;
		} else if (value === 'uesl') {
			this.userbyvalue = '';
			focuses = this.focus2;
		} else if (value === 'ufin') {
			this.finvalue = '';
			focuses = this.focus3;
		} else if (value === 'home') {
			this.accessvalue = '';
			this.userbyvalue = '';
			this.finvalue = '';
			setTimeout(() => {
				if (this.focus1 !== undefined)
					this.focus1.nativeElement.focus();
			}, 200);
		}

		setTimeout(() => {
			if (focuses !== undefined)
				focuses.nativeElement.focus();
		}, 200);
	}

	allclear() {
		this.wslID = '';
		this.FirstcustomerName = '';
		this.MiddlecustomerName = '';
		this.LastcustomerName = '';
		this.customerEmailAddressText = '';
		this.customerPhoneNumber = '';
		this.customerFaxNumber = '';
		this.customerTitle = '';
		this.Mail = '';
		this.Continued = '';
		this.City = '';
		this.State = '';
		this.Postal = '';
		this.salutationDropdownValue = '';
		this.countryDropdownValue = '';
		this.updateID = '';
		this.organizationalUnit = '';
	}

	onChangeWslid(value) {
		this.showSpinner = true;
		this.searchText = '';
		this.allclear();
		this.showWslDetails = false;
		//this.loadSalutation();
		//this.loadCountry()
		this.showsearchDetails = false;
		this.loadUserInformation(value.toUpperCase());
		this.loadFinCode(value.toUpperCase());
		this.showAccessCodeDetails = false;
		this.showWslDetails = true;
		this.showByFin = false;
	}
}
