import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import {CpaMessageMaintenanceService} from '../services/cpa-message-maintenance.service';
import {MessageMaintenanceDetail} from '../models/MessageMaintenanceDetail.model';
import {PersonalIndicatorConstants} from '../common/personalindicator.constants';

@Component({
	selector: 'cpa-msg-maint',
	templateUrl: './cpa-msg-maint.component.html',
	styleUrls: ['./cpa-msg-maint.component.css']
})
export class CpaMsgMaintComponent implements OnInit {

	@ViewChild('focus22',{static:false}) focus22: ElementRef;
	@ViewChild('focus55',{static:false}) focus55: ElementRef;
	@ViewChild('focus66',{static:false}) focus66: ElementRef;

	/**CPA */
	chooseDropdownOptions = [];
	cpaPreviousYear: any;
	cpaMaximumYear: any;
	cpaFromDateMax: any;
	cpaToDateMax: any;
	selectedCpaStatusValue: any;
	chooseModel: any;
	cpaMessage: any;
	cpaFromDate: any;
	cpaToDate: any;

	fimpsIsShown: boolean = false;
	flagToFindCpaUpdate: boolean = false;

	displayMessage: boolean = false;
	errorMessage: any;
	homefocus: ElementRef;
	messageMaintenanceDetail: MessageMaintenanceDetail;
	createdDate: any;
	createdUser: any;
	public Noauthorized: boolean = false;
	public displayPage: boolean = true;


	constructor(public messageMaintenanceService: CpaMessageMaintenanceService) {
		this.cpaPreviousYear = new Date().getFullYear() - 1;
		this.cpaMaximumYear = new Date().getFullYear() + 2;
		this.cpaFromDateMax = new Date(new Date().setFullYear(this.cpaMaximumYear));
		this.cpaToDateMax = new Date(new Date().setFullYear(this.cpaMaximumYear));
		this.selectedCpaStatusValue = "active";

	}

	ngOnInit() {
		this.getCpaMessageDetails();
	}

	cpaClear(): void{
		this.cpaMessage = null;
		this.cpaFromDate = null;
		this.cpaToDate = null;
		this.cpaPreviousYear = new Date().getFullYear()-1;
		this.cpaMaximumYear = new Date().getFullYear() + 2;
		this.cpaFromDateMax = new Date(new Date().setFullYear(this.cpaMaximumYear));
		this.cpaToDateMax = new Date(new Date().setFullYear(this.cpaMaximumYear));
		this.selectedCpaStatusValue = "active";
	}

	cpaSubmit(){
		let cpaEmptyComponentList = [];
		if(this.cpaMessage === null || this.cpaMessage === '' || this.cpaMessage === undefined){
			cpaEmptyComponentList.push(1);
		}
		if(this.cpaFromDate === null || this.cpaFromDate === undefined){
			cpaEmptyComponentList.push(2);
		}

		if(cpaEmptyComponentList.length === 1){
			if(cpaEmptyComponentList[0]=== 1){
				this.showErrorMessage("Please Enter a Message.");
				this.homefocus = this.focus22;
			}
			else{
				this.showErrorMessage("Please Select From Date");
				this.homefocus = this.focus55;
			}
		}
		else if(cpaEmptyComponentList.length > 1){
			this.showErrorMessage("Please Select All the Required Fields.");
			this.homefocus = undefined;
		}
		else{
			if(this.cpaToDate != null && this.cpaToDate != undefined){
				if(this.cpaFromDate > this.cpaToDate){
					this.showErrorMessage("Please Enter End Date Greater than Begin Date.");
					this.homefocus = this.focus66;
				}
				else{
					this.messageMaintenanceDetail = new MessageMaintenanceDetail();
					this.messageMaintenanceDetail.message = this.cpaMessage.split("\n").join("<br />");
					this.messageMaintenanceDetail.beginDate = this.cpaFromDate.getFullYear() + '-' + (this.cpaFromDate.getMonth() + 1) + '-' + this.cpaFromDate.getDate();
					this.messageMaintenanceDetail.endDate = this.cpaToDate.getFullYear() + '-' + (this.cpaToDate.getMonth() + 1) + '-' + this.cpaToDate.getDate();
					this.messageMaintenanceDetail.status = this.selectedCpaStatusValue  === 'active' ? 'Y' : 'N';
					this.messageMaintenanceDetail.createdDate = this.createdDate;
					this.messageMaintenanceDetail.createdUser = sessionStorage.getItem('userId');
					this.messageMaintenanceDetail.lastUpdateUser = sessionStorage.getItem('userId');
					this.messageMaintenanceService.insertCpaMessageDetails(this.messageMaintenanceDetail,this.flagToFindCpaUpdate).subscribe(response =>{

						if(response['result'] != null && response['result']['msgExists'] === "Submitted Successfully" ){
							if(this.flagToFindCpaUpdate){
								this.showErrorMessage("Message Updated Successfully.");
								this.homefocus = undefined;
							}
							else{
								this.showErrorMessage("Message Added Successfully.");
								this.homefocus = undefined;
							}
							this.createdDate = response['result']['savedData']['createdDate'];
							this.createdUser = response['result']['savedData']['createdUser'];
							this.flagToFindCpaUpdate = true;
						}
						else{
							this.showErrorMessage("Failed to Add Message.");
							this.homefocus = undefined;
						}

					});
				}
			}
			else{
				this.messageMaintenanceDetail = new MessageMaintenanceDetail();
				this.messageMaintenanceDetail.message = this.cpaMessage.split("\n").join("<br />");
				this.messageMaintenanceDetail.beginDate = this.cpaFromDate.getFullYear() + '-' + (this.cpaFromDate.getMonth() + 1) + '-' + this.cpaFromDate.getDate();
				this.messageMaintenanceDetail.endDate = this.cpaToDate;
				this.messageMaintenanceDetail.status = this.selectedCpaStatusValue  === 'active' ? 'Y' : 'N';
				this.messageMaintenanceDetail.createdDate = this.createdDate;
				this.messageMaintenanceDetail.createdUser = sessionStorage.getItem('userId');
				this.messageMaintenanceDetail.lastUpdateUser = sessionStorage.getItem('userId');
				this.messageMaintenanceService.insertCpaMessageDetails(this.messageMaintenanceDetail,this.flagToFindCpaUpdate).subscribe(response =>{

					if(response['result'] != null && response['result']['msgExists'] === "Submitted Successfully" ){
						if(this.flagToFindCpaUpdate){
							this.showErrorMessage("Message Updated Successfully.");
							this.homefocus = undefined;
						}
						else{
							this.showErrorMessage("Message Added Successfully.");
							this.homefocus = undefined;
						}
						this.createdDate = response['result']['savedData']['createdDate'];
						this.createdUser = response['result']['savedData']['createdUser'];
						this.flagToFindCpaUpdate = true;
					}
					else{
						this.showErrorMessage("Failed to Add Message.");
						this.homefocus = undefined;
					}

				});
			}
		}
	}

	showErrorMessage(messageToDsiplay){
		this.displayMessage = true;
		this.errorMessage = messageToDsiplay;
	}

	getCpaMessageDetails(){
		this.messageMaintenanceService.getCpaMessageDetails().subscribe(responseDetails =>{
			if((responseDetails['error'] === null ||responseDetails['error'] === undefined)||!(responseDetails['error']['messages'][0] === 'Record Not Found for the input data')){
				this.cpaMessage = responseDetails['result']['getCpaMsg']['message'].split("<br />").join("\n");
				this.cpaFromDate = new Date(responseDetails['result']['getCpaMsg']['beginDate']);
				if(responseDetails['result']['getCpaMsg']['endDate']){
					this.cpaToDate = new Date(responseDetails['result']['getCpaMsg']['endDate']);
				}
				this.selectedCpaStatusValue = responseDetails['result']['getCpaMsg']['status']==="Y"?'active':'inactive';
				this.createdDate = responseDetails['result']['getCpaMsg']['createdDate'];
				this.createdUser = responseDetails['result']['getCpaMsg']['createdUser'];
				this.flagToFindCpaUpdate = true;
			}
		});
	}

	popupclose(focus) {
		this.displayMessage = false;
		setTimeout(() => {
			if(focus !== undefined){
				if (focus.nativeElement !== undefined){
					focus.nativeElement.focus();
				}
				else if(focus.focusViewChild !== undefined){
					focus.focusViewChild.nativeElement.focus();
				}
				else if(focus !== undefined){
					focus.inputfieldViewChild.nativeElement.focus();
				}
			}
		}, 200);
	}

}