import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BatchOasisReportComponent} from './batch-oasis-report/batch-oasis-report.component';
import {FimCpaStmtComponent} from './fim-cpa-stmt/fim-cpa-stmt.component';
import {FcicAdminComponent} from './fcic-admin/fcic-admin.component';
import {RipsComponent} from './rips/rips.component';
import {UpdateProfileComponent} from './update-profile/update-profile.component';
import {FimDetailCpaStmtComponent} from './fim-detail-cpa-stmt/fim-detail-cpa-stmt.component';
import {FimCpaPgmSummaryComponent} from './fim-cpa-pgm-summary/fim-cpa-pgm-summary.component';
import {FimCpaVehiSummaryComponent} from './fim-cpa-vehi-summary/fim-cpa-vehi-summary.component';
import {FimStmtDefComponent} from './fim-stmt-def/fim-stmt-def.component';
import {RipsStatementReportComponent} from './rips-stmt-report/rips-stmt-report.component';
import {CpaLookupComponent} from './cpa-lookup/cpa-lookup.component';
import {CpaLookupSearchresultComponent} from './cpa-lookup-searchresult/cpa-lookup-searchresult.component';
import {FkcKeycodeComponent} from './fkc-keycode/fkc-keycode.component';
import {FkcKeycodeReportComponent} from './fkc-keycode-report/fkc-keycode-report.component';
import {LoginComponent} from './login/login.component';
import {OAuthCallbackComponent} from './oauth-callback.component';
import {NfppAdminComponent} from './nfpp-admin/nfpp-admin.component';
import {FimsSearchComponent} from './fimps-search/fimps-search.component';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {AuthGuardService} from './auth-guard.service';
import {FkcHomepageComponent} from './fkc-home/fkc-homepage.component';
import {LevelsOfApprovalPageComponent} from './levelsOfAuthorization/levels-of-approval-page.component';
import {FkcRequestApprovalComponent} from './fkc-request-approval/fkc-request-approval.component';
import {FleetAdminComponent} from './fleet-admin/fleet-admin.component';
import {RipsSearchComponent} from './rips-search/rips-search.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {MsgMaintComponent} from './msg-maint/msg-maint.component';
import {CpaMsgMaintComponent} from './cpa-msg-maint/cpa-msg-maint.component';
import {CookieHomeComponent} from './cookie-home/cookie-home.component';
import {AccessCodeComponent} from './access-code/access-code.component';
import {FinIndicatorComponent} from './fin-indicator/fin-indicator.component';
import {FinMasterComponent} from './fin-master/fin-master.component';
//import { MarketSegmentComponent } from './market-segment/market-segment.component';
import {PtsFileUploadComponent} from './pts-file-upload/pts-file-upload.component';
import {FinIndicatorListComponent} from './fin-indicator-list/fin-indicator-list.component';
import {UserCookieHomeComponent} from './user-cookie-home/user-cookie-home.component';
import {FinMarkedListComponent} from './marked-fin-list/marked-fin-list.component';
import {PersonalEntitlementListComponent} from './personal-entitlement-list/personal-entitlement-list.component';
import { NamDetailsComponent } from './nam-details/nam-details.component';
//import { FinIndicatorListComponent } from './fin-entitlement-list/fin-entitlement-list.component';
import { EntitlementListComponent } from './entittlements-list/entitlement-list.component';
import {FinEntitilementListComponent} from './fin-entitlement-list/fin-entitilement-list.component';
import {ConsolidatedReportComponent} from './consolidated-report/consolidated-report.component';
import { FinNamDetailsComponent } from './fin-nam-details/fin-nam-details.component';
import { SummaryNamDetailsComponent } from './summary-nam-details/summary-nam-details.component';
import { FleetAuditReportComponent } from './fleet-audit-report/fleet-audit-report.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: '/login',
		pathMatch: 'full'
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'oauth-callback',
		component: OAuthCallbackComponent
	},
	{
		path: 'oauth-callback/:fromsite',
		component: OAuthCallbackComponent
	},
	{
		path: 'oauth-callback/:fromsite/:site/:language',
		component: OAuthCallbackComponent
	},
	{
		path: 'oauth-callback/:fromsite/:namId/:reqId/:userType',
		component: OAuthCallbackComponent
	},
	{
		path: 'dashboard',
		component: DashboardComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fcic-admins',
		component: AccessCodeComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'profile-update',
		component: UpdateProfileComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'profile-update/:site/:language',
		component: UpdateProfileComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'batch-oasis-report',
		component: BatchOasisReportComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fimps-search',
		component: FimsSearchComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fimps-cpa-stmt',
		component: FimCpaStmtComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fimps-detail-cpa-stmt',
		component: FimDetailCpaStmtComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fimps-cpa-pgm-summary',
		component: FimCpaPgmSummaryComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fimps-cpa-vehi-summary',
		component: FimCpaVehiSummaryComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fimps-cpa-stmt-def',
		component: FimStmtDefComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'rips-stmt-report',
		component: RipsStatementReportComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'rips-search',
		component: RipsSearchComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'rips',
		component: RipsComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'cpa-lookup',
		component: CpaLookupComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'cpa-lookup-result',
		component: CpaLookupSearchresultComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fkc-keycode',
		component: FkcKeycodeComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fkc-keycode-reports',
		component: FkcKeycodeReportComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fkc-homepage',
		component: FkcHomepageComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fkc-request',
		component: LevelsOfApprovalPageComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fkc-approval/:namId/:reqId/:userType',
		component: FkcRequestApprovalComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'nfpp-admin',
		component: NfppAdminComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'unauthorized',
		component: UnauthorizedComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fleet-admin',
		component: FleetAdminComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'msg-maint',
		component: MsgMaintComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'cpa-msg-maint',
		component: CpaMsgMaintComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fcic-admin',
		component: FcicAdminComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fin-access-security-update',
		component: FinIndicatorComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fin-access-security-master',
		component: FinMasterComponent,
		canActivate: [AuthGuardService]
	},
	/*{
		path: 'market-segment',
		component: MarketSegmentComponent,
		canActivate: [AuthGuardService]
	},*/
	{
		path: 'cookie-home',
		component: CookieHomeComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'pacode-upload',
		component: PtsFileUploadComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fin-admin',
		component: FinIndicatorListComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'user-cookie-home',
		component: UserCookieHomeComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fin-marked-list',
		component: FinMarkedListComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'personal-entitlement-list',
		component: PersonalEntitlementListComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'nam-details',
		component: NamDetailsComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'entitlement-list',
		component: EntitlementListComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fin-entitlement',
		component: FinEntitilementListComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'consolidated-report',
		component: ConsolidatedReportComponent
	},
	{
		path: 'fin-nam-details',
		component: FinNamDetailsComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'summary-nam-details',
		component: SummaryNamDetailsComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'fleet-audit-report',
		component: FleetAuditReportComponent,
		canActivate: [AuthGuardService]
	}

];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: []
})
export class AppRoutingModule {
}

