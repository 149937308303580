import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
	selector: '[NumericSymbol]'
})
export class NumericSymbolDirective {
	@Output() ngModelChange: EventEmitter<any> = new EventEmitter();
	constructor() {
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(e: KeyboardEvent) {
			if (
			[46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
			(e.keyCode === 65 && e.ctrlKey === true) ||
			(e.keyCode === 67 && e.ctrlKey === true) ||
			(e.keyCode === 86 && e.ctrlKey === true) ||
			(e.keyCode === 88 && e.ctrlKey === true) ||
			(e.keyCode === 65 && e.metaKey === true) ||
			(e.keyCode === 67 && e.metaKey === true) ||
			(e.keyCode === 86 && e.metaKey === true) ||
			(e.keyCode === 88 && e.metaKey === true) ||
			(e.keyCode >= 35 && e.keyCode <= 39)
		) {
			return;
		}
		if (e.keyCode === 189 && !e.shiftKey ) {
				return;
		}

		if ((e.keyCode === 48 && e.shiftKey) || (e.keyCode === 57 && e.shiftKey ) ) {
			return;
		}

		if (
			(e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
			(e.keyCode < 96 || e.keyCode > 105)
		) {
			e.preventDefault();
		}
	}

	@HostListener('paste', ['$event'])
	onPaste(event) {
		const oldValue = event.currentTarget.value;
		const regex = new RegExp('^[0-9)(-]+$');

		const clipboardData = event.clipboardData || window['clipboardData'];
		const value: string = clipboardData.getData('Text').trim();
		if (value) {
			const currVal = oldValue.slice(0, event.currentTarget.selectionStart) + value + oldValue.slice(event.currentTarget.selectionEnd);
			if (!regex.test(currVal)) {
				event.preventDefault();
			} else {
				this.ngModelChange.emit(currVal);
				event.preventDefault();
			}
		} else {
			event.preventDefault();
			event.stopPropagation();
		}
	}
}
