import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DatePipe} from '@angular/common';
import {DropdownItem} from '../models/dropdownItem';
import {PersonalEntitlementService} from '../services/personal-entitlement.service';
import {PersonEntitlementArray} from '../models/PersonEntitlementArray.model';
import {ErrorResponseData} from '../models/ErrorResponseData';
import { SelectItem } from 'primeng/api';
import { CountryEnum } from '../../app/common/countryenum.model';
import { saveAs } from 'file-saver';

@Component({
	selector: 'app-personal-entitlement-list',
	templateUrl: './personal-entitlement-list.component.html',
	styleUrls: ['./personal-entitlement-list.component.css']
})
export class PersonalEntitlementListComponent implements OnInit {
	personalEntitlementList: DropdownItem[];
	todayDate = new Date();
	toDate: string;
	dealerReportData: any[];
	fleetUserReportData: any[];
	internalUserReportData: any[];
	showDealerReportData = false;
	showFleetUserReportData = false;
	showInternalUserReportData = false;
	showReport = false;
	cols: any[];
	showSpinner = false;
	displayMessage: boolean;
	selectedEntitlement: any[];
	errorResponse: ErrorResponseData;
	errorMessage: string;
	Pagerows: number = 10;
	fleetPagerows: number = 10;
	internalPagerows: number = 10;
	report: string;
	inputRows: number;
	fleetInputRows: number;
	internalInputRows: number;
	pageOptionFleet = [];
	pageOptionInternal = [];
	pageOptionDealer = [];
	countryCodes: SelectItem[];
	selectedCountryCode : any[];
	isCountryCode : boolean;

	constructor(private datePipe: DatePipe, public personEntitlement: PersonalEntitlementService) {
		this.toDate = this.datePipe.transform(this.todayDate, 'MM/dd/yyyy');
	}

	@ViewChild("countrydropdown", { read: ElementRef,static:false }) tref: ElementRef;
	@ViewChild("personaldropdown", { read: ElementRef,static:false }) personalref: ElementRef;

	ngOnInit() {
		this.cols = [
			{field: 'userId', header: 'User ID'},
			{field: 'firstName', header: 'User First Name'},
			{field: 'lastName', header: 'User Last Name'},
			{field: 'personalEntitlementDesc', header: 'Personal Entitlement'},
			{field: 'countryCode', header: 'User\'s Country Code'},
			{field: 'lastLogon', header: 'Last Logon Date'},
			{field: 'pwdLastSet', header: 'Last Password Reset Date'},
		];
		this.pageOptionDealer = [10, 15];
		this.pageOptionFleet = [10, 15];
		this.pageOptionInternal = [10, 15];
		this.loadPersonEntitlement();
		this.loadCountryCodes();
		this.isCountryCode = true;
	}

	showAll() {
		var h = this.tref.nativeElement;
		var text = document.createTextNode("All");
		h.childNodes[0].lastChild.lastChild.childNodes[1].childNodes[1].after(text);
	}

	personalshowAll() {
		var i = this.personalref.nativeElement;
		var text = document.createTextNode("All");
		i.childNodes[0].lastChild.lastChild.childNodes[1].childNodes[1].after(text);
	}

	loadPersonEntitlement() {
		this.showSpinner = true;
		this.personEntitlement.getPersonalEntitlementList().subscribe(data => {
			const options = [];
			if (data['result'] === null && data['error'] !== null) {
				this.errorResponse = new ErrorResponseData(data);
				this.showErrorMsg('Error: Method ' + this.errorResponse.error.messages[0]);
				this.showSpinner = false;
				return;
			}
			if (data['result']['personalEntitlementApi']['personalEntitlementList'].length > 0) {
				for (const val of data['result']['personalEntitlementApi']['personalEntitlementList']) {
					options.push({'label': val.RoleDescription, 'value': val.RoleName});
				}
				const sortedArray = options.sort(function (a, b) {
					return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
				});
				this.personalEntitlementList = sortedArray;
				this.showSpinner = false;
			}

			this.showSpinner = false;
		});
	}

	loadCountryCodes() {
		this.countryCodes = [
			{label: 'CAN', value:CountryEnum.CANADA },
			{label: 'USA', value:CountryEnum.USA},
			{label: 'Others', value:CountryEnum.OTHERS}
		];
	}

	onchangeCountryCode(code) {
		console.log("afs code ", code)
		this.selectedEntitlement = null;
		this.showReport = false;
		if(code.length === 0) {
			this.isCountryCode = true;
			return;
		} else {
			this.isCountryCode = false;
			this.showReport = false;
		}
	}

	onchangePersonalEntitlement(entitlements) {
		this.showReport = false;
	}


	getDownloadExcel() {
		const personalEntitlement: PersonEntitlementArray = {
			personalEntitlementList: this.selectedEntitlement,
			countryCode: this.selectedCountryCode
		};
		this.showSpinner = true;
		this.personEntitlement.getDownloadExcel(personalEntitlement).subscribe(data => {
			if (data['result'] !== null) {
				this.downloadFile(data);
			} else {
				this.errorResponse = new ErrorResponseData(data);
				this.showErrorMsg(this.errorResponse.error.messages[0]);
			}
			this.showSpinner = false;
		});
	}

	loadReport() {
		const personalEntitlement: PersonEntitlementArray = {
			personalEntitlementList: this.selectedEntitlement,
			countryCode: this.selectedCountryCode
		};
		if (personalEntitlement.countryCode === undefined || personalEntitlement.countryCode == null ) {
			this.showErrorMsg('Please select at least one Country Code to view the report');
			return;
		}
		if (personalEntitlement.personalEntitlementList === undefined || personalEntitlement.personalEntitlementList == null
			|| personalEntitlement.personalEntitlementList.length == 0) {
			this.showErrorMsg('Please select at least one Personal Entitlement to view the report');
			return;
		}

		this.showSpinner = true;
		this.personEntitlement.getPersonalEntitlementReport(personalEntitlement).subscribe(data => {
			if (data['result'] === null && data['error'] !== null) {
				this.errorResponse = new ErrorResponseData(data);
				this.showErrorMsg(this.errorResponse.error.messages[0]);
				this.showReport = false;
				this.showSpinner = false;
				return;
			}

			const personalReport = data['result']['lookupResponseParamMap'];
			if (personalReport['Dealer'] !== null && personalReport['Dealer'] !== undefined) {
				this.dealerReportData = personalReport['Dealer'];
				this.showDealerReportData = true;
				this.showReport = true;
			} else {
				this.showDealerReportData = false;
			}

			if (personalReport['Fleet User'] !== null && personalReport['Fleet User'] !== undefined) {
				this.fleetUserReportData = personalReport['Fleet User'];
				this.showFleetUserReportData = true;
				this.showReport = true;
			} else {
				this.showFleetUserReportData = false;
			}

			if (personalReport['Ford Internal User'] !== null && personalReport['Ford Internal User'] !== undefined) {
				this.internalUserReportData = personalReport['Ford Internal User'];
				this.showInternalUserReportData = true;
				this.showReport = true;
			} else {
				this.showInternalUserReportData = false;
			}

			if (data['result'] === null && data['error'] !== null) {
				this.errorResponse = new ErrorResponseData(data);
				this.showErrorMsg(this.errorResponse.error.messages[0]);
			}
			this.showSpinner = false;
		});
		this.paginateValues();
	}

	downloadFile(data: any) {
		const fileName = 'PersonalEntitlement.xlsx';
		const ua: string = window.navigator.userAgent;
		const windowsBrowserRegex = /Trident|MSIE|Edge/;
		const isIE = ua.match(windowsBrowserRegex);
		const blob = new Blob([data], {type: 'application/pdf'});
		const a = document.createElement('a');
		a.href = URL.createObjectURL(blob);
		if (isIE) {
			saveAs.msSaveOrOpenBlob(blob, fileName);
		} else {
			a.setAttribute('target', '_blank');
			a.download = fileName;
			a.click();
		}

		a.remove();
	}

	showErrorMsg(errorMsg) {
		this.displayMessage = true;
		this.errorMessage = errorMsg;
	}

	clearReport() {
		this.selectedEntitlement = [];
		this.showReport = false;
		this.paginateValues();
		this.isCountryCode = true;
		this.selectedCountryCode = null;
	}

	closeMessage() {
		this.displayMessage = false;
	}

	changeRecords(rows, reportType) {
		this.report = reportType;
		if (rows == null) {
			return;
		} else {
			let col = [];
			if (this.report === 'DEALER') {
				const inputValueDealer = Number(rows);
				this.Pagerows = inputValueDealer;
				this.inputRows = inputValueDealer;
				this.pageOptionDealer.push(inputValueDealer);
				col = this.pageOptionDealer.filter((item, i, arr) => {
					return arr.indexOf(arr.find(t => t === item)) === i;
				});
				this.pageOptionDealer = col.sort((a, b) => a - b);
			}
			if (this.report === 'FLEET') {
				const inputValueFleet = Number(rows);
				this.fleetPagerows = inputValueFleet;
				this.fleetInputRows = inputValueFleet;
				this.pageOptionFleet.push(inputValueFleet);
				col = this.pageOptionFleet.filter((item, i, arr) => {
					return arr.indexOf(arr.find(t => t === item)) === i;
				});
				this.pageOptionFleet = col.sort((a, b) => a - b);
			}
			if (this.report === 'INTERNAL') {
				const inputValueInternal = Number(rows);
				this.internalPagerows = inputValueInternal;
				this.internalInputRows = inputValueInternal;
				this.pageOptionInternal.push(inputValueInternal);
				col = this.pageOptionInternal.filter((item, i, arr) => {
					return arr.indexOf(arr.find(t => t === item)) === i;
				});
				this.pageOptionInternal = col.sort((a, b) => a - b);
			}

		}
	}

	paginate(event) {
		const el = document.getElementById('#' + event);
		el.scrollIntoView();
	}

	paginateValues() {
		this.inputRows = null;
		this.Pagerows = 10;
		this.fleetInputRows = null;
		this.fleetPagerows = 10;
		this.internalInputRows = null;
		this.internalPagerows = 10;
	}
}
