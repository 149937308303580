import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';


@Injectable()
export class NfppAdminService {
	_accessToken: string = sessionStorage.getItem('encodedAccessToken');
	fwsUrl = environment.APIEndpoint;
	userId = sessionStorage.getItem('userId');
	finEligibility = environment.finEligibility;

	private options = {
		headers: new HttpHeaders({
			'Authorization': 'Bearer ' + this._accessToken,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'origin': window.location.origin
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient) {
	}

	downloadExcelForNFPPAdmin(type) {
		const headers = new HttpHeaders({
			'authorization': 'bearer ' + this._accessToken
		});
		return this.http.get(this.finEligibility + 'api/nfpp/' + type, { headers: headers, responseType: 'blob'} );
	}

}
